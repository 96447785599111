<template>
	<div>
		<asignacion-modals :modelName="modelName"></asignacion-modals>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:parentModelName="parentModelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:showCreateBtn="false"
			:showDeleteBtn="true"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
		></pui-datatable>
	</div>
</template>
<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import AsignacionActions from './AsignacionActions';
import AsignacionModals from './AsignacionModals';

export default {
	name: 'asignaciongrid',
	mixins: [PuiGridDetailMixin],
	components: { AsignacionModals },
	data() {
		return {
			modelName: 'asignacion',
			actions: AsignacionActions.gridactions,
			columnDefs: {
				fechainicio: { withoutHours: true },
				fechafin: { withoutHours: true }
			}
		};
	},
	created() {
		if (this.masterDetail) {
			let thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel) {
				thisModel.fromGrid = true;
			}
		}
	},
	methods: {
		isEditableRegistry(registry) {
			return !this.masterDetail ? false : registry.indlibramientosact !== '1';
		}
	}
};
</script>

<style lang="postcss"></style>

<i18n>
{
    "en": {
		"grid.asignacion.idasignacion": "Authorization code",
		"grid.asignacion.idlote": "Lot id",
        "grid.asignacion.idtransportista": "Carrier code No",
        "grid.asignacion.transportista": "Carrier",
        "grid.asignacion.fechainicio": "Start date",
		"grid.asignacion.fechafin": "End date",
		"grid.asignacion.idasignacion": "Assignment code",
		"grid.asignacion.idtransportista": "Carrier code",
		"grid.asignacion.transportista":"Carrier",
		"grid.asignacion.pesoasignado":"Assigned weight (kg)",
		"grid.asignacion.idencargo": "Order code",
		"grid.asignacion.idlote": "Código lote",
		"grid.asignacion.pesolote":"Lots weight (kg)",
		"grid.asignacion.indlibramientos":"Ind. Have deliverance",
		"grid.asignacion.libramientos":"Generadted deliverance",
		"grid.asignacion.fechacreacion":"Creation date"
    },
    "es": {
        "grid.asignacion.idasignacion": "Código autorización",
        "grid.asignacion.idtransportista": "Código transportista",
        "grid.asignacion.transportista": "Transportista",
        "grid.asignacion.fechainicio": "Fecha de inicio",
		"grid.asignacion.fechafin": "Fecha de fin",
		"grid.asignacion.idasignacion": "Código asignacion",
		"grid.asignacion.idtransportista": "Código transportista",
		"grid.asignacion.transportista":"Transportista",
		"grid.asignacion.pesoasignado":"Peso asignado (kg)",
		"grid.asignacion.idencargo": "Código encargo",
		"grid.asignacion.idlote": "Código lote",
		"grid.asignacion.pesolote":"Peso lote (kg)",
		"grid.asignacion.indlibramientos":"Ind. Tiene libramientos",
		"grid.asignacion.libramientos":"Libramientos generados",
		"grid.asignacion.fechacreacion":"Fecha creación"

    },
    "ca": {
        "grid.asignacion.idasignacion": "Codi autorització",
        "grid.asignacion.idtransportista": "Codi transportista",
        "grid.asignacion.transportista": "Transportista",
        "grid.asignacion.fechainicio": "Data inici",
		"grid.asignacion.fechafin": "Data fi",
		"grid.asignacion.idasignacion": "Codi assignació",
		"grid.asignacion.idtransportista": "Codi transportista",
		"grid.asignacion.transportista":"Transportista",
		"grid.asignacion.pesoasignado":"Pes assignat (kg)",
		"grid.asignacion.idencargo": "Codi encàrrec",
		"grid.asignacion.idlote": "Código lote",
		"grid.asignacion.pesolote":"Pes lote (kg)",
		"grid.asignacion.indlibramientos":"Ind. Té lliuraments",
		"grid.asignacion.libramientos":"Lliuraments generats",
		"grid.asignacion.fechacreacion":"Data creació"
    }
}
</i18n>
/** FORM: AsignacionEncargoForm**/ /*conf comprador oculto en el clob pui_model:
"columns":[{"name":"idcomprador","title":"vtcasignaciones.idcomprador","type":"numeric","data":"idcomprador","visibility":"hidden"}],*/
