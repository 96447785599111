const disableMotivoAnulRechAction = {
	id: 'disableMotivoAnulRechAction',
	selectionType: 'single',
	label: 'motivoanulrech.disableReason',
	functionality: 'MTO_MOTIVO',
	checkAvailability: function(user) {
		if (user[0] && user[0].indbaja === '0') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-date-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, disableDate => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);

			var dataRegistry = registries[0];
			var idmotivo = dataRegistry.idmotivo;
			var urlController = model.url.disableReason + '?idmotivo=' + idmotivo;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = { date: disableDate };

			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

const enableMotivoAnulRechAction = {
	id: 'enableMotivoAnulRechAction',
	selectionType: 'single',
	label: 'motivoanulrech.enableReason',
	functionality: 'MTO_MOTIVO',
	checkAvailability: function(user) {
		if (user[0] && user[0].indbaja === '1') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var idmotivo = dataRegistry.idmotivo;
			var urlController = model.url.enableReason + '?idmotivo=' + idmotivo;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = {};
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

export default {
	gridactions: [enableMotivoAnulRechAction, disableMotivoAnulRechAction],
	formactions: [enableMotivoAnulRechAction, disableMotivoAnulRechAction]
};
