import { render, staticRenderFns } from "./ConsultarecibidaModals.vue?vue&type=template&id=7270147f&scoped=true&"
import script from "./ConsultarecibidaModals.vue?vue&type=script&lang=js&"
export * from "./ConsultarecibidaModals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7270147f",
  null
  
)

/* custom blocks */
import block0 from "./ConsultarecibidaModals.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VForm,VLayout})
