<template>
	<div>
		<encargo-term-modals :modelName="model"></encargo-term-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import EncargoTermActions from './EncargoTermActions';
import EncargoTermModals from './EncargoTermModals';

export default {
	name: 'encargotermgrid',
	components: { EncargoTermModals },
	data() {
		return {
			model: 'encargoterm',
			actions: EncargoTermActions.gridactions,
			columnDefs: {
				estado: {
					createdCell: (td, cellData, rowData, row, col) => {
						if (rowData.codestado === 'EL' && td.parentNode) {
							td.parentNode.classList.add('highlocaliza');
						}
					}
				},
				fecharecogida: { withoutHours: true }
			}
		};
	},
	computed: {
		getEstadoER() {
			return 'ER';
		}
	}
};
</script>

<style lang="postcss">
.highlocaliza {
	color: rgb(26, 123, 58);
	background-color: rgba(188, 240, 206, 0.8) !important;
	//font-weight: bold;
}
</style>

<i18n>
{
    "en": {
        "grid.encargoterm.idencargo": "Order code",
		"grid.encargoterm.idcomprador": "Buyer code",
		"grid.encargoterm.comprador": "Buyer",
        "grid.encargoterm.contrato": "Contract number",
        "grid.encargoterm.aplicacion": "Application code",
		"grid.encargoterm.referencia": "Reference",
		"grid.encargoterm.fecharecogida": "Pick-up date",
		"grid.encargoterm.idterminal": "Terminal code",
		"grid.encargoterm.terminal": "Terminal",
		"grid.encargoterm.idmercancia": "Good code",
		"grid.encargoterm.mercancia": "Good",
		"grid.encargoterm.peso": "Weight(kg)",
		"grid.encargoterm.proveedor": "Supplier code",
		"grid.encargoterm.proveedor": "Supplier",
		"grid.encargoterm.estado": "State",
		"grid.encargoterm.codestado": "State code",
		"grid.encargoterm.kiloslocalizados": "Localized weight",
		"grid.encargoterm.refusemotivo":"Reason for refusal",
		"grid.encargoterm.fechacreacion":"Creation date",
		"grid.encargoterm.fecenvioterminal": "Fecha envío terminal"

    },
    "es": {
        "grid.encargoterm.idencargo": "Código encargo",
		"grid.encargoterm.idcomprador": "Código comprador",
		"grid.encargoterm.comprador": "Comprador",
        "grid.encargoterm.contrato": "Número de contrato",
        "grid.encargoterm.aplicacion": "Código de aplicación",
		"grid.encargoterm.referencia": "Referencia",
		"grid.encargoterm.fecharecogida": "Fecha de recogida",
		"grid.encargoterm.idterminal": "Código terminal",
		"grid.encargoterm.terminal": "Terminal",
		"grid.encargoterm.idmercancia": "Código mercancía",
		"grid.encargoterm.mercancia": "Mercancía",
		"grid.encargoterm.peso": "Peso (kg)",
		"grid.encargoterm.kiloslocalizados": "Peso localizado (kg)",
		"grid.encargoterm.proveedor": "Código proveedor",
		"grid.encargoterm.proveedor": "Proveedor",
		"grid.encargoterm.estado": "Estado",
		"grid.encargoterm.codestado": "Código Estado",
		"grid.encargoterm.refusemotivo":"Motivo de rechazo",
		"grid.encargoterm.fechacreacion":"Fecha creación",
		"grid.encargoterm.fecenvioterminal": "Fecha envío terminal"
    },
    "ca": {
        "grid.encargoterm.idencargo": "Codi encàrrec",
		"grid.encargoterm.idcomprador": "Codi comprador",
		"grid.encargoterm.comprador": "Comprador",
        "grid.encargoterm.contrato": "Número de contracte",
        "grid.encargoterm.aplicacion": "Code d’aplicació",
		"grid.encargoterm.referencia": "Referència",
		"grid.encargoterm.fecharecogida": "Data de recollida",
		"grid.encargoterm.idterminal": "Codi terminal",
		"grid.encargoterm.terminal": "Terminal",
		"grid.encargoterm.idmercancia": "Codi mercaderia",
		"grid.encargoterm.mercancia": "Mercaderia",
		"grid.encargoterm.peso": "Pes (kg)",
		"grid.encargoterm.kiloslocalizados": "Pes localitzat (kg)",
		"grid.encargoterm.proveedor": "Codi proveïdor",
		"grid.encargoterm.proveedor": "Proveïdor",
		"grid.encargoterm.codestado": "Codi estat",
		"grid.encargoterm.estado": "Estat",
		"grid.encargoterm.anularmotivo":"Motiu de rebuig",
		"grid.encargoterm.fechacreacion":"Data creació",
		"grid.encargoterm.fecenvioterminal": "Data enviament terminal"
    }
}
</i18n>
