const rejectInquiryAction = {
	id: 'rejectInquiryAction',
	selectionType: 'single',
	label: 'consulta.rejectinquiry',
	functionality: 'CONSULTA_RECHAZAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado !== 'CR' && selected[0].codestado !== 'CA') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-refusemotivo-' + model.name + '-show', row);
	}
};

const generateResponseLotAction = {
	id: 'generateResponseLotAction',
	selectionType: 'single',
	label: 'consulta.generateLot',
	functionality: 'MTO_LOTE',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado === 'CS' && (selected[0].codestadoenc === 'ES' || selected[0].codestadoenc === 'ET')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const childTarget = 'lotegenerado';
		const modelChild = this.$store.getters.getModelByName(childTarget);
		modelChild.parentModelPk = {};
		modelChild.parentModelName = model.name;
		modelChild.fecharecogida = registries[0].fecharecogida;

		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				modelChild.parentModelPk[column.name] = registries[0][column.name];
			}
		}

		const params = {
			router: this.$router,
			url: '/' + childTarget + '/create/new',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};
const finalizeInquiryAction = {
	id: 'finalizeInquiryAction',
	selectionType: 'single',
	label: 'consulta.finishinquiry',
	functionality: 'CONSULTA_FINALIZAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado === 'CS' && selected[0].indtienesubconsulta === '1') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const data = {};
		data.messageBody = 'finish';
		data.object = registries[0];
		let urlController = this.$store.getters.getModelByName(model.name).url.tienelotes + '?idconsulta=' + data.object.idconsulta;
		this.$puiRequests.getRequest(
			urlController,
			null,
			response => {
				if (!response.data) {
					data.messageBody += '.lotes';
				} else if (data.object.pesolocalizado == null || data.object.pesolocalizado !== data.object.peso) {
					data.messageBody += '.peso';
				}
				finish();
			},
			() => {}
		);

		const finish = () => {
			this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', data);
			this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
				this.$puiEvents.$off('pui-action-runAction-' + model.name);
				var dataRegistry = registries[0];
				var idconsulta = dataRegistry.idconsulta;
				var urlController = model.url.finish;
				var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
				var params = { idconsulta: idconsulta };
				this.$puiRequests.patchRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
						this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
						var message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
					},
					e => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
						var message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiNotify.error(message, title);
					}
				);
			});
		};
	}
};

const generateSubInquiryAction = {
	id: 'generateSubInquiryAction',
	selectionType: 'single',
	label: 'consulta.generateSubInquiry',
	functionality: 'CONSULTA_SUBCONSULTA',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado === 'CS') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const childTarget = 'consultarealizada';
		const modelChild = this.$store.getters.getModelByName(childTarget);
		modelChild.parentModelPk = {};
		modelChild.parentModelName = model.name;

		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				modelChild.parentModelPk[column.name] = registries[0][column.name];
			}
		}
		const params = {
			router: this.$router,
			url: '/' + childTarget + '/create/new',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [rejectInquiryAction, finalizeInquiryAction, generateResponseLotAction, generateSubInquiryAction],
	formactions: [rejectInquiryAction, finalizeInquiryAction, generateResponseLotAction, generateSubInquiryAction]
};
