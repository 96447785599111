<template>
	<div class="puntocarga puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('vtcpuntocarga.codigo')" :value="getCodigo"></pui-field>
			</v-flex>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('vtcpuntocarga.descripcion')" :value="getDescripcion"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'puntocargaformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'puntocarga'
		};
	},
	computed: {
		getCodigo() {
			return this.model && this.model.codigo ? this.model.codigo : this.model && this.model.codpuntocarga ? this.model.codpuntocarga : '';
		},
		getDescripcion() {
			return this.model && this.model.descripcion ? this.model.descripcion : this.model && this.model.puntocarga ? this.model.puntocarga : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'puntocarga';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"vtcpuntocarga.descripcion": "Loading point",
		"vtcpuntocarga.codigo": "Code"
	},
	"es": {
		"vtcpuntocarga.descripcion": "Punto de carga",
		"vtcpuntocarga.codigo": "Código"
	},
	"ca": {
		"vtcpuntocarga.descripcion": "Punt de càrrega",
		"vtcpuntocarga.codigo": "Codi"
	}
}
</i18n>
