<template>
	<div>
		<libramiento-termc-modals :modelName="model"></libramiento-termc-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import LibramientoTermcActions from './LibramientoTermcActions';
import LibramientoTermcModals from './LibramientoTermcModals';

export default {
	name: 'libramientotermcgrid',
	components: { LibramientoTermcModals },
	data() {
		return {
			model: 'libramientotermc',
			actions: LibramientoTermcActions.gridactions,
			columnDefs: {
				mercancia: {
					createdCell: (td, cellData, rowData, row, col) => {
						if (rowData.color === '1' && td.parentNode) {
							td.parentNode.classList.add('highlight1');
						}
					}
				},
				fecharecogida: {
					withoutHours: true
				}
			},
			hourRules: [v => this.validateHourRules(v) || this.$t('grid.libramientotermc.modal.validate.hour')]
		};
	},
	methods: {
		validateHourRules(v) {
			// eslint-disable-next-line
			var re = /^(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[12345][0-9])$/;
			return re.test(v);
		}
	},
	computed: {
		getEstadoLR() {
			return 'LR';
		}
	}
};
</script>

<style lang="postcss">
.highlight1 {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	//font-weight: bold;
}
</style>

<i18n>
{
    "en": {
		"grid.libramientotermc.idlibramiento": "Code",
	    "grid.libramientotermc.idmercancia": "Good code",
	    "grid.libramientotermc.mercancia": "Good",
	    "grid.libramientotermc.pesosolicitado": "Weight(kg)",
	    "grid.libramientotermc.fecharecogida": "Pick-up date",
	    "grid.libramientotermc.fechainicio": "Date slot beginnig",
	    "grid.libramientotermc.fechafin": "Date slot end",
	    "grid.libramientotermc.horainicio": "Time slot beginnig",
	    "grid.libramientotermc.horafin": "Time slot end",
	    "grid.libramientotermc.horarecogida": "Pick up time",
	    "grid.libramientotermc.idtransportista": "Carrier code",
	    "grid.libramientotermc.transportista": "Carrier",
	    "grid.libramientotermc.idpuntocarga": "Loading point code",
	    "grid.libramientotermc.puntocarga": "Loading point",
	    "grid.libramientotermc.idconductor": "Driver code",
	    "grid.libramientotermc.conductor": "Driver",
	    "grid.libramientotermc.nifconductor": "ID Number Driver",
	    "grid.libramientotermc.matricula": "Plate",
	    "grid.libramientotermc.remolque": "Trailer Plate",
	    "grid.libramientotermc.indpcmodificado": "Check updated loading point",
	    "grid.libramientotermc.pcmodificado": "Updated loading point",
	    "grid.libramientotermc.estado": "State",
	    "grid.libramientotermc.codestado": "State code",
	    "grid.libramientotermc.idterminalcarga": "Loading terminal code",
		"grid.libramientotermc.idterminalgestora": "Management terminal code",
		"grid.libramientotermc.authorize.title":"Authorize riddance",
		"grid.libramientotermc.authorize":"Authorize riddance",
		"grid.libramientotermc.modal.fechaautorizacion":"Pickup time",
		"grid.libramientotermc.refusemotivo.title":"Refuse riddance",
		"grid.libramientotermc.refusemotivo":"Reason for refusal",
		"grid.libramientotermc.modifyTPC.title":"Modify loading point",
		"grid.libramientotermc.modal.puntocarga": "Loading point",
		"grid.libramientotermc.modal.terminal": "Charging terminal",
		"grid.libramientotermc.modal.validate.hour": "Time have not valid format",
		"grid.libramientotermc.fecharecogidareal": "Real pick-up date",
		"grid.libramientotermc.fechacreacion":"Creation date",
		"grid.libramientotermc.refconsulta":"Consultation reference",
		"grid.libramientotermc.refencargo":"Order reference",
		"grid.libramientotermc.contrato":"Contract number",
		"grid.libramientotermc.comprador":"Buyer",
		"grid.libramientotermc.terminalgestora": "Management terminal"
    },
    "es": {
		"grid.libramientotermc.idlibramiento": "Código",
	    "grid.libramientotermc.idmercancia": "Código mercancía",
	    "grid.libramientotermc.mercancia": "Mercancía",
	    "grid.libramientotermc.pesosolicitado": "Peso (kg)",
	    "grid.libramientotermc.fecharecogida": "Fecha de recogida",
	    "grid.libramientotermc.fechainicio": "Inicio time slot",
	    "grid.libramientotermc.fechafin": "Fin time slot",
	    "grid.libramientotermc.horainicio": "Inicio time slot",
	    "grid.libramientotermc.horafin": "Fin time slot",
	    "grid.libramientotermc.horarecogida": "Hora recogida",
	    "grid.libramientotermc.idtransportista": "Código transportista",
	    "grid.libramientotermc.transportista": "Transportista",
	    "grid.libramientotermc.idpuntocarga": "Código punto de carga",
	    "grid.libramientotermc.puntocarga": "Punto carga",
	    "grid.libramientotermc.idconductor": "Código conductor",
	    "grid.libramientotermc.conductor": "Conductor",
	    "grid.libramientotermc.nifconductor": "NIF Conductor",
	    "grid.libramientotermc.matricula": "Matrícula",
	    "grid.libramientotermc.remolque": "Remolque",
	    "grid.libramientotermc.indpcmodificado": "Ind. punto de carga modificado",
	    "grid.libramientotermc.pcmodificado": "P.C. modificado",
	    "grid.libramientotermc.estado": "Estado",
	    "grid.libramientotermc.codestado": "Código estado",
	    "grid.libramientotermc.idterminalcarga": "Código terminal carga",
		"grid.libramientotermc.idterminalgestora": "Código terminal gestora",
		"grid.libramientotermc.authorize.title":"Autorizar libramiento",
		"grid.libramientotermc.authorize":"Autorizar libramiento",
		"grid.libramientotermc.modal.fechaautorizacion":"Hora de recogida",
		"grid.libramientotermc.refusemotivo.title":"Rechazar libramiento",
		"grid.libramientotermc.refusemotivo":"Motivo de rechazo",
		"grid.libramientotermc.modifyTPC.title":"Modificar punto de carga",
		"grid.libramientotermc.modal.puntocarga": "Punto de carga",
		"grid.libramientotermc.modal.terminal": "Terminal de carga",
		"grid.libramientotermc.modal.validate.hour": "La hora no tiene un formato válido",
		"grid.libramientotermc.fecharecogidareal": "Fecha de recogida real",
		"grid.libramientotermc.fechacreacion":"Fecha creación",
		"grid.libramientotermc.refconsulta":"Referencia consulta",
		"grid.libramientotermc.refencargo":"Referencia encargo",
		"grid.libramientotermc.contrato":"Nº contrato",
		"grid.libramientotermc.comprador":"Comprador",
		"grid.libramientotermc.terminalgestora": "Terminal gestora"
    },
    "ca": {
		"grid.libramientotermc.idlibramiento": "Codi",
	    "grid.libramientotermc.idmercancia": "Codi mercaderia",
	    "grid.libramientotermc.mercancia": "Mercaderia",
	    "grid.libramientotermc.pesosolicitado": "Pes (kg)",
	    "grid.libramientotermc.fecharecogida": "Data de recollida",
	    "grid.libramientotermc.fechainicio": "Inici time slot",
	    "grid.libramientotermc.fechafin": "Fi time slot",
	    "grid.libramientotermc.horainicio": "Inici time slot",
	    "grid.libramientotermc.horafin": "Fi time slot",
	    "grid.libramientotermc.horarecogida": "Hora de recollida",
	    "grid.libramientotermc.idtransportista": "Codi transportista",
	    "grid.libramientotermc.transportista": "Transportista",
	    "grid.libramientotermc.idpuntocarga": "Codi punt de càrrega",
	    "grid.libramientotermc.puntocarga": "Punt de càrrega",
	    "grid.libramientotermc.idconductor": "Codi conductor",
	    "grid.libramientotermc.conductor": "Conductor",
	    "grid.libramientotermc.nifconductor": "NIF Conductor",
	    "grid.libramientotermc.matricula": "Matrícula",
	    "grid.libramientotermc.remolque": "Remolc",
	    "grid.libramientotermc.indpcmodificado": "Ind. Punt de càrrega modificat",
	    "grid.libramientotermc.pcmodificado": "Punt càrrega modificat",
	    "grid.libramientotermc.estado": "Estat",
	    "grid.libramientotermc.codestado": "Codi estat",
	    "grid.libramientotermc.idterminalcarga": "Codi terminal càrrega",
		"grid.libramientotermc.idterminalgestora": "Codi terminal gestora",
		"grid.libramientotermc.authorize.title":"Autoritzar lliurament",
		"grid.libramientotermc.authorize":"Autoritzar lliurament",
		"grid.libramientotermc.modal.fechaautorizacion":"Hora de recollida",
		"grid.libramientotermc.refusemotivo.title":"Rebutjar lliurament",
		"grid.libramientotermc.refusemotivo":"Motiu de rebuig",
		"grid.libramientotermc.modifyTPC.title":"Modificar punt de càrrega",
		"grid.libramientotermc.modal.puntocarga": "Punt de càrrega",
		"grid.libramientotermc.modal.terminal": "Terminal de càrrega",
		"grid.libramientotermc.modal.validate.hour": "L' hora no té un format vàlid",
		"grid.libramientotermc.fecharecogidareal": "Data de recollida real",
		"grid.libramientotermc.fechacreacion":"Data creació",
		"grid.libramientotermc.refconsulta":"Referència consulta",
		"grid.libramientotermc.refencargo":"Referència encàrrec",
		"grid.libramientotermc.contrato":"Nº contracte",
		"grid.libramientotermc.comprador":"Comprador",
		"grid.libramientotermc.terminalgestora": "Terminal gestora"
 	}
}
</i18n>
