<template>
	<div>
		<usuario-aplicacion-modals :modelName="model"></usuario-aplicacion-modals>
		<pui-datatable
			:modelName="model"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			:showDeleteBtn="false"
			showFilterListBtn
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import UsuarioCompradorActions from './UsuarioCompradorActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuariocompradorgrid',
	components: {
		UsuarioAplicacionModals
	},
	data() {
		return {
			model: 'usuariocomprador',
			actions: UsuarioCompradorActions.gridactions,
			columnDefs: {
				disableddate: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return registry.disabled !== 1;
		}
	}
};
</script>

<style scoped></style>

<i18n>
{
	"en": {
		"usuarioaplicacion" :"Users",
		"grid.usuariocomprador.usr": "User",
		"grid.usuariocomprador.name": "Name",
		"grid.usuariocomprador.email": "Email",
		"grid.usuariocomprador.disableddate": "Disabled Date",
		"grid.usuariocomprador.disabledmask": "Disabled",
		"grid.usuariocomprador.language": "Language",
		"grid.usuariocomprador.idcomprador": "Buyer code",
		"grid.usuariocomprador.fechacreacion":"Creation date",
		"grid.usuariocomprador.usuariocreacion":"Creation user"
	},
	"es": {
		"usuarioaplicacion" :"Usuarios",
		"grid.usuariocomprador.usr": "Usuario",
		"grid.usuariocomprador.name": "Nombre",
		"grid.usuariocomprador.email": "Email",
		"grid.usuariocomprador.disableddate": "Fecha desactivación",
		"grid.usuariocomprador.disabledmask": "Activo",
		"grid.usuariocomprador.language": "Idioma",
		"grid.usuariocomprador.idcomprador": "Código comprador",
		"grid.usuariocomprador.fechacreacion":"Fecha creación",
		"grid.usuariocomprador.usuariocreacion":"Usuario alta"

	},
	"ca": {
		"usuarioaplicacion" :"Usuaris",
		"grid.usuariocomprador.usr": "Usuari",
		"grid.usuariocomprador.name": "Nom",
		"grid.usuariocomprador.email": "Email",
		"grid.usuariocomprador.disableddate": "Data Desactivació",
		"grid.usuariocomprador.disabledmask": "Actiu",
		"grid.usuariocomprador.language": "Idioma",
		"grid.usuariocomprador.idcomprador": "Codi comprador",
		"grid.usuariocomprador.fechacreacion":"Data creació",
		"grid.usuariocomprador.usuariocreacion":"Usuari alta"
	}
}
</i18n>
