<template>
	<v-form class="pui-usuariotransportistaform ml-4 mr-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
		<usuario-aplicacion-modals :modelName="modelName"></usuario-aplicacion-modals>
		<usuario-aplicacion-form-base
			v-if="tipousuarioFixedFilter != null"
			:model="model"
			:formDisabled="formDisabled"
			:modelLoaded="modelLoaded"
			:method="method"
			:tipousuarioFixedFilter="tipousuarioFixedFilter"
		></usuario-aplicacion-form-base>
		<pui-form-footer>
			<v-layout>
				<v-flex xs12 sm12 md8 lg8 xl8 :mb-5="isMobile">
					<v-layout>
						<v-flex xs12 md4>
							<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
						</v-flex>
						<v-flex xs12 md8>
							<v-layout justify-end fill-height>
								<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
									this.$t('form.save')
								}}</v-btn>

								<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</pui-form-footer>
	</v-form>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import UsuarioAplicacionFormBase from './UsuarioAplicacionFormBase';
import UsuarioTransportistaActions from './UsuarioTransportistaActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuariotransportista',
	mixins: [PuiFormMethodsMixin],
	components: { UsuarioAplicacionFormBase, UsuarioAplicacionModals },
	data() {
		return {
			modelName: 'usuariotransportista',
			actions: UsuarioTransportistaActions.formactions,
			tipousuarioFixedFilter: null
		};
	},
	methods: {
		afterGetData() {
			this.model.matriconductor2 = this.model.matriconductor;
			if (this.model.transportistafijo) {
				const tipousuarioT1Filter = {
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'codtipusuario', op: 'eq', data: 3 },
								{ field: 'codtipusuario', op: 'eq', data: 4 }
							]
						},
						{ groupOp: 'and', rules: [{ field: 'codtipusuario', op: 'ne', data: 6 }] }
					],
					groupOp: 'and',
					rules: []
				};
				this.tipousuarioFixedFilter = tipousuarioT1Filter;
			} else {
				this.tipousuarioFixedFilter = null;
			}
		},
		beforeSave() {
			this.model.matriconductor = this.model.matriconductor2;
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style lang="postcss"></style>
