<template>
	<div class="table_wrapper">
		<table>
			<thead>
				<tr>
					<th>{{ $t('puiaudit.attribute') }}</th>
					<th v-if="type === 'update'">{{ $t('puiaudit.oldValue') }}</th>
					<th>{{ $t('puiaudit.newValue') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in activeFields" :key="index">
					<td>
						<span>{{ item.attribute }}</span>
					</td>
					<td v-if="type === 'update'">
						<span>{{ item.oldValue }}</span>
					</td>
					<td>
						<span>{{ item.value }}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
export default {
	name: 'auditasignacionform',
	data() {
		return {
			type: 'insert',
			content: [],
			transportistas: []
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		}
	},
	created() {
		this.getAllProfiles();
	},
	watch: {
		model(newValue) {
			if (newValue && newValue.type) {
				this.type = newValue.type;
				let contenido = JSON.parse(newValue.content);
				for (var i = 0, length = contenido.length; i < length; i++) {
					const column = contenido[i];
					if (column.attribute === 'idtransportista') {
						for (var j = 0, lengthj = this.transportistas.length; j < lengthj; j++) {
							if (column.value === this.transportistas[j].idtransportista) {
								contenido[i].value = this.transportistas[j].nombre;
							}
							if (this.type === 'update' && column.oldValue === this.transportistas[j].idtransportista) {
								contenido[i].oldValue = this.transportistas[j].nombre;
							}
						}
					}
				}
				this.content = contenido;
			}
		}
	},
	computed: {
		activeFields: function() {
			return this.content.filter(function(u) {
				return u.attribute === 'pesoasignado' || u.attribute === 'idtransportista';
			});
		}
	},
	methods: {
		getAllProfiles() {
			this.$puiRequests.postRequest('/puisearch', { model: 'vluptransportistas', rows: 1000 }, response => {
				if (response && response.data && response.data.data) {
					this.transportistas = response.data.data;
				}
			});
		}
	}
};
</script>

<style scoped>
.table_wrapper {
	height: 600px;
	overflow-y: scroll;
}
table {
	width: 100%;
}
table,
th,
td {
	border: 1px solid #dddddd;
	border-collapse: collapse;
}
th,
td {
	padding: 8px 10px;
	text-align: left;
}
</style>
