export default {
	async puiLoginDoLogin({ commit, dispatch, getters }, params) {
		commit('puiLoginRunning', true);
		// remove old session in state
		commit('clearSession');

		const {
			$app: { $puiNotify, $puiRequests, $puiEvents, $puiI18n }
		} = this;

		if (!params.user || !params.password || params.user.length <= 0 || params.password.length <= 0) {
			const title = getters.getTranslate('puilogin.titleError');
			const message = getters.getTranslate('puilogin.paramsError');

			$puiNotify.error(message, title);
			commit('puiLoginRunning', false);

			return;
		}

		if (params.user && params.password && params.user.length > 0 && params.password.length > 0) {
			if (document.getElementById('aceptadaNormativaCheck')) {
				for (var i = 0; i < document.getElementsByTagName('input').length; i++) {
					if (
						document.getElementsByTagName('input')[i].tagName.toLowerCase() === 'input' &&
						document.getElementsByTagName('input')[i].type.toLowerCase() === 'checkbox' &&
						document.getElementsByTagName('input')[i].id === 'aceptadaNormativaCheck'
					) {
						if (document.getElementsByTagName('input')[i].value === '0') {
							const title = getters.getTranslate('puilogin.titleError');
							const message = getters.getTranslate('puilogin.aceptadaNormativaError');
							$puiNotify.error(message, title);
							commit('puiLoginRunning', false);
							return;
						}
					}
				}
			}
		}

		const requestParams = {
			usr: params.user,
			password: params.password,
			persistent: params.persistent,
			client: getters.clientName
		};

		try {
			const { data: loginInfo } = await $puiRequests.postRequest('/login/signin', requestParams);

			if (!loginInfo) {
				return;
			}

			loginInfo.keepSessionAlive = params.keepSessionAlive;
			const { language, jwt, use2fa, keepSessionAlive } = loginInfo;

			commit('setHttpRequestHeaders', { acceptLanguage: language });
			commit('setHttpRequestHeaders', { authorization: jwt, acceptLanguage: language });
			commit('puiLoginSetInfo', loginInfo);
			$puiI18n.locale = language;
			window.localStorage.setItem('pui9_lang', language);
			$puiEvents.$emit('onPuiChangedLang', language);
			commit('afterGetLoginInfo', loginInfo);
			commit('setUserLanguage', language);

			if (!use2fa) {
				if (getters.afterLoginFunction) {
					getters.afterLoginFunction.call(this, loginInfo, keepSessionAlive);
				} else {
					dispatch('puiLoginAfterLogin', {
						loginInfo,
						keepSessionAlive: keepSessionAlive,
						route: params.route
					});
				}
			} else {
				if (keepSessionAlive) {
					window.localStorage.setItem(getters.getSession.keepSessionAliveInfoKey, JSON.stringify(loginInfo));
				}
			}
		} catch (error) {
			if (error && error.response && error.response.status === 401) {
				commit('puiLoginError', true);
				commit('puiLoginErrorMessage', error.response.data.message);
			} else {
				const title = getters.getTranslate('puilogin.titleError');
				const message = getters.getTranslate('puilogin.proxyError');
				$puiNotify.error(message, title);
			}
		} finally {
			commit('puiLoginRunning', false);
		}
	},
	// eslint-disable-next-line no-unused-vars
	puiLoginRedirectAfterLogin({ dispatch }, params) {
		const {
			$app: { $router, $store }
		} = this;

		if ($router.currentRoute.path === '/login' && !$store.getters.getToken) {
			return;
		}
		$router.replace({
			path: $router.currentRoute.query.redirect,
			query: getQueryParams($router.currentRoute.query)
		});
	}
};

function getQueryParams(object) {
	return Object.entries(object).reduce((o, [k, v]) => {
		if (k !== 'redirect') {
			o[k] = v;
		}
		return o;
	}, {});
}
