<template>
	<div>
		<v-layout>
			<v-flex xs12 sm12 md8 lg8 xl8>
				<v-layout wrap class="pui-form-layout">
					<v-flex xs12 v-if="modelLoaded && esFormAudit">
						<pui-text-field
							:label="$t('encargo.idencargo')"
							v-model="model.idencargo"
							required
							disabled
							valueColumnStyles="xs4 sm2 md1"
						></pui-text-field>
					</v-flex>
					<v-flex xs12>
						<pui-select
							v-if="modelLoaded && esFormTerm"
							attach="idcomprador"
							:label="$t('encargo.idcomprador')"
							clearable
							:disabled="formDisabled"
							v-model="model"
							required
							modelName="vlupcompradores"
							:itemsToSelect="[{ idcomprador: model.idcomprador }]"
							:modelFormMapping="{ idcomprador: 'idcomprador' }"
							:itemValue="['idcomprador']"
							:itemText="(item) => `${item.nombre}`"
							:key="'lupaComp' + lupaKey"
							:order="{ nombre: 'asc' }"
						></pui-select>
					</v-flex>
					<v-flex xs12>
						<pui-select
							v-if="modelLoaded && esFormComp"
							attach="idterminal"
							:label="$t('encargo.idterminal')"
							clearable
							:disabled="formDisabledEstadoTM"
							v-model="model"
							required
							modelName="vlupterminales"
							:itemsToSelect="[{ idterminal: model.idterminal }]"
							:modelFormMapping="{ idterminal: 'idterminal' }"
							:itemValue="['idterminal']"
							:itemText="(item) => `${item.nombre}`"
							:key="'lupaTerm' + lupaKey"
							:order="{ nombre: 'asc' }"
							:fixedFilter="getFiltroTerminal"
						></pui-select>
					</v-flex>
					<v-flex xs12>
						<pui-date-field
							v-if="modelLoaded"
							id="fecharecogida"
							attach="fecharecogida"
							v-model="model.fecharecogida"
							valueColumnStyles="xs12 sm4 md4 xl2"
							:label="$t('encargo.fecharecogida')"
							required
							:disabled="formDisabled"
							:min="formDisabled ? undefined : fechahoy"
							noLazyModel
						></pui-date-field>
					</v-flex>
					<v-flex xs12>
						<pui-select
							v-if="modelLoaded"
							attach="idmercancia"
							:label="$t('encargo.idmercancia')"
							clearable
							:disabled="formDisabled"
							v-model="model"
							required
							modelName="vlupmercancias"
							:itemsToSelect="[{ idmercancia: model.idmercancia }]"
							:modelFormMapping="{ idmercancia: 'idmercancia' }"
							:itemValue="['idmercancia']"
							:itemText="(item) => `${item.nombre}`"
							:key="'lupaMer' + lupaKey"
							:order="{ nombre: 'asc' }"
						></pui-select>
					</v-flex>
					<v-flex xs12>
						<pui-number-field
							:label="$t('encargo.peso')"
							v-model="model.peso"
							decimals="0"
							max="8000000"
							required
							:disabled="formDisabled"
							:valueColumnStyles="getPesoCols"
							id="peso"
							:suffix="getSuffixPeso"
						></pui-number-field>
					</v-flex>
					<v-flex xs12>
						<pui-number-field
							v-if="modelLoaded && esFormTerm"
							:label="$t('encargo.kiloslocalizados')"
							v-model="model.kiloslocalizados"
							disabled
							valueColumnStyles="xs12 sm6 md4 xl2"
							id="kiloslocalizados"
						></pui-number-field>
					</v-flex>
					<v-flex xs12>
						<pui-text-field
							:label="$t('encargo.proveedor')"
							v-model="model.proveedor"
							maxlength="100"
							required
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12>
						<pui-text-field
							:label="$t('encargo.contrato')"
							v-model="model.contrato"
							maxlength="50"
							required
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12>
						<pui-text-field
							v-if="esFormComp"
							:label="$t('encargo.aplicacion')"
							v-model="model.aplicacion"
							maxlength="50"
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12>
						<pui-text-field
							:label="$t('encargo.referencia')"
							v-model="model.referencia"
							maxlength="50"
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 v-show="!isCreatingElement">
						<pui-select
							v-if="modelLoaded"
							attach="idestado"
							:label="$t('encargo.idestado')"
							clearable
							v-model="model"
							disabled
							modelName="vlupestados"
							:itemsToSelect="[{ idestado: model.idestado }]"
							:modelFormMapping="{ idestado: 'idestado' }"
							:itemValue="['idestado']"
							:itemText="(item) => `${item.descestado}`"
							:key="'lupaEstado' + lupaKey"
							:order="{ descestado: 'asc' }"
						></pui-select>
					</v-flex>
					<v-flex xs12 v-show="!isCreatingElement">
						<pui-select
							id="idmotivoanulrechazo"
							attach="idmotivoanulrechazo"
							:label="$t('encargo.idmotivoanulrechazo')"
							disabled
							v-model="model"
							modelName="vlupmotivoanulrech"
							:itemsToSelect="[{ idmotivo: model.idmotivoanulrechazo }]"
							:modelFormMapping="{ idmotivo: 'idmotivoanulrechazo' }"
							:itemValue="['idmotivo']"
							:itemText="(item) => `${item.descripcion}`"
							:order="{ descripcion: 'asc' }"
						></pui-select>
					</v-flex>
					<v-flex xs12 v-show="!isCreatingElement">
						<pui-text-area
							v-model="model.obsrechazo"
							:label="this.$t('encargo.obsrechazo')"
							maxlength="1000"
							clearable
							disabled
						></pui-text-area>
					</v-flex>
					<v-flex xs12 v-show="!isCreatingElement">
						<pui-text-area
							v-model="model.obsvalidacion"
							:label="this.$t('encargo.obsvalidacion')"
							maxlength="1000"
							clearable
							disabled
						></pui-text-area>
					</v-flex>
					<v-flex xs12>
						<pui-text-area
							v-model="model.observaciones"
							:label="this.$t('encargo.observaciones')"
							maxlength="300"
							clearable
							:disabled="formDisabled"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
export default {
	data() {
		return {
			fechahoy: this.$dateTimeUtils.getLocalDateNow(),
			terminalFixedFilter: {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'indbaja', op: 'eq', data: '0' }]
			},
			terminalAllFixedFilter: {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'indbaja', op: 'eq', data: '0' },
					{ field: 'indbaja', op: 'eq', data: '1' }
				]
			}
		};
	},
	computed: {
		formDisabledEstadoTM() {
			return this.getFormDisabledEstadoTM();
		},
		esFormComp() {
			return this.tipoEncargo === 'C';
		},
		esFormTerm() {
			return this.tipoEncargo === 'T';
		},
		getFiltroTerminal() {
			if (this.getFormDisabledEstadoTM()) {
				return this.terminalAllFixedFilter;
			} else {
				return this.terminalFixedFilter;
			}
		},
		esFormAudit() {
			return this.auditoria === 'A';
		},
		getSuffixPeso() {
			return this.showSuffixPeso() ? this.$t('encargo.peso.ayuda') : '';
		},
		getPesoCols: function () {
			var tamanyo = 'xs12 sm6 md4 xl2';
			if (this.showSuffixPeso()) {
				tamanyo = 'xs12 sm8 md6 xl4';
			}
			return tamanyo;
		}
	},
	methods: {
		getFormDisabledEstadoTM() {
			return this.formDisabled || (this.model.idestado !== null && this.model.idestado !== 1);
		},
		showSuffixPeso() {
			return this.esFormComp && !this.formDisabled;
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		tipoEncargo: {
			type: String,
			required: true
		},
		lupaKey: {
			type: Number,
			required: false,
			default: 1
		},
		isCreatingElement: {
			type: Boolean,
			required: false
		},
		auditoria: {
			type: String,
			required: false
		}
	}
};
</script>
