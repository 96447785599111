<template>
	<div class="pui-form">
		<v-form class="pui-mercanciaform mb-4 pb-4 mt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs8>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 v-if="this.method != 'create'">
							<pui-text-field
								:label="$t('mercancia.idmercancia')"
								v-model="model.idmercancia"
								required
								disabled
								valueColumnStyles="xs4 sm2 md1"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('mercancia.nombre')"
								v-model="model.nombre"
								maxlength="100"
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-area
								v-model="model.observaciones"
								:label="this.$t('mercancia.observaciones')"
								maxlength="300"
								clearable
								:disabled="formDisabled"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import MercanciaActions from './MercanciaActions';

export default {
	name: 'mercancia',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'mercancia',
			actions: MercanciaActions.formactions
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "mercancia.idmercancia": "Code",
        "mercancia.nombre": "Name",
        "mercancia.observaciones": "Comments"
    },
    "es": {
        "mercancia.idmercancia": "Código",
        "mercancia.nombre": "Nombre",
        "mercancia.observaciones": "Observaciones"
    },
    "ca": {
        "mercancia.idmercancia": "Codi",
        "mercancia.nombre": "Nom",
        "mercancia.observaciones": "Observacions"
    }
}
</i18n>
