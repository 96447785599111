<template>
	<div class="asignaciontrans puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('asignaciontrans.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md3>
				<pui-field :label="this.$t('asignaciontrans.fecharecogida')" :value="getDiaRecogida"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="this.$t('asignaciontrans.peso')" :value="getPeso"></pui-field>
			</v-flex>
			<v-flex xs12 md3>
				<pui-field :label="this.$t('asignaciontrans.pesopendiente')" :value="getPesoPendiente"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'asignaciontransformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'asignaciontrans'
		};
	},
	computed: {
		getMercancia() {
			return this.model && this.model.mercancia ? this.model.mercancia : '';
		},
		getDiaRecogida() {
			return this.model && this.model.fecharecogida
				? this.$dateTimeUtils.formatDate(this.model.fecharecogida, this.$store.getters.dateFormat)
				: '';
		},
		getPeso() {
			return this.model && this.model.peso ? this.formatNumber(this.model.peso) : '';
		},
		getPesoPendiente() {
			return this.model && this.model.pesopendiente ? this.formatNumber(this.model.pesopendiente) : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'asignaciontrans';
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"asignaciontrans.mercancia": "Goods",
		"asignaciontrans.fecharecogida": "Pick up date",
		"asignaciontrans.peso": "Number of Kg.",
		"asignaciontrans.pesopendiente":"Weight to be allocated (kg)"
	},
	"es": {
		"asignaciontrans.mercancia": "Mercancía",
		"asignaciontrans.fecharecogida": "Fecha de recogida",
		"asignaciontrans.peso": "Peso (kg)",
		"asignaciontrans.pesopendiente":"Peso pendiente de asignar (kg)"
	},
	"ca": {
		"asignaciontrans.mercancia": "Mercaderia",
		"asignaciontrans.fecharecogida":"Data de recollida",
		"asignaciontrans.peso": "Num. Kg.",
		"asignaciontrans.peso": "Pes (kg)",
		"asignaciontrans.pesopendiente":"Pes pendent d'assignar (kg)"
	}
}
</i18n>
