<template>
	<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
		<v-layout wrap class="pui-form-layout">
			<v-flex xs12 md4>
				<pui-field-set :title="getTitleFieldSet1">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field
								:label="this.$t('tipousuarioperfil.profile')"
								v-model="model.profile"
								maxlength="100"
								toplabel
								required
								:disabled="formDisabled || this.method != 'create'"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								:label="this.$t('tipousuarioperfil.name')"
								v-model="model.name"
								maxlength="200"
								toplabel
								required
								:disabled="formDisabled"
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6 mx-2>
							<pui-select
								v-if="modelLoaded"
								attach="idtipousuariopk"
								:label="$t('tipousuarioperfil.idtipousuario')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								required
								modelName="vluptipousuario"
								:itemsToSelect="[{ codtipusuario: model.idtipousuariopk }]"
								:modelFormMapping="{ codtipusuario: 'idtipousuariopk' }"
								itemValue="codtipusuario"
								itemText="descripcion"
								toplabel
								:order="{ descripcion: 'asc' }"
								:fixedFilter="tipousuarioFixedFilter"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs6>
							<pui-checkbox
								:label="$t('tipousuarioperfil.indseleccionado')"
								v-model="model.indseleccionado"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-flex>
			<v-flex xs12 md8>
				<pui-field-set :title="getTitleFieldSet2">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-select
								:label="$t('tipousuarioperfil.subsystem')"
								v-model="subsystemSelectedObject"
								toplabel
								clearable
								:disabled="formDisabled"
								modelName="puisubsystem"
								:itemsToSelect="[subsystemSelectedObject]"
								:modelFormMapping="{ subsystem: 'subsystem' }"
								itemValue="subsystem"
								itemText="name"
							></pui-select>
						</v-flex>
					</v-layout>
					<pui-multi-select
						itemValue="functionality"
						itemText="name"
						:itemsToSelect="model.functionalities"
						:items="getFunctionalities"
						v-model="model.functionalities"
						:disabled="formDisabled"
						:key="functionalitiesKey"
					></pui-multi-select>
				</pui-field-set>
			</v-flex>
		</v-layout>
		<pui-form-footer>
			<v-layout>
				<v-flex xs12 sm12 md8 lg8 xl8>
					<v-layout>
						<v-flex xs12 md4>
							<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
						</v-flex>
						<v-flex xs12 md8>
							<v-layout justify-end fill-height>
								<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
									this.$t('form.save')
								}}</v-btn>

								<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</pui-form-footer>
	</v-form>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
/* eslint-disable */
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import TipoUsuarioPerfilActions from './TipoUsuarioPerfilActions';

export default {
	name: 'tipousuarioperfilform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'tipousuarioperfil',
			actions: TipoUsuarioPerfilActions.formactions,
			functionalitiesKey: 0,
			allFunctionalities: [],
			functionalitiesBySubsystem: [],
			subsystemSelected: null,
			subsystemSelectedObject: {
				subsystem: null
			},
			tipousuarioFixedFilter: {
				groups: [],
				groupOp: 'or',
				rules: [{ field: 'codtipusuario', op: 'ne', data: 6 }]
			}
		};
	},
	created() {
		this.getAllFunctionalities();
	},
	computed: {
		getTitleFieldSet1() {
			return this.$t('tipousuarioperfil.title');
		},
		getTitleFieldSet2() {
			return this.$t('tipousuarioperfil.functionalities');
		},
		getFunctionalities() {
			return !this.subsystemSelected ? this.allFunctionalities : this.functionalitiesBySubsystem[this.subsystemSelectedObject.subsystem];
		}
	},
	watch: {
		'subsystemSelectedObject.subsystem'(newSubsystemSelected) {
			this.subsystemSelected = newSubsystemSelected;
			this.functionalitiesKey += 1;
		}
	},
	methods: {
		getAllFunctionalities() {
			this.$puiRequests.postRequest('/puisearch', { model: 'puifunctionality', rows: 1000 }, (response) => {
				if (response && response.data && response.data.data) {
					this.allFunctionalities = response.data.data;
					this.functionalitiesBySubsystem = [];
					for (var i = 0, length = this.allFunctionalities.length; i < length; i++) {
						if (!this.functionalitiesBySubsystem[this.allFunctionalities[i]['subsystem']]) {
							this.functionalitiesBySubsystem[this.allFunctionalities[i]['subsystem']] = [this.allFunctionalities[i]];
						} else {
							this.functionalitiesBySubsystem[this.allFunctionalities[i]['subsystem']].push(this.allFunctionalities[i]);
						}
					}
					this.functionalitiesKey += 1;
				}
			});
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
	"en": {
		"tipousuarioperfil.title": "Profile Data",
		"tipousuarioperfil.profile": "Profile",
		"tipousuarioperfil.name": "Description",
		"tipousuarioperfil.description": "Description",
		"tipousuarioperfil.subsystem": "Subsystem",
		"tipousuarioperfil.functionalities": "Functionalities",
		"tipousuarioperfil.idtipousuario":"User type",
		"tipousuarioperfil.indseleccionado":"Selected by default"
	},
	"es": {
		"tipousuarioperfil.title": "Información del perfil",
		"tipousuarioperfil.profile": "Perfil",
		"tipousuarioperfil.name": "Descripción",
		"tipousuarioperfil.description": "Descripción",
		"tipousuarioperfil.subsystem": "Subsistema",
		"tipousuarioperfil.functionalities": "Funcionalidades",
		"tipousuarioperfil.idtipousuario":"Tipo usuario",
		"tipousuarioperfil.indseleccionado":"Seleccionado por defecto"
	},
	"ca": {
		"tipousuarioperfil.title": "Informació de perfil",
		"tipousuarioperfil.profile": "Perfil",
		"tipousuarioperfil.name": "Descripció",
		"tipousuarioperfil.description": "Descripció",
		"tipousuarioperfil.subsystem": "Subsistema",
		"tipousuarioperfil.functionalities": "Funcionalitats",
		"tipousuarioperfil.idtipousuario":"Tipus usuari",
		"tipousuarioperfil.indseleccionado":"Seleccionat por defecte"
	}
}
</i18n>
