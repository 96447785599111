<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtclibramientostc.authorize.title')"
			:modelName="modelName"
			:dialogName="dialogNameAuthorize"
			v-model="modalData"
			:showDialogProp="showDialogAuthorize"
			:onOk="onOkAuthorize"
			:widthDialog="800"
			:componentHeaderName="'libramientotermcformheader'"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex xs12 md6>
						<pui-text-field
							id="horainicio"
							:label="$t('vtclibramientostc.horainicio')"
							v-model="modalData.horainicio"
							maxlength="5"
							labelColumnStyles="xs6"
							valueColumnStyles="xs2 md4"
							required
							disabled
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md6>
						<pui-text-field
							id="horafin"
							:label="$t('vtclibramientostc.horafin')"
							v-model="modalData.horafin"
							maxlength="5"
							labelColumnStyles="xs6"
							valueColumnStyles="xs2 md4"
							required
							disabled
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md6>
						<!--<pui-date-field
								id="fechaautorizacion"
								:label="$t('vtclibramientostc.modal.fechaautorizacion')"
								v-model="modals.authorize.fechaautorizacion"
								required
								:disabled="formDisabled"
								timesecs
								labelColumnStyles="xs3"
								valueColumnStyles="xs5"
								attach="fechaautorizacion"
							></pui-date-field>
						-->
						<pui-text-field
							id="horaautorizacion"
							:label="$t('vtclibramientostc.modal.fechaautorizacion')"
							v-model="modalData.fechaautorizacion"
							maxlength="5"
							labelColumnStyles="xs6"
							valueColumnStyles="xs2 md4"
							required
							:rules="hourRules"
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout class="justify-center">
					<v-flex xs12>
						<pui-select
							id="idpuntocarga-aut"
							attach="idpuntocarga-aut"
							:label="$t('vtclibramientostc.modal.puntocarga')"
							clearable
							v-model="modalData"
							required
							:itemsToSelect="[{ idpuntocarga: modalData.idpuntocarga }]"
							:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
							:itemValue="'idpuntocarga'"
							:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
							:items="puntosCarga"
							labelColumnStyles="xs3"
							valueColumnStyles="xs9"
							:order="{ descripcion: 'asc' }"
							:key="puntocargakey"
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtclibramientostc.refusemotivo.title')"
			:modelName="modelName"
			:dialogName="dialogNameRefusemotivo"
			v-model="modalData"
			:showDialogProp="showDialogRefusemotivo"
			:onOk="onOkRefusemotivo"
			:widthDialog="800"
			:componentHeaderName="'libramientotermcformheader'"
		>
			<template slot="message">
				<v-layout class="justify-center">
					<v-flex xd12>
						<pui-select
							attach="codigo"
							:label="$t('vtclibramientostc.refusemotivo')"
							clearable
							required
							v-model="modalData"
							modelName="vlupmotivoanulrech"
							:itemsToSelect="[{ codigo: modalData.codmotivoanulrechazo }]"
							:modelFormMapping="{ codigo: 'codmotivoanulrechazo' }"
							:itemValue="['codigo']"
							:itemText="(item) => `${item.descripcion}`"
							:fixedFilter="getEstadoLRFixedFilter"
							:order="{ descripcion: 'asc' }"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout class="justify-center">
					<v-flex xd12>
						<pui-text-area :label="$t('libramiento.obsrechazo')" v-model="modalData.obsrechazo" maxlength="300"></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtclibramientostc.modifyTPC.title')"
			:modelName="modelName"
			:dialogName="dialogNameModifyTPC"
			v-model="modalData"
			:showDialogProp="showDialogModifyTPC"
			:onOk="onOkModifyTPC"
			:widthDialog="800"
			:componentHeaderName="'libramientotermcformheader'"
		>
			<template slot="message">
				<v-layout class="justify-center">
					<v-flex xd12>
						<!--
						<pui-select
							id="idpuntocarga"
							attach="idpuntocarga"
							:label="$t('vtclibramientostc.modal.puntocarga')"
							clearable
							v-model="modalData"
							required
							:itemsToSelect="[{ idpuntocarga: modalData.idpuntocarga }]"
							:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
							:itemValue="['idpuntocarga']"
							:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
							:items="puntosCarga"
							labelColumnStyles="xs4"
							valueColumnStyles="xs5"
							:order="{ descripcion: 'asc' }"
						></pui-select>
						-->
						<pui-select
							id="idpuntocarga"
							attach="idpuntocarga"
							:label="$t('vtclibramientostc.modal.puntocarga')"
							clearable
							v-model="modalData"
							required
							modelName="vluppuntocarga"
							:itemsToSelect="[{ idpuntocarga: modalData.idpuntocarga }]"
							:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
							:itemValue="['idpuntocarga']"
							:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
							filterId="idterminal"
							:filterMap="{ idterminal: modalData.idterminalcarga }"
							:fixedFilter="fixedFilterPuntoCarga"
							labelColumnStyles="xs4"
							valueColumnStyles="xs5"
							:order="{ descripcion: 'asc' }"
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="this.$t('vtclibramientostc.authorizebulk.title')"
			:modelName="modelName"
			:dialogName="dialogNameAuthorizeBulk"
			v-model="modalData"
			:showDialogProp="showDialogAuthorizeBulk"
			:onOk="onOkAuthorizeBulk"
			:okText="this.$t('form.authorize')"
			:onShow="createTemplate"
			widthDialog="100%"
		>
			<template slot="message">
				<v-form ref="form" @submit.prevent>
					<v-data-table
						class="table-custom-styles"
						v-model="modalData.selectedRows"
						:items="modalData.libramientosautorizar"
						item-key="idlibramiento"
						show-select
						must-sort
						:headers="[
							{
								text: $t('grid.libramientotermc.modal.idlibramiento'),
								value: 'idlibramiento',
								width: '7%'
							},
							{ text: $t('grid.libramientotermc.modal.puntocarga'), value: 'puntocarga', width: '30%', align: 'left' },
							{ text: $t('grid.libramientotermc.modal.fecharecogida'), value: 'fecharecogida', width: '10%', align: 'left' },
							{ text: $t('grid.libramientotermc.modal.horainicio'), value: 'horainicio', width: '6%', align: 'left' },
							{ text: $t('grid.libramientotermc.modal.horafin'), value: 'horafin', width: '5%', align: 'left' },
							{ text: $t('grid.libramientotermc.modal.fechaautorizacion'), value: 'horamarcada', width: '8%', align: 'left' },
							{ text: $t('grid.libramientotermc.matricula'), value: 'matricula', width: '10%', align: 'left' },
							{ text: $t('grid.libramientotermc.modal.nifconductor'), value: 'nifconductor', width: '10%', align: 'left' },
							{ text: $t('grid.libramientotermc.transportista'), value: 'transportista', width: '15%', align: 'left' }
						]"
					>
						<template v-slot:item.fecharecogida="{ item }">
							{{ getDiaRecogida(item.fecharecogida) }}
						</template>
						<template v-slot:item.horamarcada="{ item }">
							<v-text-field
								v-model="item.horamarcada"
								maxlength="5"
								:rules="[
									(v) => !modalData.selectedRows.includes(item) || (modalData.selectedRows.includes(item) && !!v) || '',
									(v) => !modalData.selectedRows.includes(item) || validateHourRules(v) || '',
									(v) => !modalData.selectedRows.includes(item) || validateHourBetweenRules(item, v) || ''
								]"
								:disabled="!modalData.selectedRows.includes(item)"
								:key="modalData.selectedRows.includes(item)"
								class="ma-0 pa-1 border-full"
								placeholder="HH:mm"
								hide-details
							></v-text-field>
						</template>
					</v-data-table>
					<div class="d-flex justify-end">
						<span>{{ getTotalesAutorizheBulk }}</span>
					</div>
				</v-form>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog
			v-if="modals.esperaautorizar.show"
			:messageText="modals.esperaautorizar.text"
			:titleText="modals.esperaautorizar.titleText"
			:dialogName="`esperaautorizar-${this.modelName}`"
			ref="esperaautorizar"
			disableOk
			:widthDialog="800"
			:okText="$t('form.accept')"
		></pui-modal-dialog>
		<pui-modal-dialog
			v-if="modals.confirm.show"
			:messageText="$t('asignacion.modal.confirm.texto')"
			:titleText="modals.confirm.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:cancelText="$t('form.cancel')"
			:okText="$t('form.accept')"
		></pui-modal-dialog>
		<pui-modal-dialog-form
			:titleText="getResultTitle"
			:modelName="modelName"
			:dialogName="dialogNameResult"
			v-model="modalData"
			:showDialogProp="showDialogResult"
			:onOk="onOkResult"
			:widthDialog="900"
		>
			<template slot="message">
				<div v-for="(detalle, index) in modalData.data" :key="index">
					<v-layout wrap :class="index === 0 ? 'resultOK' : 'resultKO'">
						<v-flex xs12>{{ detalle }}</v-flex>
					</v-layout>
				</div>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'libramientotermgmodals',
	data() {
		return {
			modalData: {},
			dialogNameModifyTPC: 'modifyTPC',
			showDialogModifyTPC: true,
			dialogNameRefusemotivo: 'refusemotivo',
			showDialogRefusemotivo: true,
			dialogNameAuthorize: 'authorize',
			showDialogAuthorize: true,
			fixedFilterPuntoCarga: null,
			dialogNameAuthorizeBulk: 'authorizebulk',
			showDialogAuthorizeBulk: true,
			modals: {
				esperaautorizar: {
					disableOk: false,
					show: false,
					titleText: this.$t('libramiento.authorizebulk'),
					text: ''
				},
				confirm: {
					disableOk: false,
					show: false,
					titleText: this.$t('libramiento.modal.confirm.title')
				}
			},
			hourRules: [(v) => this.validateHourRules(v) || ''],
			puntosCarga: [],
			puntocargakey: 0,
			showDialogResult: true,
			dialogNameResult: 'result'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameModifyTPC}-${this.modelName}-show`, (data) => {
			const fecha = data.fecharecogidaenc ? data.fecharecogidaenc : data.fecharecogida;
			this.fixedFilterPuntoCarga = this.makeFilterLoadingPoint(fecha);
			this.showDialogModifyTPC = true;

			/*			const fecha = data.fecharecogidaenc ? data.fecharecogidaenc : data.fecharecogida;
			const idpuntocarga = data.idpuntocarga;
			const horainicio = data.horainicio;
			const horafin = data.horafin;

			this.getPuntosCarga(data.idterminalcarga, idpuntocarga, fecha, horainicio, horafin);
			this.showDialogModifyTPC = true;
			*/
		});
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameRefusemotivo}-${this.modelName}-show`, (data) => {
			this.showDialogRefusemotivo = true;
		});
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameAuthorize}-${this.modelName}-show`, (data) => {
			this.getPuntosCargaAut(data.idterminalcarga, data.fechainicio, data.horainicio);
			this.showDialogAuthorize = true;
		});
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameAuthorizeBulk}-${this.modelName}-show`, (data) => {
			this.modals.esperaautorizar.show = false;
			this.showDialogAuthorizeBulk = true;
		});
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameResult}-${this.modelName}-show`, (data) => {
			this.showDialogResult = true;
		});
		this.subscribeToModalDialogEvent();
	},
	methods: {
		onOkModifyTPC(modalData) {
			let registries = modalData.registries;
			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;
			var idpuntocarga = modalData.idpuntocarga;
			var errors = [];
			let urlController = this.$store.getters.getModelByName(this.modelName).url.modifyTPC;

			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				var idlibramiento = registry.idlibramiento;

				var params = { idlibramiento: idlibramiento, idpuntocarga: idpuntocarga };

				this.$puiRequests.patchRequest(
					urlController,
					params,
					() => {
						progress++;
						corrects++;
						finish();
					},
					(error) => {
						success = false;
						progress++;
						if (error.response && error.response.data) {
							let message = error.response.data.message;
							var data = JSON.parse(error.response.config.data);
							errors.push(this.$puiI18n.t('grid.libramientotermc.idlibramiento') + ' ' + data.idlibramiento + ': ' + message);
						}
						finish();
					}
				);
			}

			const finish = () => {
				if (counter === progress) {
					this.showDialogModifyTPC = false;
					//var message;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

					var row = {};
					row.isAction = true;
					row.data = [];
					row.data.push(corrects + this.$puiI18n.t('action.notifySuccessMultiple'));
					row.data = row.data.concat(errors);
					this.$puiEvents.$emit('pui-modalDialogForm-' + this.dialogNameResult + '-' + this.modelName + '-show', row);

					/*var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.modifyTPC');
					if (success) {
						message = counter + this.$puiI18n.t('action.notifySuccessMultiple');
						this.$puiNotify.success(message, title);
					} else {
						message = corrects + this.$puiI18n.t('action.notifyErrorMultiple') + counter;
						this.$puiNotify.error(message, title);
					}
					*/
				}
			};
		},
		onOkRefusemotivo(modalData) {
			let registries = modalData.registries;
			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;
			var codrechazo = modalData.codmotivoanulrechazo;
			var obsrechazo = modalData.obsrechazo;

			let urlController = this.$store.getters.getModelByName(this.modelName).url.refuse;

			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				var idlibramiento = registry.idlibramiento;

				var params = { idlibramiento: idlibramiento, codrechazo: codrechazo, obsrechazo: obsrechazo };
				this.$puiRequests.patchRequest(
					urlController,
					params,
					() => {
						progress++;
						corrects++;
						finish();
					},
					() => {
						success = false;
						progress++;
						finish();
					}
				);

				const finish = () => {
					if (counter === progress) {
						this.showDialogRefusemotivo = false;
						var message;
						this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.refuse');
						if (success) {
							message = counter + this.$puiI18n.t('action.notifySuccessMultiple');
							this.$puiNotify.success(message, title);
						} else {
							message = corrects + this.$puiI18n.t('action.notifyErrorMultiple') + counter;
							this.$puiNotify.error(message, title);
						}
					}
				};
			}
		},
		onOkAuthorize(modalData) {
			let body = {
				idlibramiento: modalData.headerPk.idlibramiento,
				idpuntocarga: modalData.idpuntocarga,
				horalibramiento: modalData.fechaautorizacion
			};
			let urlController = this.$store.getters.getModelByName(this.modelName).url.authorize;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.authorize');
			this.$puiRequests.patchRequest(
				urlController,
				body,
				() => {
					this.showDialogAuthorize = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.showDialogAuthorize = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		onOkAuthorizeBulk(modalData) {
			this.$refs.form.validate();
			let registries = modalData.selectedRows;
			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;
			let aEnviar = [];
			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				if (!this.validateHourRules(registry.horamarcada)) {
					this.$puiNotify.error(this.$t('vtclibramientostc.modal.validate.hour'));
					return;
				} else if (!this.validateHourBetweenRules(registry, registry.horamarcada)) {
					this.$puiNotify.error(this.$t('vtclibramientostc.modal.validate.hourBetween'));
					return;
				}

				var params = {
					idlibramiento: registry.idlibramiento,
					idpuntocarga: registry.idpuntocarga,
					horalibramiento: registry.horamarcada
				};

				aEnviar.push(params);
			}

			var counterEnviar = aEnviar.length;
			if (counterEnviar < 1) {
				this.$puiNotify.error(this.$t('vtclibramientostc.modal.validate.libramientoautorizar'));
				return;
			}

			let urlController = this.$store.getters.getModelByName(this.modelName).url.authorize;

			for (let i = 0; i < counterEnviar; i++) {
				const registry = aEnviar[i];

				this.$puiRequests.patchRequest(
					urlController,
					registry,
					() => {
						progress++;
						corrects++;
						finishBulk();
					},
					() => {
						success = false;
						progress++;
						finishBulk();
					}
				);

				const finishBulk = () => {
					if (counterEnviar === progress) {
						this.showDialogAuthorizeBulk = false;
						var message;
						this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.authorizebulk');
						if (success) {
							message = counterEnviar + this.$puiI18n.t('action.notifySuccessMultiple');
							this.$puiNotify.success(message, title);
						} else {
							message = corrects + this.$puiI18n.t('action.notifyErrorMultiple') + counterEnviar;
							this.$puiNotify.error(message, title);
						}
					}
				};
			}
		},
		createTemplate(modalData) {
			modalData.libramientosautorizar = modalData.libramientosautorizar.map((row) => {
				return { ...row, horamarcada: row.horainicio };
			});
			this.$set(modalData, 'selectedRows', modalData.libramientosautorizar.slice());
			this.$set(modalData, 'pagination', {});
		},
		validateHourRules(v) {
			// eslint-disable-next-line
			var re = /^(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[12345][0-9])$/;
			return re.test(v);
		},
		validateHourBetweenRules(row, hourSet) {
			// eslint-disable-next-line
			if (hourSet) {
				let horaini = parseInt(row.horainicio.replace(':', ''));
				let horafin = parseInt(row.horafin.replace(':', ''));
				let horamarcada = parseInt(hourSet.replace(':', ''));

				if (horamarcada >= horaini && horamarcada <= horafin) {
					return true;
				}
			}
			return false;
		},
		getPuntosCarga(idterminal, idpuntocarga, fecrecogida, horainicio, horafin) {
			const params = { idterminal: idterminal, idpuntocarga: idpuntocarga, fecrecogida: fecrecogida, horainicio: horainicio, horafin: horafin };
			this.$puiRequests.getRequest(
				'/libramientotermc/obtenerComboPCC',
				params,
				(response) => {
					if (response.data) {
						this.puntosCarga = response.data;
					}
				},
				(error) => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getPuntosCargaAut(idterminal, fecrecogida, hora) {
			const params = { idterminal: idterminal, fecrecogida: fecrecogida, hora: hora };
			this.$puiRequests.getRequest(
				'/libramientotermc/obtenerCombo',
				params,
				(response) => {
					if (response.data) {
						this.puntosCarga = response.data;
						this.puntocargakey++;
					}
				},
				(error) => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		subscribeToModalDialogEvent() {
			this.$puiEvents.$on(`pui-modalDialog-esperaautorizar-${this.modelName}-show`, () => {
				this.modals.esperaautorizar.text = this.$t('vtclibramientostc.modal.validate.esperaautorizar');
				this.modals.esperaautorizar.show = true;
			});
			this.$puiEvents.$on(`pui-modalDialog-esperaautorizar-${this.modelName}-cancel`, () => {
				this.modals.esperaautorizar.show = false;
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, () => {
				this.modals.confirm.show = true;
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.modals.confirm.show = false;
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.modals.confirm.show = false;
				this.$puiEvents.$emit('pui-action-runAction-' + this.modelName);
			});
		},
		getDiaRecogida(value) {
			return value ? this.$dateTimeUtils.formatDate(value, this.$store.getters.dateFormat) : '';
		},
		toggleAll() {
			if (this.modalData.selectedRows.length) this.modalData.selectedRows = [];
			else this.modalData.selectedRows = this.modalData.libramientosautorizar.slice();
		},
		onOkResult() {
			this.showDialogResult = false;
		},
		makeFilterLoadingPoint(fecha) {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{
						field: 'fecbaja',
						op: 'gt',
						data: fecha
					},
					{
						field: 'fecbaja',
						op: 'nu',
						data: null
					}
				]
			};
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
		this.$puiEvents.$off(`pui-modalDialog-esperaautorizar-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-esperaautorizar-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-esperaautorizar-${this.modelName}-show`);
	},
	computed: {
		getEstadoLR() {
			return 'LR';
		},
		getEstadoLRFixedFilter() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'indbaja', op: 'eq', data: '0' },
							{ field: 'indbaja', op: 'nu', data: null }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: 'LR' }] }
				],
				groupOp: 'and',
				rules: []
			};
		},
		getTotalesAutorizheBulk() {
			if (this.modalData && this.modalData.libramientosautorizar) {
				const total = this.modalData.libramientosautorizar.length;
				const selected = this.modalData.selectedRows.length;
				return selected + ' ' + this.$t('vtclibramientostc.modal.authorizebulk.seleccionados') + ' ' + total;
			} else {
				return '';
			}
		},
		getResultTitle() {
			return this.$t('vtclibramientostc.modifyTPC.title');
		}
	}
};
</script>
<style lang="postcss" scoped>
.resultKO {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.resultOK {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
</style>
<i18n>
{
	"en": {
		"vtclibramientostc.horainicio": "Time slot beginnig",
	    "vtclibramientostc.horafin": "Time slot end",
		"vtclibramientostc.authorize.title":"Authorize riddance",
		"vtclibramientostc.authorize":"Authorize riddance",
		"vtclibramientostc.modal.fechaautorizacion":"Pickup time",
		"vtclibramientostc.refusemotivo.title":"Refuse riddance",
		"vtclibramientostc.refusemotivo":"Reason for refusal",
		"vtclibramientostc.modifyTPC.title":"Modify loading point",
		"vtclibramientostc.modal.puntocarga": "Loading point",
		"vtclibramientostc.modal.terminal": "Charging terminal",
		"vtclibramientostc.modal.validate.hour": "Time have not valid format",
		"vtclibramientostc.fecharecogidareal": "Real pick-up date"
	},
	"es": {
		"vtclibramientostc.horainicio": "Inicio time slot",
	    "vtclibramientostc.horafin": "Fin time slot",
		"vtclibramientostc.authorize.title":"Autorizar libramiento",
		"vtclibramientostc.authorize":"Autorizar libramiento",
		"vtclibramientostc.modal.fechaautorizacion":"Hora de recogida",
		"vtclibramientostc.refusemotivo.title":"Rechazar libramiento",
		"vtclibramientostc.refusemotivo":"Motivo de rechazo",
		"vtclibramientostc.modifyTPC.title":"Modificar punto de carga",
		"vtclibramientostc.modal.puntocarga": "Punto de carga",
		"vtclibramientostc.modal.terminal": "Terminal de carga",
		"vtclibramientostc.modal.validate.hour": "La hora no tiene un formato válido",
		"vtclibramientostc.fecharecogidareal": "Fecha de recogida real"
	},
	"ca": {
		"vtclibramientostc.horainicio": "Inici time slot",
	    "vtclibramientostc.horafin": "Fi time slot",
		"vtclibramientostc.authorize.title":"Autoritzar lliurament",
		"vtclibramientostc.authorize":"Autoritzar lliurament",
		"vtclibramientostc.modal.fechaautorizacion":"Hora de recollida",
		"vtclibramientostc.refusemotivo.title":"Rebutjar lliurament",
		"vtclibramientostc.refusemotivo":"Motiu de rebuig",
		"vtclibramientostc.modifyTPC.title":"Modificar punt de càrrega",
		"vtclibramientostc.modal.puntocarga": "Punt de càrrega",
		"vtclibramientostc.modal.terminal": "Terminal de càrrega",
		"vtclibramientostc.modal.validate.hour": "L' hora no té un format vàlid",
		"vtclibramientostc.fecharecogidareal": "Data de recollida real"
	}
}
</i18n>
<style scoped>
.delSpace {
	display: none !important;
}
</style>
<style>
.border-full > .v-input__control {
	border: 1px solid !important;
}
.table-custom-styles .v-data-table__checkbox .v-icon {
	font-size: 18px;
}
.table-custom-styles .v-data-table__selected .v-data-table__checkbox .v-icon {
	color: var(--primarycolor);
}
.table-custom-styles .v-data-table-header th.sortable .v-data-table-header__icon {
	padding-left: 3px;
}
</style>
