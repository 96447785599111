<template>
	<div class="pui-form ml-4" ref="planiftimeslots">
		<timeslot-planif-modals :modelName="modelName"></timeslot-planif-modals>
		<v-form class="pui-timeslotplanifform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout ref="planifcab">
				<v-flex xs12>
					<v-layout v-if="this.method !== 'create'">
						<v-flex xs12 v-show="false">
							<pui-text-field :label="$t('timeslotplanif.idplanif')" v-model="model.idplanif" disabled required></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap v-show="false">
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								:label="this.$t('timeslotplanif.idterminal')"
								attach="idterminal"
								clearable
								:disabled="true"
								v-model="model"
								modelName="vlupterminales"
								:itemsToSelect="[{ idterminal: model.idterminal }]"
								:modelFormMapping="{ idterminal: 'idterminal' }"
								itemValue="idterminal"
								itemText="nombre"
								:order="{ nombre: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								id="idpuntocarga"
								attach="idpuntocarga"
								:label="$t('timeslotplanif.idpuntocarga')"
								clearable
								v-model="model"
								required
								:disabled="formDisabled || this.method === 'update'"
								modelName="vluppuntocarga"
								:itemsToSelect="[{ idpuntocarga: model.idpuntocarga }]"
								:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
								:itemValue="['idpuntocarga']"
								:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
								filterId="idterminal"
								:filterMap="{ idterminal: model.idterminal }"
								labelColumnStyles="xs2"
								valueColumnStyles="xs5"
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-date-field
								:label="$t('timeslotplanif.fechainicio')"
								v-model="model.fechainicio"
								:max="model.fechafin"
								required
								:disabled="formDisabled"
								id="fechainicio"
								labelColumnStyles="xs4"
								valueColumnStyles="xs8 md4"
								attach="fechainicio"
							></pui-date-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-date-field
								:label="$t('timeslotplanif.fechafin')"
								v-model="model.fechafin"
								:min="model.fechainicio"
								:disabled="formDisabled"
								id="fechafin"
								labelColumnStyles="xs4"
								valueColumnStyles="xs8 md4"
								attach="fechafin"
							></pui-date-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.observaciones"
								:label="this.$t('timeslotplanif.observaciones')"
								maxlength="300"
								clearable
								:disabled="formDisabled"
								labelColumnStyles="xs2"
								valueColumnStyles="xs8"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>

			<v-layout>
				<v-flex xs12>
					<timeslot-planif-detalle-form
						:formDisabled="formDisabled"
						:datos="model"
						:height="getPlanifHeight"
					></timeslot-planif-detalle-form>
				</v-flex>
			</v-layout>

			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import TimeslotPlanifDetalleForm from './timeslotplanifdetalle/TimeslotPlanifDetalleForm';
import TimeslotPlanifActions from './TimeslotPlanifActions';
import TimeslotPlanifModals from './TimeslotPlanifModals';

export default {
	name: 'timeslotplanif',
	mixins: [PuiFormMethodsMixin],
	components: {
		TimeslotPlanifDetalleForm,
		TimeslotPlanifModals
	},
	data() {
		return {
			modelName: 'timeslotplanif',
			actions: TimeslotPlanifActions.formactions
		};
	},
	computed: {
		getPlanifHeight() {
			const windowHeight = window.innerHeight;
			const mainPanelHeight = this.$refs.planiftimeslots.clientHeight;
			const hh = mainPanelHeight - 300;
			const h = windowHeight - hh;
			return h;
		}
	},
	methods: {
		afterGetData() {
			if (this.method === 'create') {
				this.model.detalles = [];
			}
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"timeslotplanif.idplanif": "Internal Code",
		"timeslotplanif.idpuntocarga": "Internal Code",
        "timeslotplanif.fechainicio": "Start date",
        "timeslotplanif.fechafin": "End date",
        "timeslotplanif.observaciones": "Comments"
    },
    "es": {
        "timeslotplanif.idplanif": "Código",
		"timeslotplanif.idpuntocarga": "Punto carga",
        "timeslotplanif.fechainicio": "Fecha inicio",
        "timeslotplanif.fechafin": "Fecha fin",
        "timeslotplanif.observaciones": "Observaciones"
    },
    "ca": {
        "timeslotplanif.idplanif": "Codi",
		"timeslotplanif.idpuntocarga": "Punt càrrega",
        "timeslotplanif.fechainicio": "Data inici",
        "timeslotplanif.fechafin": "Data fi",
        "timeslotplanif.observaciones": "Observacions"
    }
}
</i18n>
