const authorizeAction = {
	id: 'authorizeAction',
	selectionType: 'single',
	label: 'libramiento.authorize',
	functionality: 'LIBRAMIENTO_AUTORIZAR',
	checkAvailability: function (selected) {
		if (selected && selected[0] && selected[0].codestado === 'LS') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.fechaautorizacion = row.horainicio;

		this.$puiEvents.$emit('pui-modalDialogForm-authorize-' + model.name + '-show', row);
	}
};

const refuseAction = {
	id: 'refuseAction',
	selectionType: 'multiple',
	label: 'libramiento.refuse',
	functionality: 'LIBRAMIENTO_RECHAZAR',
	checkAvailability: function (selected) {
		if (selected && selected && (selected.codestado === 'LM' || selected.codestado === 'LS' || selected.codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		//row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-refusemotivo-' + model.name + '-show', row);
	}
};

const refuseSingleAction = {
	id: 'refuseAction',
	selectionType: 'single',
	label: 'libramiento.refuse',
	functionality: 'LIBRAMIENTO_RECHAZAR',
	checkAvailability: function (selected) {
		if (selected && (selected[0].codestado === 'LM' || selected[0].codestado === 'LS' || selected[0].codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-refusemotivo-' + model.name + '-show', row);
	}
};
/*
const modifyTPCAction = {
	id: 'modifyTPCAction',
	selectionType: 'single',
	label: 'libramiento.modifyTPC',
	functionality: 'LIBRAMIENTO_MOD_PCARGA',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'LM' || selected[0].codestado === 'LS' || selected[0].codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		var dataRegistry = registries[0];

		this.$puiEvents.$emit(`pui-modalDialog-modifyTPC-` + model.name + `-show`, dataRegistry);
		this.$puiEvents.$on(`pui-action-runAction-` + model.name, idpuntocarga => {
			this.$puiEvents.$off(`pui-action-runAction-` + model.name);

			var idlibramiento = dataRegistry.idlibramiento;
			var urlController = model.url.modifyTPC;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = { idlibramiento: idlibramiento, idpuntocarga: idpuntocarga };

			this.$puiRequests.patchRequest(
				urlController,
				params,
				response => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				e => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					if (e.response && e.response.data) {
						let message = e.response.data.message;
						this.$puiNotify.error(message);
					}
				}
			);
		});
	}
};
*/
const modifyTPCAction = {
	id: 'modifyTPCCAction',
	selectionType: 'multiple',
	label: 'libramiento.modifyTPC',
	functionality: 'LIBRAMIENTO_MOD_PCARGA',
	checkAvailability: function (selected) {
		if (selected && (selected.codestado === 'LM' || selected.codestado === 'LS' || selected.codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		if (registries.length > 1 && registries[0].fecharecogidareal !== undefined) {
			const fecharecogida = registries[0].fecharecogida;
			//const idpuntocarga = registries[0].idpuntocarga;
			//const horainicio = registries[0].horainicio;
			//const horafin = registries[0].horafin;
			for (var indexRegistries in registries) {
				if (fecharecogida !== registries[indexRegistries].fecharecogida) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.diffecharecogida');
					this.$puiNotify.error(message, title);
					return false;
				} /* else if (idpuntocarga !== registries[indexRegistries].idpuntocarga) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.difidpuntocarga');
					this.$puiNotify.error(message, title);
					return false;
				} else if (horainicio !== registries[indexRegistries].horainicio) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.difhorainicio');
					this.$puiNotify.error(message, title);
					return false;
				} else if (horafin !== registries[indexRegistries].horafin) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.difhorafin');
					this.$puiNotify.error(message, title);
					return false;
				}*/
			}
		}

		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		//row.headerPk = objectPk;
		row.isAction = true;
		row.idpuntocarga = null;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-modifyTPC-' + model.name + '-show', row);
	}
};
const modifyTPCSingleAction = {
	id: 'modifyTPCCAction',
	selectionType: 'single',
	label: 'libramiento.modifyTPC',
	functionality: 'LIBRAMIENTO_MOD_PCARGA',
	checkAvailability: function (selected) {
		if (selected && (selected[0].codestado === 'LM' || selected[0].codestado === 'LS' || selected[0].codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.idpuntocarga = null;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-modifyTPC-' + model.name + '-show', row);
	}
};

const authorizeBulkAction = {
	id: 'authorizeBulkAction',
	selectionType: 'general',
	label: 'libramiento.authorizebulk',
	functionality: 'LIBRAMIENTO_AUTORIZAR',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model) {
		const row = {};
		this.$puiEvents.$emit('pui-modalDialog-esperaautorizar-' + model.name + '-show', row);

		let urlController = this.$store.getters.getModelByName(model.name).url.libramientosautorizar;
		this.$puiRequests.getRequest(
			urlController,
			null,
			(response) => {
				if (response.data) {
					this.$puiEvents.$off(`pui-action-runAction-` + model.name);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					row.libramientosautorizar = response.data;
					this.$puiEvents.$emit('pui-modalDialogForm-authorizebulk-' + model.name + '-show', row);
				}
			},
			(error) => {
				this.$puiEvents.$off(`pui-action-runAction-` + model.name);
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				this.$puiNotify.error(error);
			}
		);
	}
};

const confirmSingleAction = {
	id: 'confirmAction',
	selectionType: 'single',
	label: 'libramiento.confirm',
	functionality: 'LIBRAMIENTO_CONFIRM_ENT_TERMINALL',
	checkAvailability: function (registries) {
		const registry = Array.isArray(registries) ? registries[0] : registries;
		if (registry && (registry.codestado === 'LM' || registry.codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', row);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var idlibramiento = dataRegistry.idlibramiento;
			var urlController = model.url.confirm;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = { idlibramiento: idlibramiento };
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

export default {
	gridactions: [authorizeAction, confirmSingleAction, refuseAction, modifyTPCAction, authorizeBulkAction],
	formactions: [authorizeAction, confirmSingleAction, refuseSingleAction, modifyTPCSingleAction]
};
