const refuseAction = {
	id: 'refuseAction',
	selectionType: 'multiple',
	label: 'libramiento.refuse',
	functionality: 'LIBRAMIENTO_RECHAZAR',
	checkAvailability: function (selected) {
		if (selected && selected && (selected.codestado === 'LM' || selected.codestado === 'LS' || selected.codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		//row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-refusemotivo-' + model.name + '-show', row);
	}
};

const refuseSingleAction = {
	id: 'refuseAction',
	selectionType: 'single',
	label: 'libramiento.refuse',
	functionality: 'LIBRAMIENTO_RECHAZAR',
	checkAvailability: function (selected) {
		if (selected && (selected[0].codestado === 'LM' || selected[0].codestado === 'LS' || selected[0].codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		row.simple = true;
		this.$puiEvents.$emit('pui-modalDialogForm-refusemotivo-' + model.name + '-show', row);
	}
};
/*
const modifyTPCAction = {
	id: 'modifyTPCAction',
	selectionType: 'single',
	label: 'libramiento.modifyTPC',
	functionality: 'LIBRAMIENTO_MOD_PCARGA',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'LM' || selected[0].codestado === 'LS' || selected[0].codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		var dataRegistry = registries[0];

		this.$puiEvents.$emit(`pui-modalDialog-modifyTPC-` + model.name + `-show`, dataRegistry);
		this.$puiEvents.$on(`pui-action-runAction-` + model.name, idpuntocarga => {
			this.$puiEvents.$off(`pui-action-runAction-` + model.name);

			var idlibramiento = dataRegistry.idlibramiento;
			var urlController = model.url.modifyTPC;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = { idlibramiento: idlibramiento, idpuntocarga: idpuntocarga };

			this.$puiRequests.patchRequest(
				urlController,
				params,
				response => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				e => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					if (e.response && e.response.data) {
						let message = e.response.data.message;
						this.$puiNotify.error(message);
					}
				}
			);
		});
	}
};
*/
const modifyTPCAction = {
	id: 'modifyTPCGAction',
	selectionType: 'multiple',
	label: 'libramiento.modifyTPC',
	functionality: 'LIBRAMIENTO_MOD_PCARGA',
	checkAvailability: function (selected) {
		if (selected && (selected.codestado === 'LM' || selected.codestado === 'LS' || selected.codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		if (registries.length > 1 && registries[0].fecharecogidareal !== undefined) {
			const fecharecogida = registries[0].fecharecogida;
			//const idpuntocarga = registries[0].idpuntocarga;
			//const hora = registries[0].codestado === 'LT' || registries[0].codestado === 'LM' ? registries[0].horarecogida : registries[0].horainicio;
			for (var indexRegistries in registries) {
				if (fecharecogida !== registries[indexRegistries].fecharecogida) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.diffecharecogida');
					this.$puiNotify.error(message, title);
					return false;
				} /* else if (idpuntocarga !== registries[indexRegistries].idpuntocarga) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.difidpuntocarga');
					this.$puiNotify.error(message, title);
					return false;
				} else if (
					hora !==
					(registries[indexRegistries].codestado === 'LT'
						? registries[indexRegistries].horarecogida
						: registries[indexRegistries].horainicio)
				) {
					this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					var message = this.$puiI18n.t('action.difhorarecgidahorainicio');
					this.$puiNotify.error(message, title);
					return false;
				}*/
			}
		}
		const row = registries[0];
		row.idterminal = null;
		row.idpuntocarga = null;
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		//row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-modifyTPC-' + model.name + '-show', row);
	}
};
const modifyTPCSingleAction = {
	id: 'modifyTPCGAction',
	selectionType: 'single',
	label: 'libramiento.modifyTPC',
	functionality: 'LIBRAMIENTO_MOD_PCARGA',
	checkAvailability: function (selected) {
		if (selected && (selected[0].codestado === 'LM' || selected[0].codestado === 'LS' || selected[0].codestado === 'LT')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.registries = registries;
		row.simple = true;
		if (!row.idterminal) row.idterminal = null;
		row.idpuntocarga = null;
		this.$puiEvents.$emit('pui-modalDialogForm-modifyTPC-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [refuseAction, modifyTPCAction],
	formactions: [refuseSingleAction, modifyTPCSingleAction]
};
