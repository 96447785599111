<template>
	<div class="libramiento puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('libramiento.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('libramiento.comprador')" :value="getComprador"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('libramiento.fecharecogida')" :value="getFecharecogida"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('libramiento.peso')" :value="getPeso"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('libramiento.timeslot')" :value="getTimeslot"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'libramientotermgformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'libramientotermg'
		};
	},
	computed: {
		getMercancia() {
			return this.model && this.model.mercancia ? this.model.mercancia : '';
		},
		getFecharecogida() {
			return this.model && this.model.fecharecogida
				? this.$dateTimeUtils.formatDate(this.model.fecharecogida, this.$store.getters.dateFormat)
				: '';
		},
		getPeso() {
			return this.model && this.model.pesosolicitado ? this.formatNumber(this.model.pesosolicitado) : '';
		},
		getTimeslot() {
			return this.model && this.model.horainicio && this.model.horafin
				? this.$t('libramiento.de') + ` ${this.model.horainicio} ` + this.$t('libramiento.a') + ` ${this.model.horafin}`
				: '';
		},
		getComprador() {
			return this.model && this.model.comprador ? this.model.comprador : '';
		}
	},
	methods: {
		extendFilter(filter) {
			filter.model = 'libramientotermg';
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"libramiento.mercancia": "Goods",
		"libramiento.fecharecogida": "Pick up date",
		"libramiento.peso": "Number of Kg.",
		"libramiento.timeslot": "Time slot",
		"libramiento.de": "From",
		"libramiento.a": "to",
		"libramiento.comprador": "Buyer"
	},
	"es": {
		"libramiento.mercancia": "Mercancía",
		"libramiento.fecharecogida": "Fecha de recogida",
		"libramiento.peso": "Nº Kg.",
		"libramiento.timeslot": "Time slot",
		"libramiento.de": "De",
		"libramiento.a": "a",
		"libramiento.comprador": "Comprador"
	},
	"ca": {
		"libramiento.mercancia": "Mercaderia",
		"libramiento.fecharecogida": "Data de recollida",
		"libramiento.peso": "Num. Kg.",
		"libramiento.timeslot": "Time slot",
		"libramiento.de": "De",
		"libramiento.a": "a",
		"libramiento.comprador": "Comprador"
	}
}
</i18n>
