<template>
	<div class="pui-form" v-if="modelLoaded">
		<pui-form-header :showHeader="!isCreatingElement">
			<encargocompformheader :modelPk="parentModelPk"></encargocompformheader>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 sm12 md10 lg10 xl10>
					<libramiento-form-base
						:model="model"
						:formDisabled="formDisabled"
						tipoLibramiento="C"
						:modelLoaded="modelLoaded"
						:conductorFixedFilter="fixedFilterConductor"
					></libramiento-form-base>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="popUpBeforeSave()">{{
										this.$t('form.save')
									}}</v-btn>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import LibramientoFormBase from '../LibramientoFormBase';
import LibramientoCompActions from './LibramientoCompActions';

export default {
	name: 'libramientocompform',
	mixins: [PuiFormMethodsMixin],
	components: { LibramientoFormBase },
	data() {
		return {
			modelName: 'libramientocomp',
			actions: LibramientoCompActions.formactions,
			parentModelPk: null,
			fixedFilterConductor: null
		};
	},
	methods: {
		// Override
		afterGetData() {
			this.parentModelPk = { idencargo: this.model.idencargo };
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>
<i18n>
{
    "en": {
    },
    "es": {
    },
    "ca": {
    }
}
</i18n>
