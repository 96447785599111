<template>
	<v-form class="pui-usuarioaplicacionform ml-4 mr-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
		<usuario-aplicacion-modals :modelName="modelName"></usuario-aplicacion-modals>
		<usuario-aplicacion-form-base
			:model="model"
			:formDisabled="formDisabled"
			:modelLoaded="modelLoaded"
			:method="method"
			:tipousuarioFixedFilter="tipousuarioFixedFilter"
		></usuario-aplicacion-form-base>
		<pui-form-footer>
			<v-layout>
				<v-flex xs12 sm12 md8 lg8 xl8 :mb-5="isMobile">
					<v-layout>
						<v-flex xs12 md4>
							<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
						</v-flex>
						<v-flex xs12 md8>
							<v-layout justify-end fill-height>
								<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
									this.$t('form.save')
								}}</v-btn>

								<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</pui-form-footer>
	</v-form>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import UsuarioAplicacionFormBase from './UsuarioAplicacionFormBase';
import UsuarioAplicacionActions from './UsuarioAplicacionActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuarioaplicacion',
	mixins: [PuiFormMethodsMixin],
	components: { UsuarioAplicacionFormBase, UsuarioAplicacionModals },
	data() {
		return {
			modelName: 'usuarioaplicacion',
			actions: UsuarioAplicacionActions.formactions,
			tipousuarioFixedFilter: {
				groups: [],
				groupOp: 'or',
				rules: [{ field: 'codtipusuario', op: 'ne', data: 6 }]
			}
		};
	},
	methods: {
		afterGetData() {
			this.model.matriconductor2 = this.model.matriconductor;
		},
		beforeSave() {
			this.model.matriconductor = this.model.matriconductor2;
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style lang="postcss"></style>
<i18n>
{
	"en": {
		"usuarioaplicacion.title": "User data",
		"usuarioaplicacion.email": "Email",
		"usuarioaplicacion.name": "Name",
		"usuarioaplicacion.language": "Language",
		"usuarioaplicacion.password": "Password",
		"usuarioaplicacion.repeatpassword": "Repeat Password",
		"usuarioaplicacion.disabled": "Disabled",
		"usuarioaplicacion.profiles": "User profiles",
		"usuarioaplicacion.changeImage":"Select ...",
		"usuarioaplicacion.idtipousuario":"User type",
		"usuarioaplicacion.idterminal":"Terminal",
		"usuarioaplicacion.idcomprador":"Buyer",
		"usuarioaplicacion.idtransportista":"Transportista",
		"usuarioaplicacion.disabled_date": "Disabled date",
		"usuarioaplicacion.nifconductor": "Tax Identifier",
		"usuarioaplicacion.matriconductor": "License plate",
		"usuarioaplicacion.remolqueconductor": "Trailer",

		"usuarioaplicacion.validate.repeatPass":"Passwords must match",
		"usuarioaplicacion.validate.email":"The email is not well-formed 'email@domain.com'"
	},
	"es": {
		"usuarioaplicacion.title": "Información de usuario",
		"usuarioaplicacion.email": "Email",
		"usuarioaplicacion.name": "Nombre",
		"usuarioaplicacion.language": "Idioma",
		"usuarioaplicacion.password": "Contraseña",
		"usuarioaplicacion.repeatpassword": "Repetir contraseña",
		"usuarioaplicacion.disabled": "Activo",
		"usuarioaplicacion.profiles": "Perfiles del usuario",
		"usuarioaplicacion.changeImage":"Seleccionar ...",
		"usuarioaplicacion.change":"Seleccionar ...",
		"usuarioaplicacion.idtipousuario":"Tipo usuario",
		"usuarioaplicacion.idterminal":"Terminal",
		"usuarioaplicacion.idcomprador":"Comprador",
		"usuarioaplicacion.idtransportista":"Transportista",
		"usuarioaplicacion.disabled_date": "Fecha desactivación",
		"usuarioaplicacion.nifconductor": "Identificador fiscal",
		"usuarioaplicacion.matriconductor": "Matrícula",
		"usuarioaplicacion.remolqueconductor": "Remolque",

		"usuarioaplicacion.validate.repeatPass":"Las Contraseñas tienen que coincidir",
		"usuarioaplicacion.validate.email":"El email no está bien formado 'email@dominio.com'"
	},
	"ca": {
		"usuarioaplicacion.title": "Informació d'usuari",
		"usuarioaplicacion.email": "Email",
		"usuarioaplicacion.name": "Nom",
		"usuarioaplicacion.language": "Idioma",
		"usuarioaplicacion.password": "Contraseña",
		"usuarioaplicacion.repeatpassword": "Repetir Password",
		"usuarioaplicacion.disabled": "Actiu",
		"usuarioaplicacion.profiles": "Perfils d'usuari",
		"usuarioaplicacion.changeImage":"Seleccionar ...",
		"usuarioaplicacion.idtipousuario":"Tipus usuari",
		"usuarioaplicacion.idterminal":"Terminal",
		"usuarioaplicacion.idcomprador":"Comprador",
		"usuarioaplicacion.idtransportista":"Transportista",
		"usuarioaplicacion.disabled_date": "Data baixa",
		"usuarioaplicacion.nifconductor": "Identificador fiscal conductor",
		"usuarioaplicacion.matriconductor": "Matrícula conductor",
		"usuarioaplicacion.remolqueconductor": "Remolc conductor",

		"usuarioaplicacion.validate.repeatPass":"Les Contrasenyes han de coincidir",
		"usuarioaplicacion.validate.email":"L'email no està ben format 'email@domini.com'"
	}
}
</i18n>
