<template>
	<div class="pui-form mt-4">
		<v-form class="pui-documentacioncabform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout class="pui-form-layout">
				<v-flex xs8>
					<v-layout>
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								attach="idtipodocumento"
								:label="$t('documentacion.idtipodocumento')"
								clearable
								disabled
								v-model="model"
								modelName="vluptipodocumento"
								:itemsToSelect="[{ idtipodocumento: model.idtipodocumento }]"
								:modelFormMapping="{ idtipodocumento: 'idtipodocumento' }"
								itemValue="idtipodocumento"
								itemText="descripcion"
								labelColumnStyles="xs3"
								valueColumnStyles="xs12 md4 lg4"
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-text-field
								:label="$t('documentacion.nombre')"
								v-model="model.nombre"
								maxlength="100"
								required
								disabled
								labelColumnStyles="xs3"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs3>
							<pui-text-field
								:label="$t('documentacion.url')"
								v-model="model.url"
								maxlength="1000"
								required
								:disabled="formDisabled"
								valueColumnStyles="delUrl"
							></pui-text-field>
						</v-flex>
						<v-flex xs9>
							<a :href="model.url" target="_blank">{{ model.url }}</a>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'documentacioncab',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'documentacioncab'
		};
	}
};
</script>
<style lang="postcss">
.delUrl {
	display: none !important;
}
.labelUrl {
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.6px;
	color: var(--N-400);
	padding-top: 1px;
	display: inline-block;
}
.urlLabel {
	display: inline-block;
}
</style>

<i18n>
{
    "en": {
        "documentacion.iddoc": "Code",
		"documentacion.nombre": "Name",
		"documentacion.idtipousuario": "User type",
        "documentacion.url": "URL",
        "documentacion.observaciones": "Comments",
		"documentacion.idtipodocumento": "Document type"
    },
    "es": {
        "documentacion.iddoc": "Código",
		"documentacion.nombre": "Nombre",
		"documentacion.idtipousuario": "Tipo de usuario",
        "documentacion.url": "URL",
        "documentacion.observaciones": "Observaciones",
		"documentacion.idtipodocumento": "Tipo documento"
    },
    "ca": {
        "documentacion.iddoc": "Codi",
		"documentacion.nombre": "Nom",
		"documentacion.idtipousuario": "Tipus d'usuari",
        "documentacion.url": "URL",
        "documentacion.observaciones": "Observacions",
		"documentacion.idtipodocumento": "Tipus document"
    }
}
</i18n>
