<template>
	<div class="pui-form">
		<asignacion-trans-modals :modelName="modelName"></asignacion-trans-modals>
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<asignaciontransformheader></asignaciontransformheader>
		</pui-form-header>
		<v-form class="pui-asignaciontransform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('asignacion.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#libramientotrans'" v-if="!isCreatingElement">{{ $t('asignaciontrans.libramientos') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap>
						<v-flex xs12 sm12 md8 lg8 xl8>
							<v-layout wrap class="pui-form-layout">
								<v-flex xs12>
									<pui-select
										v-if="modelLoaded"
										attach="idcomprador"
										:label="$t('asignacion.idcomprador')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupcompradores"
										:itemsToSelect="[{ idcomprador: model.idcomprador }]"
										:modelFormMapping="{ idcomprador: 'idcomprador' }"
										:itemValue="['idcomprador']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-date-field
										:label="$t('asignacion.fecharecogida')"
										v-model="model.fecharecogida"
										required
										:disabled="formDisabled"
										valueColumnStyles="xs12 sm4 md4 xl2"
										id="fecharecogida"
										attach="fecharecogida"
									></pui-date-field>
								</v-flex>
								<v-flex xs12>
									<pui-select
										v-if="modelLoaded"
										attach="idmercancia"
										:label="$t('asignacion.idmercancia')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupmercancias"
										:itemsToSelect="[{ idmercancia: model.idmercancia }]"
										:modelFormMapping="{ idmercancia: 'idmercancia' }"
										:itemValue="['idmercancia']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-number-field
										:label="$t('asignacion.pesoasignado')"
										v-model="model.pesoasignado"
										decimals="0"
										max="8000000"
										required
										:disabled="formDisabled"
										valueColumnStyles="xs12 sm6 md4 xl2"
										id="peso"
									></pui-number-field>
								</v-flex>
								<v-flex xs12>
									<pui-select
										v-if="modelLoaded"
										attach="idterminal"
										:label="$t('asignacion.idterminal')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupterminales"
										:itemsToSelect="[{ idterminal: model.idterminal }]"
										:modelFormMapping="{ idterminal: 'idterminal' }"
										:itemValue="['idterminal']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('asignacion.contrato')"
										v-model="model.contrato"
										maxlength="50"
										required
										:disabled="formDisabled"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('asignacion.aplicacion')"
										v-model="model.aplicacion"
										maxlength="50"
										:disabled="formDisabled"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-checkbox
										:label="$t('asignacion.indtienelibramientos')"
										v-model="model.indtienelibramientos"
										true-value="1"
										false-value="0"
										disabled
									></pui-checkbox>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`libramientotrans`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="libramientotransgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idasignacion: 'idasignacion' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">
										{{ this.$t('form.save') }}
									</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import AsignacionTransActions from './AsignacionTransActions';
import AsignacionTransModals from './AsignacionTransModals';

export default {
	name: 'asignaciontrans',
	mixins: [PuiFormMethodsMixin],
	components: { AsignacionTransModals },
	data() {
		return {
			modelName: 'asignaciontrans',
			actions: AsignacionTransActions.formactions,
			modelLoadedNew: false,
			modeldetail: true,
			tabmodel: 'maintab',
			parentModelPk: { idencargo: 2 }
		};
	},
	methods: {
		// override
		afterGetData() {
			this.parentModelPk = { idencargo: this.model.idencargo };
			this.modelLoadedNew = this.modelLoaded;
		}
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"asignacion.idencargo": "Order code",
		"asignacion.idcomprador": "Buyer",
		"asignacion.idmercancia": "Good",
		"asignacion.idterminal": "Management terminal",
		"asignacion.pesoasignado": "Weight(kg)",
		"asignacion.contrato": "Contract",
		"asignacion.aplicacion": "Application",
		"asignacion.fecharecogida": "Pick-up date",
		"asignacion.indtienelibramientos":"Have deliverance",

		"asignacion.maintab":"Assignament",
		"asignacion.libramientos":"Riddances"
    },
    "es": {
        "asignacion.idencargo": "Código encargo",
		"asignacion.idcomprador": "Comprador",
		"asignacion.idmercancia": "Mercancía",
		"asignacion.idterminal": "Terminal gestora",
		"asignacion.pesoasignado": "Peso(kg)",
		"asignacion.contrato": "Contrato",
		"asignacion.aplicacion": "Aplicación",
		"asignacion.fecharecogida": "Fecha de recogida",
		"asignacion.indtienelibramientos":"Tiene libramientos",

		"asignacion.maintab":"Asignación",
		"asignacion.libramientos":"Libramientos"
    },
    "ca": {
        "asignacion.idencargo": "Code Encàrrec",
		"asignacion.idcomprador": "Comprador",
		"asignacion.idmercancia": "Mercaderia",
		"asignacion.idterminal": "Terminal gestora",
		"asignacion.pesoasignado": "Pes(kg)",
		"asignacion.contrato": "Contracte",
		"asignacion.aplicacion": "Aplicació",
		"asignacion.fecharecogida": "Data de recollida",
		"asignacion.indtienelibramientos":"Té lliuraments",

		"asignacion.maintab":"Assignació",
		"asignacion.libramientos":"Lliuraments"
    }
}
</i18n>
