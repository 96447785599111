<template>
	<div>
		<libramiento-termg-modals :modelName="model"></libramiento-termg-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import LibramientoTermgActions from './LibramientoTermgActions';
import LibramientoTermgModals from './LibramientoTermgModals';

export default {
	name: 'libramientotermggrid',
	components: { LibramientoTermgModals },
	data() {
		return {
			model: 'libramientotermg',
			actions: LibramientoTermgActions.gridactions,
			columnDefs: {
				fecharecogida: { withoutHours: true }
			}
		};
	},
	computed: {
		getEstadoLR() {
			return 'LR';
		},
		getPublico() {
			return '1';
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
		"grid.libramientotermg.idlibramiento": "Code",
   		"grid.libramientotermg.contrato": "Contract number",
		"grid.libramientotermg.mercancia": "Good",
		"grid.libramientotermg.idtransportista": "Carrier code",
		"grid.libramientotermg.transportista": "Carrier",
		"grid.libramientotermg.fecharecogida": "Pick-up date",
		"grid.libramientotermg.fechainicio": "Date slot beginnig",
		"grid.libramientotermg.fechafin": "Date slot end",
		"grid.libramientotermg.horainicio": "Time slot beginnig",
		"grid.libramientotermg.horafin": "Time slot end",
		"grid.libramientotermg.horarecogida": "Pick up time",
		"grid.libramientotermg.idterminal": "Terminal code",
		"grid.libramientotermg.terminalcarga": "Charging terminal",
		"grid.libramientotermg.indpcmodificado": "Check updated loading point",
		"grid.libramientotermg.pcmodificado": "Updated loading point",
		"grid.libramientotermg.estado": "State",
		"grid.libramientotermg.refusemotivo.title":"Refuse riddance",
		"grid.libramientotermg.refusemotivo":"Reason for refusal",
		"grid.libramientotermg.idpuntocarga": "Loading point code",
		"grid.libramientotermg.codestado": "State code",
		"grid.libramientotermg.pesosolicitado": "Weight(kg)",
		"grid.libramientotermg.modifyTPC.title":"Modify loading point",
		"grid.libramientotermg.modal.puntocarga": "Loading point",
		"grid.libramientotermg.modal.terminal": "Charging terminal",
		"grid.libramientotermg.comprador": "Buyer",
		"grid.libramientotermg.fecharecogidareal": "Real pick-up date",
		"grid.libramientotermg.fechacreacion":"Creation date",
		"grid.libramientotermg.refencargo":"Order reference"
    },
    "es": {
        "grid.libramientotermg.idlibramiento": "Código",
        "grid.libramientotermg.contrato": "Número de contrato",
		"grid.libramientotermg.mercancia": "Mercancía",
		"grid.libramientotermg.idtransportista": "Código transportista",
		"grid.libramientotermg.transportista": "Transportista",
		"grid.libramientotermg.fecharecogida": "Fecha de recogida",
		"grid.libramientotermg.fechainicio": "Inicio fecha slot",
		"grid.libramientotermg.fechafin": "Fin fecha slot",
		"grid.libramientotermg.horainicio": "Inicio time slot",
		"grid.libramientotermg.horafin": "Fin time slot",
		"grid.libramientotermg.horarecogida": "Hora de recogida",
		"grid.libramientotermg.idterminal": "Código terminal",
		"grid.libramientotermg.terminalcarga": "Terminal de carga",
		"grid.libramientotermg.indpcmodificado": "Ind. punto de carga modificado",
		"grid.libramientotermg.pcmodificado": "P.C. modificado",
		"grid.libramientotermg.estado": "Estado",
		"grid.libramientotermg.codestado": "Código estado",
		"grid.libramientotermg.pesosolicitado": "Peso (Kg)",
		"grid.libramientotermg.refusemotivo.title":"Rechazar libramiento",
		"grid.libramientotermg.refusemotivo":"Motivo de rechazo",
		"grid.libramientotermg.idpuntocarga": "Código punto de carga",
		"grid.libramientotermg.modifyTPC.title":"Modificar punto de carga",
		"grid.libramientotermg.modal.puntocarga": "Punto de carga",
		"grid.libramientotermg.modal.terminal": "Terminal de carga",
		"grid.libramientotermg.comprador": "Comprador",
		"grid.libramientotermg.fecharecogidareal": "Fecha de recogida real",
		"grid.libramientotermg.fechacreacion":"Fecha creación",
		"grid.libramientotermg.refencargo":"Referencia encargo"
    },
    "ca": {
        "grid.libramientotermg.idlibramiento": "Codi",
        "grid.libramientotermg.contrato": "Número de contracte",
		"grid.libramientotermg.mercancia": "Mercaderia",
		"grid.libramientotermg.idtransportista": "Codi transportista",
		"grid.libramientotermg.transportista": "Transportista",
		"grid.libramientotermg.fecharecogida": "Data de recollida",
		"grid.libramientotermg.fechainicio": "Inici data slot",
		"grid.libramientotermg.fechafin": "Fi data slot",
		"grid.libramientotermg.horainicio": "Inici time slot",
		"grid.libramientotermg.horafin": "Fi time slot",
		"grid.libramientotermg.horarecogida": "Hora de recollida",
		"grid.libramientotermg.idterminal": "Codi terminal",
		"grid.libramientotermg.terminalcarga": "Terminal de càrrega",
		"grid.libramientotermg.pcmodificado": "Punt càrrega modificat",
		"grid.libramientotermg.indpcmodificado": "Ind. Punt de càrrega modificat",
		"grid.libramientotermg.estado": "Estat",
		"grid.libramientotermg.codestado": "Codi estat",
		"grid.libramientotermg.pesosolicitado": "Pes (Kg)",
		"grid.libramientotermg.refusemotivo.title":"Rebutjar lliurament",
		"grid.libramientotermg.refusemotivo":"Motiu de rebuig",
		"grid.libramientotermg.idpuntocarga": "Codi punt de càrrega",
		"grid.libramientotermg.modifyTPC.title":"Modificar punt de càrrega",
		"grid.libramientotermg.modal.puntocarga": "Punt de càrrega",
		"grid.libramientotermg.modal.terminal": "Terminal de càrrega",
		"grid.libramientotermg.comprador": "Comprador",
		"grid.libramientotermg.fecharecogidareal": "Data de recollida real",
		"grid.libramientotermg.fechacreacion":"Data creació",
		"grid.libramientotermg.refencargo":"Referència encàrrec"
 	}
}
</i18n>
