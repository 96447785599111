<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtcencargosterm.refusemotivo.title')"
			:modelName="modelName"
			:dialogName="dialogNameRefusemotivo"
			v-model="modalData"
			:showDialogProp="showDialogRefusemotivo"
			:onOk="onOkRefusemotivo"
			:componentHeaderName="'encargotermformheader'"
			:widthDialog="700"
		>
			<template slot="message">
				<v-layout class="justify-center">
					<v-flex xs12>
						<pui-select
							attach="codigo"
							:label="$t('vtcencargosterm.refusemotivo')"
							clearable
							required
							v-model="modalData"
							modelName="vlupmotivoanulrech"
							:itemsToSelect="[{ codigo: modalData.codmotivoanulrechazo }]"
							:modelFormMapping="{ codigo: 'codmotivoanulrechazo' }"
							:itemValue="['codigo']"
							:itemText="(item) => `${item.descripcion}`"
							:fixedFilter="getEstadoERFixedFilter"
							:order="{ descripcion: 'asc' }"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout class="justify-center">
					<v-flex xs12>
						<pui-text-area
							v-model="modalData.obsrechazo"
							:label="this.$t('vtcencargosterm.obsrechazo')"
							maxlength="1000"
							clearable
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog
			v-if="modals.confirm.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.confirm.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.confirm.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<encargotermformheader v-if="parentModelPk" :modelPk="parentModelPk"></encargotermformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12>
									<pui-text-area
										v-model="modals.confirm.obsvalidacion"
										:label="modals.confirm.labelText"
										maxlength="1000"
										clearable
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ getTextoConfirm }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'encargotermmodals',
	data() {
		return {
			modals: {
				confirm: {
					disableOk: false,
					show: false,
					titleText: this.$t('vtcencargosterm.confvalidate.title'),
					prevalidacionOk: false,
					parentModelPk: null,
					obsvalidacion: null,
					labelText: this.$t('encargo.obsvalidacion')
				}
			},
			modalData: {},
			dialogNameRefusemotivo: 'refusemotivo',
			showDialogRefusemotivo: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		onOkRefusemotivo(modalData) {
			let body = {
				idencargo: modalData.headerPk.idencargo,
				codrechazo: modalData.codmotivoanulrechazo,
				obsrechazo: modalData.obsrechazo
			};
			let urlController = this.$store.getters.getModelByName(this.modelName).url.refuse;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('encargo.refuse');
			this.$puiRequests.patchRequest(
				urlController,
				body,
				(response) => {
					this.showDialogRefusemotivo = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.showDialogRefusemotivo = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		subscribeToModalDialogEvent() {
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.setPrevalidacionOk(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName, this.modals.confirm.obsvalidacion);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.setPrevalidacionOk(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.setPrevalidacionOk(data.prevalidate);
				this.parentModelPk = data.headerPk;
				this.setShowConfirm(true);
			});
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameRefusemotivo}-${this.modelName}-show`, (data) => {
				this.showDialogRefusemotivo = true;
			});
		},
		setShowConfirm(action) {
			this.modals.confirm.show = action;
		},
		setPrevalidacionOk(prevalidacionOk) {
			this.modals.confirm.prevalidacionOk = prevalidacionOk;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	},
	computed: {
		getTextoConfirm() {
			if (this.modals.confirm.prevalidacionOk) {
				return this.$t('vtcencargosterm.confirm.validate');
			} else {
				return this.$t('vtcencargosterm.confirm.validateyfinalizar');
			}
		},
		getEstadoER() {
			return 'ER';
		},
		getEstadoERFixedFilter() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'indbaja', op: 'eq', data: '0' },
							{ field: 'indbaja', op: 'nu', data: null }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: 'ER' }] }
				],
				groupOp: 'and',
				rules: []
			};
		}
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtcencargosterm.confirm.validate":"The order will be validate. Would you like to continue?",
		"vtcencargosterm.confirm.validateyfinalizar":"The order has unfinished consultations. If you validate these inquiries, they will be finalized. Would you like to continue?",
		"vtcencargosterm.refusemotivo":"Reason for refusal",
		"vtcencargosterm.refusemotivo.title":"Refuse order",
		"vtcencargosterm.confvalidate.title":"Validate order",
		"vtcencargosterm.obsrechazo":"Rejection Remarks"
	},
	"es": {
		"vtcencargosterm.confirm.validate":"Se validará el encargo. ¿Desea continuar?",
		"vtcencargosterm.confirm.validateyfinalizar":"El encargo tiene consultas no finalizadas. Si lo valida estas consultas se finalizarán. ¿Desea continuar?",
		"vtcencargosterm.refusemotivo":"Motivo de rechazo",
		"vtcencargosterm.refusemotivo.title":"Rechazar encargo",
		"vtcencargosterm.confvalidate.title":"Validar encargo",
		"vtcencargosterm.obsrechazo":"Observaciones rechazo"

	},
	"ca": {
		"vtcencargosterm.confirm.validate":"Es validarà l'encàrrec. Voleu continuar?",
		"vtcencargosterm.confirm.validateyfinalizar":"L'encàrrec té consultes no finalitzades. Si el valida aquestes consultes es finalitzaran. Voleu continuar?",
		"vtcencargosterm.anularmotivo":"Motiu de rebuig",
		"vtcencargosterm.refusemotivo":"Motiu de rebuig",
		"vtcencargosterm.refusemotivo.title":"Rebutjar encàrrec",
		"vtcencargosterm.confvalidate.title":"Validar encàrrec",
		"vtcencargosterm.obsrechazo":"Observacions rebuig"
	}
}
</i18n>
