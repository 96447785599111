<template>
	<pui-datatable v-if="!masterDetail" :modelName="model" :actions="actions" showFilterListBtn :modelColumnDefs="columnDefs"></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:actions="actions"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:parentModelName="parentModelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:isEditableRegistry="isEditableRegistry"
		:modelColumnDefs="columnDefs"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import HistoricoAccionTerminalActions from './HistoricoAccionTerminalActions';

export default {
	name: 'historicoaccionterminalgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'historicoaccionterminal',
			actions: HistoricoAccionTerminalActions.gridactions,
			columnDefs: {
				ejecutadocliente: {
					render: (data, type, row, meta) => {
						if (data == '1') {
							return "<center><i class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i></center>";
						} else {
							return '';
						}
					}
				},
				ejecutadoapi: {
					render: (data, type, row, meta) => {
						if (data == '1') {
							return "<center><i class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i></center>";
						} else {
							return '';
						}
					}
				}
			}
		};
	},
	methods: {
		isEditableRegistry() {
			return false;
		}
	}
};
</script>

<i18n>
</i18n>
