<template>
	<div>
		<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import TipoUsuarioPerfilActions from './TipoUsuarioPerfilActions';

export default {
	name: 'tipousuarioperfilgrid',
	data() {
		return {
			model: 'tipousuarioperfil',
			actions: TipoUsuarioPerfilActions.gridactions
		};
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"grid.tipousuarioperfil.profile": "Profile",
		"grid.tipousuarioperfil.name": "Description",
		"grid.tipousuarioperfil.lang": "Language",
		"grid.tipousuarioperfil.idtipousuario":"User type code",
		"grid.tipousuarioperfil.tipousuario":"User type",
		"grid.tipousuarioperfil.observaciones":"Comments",
		"grid.tipousuarioperfil.indseleccionado":"Ind. Selected by default",
		"grid.tipousuarioperfil.seleccionado":"Selected by default",
		"grid.tipousuarioperfil.fechacreacion":"Creation date"
	},
	"es": {
		"grid.tipousuarioperfil.profile": "Perfil",
		"grid.tipousuarioperfil.name": "Descripción",
		"grid.tipousuarioperfil.lang": "Idioma",
		"grid.tipousuarioperfil.idtipousuario":"Código tipo usuario",
		"grid.tipousuarioperfil.tipousuario":"Tipo usuario",
		"grid.tipousuarioperfil.observaciones":"Observaciones",
		"grid.tipousuarioperfil.indseleccionado":"Ind. Seleccionado por defecto",
		"grid.tipousuarioperfil.seleccionado":"Seleccionado por defecto",
		"grid.tipousuarioperfil.fechacreacion":"Fecha creación"
	},
	"ca": {
		"grid.tipousuarioperfil.profile": "Perfil",
		"grid.tipousuarioperfil.name": "Descripció",
		"grid.tipousuarioperfil.lang": "Idioma",
		"grid.tipousuarioperfil.idtipousuario":"Codi tipus usuari",
		"grid.tipousuarioperfil.tipousuario":"Tipus usuari",
		"grid.tipousuarioperfil.observaciones":"Observacions",
		"grid.tipousuarioperfil.indseleccionado":"Ind. Seleccionat per defecte",
		"grid.tipousuarioperfil.seleccionado":"Seleccionat per defecte",
		"grid.tipousuarioperfil.fechacreacion":"Data creació"
	}
}
</i18n>
