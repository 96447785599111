<template>
	<div class="pui-form" v-if="modelLoaded">
		<consultarecibida-modals :modelName="modelName"></consultarecibida-modals>
		<pui-form-header :showHeader="!isCreatingElement">
			<consultarecibidaformheader></consultarecibidaformheader>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('consultarecibida.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#lotegenerado'" v-if="!isCreatingElement">{{ $t('consultarecibida.lotesgenerados') }}</v-tab>
				<v-tab :key="2" :href="'#consultarealizada'" v-if="!isCreatingElement">{{ $t('consultarecibida.subconsultas') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<pui-field-set>
								<v-layout wrap v-if="!showParentModelHeader && isCreatingElement">
									<v-flex xs12>
										<pui-select
											id="idencargo"
											:label="this.$t('consultarecibida.idencargo')"
											attach="idencargo"
											clearable
											required
											:disabled="formDisabled || this.method != 'create'"
											v-model="model"
											modelName="vlupencargo"
											:itemsToSelect="[{ codigo: model.idencargo }]"
											:modelFormMapping="{ codigo: 'idencargo' }"
											itemValue="codigo"
											itemText="descripcion"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:order="{ descripcion: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											v-model="model.referencia"
											:label="this.$t('consultarecibida.referencia')"
											:disabled="formDisabled"
											required
											maxlength="100"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:label="this.$t('consultarecibida.idterminalorigen')"
											id="idterminalorigen"
											attach="idterminalorigen"
											clearable
											required
											disabled
											v-model="model"
											modelName="vlupterminales"
											:itemsToSelect="[{ idterminal: model.idterminalorigen }]"
											:modelFormMapping="{ idterminal: 'idterminalorigen' }"
											itemValue="idterminal"
											itemText="nombre"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:order="{ nombre: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:label="this.$t('consultarecibida.pesosolicitado')"
											v-model.number="model.pesosolicitado"
											decimals="0"
											suffix="kg"
											required
											:disabled="formDisabled"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm4 md1"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap v-if="model.idestado === 10">
									<v-flex xs12>
										<pui-text-area
											v-model="model.motivorechazo"
											:label="this.$t('consultarecibida.motivorechazo')"
											maxlength="300"
											disabled
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-area
											v-model="model.observaciones"
											:label="this.$t('consultarecibida.observaciones')"
											maxlength="300"
											clearable
											:disabled="formDisabled"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`lotegenerado`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="lotegeneradogrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idconsulta: 'idconsulta' }"
						:showCreateBtn="model.codestado === 'CS'"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="`consultarealizada`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="consultarealizadagrid"
						:parentModelName="modelName"
						:parentPkChildFk="{ extendFilter: { field: 'idconsultaorigen', op: 'eq', data: model.idconsulta } }"
						:showCreateBtn="model.codestado === 'CS'"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ConsultarecibidaActions from './ConsultarecibidaActions';
import ConsultarecibidaModals from './ConsultarecibidaModals';

export default {
	name: 'consultarecibidaform',
	mixins: [PuiFormMethodsMixin],
	components: { ConsultarecibidaModals },
	data() {
		return {
			modelName: 'consultarecibida',
			actions: ConsultarecibidaActions.formactions,
			parentModelName: 'consultarecibida',
			parentModelPk: null,
			showParentModelHeader: false,
			modeldetail: true,
			tabmodel: 'maintab'
		};
	},
	watch: {
		model(newVal) {
			if (newVal.idconsulta) {
				this.parentModelPk = {
					idconsulta: newVal.idconsulta
				};
				this.showParentModelHeader = true;
			}
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"consultarecibida.idencargo": "Order",

		"consultarecibida.referencia": "Reference",
        "consultarecibida.idterminalorigen": "Origin Terminal",
        "consultarecibida.pesosolicitado": "Weight (kg)",
		"consultarecibida.observaciones": "Inquiry state",

		"consultarecibida.maintab":"Inquiry",
		"consultarecibida.lotesgenerados":"Lots",
		"consultarecibida.subconsultas":"Sub-inquiry",
		"consultarecibida.motivorechazo": "Rejection reason"
    },
    "es": {
		"consultarecibida.idencargo": "Encargo",

		"consultarecibida.referencia": "Referencia",
        "consultarecibida.idterminalorigen": "Terminal origen",
		"consultarecibida.pesosolicitado": "Peso (kg)",
        "consultarecibida.observaciones": "Observaciones",

		"consultarecibida.maintab":"Consulta",
		"consultarecibida.lotesgenerados":"Lotes",
		"consultarecibida.subconsultas":"Subconsultas",
		"consultarecibida.motivorechazo": "Motivo rechazo"
    },
    "ca": {
		"consultarecibida.idencargo": "Encàrrec",

		"consultarecibida.referencia": "Referència",
        "consultarecibida.idterminalorigen": "Terminal origen",
        "consultarecibida.pesosolicitado": "Pes sol·licitat (kg)",
        "consultarecibida.observaciones": "Observacions",

		"consultarecibida.maintab":"Consulta",
		"consultarecibida.lotesgenerados":"Lots",
		"consultarecibida.subconsultas":"Sub-consultes",
		"consultarecibida.motivorechazo": "Motiu rebuig"
    }
}
</i18n>
