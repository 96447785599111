<template>
	<div class="pui-form">
		<encargo-term-modals :modelName="modelName"></encargo-term-modals>
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<encargotermformheader></encargotermformheader>
		</pui-form-header>
		<v-form class="pui-encargoform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('encargoterm.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#historicoterm'" v-if="!isCreatingElement">{{ $t('encargoterm.historico') }}</v-tab>
				<v-tab :key="2" :href="'#consultarealizada'" v-if="!isCreatingElement">{{ $t('encargoterm.consultasrealizadas') }}</v-tab>
				<v-tab :key="3" :href="'#loterecibido'" v-if="!isCreatingElement">{{ $t('encargoterm.loterecibido') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<encargo-form-base
						:model="model"
						:formDisabled="formDisabled"
						:modelLoaded="modelLoaded"
						tipoEncargo="T"
						:isCreatingElement="isCreatingElement"
					></encargo-form-base>
				</v-tab-item>
				<v-tab-item :key="1" :value="`historicoterm`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="historicotermgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="`consultarealizada`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="consultarealizadagrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="3" :value="`loterecibido`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="loterecibidogrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
						:showCreateBtn="false"
						:showDeleteBtn="false"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">
										{{ this.$t('form.save') }}
									</v-btn>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import EncargoFormBase from '../EncargoFormBase';
import EncargoTermActions from './EncargoTermActions';
import EncargoTermModals from './EncargoTermModals';

export default {
	name: 'encargotermform',
	mixins: [PuiFormMethodsMixin],
	components: { EncargoFormBase, EncargoTermModals },
	data() {
		return {
			modelName: 'encargoterm',
			actions: EncargoTermActions.formactions,
			tabmodel: 'maintab'
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"encargoterm.maintab": "Order",
		"encargoterm.consultasrealizadas": "Inquiry performed",
		"encargoterm.loterecibido": "Lots",
		"encargoterm.historico": "Historic"
    },
    "es": {
        "encargoterm.maintab": "Encargo",
		"encargoterm.consultasrealizadas": "Consultas realizadas",
		"encargoterm.loterecibido": "Lotes",
		"encargoterm.historico": "Histórico"
    },
    "ca": {
        "encargoterm.maintab": "Encàrrec",
		"encargoterm.consultasrealizadas": "Consultes realitzades",
		"encargoterm.loterecibido": "Lots",
		"encargoterm.historico": "Històric"
    }
}
</i18n>
