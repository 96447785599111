<template>
	<div>
		<pui-modal-dialog
			v-if="modals.anular.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.anular.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.anular.titleText"
			:dialogName="`anular-${this.modelName}`"
			ref="anular"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<consultarealizadaformheader v-if="parentModelPk" :modelPk="parentModelPk"></consultarealizadaformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('consultarealizada.validate.enable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'consultarealizadamodals',
	data() {
		return {
			modals: {
				anular: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-anular-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-anular-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-anular-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idconsulta: data.idconsulta
				};
				this.setShowConfirm(true);
			});
		},
		setShowConfirm(action) {
			this.modals.anular.show = action;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-anular-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-anular-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-anular-${this.modelName}-show`);
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"consultarealizada.validate.enable": "Are you sure you want to cancel the inquiry?"
	},
	"es": {
		"consultarealizada.validate.enable": "¿Está seguro que desea anular la consulta?"
	},
	"ca": {
		"consultarealizada.validate.enable": "Està segur que desitja anul·lar la consulta?"
	}
}
</i18n>
