<template>
	<div>
		<v-form class="pui-usuarioterminalform ml-4 mr-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<usuario-aplicacion-modals :modelName="modelName"></usuario-aplicacion-modals>
			<usuario-aplicacion-form-base
				:model="model"
				:formDisabled="formDisabled"
				:modelLoaded="modelLoaded"
				:method="method"
				:tipousuarioFixedFilter="tipousuarioFixedFilter"
			></usuario-aplicacion-form-base>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8 :mb-5="isMobile">
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="savevalidate()">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
		<pui-modal-dialog
			v-if="modals.confirmcomp.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.confirmcomp.disableOk"
			:okText="$t('form.accept')"
			:messageText="modals.confirmcomp.text"
			:titleText="modals.confirmcomp.titleText"
			:dialogName="`confirmcomp-${this.modelName}`"
			ref="confirmcomp"
			:widthDialog="800"
		></pui-modal-dialog>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import UsuarioAplicacionFormBase from './UsuarioAplicacionFormBase';
import UsuarioTerminalActions from './UsuarioTerminalActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuarioterminal',
	mixins: [PuiFormMethodsMixin],
	components: { UsuarioAplicacionFormBase, UsuarioAplicacionModals },
	data() {
		return {
			modelName: 'usuarioterminal',
			actions: UsuarioTerminalActions.formactions,
			tipousuarioFixedFilter: null,
			modals: {
				confirmcomp: {
					disableOk: false,
					show: false,
					titleText: this.$t('usuarioterminal.modal.confirmcomp.title'),
					text: ''
				}
			}
		};
	},
	methods: {
		afterGetData() {
			const tipousuarioT1Filter = {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'codtipusuario', op: 'eq', data: 1 },
							{ field: 'codtipusuario', op: 'eq', data: 2 }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'codtipusuario', op: 'ne', data: 6 }] }
				],
				groupOp: 'and',
				rules: []
			};
			this.tipousuarioFixedFilter = tipousuarioT1Filter;
			if (this.method === 'create') {
				this.subscribeToModalDialogEvent();
			}
		},
		savevalidate() {
			if (this.model.idtipousuario === 1) this.save(false);
			else {
				if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
					this.$puiNotify.error(this.$t('pui9.error.form_errors'));
					return;
				}
				this.$puiEvents.$emit('pui-modalDialog-confirmcomp-' + this.modelName + '-show', '');
			}
		},
		beforeSave() {
			if (this.model.idtipousuario === 1) {
				this.model.idconductor = null;
			} else if (this.model.idtipousuario === 2) {
				this.model.idterminal = null;
			}
		},
		subscribeToModalDialogEvent() {
			// Modal Minorpeso
			this.$puiEvents.$on(`pui-modalDialog-confirmcomp-${this.modelName}-ok`, () => {
				this.modals.confirmcomp.show = false;
				this.save(false);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirmcomp-${this.modelName}-cancel`, () => {
				this.modals.confirmcomp.show = false;
			});
			this.$puiEvents.$on(`pui-modalDialog-confirmcomp-${this.modelName}-show`, () => {
				this.modals.confirmcomp.text =
					this.$t('usuarioterminal.modal.confirmcomp.text1') +
					this.model.usr +
					this.$t('usuarioterminal.modal.confirmcomp.text2') +
					this.model.email +
					this.$t('usuarioterminal.modal.confirmcomp.text3') +
					this.model.empresa +
					this.$t('usuarioterminal.modal.confirmcomp.text4');
				this.modals.confirmcomp.show = true;
			});
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirmcomp-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirmcomp-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirmcomp-${this.modelName}-show`);
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style lang="postcss"></style>

<i18n>
{
    "en": {
		"usuarioterminal.modal.confirmcomp.title": "Register user",
		"usuarioterminal.modal.confirmcomp.text1": "Are you sure you want to create the user \"",
		"usuarioterminal.modal.confirmcomp.text2": " with email ",
		"usuarioterminal.modal.confirmcomp.text3": " for the company ",
		"usuarioterminal.modal.confirmcomp.text4": "?"
    },
    "es": {
		"usuarioterminal.modal.confirmcomp.title": "Crear usuario",
		"usuarioterminal.modal.confirmcomp.text1": "¿Está seguro que quiere crear el usuario \"",
		"usuarioterminal.modal.confirmcomp.text2": "\" con email ",
		"usuarioterminal.modal.confirmcomp.text3": " para la empresa ",
		"usuarioterminal.modal.confirmcomp.text4": "?"
    },
    "ca": {
		"usuarioterminal.modal.confirmcomp.title": "Crear usuario",
		"usuarioterminal.modal.confirmcomp.text1": "Està segur que vol crear l'usuari \"",
		"usuarioterminal.modal.confirmcomp.text2": "\" amb email ",
		"usuarioterminal.modal.confirmcomp.text3": " per a l'empresa ",
		"usuarioterminal.modal.confirmcomp.text4": "?"
	}
}
</i18n>
