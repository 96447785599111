<template>
	<div class="timesslotplanifdetalleform pui-form" :style="{ height: getPanelHeight }">
		<v-layout wrap>
			<v-flex xs12>
				<v-layout wrap>
					<v-flex xs4></v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.lunes') }}</v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.martes') }}</v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.miercoles') }}</v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.jueves') }}</v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.viernes') }}</v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.sabado') }}</v-flex>
					<v-flex xs1>{{ $t('timeslotplanifdetalle.domingo') }}</v-flex>
					<v-flex xs1 v-show="!formDisabled"></v-flex>
				</v-layout>
				<div v-for="(detalle, index) in datos.detalles" :key="index">
					<v-flex xs12>
						<v-layout>
							<v-flex xs1>
								<pui-text-field
									v-model="detalle.horainicio"
									maxlength="5"
									:disabled="formDisabled"
									valueColumnStyles="xs1"
									required
									:rules="hourRules"
								></pui-text-field>
							</v-flex>
							<v-flex xs1 class="text-center">{{ $t('timeslotplanifdetalle.a') }}</v-flex>
							<v-flex xs1>
								<pui-text-field
									v-model="detalle.horafin"
									maxlength="5"
									:disabled="formDisabled"
									valueColumnStyles="xs1"
									required
									:rules="hourRules"
								></pui-text-field>
							</v-flex>
							<v-flex xs1></v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidadl"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidadm"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidadx"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidadj"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidadv"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidads"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1>
								<pui-number-field
									v-model.number="detalle.capacidadd"
									decimals="0"
									minvalue="0"
									maxvalue="9999"
									:disabled="formDisabled"
									labelColumnStyles="delSpace"
								></pui-number-field>
							</v-flex>
							<v-flex xs1 class="pointer" @click="removeDetalle(index)" v-show="!formDisabled">
								<v-list-item>
									<v-icon small class="trashIcon">fa fa-trash-alt</v-icon>
								</v-list-item>
							</v-flex>
						</v-layout>
					</v-flex>
				</div>
			</v-flex>
			<v-flex xs12 v-show="!formDisabled">
				<v-btn depressed color="secondary" class="elevation-0" @click.native="addDetalle()">{{ this.$t('addDetalle') }}</v-btn>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	name: 'timeslotplanifdetalle',
	data() {
		return {
			hourRules: [v => this.validateHourRules(v) || this.$t('timeslotplanifdetalle.validate.hour')]
		};
	},
	props: {
		formDisabled: {
			type: Boolean,
			required: true
		},
		datos: {
			type: Object,
			required: true
		},
		height: {
			type: Number,
			required: true
		}
	},
	created() {
		this.getInitial();
	},
	computed: {
		getPanelHeight() {
			return this.height + 'px';
		}
	},
	methods: {
		removeDetalle(index) {
			this.datos.detalles.splice(index, 1);
			if (this.datos.detalles.length === 0) {
				this.addDetalle();
			}
		},
		addDetalle() {
			this.datos.detalles.push({});
		},
		getInitial() {
			if (this.datos.detalles.length === 0) {
				this.addDetalle();
			}
		},
		validateHourRules(v) {
			// eslint-disable-next-line
			var re = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
			return re.test(v);
		}
	}
};
</script>
<style lang="postcss">
.timesslotplanifdetalleform {
	overflow-y: auto;
}
.delSpace {
	display: none !important;
}
</style>

<i18n>
{
    "en" : {
		"timeslotplanifdetalle.lunes" : "Monday",
		"timeslotplanifdetalle.martes" : "Tuesday",
		"timeslotplanifdetalle.miercoles" : "Wednesday",
		"timeslotplanifdetalle.jueves" : "Thursday",
		"timeslotplanifdetalle.viernes" : "Friday",
		"timeslotplanifdetalle.sabado" : "Saturday",
		"timeslotplanifdetalle.domingo" : "Sunday",
		"timeslotplanifdetalle.a" : "to",
        "addDetalle": "+",
		"wrongDetalles": "Check time slots. You must indicate at least one day of the week",
		"timeslotplanifdetalle.validate.hour": "Time not have a valid format (hh:mm)"
    },
    "es": {
        "timeslotplanifdetalle.lunes" : "Lunes",
		"timeslotplanifdetalle.martes" : "Martes",
		"timeslotplanifdetalle.miercoles" : "Miércoles",
		"timeslotplanifdetalle.jueves" : "Jueves",
		"timeslotplanifdetalle.viernes" : "Viernes",
		"timeslotplanifdetalle.sabado" : "Sabado",
		"timeslotplanifdetalle.domingo" : "Domingo",
		"timeslotplanifdetalle.a" : "a",
		"addDetalle": "+",
		"wrongDetalles": "Revise las franjas horarias. Debe indicar al menos un día de la semana",
		"timeslotplanifdetalle.validate.hour": "La hora no tiene un formato válido (hh:mm)"
    },
    "ca": {
        "timeslotplanifdetalle.lunes" : "Dilluns",
		"timeslotplanifdetalle.martes" : "Dimarts",
		"timeslotplanifdetalle.miercoles" : "Dimecres",
		"timeslotplanifdetalle.jueves" : "Dijous",
		"timeslotplanifdetalle.viernes" : "Divendres",
		"timeslotplanifdetalle.sabado" : "Dissabte",
		"timeslotplanifdetalle.domingo" : "Diumenge",
		"timeslotplanifdetalle.a" : "a",
        "addDetalle": "+",
		"wrongDetalles": "Revise les franges horàries. Ha d'indicar almenys un dia de la setmana",
		"timeslotplanifdetalle.validate.hour": "La hora no tiene un formato válido (hh:mm)"
    }
}
</i18n>
