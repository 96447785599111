<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'timeslotauditgrid',
	data() {
		return {
			model: 'timeslotaudit',
			columnDefs: {
				fecha: { withoutHours: true },
				disponibles: {
					render: (data, type, row, meta) => {
						if (data < 0) {
							return `<label class="highlight-rounded highlight1">${data}</label>`;
						} else if (data === 0) {
							return `<label class="highlight-rounded highlight2">${data}</label>`;
						} else if (data > 0) {
							return `<label class="highlight-rounded highlight3">${data}</label>`;
						}
						return data;
					}
				}
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss">
.highlight1 {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.highlight2 {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highlight3 {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
.highlight4 {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highlight-rounded {
	padding: 3px 6px;
	border-radius: 5px;
}
</style>

<i18n>
{
    "en": {
		"grid.timeslotaudit.idterminal": "Terminal id",
		"grid.timeslotaudit.terminal": "Terminal",
		"grid.timeslotaudit.idtimeslot": "Time slot identifier",
		"grid.timeslotaudit.idplanif": "Planning code",
		"grid.timeslotaudit.idpuntocarga": "Loading point code",
		"grid.timeslotaudit.codpuntocarga": "Loading point code",
		"grid.timeslotaudit.puntocarga": "Loading point",
		"grid.timeslotaudit.fecha": "Date",
		"grid.timeslotaudit.horainicio": "Start hour",
		"grid.timeslotaudit.horafin": "End hour",
		"grid.timeslotaudit.capacidad": "Number of trucks",
		"grid.timeslotaudit.asignados": "Assigned",
		"grid.timeslotaudit.disponibles": "Avaible",
		"grid.timeslotaudit.fechacreacion":"Creation date"
    },
    "es": {
		"grid.timeslotaudit.idterminal": "Código terminal",
		"grid.timeslotaudit.terminal": "Terminal",
		"grid.timeslotaudit.idtimeslot": "Código time slot",
		"grid.timeslotaudit.idplanif": "Código planificación",
		"grid.timeslotaudit.idpuntocarga": "Cód. punto carga",
		"grid.timeslotaudit.codpuntocarga": "Código punto carga",
		"grid.timeslotaudit.puntocarga": "Punto carga",
		"grid.timeslotaudit.fecha": "Fecha",
		"grid.timeslotaudit.horainicio": "Hora de inicio",
		"grid.timeslotaudit.horafin": "Hora de fin",
		"grid.timeslotaudit.capacidad": "Nº de camiones",
		"grid.timeslotaudit.asignados": "Asignados",
		"grid.timeslotaudit.disponibles": "Disponibles",
		"grid.timeslotaudit.fechacreacion":"Fecha creación"
    },
    "ca": {
		"grid.timeslotaudit.idterminal": "Codi terminal",
		"grid.timeslotaudit.terminal": "Terminal",
		"grid.timeslotaudit.idtimeslot": "Codi time slot",
		"grid.timeslotaudit.idplanif": "Codi planificació",
		"grid.timeslotaudit.idpuntocarga": "Cod. punt càrrega",
		"grid.timeslotaudit.codpuntocarga": "Codi punt càrrega",
		"grid.timeslotaudit.puntocarga": "Punt càrrega",
		"grid.timeslotaudit.fecha": "Data",
		"grid.timeslotaudit.horainicio": "Hora d' inici",
		"grid.timeslotaudit.horafin": "Hora de fi",
		"grid.timeslotaudit.capacidad": "Nº camions",
		"grid.timeslotaudit.asignados": "Assignats",
		"grid.timeslotaudit.disponibles": "Disponibles",
		"grid.timeslotaudit.fechacreacion":"Data creació"
    }
}
</i18n>
