<template>
	<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */

export default {
	name: 'documentacioncabgrid',
	data() {
		return {
			model: 'documentacioncab',
			columnDefs: {
				tipodocumento: {
					render: (data, type, row, meta) => {
						if (data === null) {
							return ``;
						}
						return data;
					}
				}
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.documentacioncab.iddoc": "Code",
        "grid.documentacioncab.nombre": "Name",
		"grid.documentacioncab.url": "URL",
		"grid.documentacioncab.idtipousuario": "User type code",
		"grid.documentacioncab.tipousuario": "User type",
        "grid.documentacioncab.observaciones": "Comments",
		"grid.documentacioncab.idtipodocumento":"Document type code",
		"grid.documentacioncab.tipodocumento":"Document type"
    },
    "es": {
        "grid.documentacioncab.iddoc": "Código",
        "grid.documentacioncab.nombre": "Nombre",
		"grid.documentacioncab.url": "URL",
		"grid.documentacioncab.idtipousuario": "Código tipo usuario",
		"grid.documentacioncab.tipousuario": "Tipo usuario",
        "grid.documentacioncab.observaciones": "Observaciones",
		"grid.documentacioncab.idtipodocumento":"Código tipo documento",
		"grid.documentacioncab.tipodocumento":"Tipo documento"
    },
    "ca": {
        "grid.documentacioncab.iddoc": "Codi",
        "grid.documentacioncab.nombre": "Nom",
		"grid.documentacioncab.url": "URL",
		"grid.documentacioncab.idtipousuario": "Codi tipus usuari",
		"grid.documentacioncab.tipousuario": "Tipus usuari",
        "grid.documentacioncab.observaciones": "Observacions",
		"grid.documentacioncab.idtipodocumento":"Codi tipus document",
		"grid.documentacioncab.tipodocumento":"Tipus document"
    }
}
</i18n>
