<template>
	<pui-datatable v-if="!masterDetail" :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:actions="actions"
		:parentModelName="parentModelName"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
		:modelColumnDefs="columnDefs"
	></pui-datatable>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import LibramientoCompActions from './LibramientoCompActions';

export default {
	name: 'libramientocompgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'libramientocomp',
			actions: LibramientoCompActions.gridactions,
			columnDefs: {
				estado: {
					render: (data, type, row, meta) => {
						if (row.codestado === 'LS') {
							return (
								`<label class="highEncLibComp-rounded highSolicitadoLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LS') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LA') {
							return (
								`<label class="highEncLibComp-rounded highAnuladoLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LA') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LT') {
							return (
								`<label class="highEncLibComp-rounded highAutorizadoLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LT') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LR') {
							return (
								`<label class="highEncLibComp-rounded highRechazadoLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LR') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LM') {
							return (
								`<label class="highEncLibComp-rounded highModifPuntLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LM') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LE') {
							return (
								`<label class="highEncLibComp-rounded highEntregadoLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LE') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LN') {
							return (
								`<label class="highEncLibComp-rounded highNoRegLibComp" title="` +
								this.$t('grid.libramientocomp.estado.LN') +
								`">${data}</label>`
							);
						}

						return data;
					}
				},
				fecharecogida: { withoutHours: true }
			},
			masterDetailColumnVisibles: {
				idlibramiento: true,
				idencargo: false,
				contrato: false,
				aplicacion: false,
				idmercancia: false,
				mercancia: false,
				idtransportista: false,
				transportista: true,
				pesosolicitado: true,
				fecharecogida: true,
				horainicio: true,
				horafin: true,
				horarecogida: true,
				idterminal: false,
				terminalcarga: true,
				idpuntocarga: false,
				puntocarga: true,
				matricula: true,
				pcmodificado: true,
				estado: true,
				fechaentrega: true,
				fechacreacion: true
			}
		};
	}
};
</script>

<style lang="postcss">
.highAnuladoLibComp {
	color: rgb(152, 155, 153);
	background-color: rgba(227, 231, 230, 0.8) !important;
	font-weight: bold;
}
.highRechazadoLibComp {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.highSolicitadoLibComp {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highEntregadoLibComp {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highAutorizadoLibComp {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highModifPuntLibComp {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highNoRegLibComp {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highEncLibComp-rounded {
	padding: 3px 6px;
	border-radius: 5px;
}
</style>

<i18n>
{
    "en": {
        "grid.libramientocomp.idlibramiento": "Code",
        "grid.libramientocomp.idencargo": "Order code",
        "grid.libramientocomp.contrato": "Contract number",
        "grid.libramientocomp.aplicacion": "Application code",
		"grid.libramientocomp.idmercancia": "Good code",
		"grid.libramientocomp.mercancia": "Good",
		"grid.libramientocomp.idtransportista": "Carrier code",
		"grid.libramientocomp.transportista": "Carrier",
		"grid.libramientocomp.pesosolicitado": "Weight (kg)",
		"grid.libramientocomp.fecharecogida": "Pick-up date",
		"grid.libramientocomp.horainicio": "Time slot beginnig",
		"grid.libramientocomp.horafin": "Time slot end",
		"grid.libramientocomp.horarecogida": "Pick-up time",
		"grid.libramientocomp.idterminal": "Terminal code",
		"grid.libramientocomp.terminalcarga": "Charging terminal",
		"grid.libramientocomp.idpuntocarga": "Loading point code",
		"grid.libramientocomp.puntocarga": "Loading point",
		"grid.libramientocomp.matricula": "Plate",
		"grid.libramientocomp.pcmodificado": "Updated L. P.",
		"grid.libramientocomp.codestado": "Id State",
		"grid.libramientocomp.estado": "State",
		"grid.libramientocomp.fechaentrega": "Actual delivery date",
		"grid.libramientocomp.fechacreacion":"Creation date"
    },
    "es": {
        "grid.libramientocomp.idlibramiento": "Código",
        "grid.libramientocomp.idencargo": "Código encargo",
        "grid.libramientocomp.contrato": "Número de contrato",
        "grid.libramientocomp.aplicacion": "Código de aplicación",
		"grid.libramientocomp.idmercancia": "Código mercancía",
		"grid.libramientocomp.mercancia": "Mercancía",
		"grid.libramientocomp.idtransportista": "Código transportista",
		"grid.libramientocomp.transportista": "Transportista",
		"grid.libramientocomp.pesosolicitado": "Peso (kg)",
		"grid.libramientocomp.fecharecogida": "Fecha recogida",
		"grid.libramientocomp.horainicio": "Inicio time slot",
		"grid.libramientocomp.horafin": "Fin time slot",
		"grid.libramientocomp.horarecogida": "Hora recogida",
		"grid.libramientocomp.idterminal": "Código terminal",
		"grid.libramientocomp.terminalcarga": "Terminal de carga",
		"grid.libramientocomp.idpuntocarga": "Código punto de carga",
		"grid.libramientocomp.puntocarga": "Punto de carga",
		"grid.libramientocomp.matricula": "Matrícula",
		"grid.libramientocomp.pcmodificado": "P. C. Modificado",
		"grid.libramientocomp.codestado": "Código estado",
		"grid.libramientocomp.estado": "Estado",
		"grid.libramientocomp.fechaentrega": "Fecha real de entrega",
		"grid.libramientocomp.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.libramientocomp.idlibramiento": "Codi",
        "grid.libramientocomp.idencargo": "Codi encàrrec",
        "grid.libramientocomp.contrato": "Número de contracte",
        "grid.libramientocomp.aplicacion": "Codi d'aplicació",
		"grid.libramientocomp.idmercancia": "Codi mercaderia",
		"grid.libramientocomp.mercancia": "Mercaderia",
		"grid.libramientocomp.idtransportista": "Codi transportista",
		"grid.libramientocomp.transportista": "Transportista",
		"grid.libramientocomp.pesosolicitado": "Pes (kg)",
		"grid.libramientocomp.fecharecogida": "Data recollida",
		"grid.libramientocomp.horainicio": "Inici time slot",
		"grid.libramientocomp.horafin": "Fi time slot",
		"grid.libramientocomp.horarecogida": "Hora recollida",
		"grid.libramientocomp.idterminal": "Codi terminal",
		"grid.libramientocomp.terminalcarga": "Terminal de càrrega",
		"grid.libramientocomp.idpuntocarga": "Codi punt de càrrega",
		"grid.libramientocomp.puntocarga": "Punt de càrrega",
		"grid.libramientocomp.matricula": "Matrícula",
		"grid.libramientocomp.pcmodificado": "Punt càrrega modificat",
		"grid.libramientocomp.codestado": "Codi estat",
		"grid.libramientocomp.estado": "Estat",
		"grid.libramientocomp.fechaentrega": "Data real d'entrega",
		"grid.libramientocomp.fechacreacion":"Data creació"
    }
}
</i18n>
