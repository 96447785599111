<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'loteauditgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'loteaudit',
			masterDetailColumnVisibles: {
				idlote: true,
				terminalorigen: true,
				puntocarga: true,
				terminaldestino: true,
				pesolocalizado: true,
				fechacreacion: true
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
     "en": {
		"grid.loteaudit.contrato": "Order contract number",
        "grid.loteaudit.idlote": "Lot code",
        "grid.loteaudit.idterminaldestino": "Origin terminal code",
        "grid.loteaudit.idterminalorigen": "Destination terminal code",
        "grid.loteaudit.terminalorigen": "Origin terminal",
        "grid.loteaudit.terminaldestino": "Destination terminal",
        "grid.loteaudit.terminaldestino": "Terminal destino",
        "grid.loteaudit.terminalorigen": "Terminal origen",
		"grid.loteaudit.idpuntocarga": "Loading point code",
		"grid.loteaudit.puntocarga": "Loading point",
		"grid.loteaudit.pesolocalizado": "Located weight (kg)",
		"grid.loteaudit.observaciones": "Comments",
		"grid.loteaudit.fechacreacion": "Creation date",
		"grid.loteaudit.referencia": "Reference of the inquiry",
		"grid.loteaudit.idencargo": "Order code",
		"grid.loteaudit.idconsulta": "Inquiry code"
    },
    "es": {
		"grid.loteaudit.contrato": "Número de contrato del encargo",
		"grid.loteaudit.idlote": "Código lote",
        "grid.loteaudit.idterminaldestino": "Cód. terminal destino",
        "grid.loteaudit.idterminalorigen": "Cód. terminal origen",
        "grid.loteaudit.terminaldestino": "Terminal destino",
        "grid.loteaudit.terminalorigen": "Terminal origen",
		"grid.loteaudit.idpuntocarga": "Código punto de carga",
		"grid.loteaudit.puntocarga": "Punto de carga",
		"grid.loteaudit.pesolocalizado": "Peso localizado (kg)",
		"grid.loteaudit.observaciones": "Observaciones",
		"grid.loteaudit.fechacreacion": "Fecha de creación",
		"grid.loteaudit.referencia": "Referencia de la consulta",
		"grid.loteaudit.idconsulta": "Código consulta",
		"grid.loteaudit.idencargo": "Código encargo"
	},
    "ca": {
		"grid.loteaudit.contrato": "Nombre contracte de l'encàrrec",
        "grid.loteaudit.idlote": "Codi lot",
        "grid.loteaudit.idterminaldestino": "Cód. terminal destí",
        "grid.loteaudit.idterminalorigen": "Cód. terminal origen",
        "grid.loteaudit.terminalorigen": "Terminal origen",
        "grid.loteaudit.terminaldestino": "Terminal destí",
		"grid.loteaudit.idpuntocarga": "Codi punt de càrrega",
		"grid.loteaudit.puntocarga": "Punt de Càrrega",
		"grid.loteaudit.pesolocalizado": "Pes localitzat (kg)",
		"grid.loteaudit.observaciones": "Observacions",
		"grid.loteaudit.fechacreacion": "Data de creació",
		"grid.loteaudit.referencia": "Referència de la consulta",
		"grid.loteaudit.idconsulta": "Codi consulta",
		"grid.loteaudit.idencargo": "Codi encàrrec"
	}
}
</i18n>
