<template>
	<div>
		<pui-modal-dialog
			v-if="modals.disableReason.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.disableReason.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.disableReason.titleText"
			:dialogName="`date-${this.modelName}`"
			ref="date"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<motivoanulrechformheader v-if="parentModelPk" :modelPk="parentModelPk"></motivoanulrechformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('motivoanulrech.validate.disable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
		<pui-modal-dialog
			v-if="modals.enableReason.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.enableReason.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.enableReason.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<motivoanulrechformheader v-if="parentModelPk" :modelPk="parentModelPk"></motivoanulrechformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('motivoanulrech.validate.enable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */
export default {
	name: 'usuarioaplicacionmodals',
	data() {
		return {
			modals: {
				disableReason: {
					disableOk: false,
					show: false,
					parentModelPk: null
				},
				enableReason: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			// Modal Ask Date
			this.$puiEvents.$on(`pui-modalDialog-date-${this.modelName}-ok`, () => {
				this.setShowDate(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-date-${this.modelName}-cancel`, () => {
				this.setShowDate(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-date-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idmotivo: data.idmotivo
				};
				this.setShowDate(true);
			});
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idmotivo: data.idmotivo
				};
				this.setShowConfirm(true);
			});
		},
		setShowDate(action) {
			this.modals.disableReason.show = action;
		},
		setShowConfirm(action) {
			this.modals.enableReason.show = action;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-show`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	}
};
</script>
