<template>
	<pui-datatable
		v-if="!masterDetail"
		:modelName="model"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:isEditableRegistry="isEditableRegistry"
		showFilterListBtn
	></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:parentModelName="parentModelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:isEditableRegistry="isEditableRegistry"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
	></pui-datatable>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'consultaauditgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'consultaaudit',
			masterDetailColumnVisibles: {
				idconsulta: true,
				terminalorigen: true,
				terminaldestino: true,
				referencia: true,
				pesosolicitado: true,
				fechacreacion: true,
				estado: true,
				lotes: true
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.consultaaudit.idencargo": "Order code",
		"grid.consultaaudit.idconsulta": "Inquiry code",
        "grid.consultaaudit.mercancia": "Good",
        "grid.consultaaudit.pesosolicitado": "Requested weight (kg)",
        "grid.consultaaudit.idterminaldestino": "Origin terminal code",
        "grid.consultaaudit.terminaldestino": "Origin terminal",
        "grid.consultaaudit.idterminalorigen": "Destination terminal code",
        "grid.consultaaudit.terminalorigen": "Destination terminal",
        "grid.consultaaudit.codestado": "State code",
        "grid.consultaaudit.estado": "State",
		"grid.consultaaudit.referencia": "Reference",
		"grid.consultaaudit.fechacreacion": "Creation date",
		"grid.consultaaudit.motivorechazo": "Rejection reason",
		"grid.consultaaudit.lotes": "Rejection reason"
    },
    "es": {
        "grid.consultaaudit.idencargo": "Código encargo",
        "grid.consultaaudit.idconsulta": "Código consulta",
		"grid.consultaaudit.mercancia": "Mercancía",
		"grid.consultaaudit.pesosolicitado": "Peso solicitado (kg)",
        "grid.consultaaudit.idterminaldestino": "Código terminal origen",
        "grid.consultaaudit.terminaldestino": "Terminal origen",
        "grid.consultaaudit.idterminalorigen": "Código terminal destino",
        "grid.consultaaudit.terminalorigen": "Terminal destino",
        "grid.consultaaudit.codestado": "Código estado",
        "grid.consultaaudit.estado": "Estado",
		"grid.consultaaudit.referencia": "Referencia",
		"grid.consultaaudit.fechacreacion": "Fecha de creación",
		"grid.consultaaudit.motivorechazo": "Motivo rechazo",
		"grid.consultaaudit.lotes": "Tiene lotes"
    },
    "ca": {
        "grid.consultaaudit.idencargo": "Codi encàrrec",
        "grid.consultaaudit.idconsulta": "Codi consulta",
		"grid.consultaaudit.mercancia": "Mercaderia",
		"grid.consultaaudit.pesosolicitado": "Pes sol·licitat (kg)",
        "grid.consultaaudit.idterminaldestino": "Codi terminal origen",
        "grid.consultaaudit.terminaldestino": "Terminal origen",
        "grid.consultaaudit.idterminalorigen": "Codi terminal destí",
        "grid.consultaaudit.terminalorigen": "Terminal destí",
        "grid.consultaaudit.codestado": "Codi estat",
        "grid.consultaaudit.estado": "Estat",
		"grid.consultaaudit.referencia": "Referència",
		"grid.consultaaudit.fechacreacion": "Data de creació",
		"grid.consultaaudit.motivorechazo": "Motiu rebuig",
		"grid.consultaaudit.lotes": "Te lots"
    }
}
</i18n>
