<template>
	<div>
		<timeslot-planif-modals :modelName="model"></timeslot-planif-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import TimeslotPlanifActions from './TimeslotPlanifActions';
import TimeslotPlanifFormHeader from './TimeslotPlanifFormHeader';
import TimeslotPlanifModals from './TimeslotPlanifModals';
export default {
	name: 'timeslotplanifgrid',
	components: {
		TimeslotPlanifFormHeader,
		TimeslotPlanifModals
	},
	data() {
		return {
			model: 'timeslotplanif',
			actions: TimeslotPlanifActions.gridactions,
			columnDefs: {
				fechainicio: { withoutHours: true },
				fechafin: { withoutHours: true }
			}
		};
	},
	created() {
		this.isMobile = this.$store.getters.isMobile;
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.timeslotplanif.idplanif": "Code",
		"grid.timeslotplanif.idpuntocarga": "Loading point identifier",
		"grid.timeslotplanif.codpuntocarga": "Loading point code",
		"grid.timeslotplanif.descpuntocarga": "Loading point",
		"grid.timeslotplanif.fechainicio": "Start date",
		"grid.timeslotplanif.fechafin": "End date",
        "grid.timeslotplanif.observaciones": "Comments",
		"grid.timeslotplanif.action.generateTitle": "Generate time slotS",
		"grid.timeslotplanif.action.fechainicioLabel": "Start date",
		"grid.timeslotplanif.action.fechafinLabel": "End date",
		"grid.timeslotplanif.fechacreacion":"Creation date"
    },
    "es": {
        "grid.timeslotplanif.idplanif": "Código",
		"grid.timeslotplanif.idpuntocarga": "Cód. punto carga",
		"grid.timeslotplanif.codpuntocarga": "Código punto carga",
		"grid.timeslotplanif.descpuntocarga": "Punto carga",
		"grid.timeslotplanif.fechainicio": "Fecha inicio",
		"grid.timeslotplanif.fechafin": "Fecha fin",
		"grid.timeslotplanif.observaciones": "Observaciones",
		"grid.timeslotplanif.action.generateTitle": "Generar time slots",
		"grid.timeslotplanif.action.fechainicioLabel": "Fecha inicio",
		"grid.timeslotplanif.action.fechafinLabel": "Fecha fin",
		"grid.timeslotplanif.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.timeslotplanif.idplanif": "Codi",
		"grid.timeslotplanif.idpuntocarga": "Punt de càrrega",
		"grid.timeslotplanif.codpuntocarga": "Codi punt càrrega",
		"grid.timeslotplanif.descpuntocarga": "Punt càrrega",
		"grid.timeslotplanif.fechainicio": "Data inici",
		"grid.timeslotplanif.fechafin": "Data fi",
        "grid.timeslotplanif.observaciones": "Observacions",
		"grid.timeslotplanif.action.generateTitle": "Generar time slots",
		"grid.timeslotplanif.action.fechainicioLabel": "Data inici",
		"grid.timeslotplanif.action.fechafinLabel": "Data fi",
		"grid.timeslotplanif.fechacreacion":"Data creació"
    }
}
</i18n>
