<template>
	<div class="pui-form">
		<v-form class="pui-asignacionauditform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('asignacion.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#libramientoaudit'" v-if="!isCreatingElement">{{ $t('asignacionaudit.libramientos') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap>
						<v-flex xs12 sm12 md8 lg8 xl8>
							<v-layout wrap class="pui-form-layout">
								<v-flex xs12>
									<pui-text-field
										:label="$t('asignacion.idasignacion')"
										v-model="model.idasignacion"
										required
										disabled
										valueColumnStyles="xs4 sm2 md1"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-select
										id="idlote"
										:label="$t('asignacion.idlote')"
										attach="idlote"
										disabled
										v-model="model"
										modelName="vluplotes"
										:itemsToSelect="[{ idlote: model.idlote }]"
										:modelFormMapping="{ idlote: 'idlote' }"
										:itemValue="['idlote']"
										:itemText="item => `${item.puntocarga} - ${item.peso} Kg`"
										:order="{ idlote: 'asc' }"
										detailModelName="loteaudit"
										detailComponentName="detailloteform"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-select
										v-if="modelLoaded"
										attach="idcomprador"
										:label="$t('asignacion.idcomprador')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupcompradores"
										:itemsToSelect="[{ idcomprador: model.idcomprador }]"
										:modelFormMapping="{ idcomprador: 'idcomprador' }"
										:itemValue="['idcomprador']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-select
										id="idtransportista"
										attach="idtransportista"
										:label="$t('asignacion.idtransportista')"
										:disabled="formDisabled"
										v-model="model"
										modelName="vluptransportistas"
										:itemsToSelect="[{ idtransportista: model.idtransportista }]"
										:modelFormMapping="{ idtransportista: 'idtransportista' }"
										:itemValue="['idtransportista']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-select
										v-if="modelLoaded"
										attach="idterminal"
										:label="$t('asignacion.idterminal')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupterminales"
										:itemsToSelect="[{ idterminal: model.idterminal }]"
										:modelFormMapping="{ idterminal: 'idterminal' }"
										:itemValue="['idterminal']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('asignacion.contrato')"
										v-model="model.contrato"
										maxlength="50"
										required
										:disabled="formDisabled"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('asignacion.aplicacion')"
										v-model="model.aplicacion"
										maxlength="50"
										:disabled="formDisabled"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-date-field
										:label="$t('asignacion.fecharecogida')"
										v-model="model.fecharecogida"
										required
										:disabled="formDisabled"
										id="fecharecogida"
										attach="fecharecogida"
										valueColumnStyles="xs12 sm4 md4 xl2"
									></pui-date-field>
								</v-flex>
								<v-flex xs12>
									<pui-select
										v-if="modelLoaded"
										attach="idmercancia"
										:label="$t('asignacion.idmercancia')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupmercancias"
										:itemsToSelect="[{ idmercancia: model.idmercancia }]"
										:modelFormMapping="{ idmercancia: 'idmercancia' }"
										:itemValue="['idmercancia']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-number-field
										:label="$t('asignacion.pesoasignado')"
										v-model="model.pesoasignado"
										decimals="0"
										max="8000000"
										required
										:disabled="formDisabled"
										valueColumnStyles="xs12 sm6 md4 xl2"
										id="peso"
									></pui-number-field>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('asignacion.empresacreacion')"
										v-model="model.empresacreacion"
										maxlength="100"
										:disabled="formDisabled"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-date-field
										id="fechacreacion"
										:label="$t('asignacion.fechacreacion')"
										v-model="model.fecalta"
										disabled
										timesecs
										attach="fecalta"
										valueColumnStyles="xs12 sm6 md4"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`libramientoaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="libramientoauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idasignacion: 'idasignacion' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'asignacionauditform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'asignacionaudit'
		};
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"asignacion.idencargo": "Order code",
		"asignacion.idcomprador": "Buyer",
		"asignacion.idmercancia": "Good",
		"asignacion.idterminal": "Management terminal",
		"asignacion.pesoasignado": "Weight(kg)",
		"asignacion.contrato": "Contract number",
		"asignacion.aplicacion": "Application",
		"asignacion.fecharecogida": "Pick-up date",
		"asignacion.indtienelibramientos":"Have deliverance",
		"asignacion.fechacreacion": "Creation date",
		"asignacion.empresacreacion":"Creation company",
		"asignacion.idlote": "Lot",
		"asignacion.idtransportista": "Carrier",
		"asignacion.idasignacion":"Assignment code",
		"asignacion.maintab":"Assignament",
		"asignacion.libramientos":"Riddances"
    },
    "es": {
        "asignacion.idencargo": "Código encargo",
		"asignacion.idcomprador": "Comprador",
		"asignacion.idmercancia": "Mercancía",
		"asignacion.idterminal": "Terminal gestora",
		"asignacion.pesoasignado": "Peso(kg)",
		"asignacion.contrato": "Número de contrato",
		"asignacion.aplicacion": "Aplicación",
		"asignacion.fecharecogida": "Fecha de recogida",
		"asignacion.indtienelibramientos":"Tiene libramientos",
		"asignacion.fechacreacion": "Fecha de creación",
		"asignacion.empresacreacion":"Empresa creadora",
		"asignacion.idlote": "Lote",
		"asignacion.idtransportista": "Transportista",
		"asignacion.idasignacion":"Código asignación",
		"asignacion.maintab":"Asignación",
		"asignacion.libramientos":"Libramientos"
    },
    "ca": {
        "asignacion.idencargo": "Code Encàrrec",
		"asignacion.idcomprador": "Comprador",
		"asignacion.idmercancia": "Mercaderia",
		"asignacion.idterminal": "Terminal gestora",
		"asignacion.pesoasignado": "Pes(kg)",
		"asignacion.contrato": "Número de contracte",
		"asignacion.aplicacion": "Aplicació",
		"asignacion.fecharecogida": "Data de recollida",
		"asignacion.indtienelibramientos":"Té lliuraments",
		"asignacion.fechacreacion": "Data de creació",
		"asignacion.empresacreacion":"Empresa creadora",
		"asignacion.idlote": "Lot",
		"asignacion.idtransportista": "Transportista",
		"asignacion.idasignacion":"Codi assignació",
		"asignacion.maintab":"Assignació",
		"asignacion.libramientos":"Lliuraments"
    }
}
</i18n>
