<template>
	<div class="pui-form" v-if="modelLoaded && myModelLoaded">
		<loterecibido-modals :modelName="modelName"></loterecibido-modals>
		<pui-form-header :showHeader="parentModelName === 'consultarealizada' && !isCreatingElement">
			<consultarealizadaformheader :modelPk="parentModelPk"></consultarealizadaformheader>
		</pui-form-header>
		<pui-form-header :showHeader="parentModelName === 'encargoterm' && !isCreatingElement">
			<encargotermformheader :modelPk="parentModelPk"></encargotermformheader>
		</pui-form-header>
		<div ref="windowcontainer">
			<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
				<v-layout wrap class="pui-form-layout">
					<v-flex xs12>
						<pui-field-set>
							<v-flex xs12>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											id="idterminal"
											:label="$t('loterecibido.idterminal')"
											attach="idterminal"
											clearable
											disabled
											v-model="model"
											modelName="vlupterminales"
											:itemsToSelect="[{ idterminal: model.idterminal }]"
											:modelFormMapping="{ idterminal: 'idterminal' }"
											itemValue="idterminal"
											itemText="nombre"
											labelColumnStyles="xs12 sm4 md2 lg2"
											valueColumnStyles="xs12 sm6 md4"
											:order="{ nombre: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:label="$t('loterecibido.peso')"
											v-model.number="model.peso"
											decimals="0"
											minvalue="0"
											maxvalue="9999999"
											suffix="kg"
											required
											:disabled="formDisabled"
											labelColumnStyles="xs12 sm4 md2 lg2"
											valueColumnStyles="xs12 sm4 md1"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-area
											v-model="model.observaciones"
											:label="$t('loterecibido.observaciones')"
											maxlength="300"
											clearable
											:disabled="formDisabled"
											labelColumnStyles="xs12 sm4 md2 lg2"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
							</v-flex>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<pui-form-footer>
					<v-layout>
						<v-flex xs12 sm12 md8 lg8 xl8>
							<v-layout>
								<v-flex xs12 md4>
									<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
								</v-flex>
								<v-flex xs12 md8>
									<v-layout justify-end fill-height>
										<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">
											{{ this.$t('form.save') }}
										</v-btn>
										<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-form-footer>
			</v-form>
		</div>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import LoterecibidoActions from './LoterecibidoActions';
import LoterecibidoModals from './LoterecibidoModals';

export default {
	name: 'loterecibidoform',
	mixins: [PuiFormMethodsMixin],
	components: { LoterecibidoModals },
	data() {
		return {
			modelName: 'loterecibido',
			actions: LoterecibidoActions.formactions,
			parentModelName: null,
			parentModelPk: null,
			showParentModelHeader: false,
			myModelLoaded: false
		};
	},
	methods: {
		// override
		afterGetData() {
			// Obtengo la PK del padre
			const thisModel = this.$store.getters.getModelByName(this.modelName);

			if (thisModel.parentModelName != null) {
				this.parentModelPk = thisModel.parentModelPk;
				this.parentModelName = thisModel.parentModelName;

				if (thisModel.parentModelName === 'consultarealizada') {
					this.model.idconsulta = this.parentModelPk.idconsulta;
				}
				if (thisModel.parentModelName === 'encargoterm') {
					this.model.idencargo = this.parentModelPk.idencargo;
				}
			}
			this.myModelLoaded = true;
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"loterecibido.idlote": "Lot",
		"loterecibido.idterminal": "Charging terminal",
		"loterecibido.peso": "Located weight (kg)",
		"loterecibido.observaciones": "Comments"
    },
    "es": {
		"loterecibido.idlote": "Lote",
		"loterecibido.idterminal": "Terminal de carga",
		"loterecibido.peso": "Peso localizado (kg)",
		"loterecibido.observaciones": "Observaciones"
	},
    "ca": {
		"loterecibido.idlote": "Lot",
		"loterecibido.idterminal": "Terminal de càrrega",
		"loterecibido.peso": "Pes localitzat (kg)",
		"loterecibido.observaciones": "Observacions"
	}
}
</i18n>
