<template>
	<div class="pui-form" v-if="modelLoaded">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12>
					<pui-field-set>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									id="idterminalorigen"
									:label="this.$t('detailconsulta.idterminalorigen')"
									attach="idterminalorigen"
									disabled
									v-model="model"
									modelName="vlupterminales"
									:itemsToSelect="[{ idterminal: model.idterminalorigen }]"
									:modelFormMapping="{ idterminal: 'idterminalorigen' }"
									itemValue="idterminal"
									itemText="nombre"
									labelColumnStyles="xs12 sm4 md2 lg1"
									valueColumnStyles="xs12 sm6 md4"
									:order="{ nombre: 'asc' }"
								></pui-select>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12>
								<pui-text-field
									v-model="model.referencia"
									:label="this.$t('detailconsulta.referencia')"
									disabled
									maxlength="100"
									labelColumnStyles="xs12 sm4 md2 lg1"
									valueColumnStyles="xs12 sm6 md4"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									id="idterminaldestino"
									:label="this.$t('detailconsulta.idterminaldestino')"
									attach="idterminaldestino"
									disabled
									v-model="model"
									modelName="vlupterminales"
									:itemsToSelect="[{ idterminal: model.idterminaldestino }]"
									:modelFormMapping="{ idterminal: 'idterminaldestino' }"
									itemValue="idterminal"
									itemText="nombre"
									labelColumnStyles="xs12 sm4 md2 lg1"
									valueColumnStyles="xs12 sm6 md4"
									:order="{ nombre: 'asc' }"
								></pui-select>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12>
								<pui-number-field
									:label="this.$t('detailconsulta.pesosolicitado')"
									v-model.number="model.pesosolicitado"
									decimals="0"
									suffix="kg"
									disabled
									labelColumnStyles="xs12 sm4 md2 lg1"
									valueColumnStyles="xs12 sm4 md1"
								></pui-number-field>
							</v-flex>
						</v-layout>
						<v-layout wrap v-if="model.idestado === 10">
							<v-flex xs12>
								<pui-text-area
									v-model="model.motivorechazo"
									:label="this.$t('detailconsulta.motivorechazo')"
									maxlength="300"
									disabled
									labelColumnStyles="xs12 sm4 md2 lg1"
									valueColumnStyles="xs12 sm6 md4"
								></pui-text-area>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12>
								<pui-text-area
									v-model="model.observaciones"
									:label="this.$t('detailconsulta.observaciones')"
									maxlength="300"
									disabled
									labelColumnStyles="xs12 sm4 md2 lg1"
									valueColumnStyles="xs12 sm6 md4"
								></pui-text-area>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'detailconsultaform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'consultarealizada',
			modeldetail: true
		};
	},
	methods: {
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		}
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"detailconsulta.referencia": "Reference",
        "detailconsulta.idterminaldestino": "Destination terminal",
        "detailconsulta.idterminalorigen": "Origin terminal",
        "detailconsulta.pesosolicitado": "Weight (kg)",
		"detailconsulta.observaciones": "Inquiry state",
		"detailconsulta.motivorechazo": "Rejection reason"
    },
    "es": {
		"detailconsulta.referencia": "Referencia",
        "detailconsulta.idterminaldestino": "Terminal destino",
        "detailconsulta.idterminalorigen": "Terminal origen",
		"detailconsulta.pesosolicitado": "Peso (kg)",
        "detailconsulta.observaciones": "Observaciones",
		"detailconsulta.motivorechazo": "Motivo rechazo"
    },
    "ca": {
		"detailconsulta.referencia": "Referència",
        "detailconsulta.idterminaldestino": "Terminal destí",
        "detailconsulta.idterminalorigen": "Terminal origen",
        "detailconsulta.pesosolicitado": "Pes sol·licitat (kg)",
        "detailconsulta.observaciones": "Observacions",
		"detailconsulta.motivorechazo": "Motiu rebuig"
    }
}
</i18n>
