const anularConsultaAction = {
	id: 'anularConsultaAction',
	selectionType: 'single',
	label: 'consultarealizada.anular',
	functionality: 'MTO_CONSULTA',
	checkAvailability: function(asignacion) {
		if (asignacion[0] && asignacion[0].codestado === 'CS') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-anular-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var urlController = model.url.cancel;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			const params = { idconsulta: dataRegistry.idconsulta };
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

const accederEncargoAction = {
	id: 'accederEncargoAction',
	selectionType: 'single',
	label: 'consulta.accederEncargo',
	functionality: 'VIS_ENCARGO_TERM',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].indverencargo === '1') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const childTarget = 'encargoterm';
		const modelChild = {
			idencargo: registries[0].idencargo
		};

		const pkStr = JSON.stringify(modelChild);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);
		const params = {
			router: this.$router,
			url: '/' + childTarget + '/read/' + pk64 + '/',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [anularConsultaAction, accederEncargoAction],
	formactions: [anularConsultaAction, accederEncargoAction]
};
