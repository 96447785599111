<template>
	<div class="pui-form">
		<v-form class="pui-form-layout mb-4 pb-4 mt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs8 md4>
					<pui-text-field
						v-if="modelLoaded"
						:label="$t('camion.matricula')"
						v-model="model.matricula"
						maxlength="20"
						required
						:disabled="formDisabled"
						:rules="matriculaRules"
						labelColumnStyles="xs12 sm3"
						valueColumnStyles="xs12 sm5"
					></pui-text-field>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex xs8 md4>
					<pui-text-field
						v-if="modelLoaded"
						:label="$t('camion.remolque')"
						v-model="model.remolque"
						maxlength="20"
						:disabled="formDisabled"
						labelColumnStyles="xs12 sm3"
						valueColumnStyles="xs12 sm5"
					></pui-text-field>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex xs8 md4>
					<pui-select
						v-if="modelLoaded"
						attach="idconductor"
						:label="$t('camion.idconductor')"
						clearable
						:disabled="formDisabled"
						v-model="model"
						modelName="vlupconductorcamion"
						:itemsToSelect="[{ usr: model.idconductor }]"
						:modelFormMapping="{ usr: 'idconductor' }"
						:itemValue="['usr']"
						:itemText="(item) => `${item.nombre}`"
						:id="'idconductor'"
						:fixedFilter="fixedFilterConductor"
						:order="{ nombre: 'asc' }"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12"
					></pui-select>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex xs8 md4>
					<pui-number-field
						:label="$t('camion.capacidad')"
						v-model="model.capacidad"
						decimals="0"
						minvalue="0"
						maxvalue="9999999"
						suffix="Kg"
						required
						:disabled="formDisabled"
						labelColumnStyles="xs12 sm3"
						valueColumnStyles="xs12 sm4"
					></pui-number-field>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import CamionActions from './CamionActions';

export default {
	name: 'camion',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'camion',
			actions: CamionActions.formactions,
			matriculaRules: [(v) => this.validateMatriculaRules(v) || this.$t('camion.validate.matricula')]
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	},
	methods: {
		// Override
		afterGetData() {
			this.fixedFilterConductor = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista }]
			};
		},
		validateMatriculaRules(v) {
			var re = /^[a-z0-9A-Z]*$/;
			return re.test(v);
		}
	}
};
</script>
<style scoped></style>
