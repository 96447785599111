<template>
	<div>
		<consultarealizada-modals :modelName="model"></consultarealizada-modals>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:showCreateBtn="showCreateBtn"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import ConsultarealizadaActions from './ConsultarealizadaActions';
import ConsultarealizadaModals from './ConsultarealizadaModals';

export default {
	name: 'consultarealizadagrid',
	mixins: [PuiGridDetailMixin],
	components: { ConsultarealizadaModals },
	data() {
		return {
			model: 'consultarealizada',
			actions: ConsultarealizadaActions.gridactions,
			columnDefs: {
				fecharecogida: { withoutHours: true }
			},
			masterDetailColumnVisibles: {
				idconsulta: true,
				referencia: true,
				peso: true,
				terminaldestino: true,
				estado: true,
				fecharecogida: false,
				fechacreacion: false
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			if (registry.codestado === 'CS') {
				return true;
			} else {
				return false;
			}
		}
	},
	created() {
		if (this.masterDetail) {
			let thisModel = this.$store.getters.getModelByName(this.model);

			if (thisModel) {
				let parentModelPk = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
				thisModel.parentModelName = this.parentModelName;
				thisModel.parentModelPk = parentModelPk.pk;
			}
			if (this.parentModelName === 'consultarecibida') {
				this.masterDetailColumnVisibles.pesolocalizado = true;
				this.externalFilter = { groupOp: 'and', rules: [this.parentPkChildFk.extendFilter], groups: [] };
			}
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.consultarealizada.contrato": "Contract number",
        "grid.consultarealizada.fecharecogida": "Pick-up date",
        "grid.consultarealizada.idencargo": "Order code",
		"grid.consultarealizada.idconsulta": "Inquiry code",
		"grid.consultarealizada.idmercancia": "Good code",
        "grid.consultarealizada.mercancia": "Good",
        "grid.consultarealizada.peso": "Requested weight (kg)",
        "grid.consultarealizada.idterminalorigen": "Origin terminal code",
        "grid.consultarealizada.idterminaldestino": "Destination terminal code",
        "grid.consultarealizada.terminaldestino": "Destination terminal",
		"grid.consultarealizada.estado": "State",
		"grid.consultarealizada.codestado": "State code",
		"grid.consultarealizada.referencia": "Reference",
		"grid.consultarealizada.pesolocalizado": "Located weight (kg)",
		"grid.consultarealizada.motivorechazo": "Rejection reason",
		"grid.consultarealizada.indverencargo": "Ind. See order",
		"grid.consultarealizada.idencargo": "Order code",
		"grid.consultarealizada.codtipo": "Type code",
		"grid.consultarealizada.tipo": "Type",
		"grid.consultarealizada.fechacreacion":"Creation date"
    },
    "es": {
        "grid.consultarealizada.contrato": "Número de contrato",
        "grid.consultarealizada.fecharecogida": "Fecha de recogida",
        "grid.consultarealizada.idencargo": "Código encargo",
        "grid.consultarealizada.idconsulta": "Código consulta",
        "grid.consultarealizada.idmercancia": "Código mercancía",
		"grid.consultarealizada.mercancia": "Mercancía",
		"grid.consultarealizada.peso": "Peso solicitado (kg)",
        "grid.consultarealizada.idterminalorigen": "Código terminal origen",
        "grid.consultarealizada.idterminaldestino": "Código terminal destino",
        "grid.consultarealizada.terminaldestino": "Terminal destino",
        "grid.consultarealizada.estado": "Estado",
        "grid.consultarealizada.codestado": "Código estado",
		"grid.consultarealizada.referencia": "Referencia",
		"grid.consultarealizada.pesolocalizado": "Peso localizado (kg)",
		"grid.consultarealizada.motivorechazo": "Motivo rechazo",
		"grid.consultarealizada.indverencargo": "Ind. Ver encargo",
		"grid.consultarealizada.idencargo": "Código encargo",
		"grid.consultarealizada.codtipo": "Código tipo",
		"grid.consultarealizada.tipo": "Tipo",
		"grid.consultarealizada.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.consultarealizada.contrato": "Número de contracte",
        "grid.consultarealizada.fecharecogida": "Data de recollida",
        "grid.consultarealizada.idencargo": "Codi encàrrec",
        "grid.consultarealizada.idconsulta": "Codi consulta",
        "grid.consultarealizada.idmercancia": "Codi mercaderia",
		"grid.consultarealizada.mercancia": "Mercaderia",
		"grid.consultarealizada.peso": "Pes sol·licitat (kg)",
        "grid.consultarealizada.idterminalorigen": "Codi terminal origen",
        "grid.consultarealizada.idterminaldestino": "Codi terminal destí",
        "grid.consultarealizada.terminaldestino": "Terminal destí",
        "grid.consultarealizada.estado": "Estat",
        "grid.consultarealizada.codestado": "Codi estat",
		"grid.consultarealizada.referencia": "Referència",
		"grid.consultarealizada.pesolocalizado": "Pes localitzat (kg)",
		"grid.consultarealizada.motivorechazo": "Motiu rebuig",
		"grid.consultarealizada.indverencargo": "Ind. Vore encàrrec",
		"grid.consultarealizada.idencargo": "Codi encàrrec",
		"grid.consultarealizada.codtipo": "Codi tipus",
		"grid.consultarealizada.tipo": "Tipus",
		"grid.consultarealizada.fechacreacion":"Data creació"
    }
}
</i18n>
