const enviarAction = {
	id: 'enviarAction',
	selectionType: 'multiple',
	label: 'encargo.enviar',
	functionality: 'ENCARGO_ENVIAR',
	checkAvailability: function(selected) {
		if (selected && selected.codestado === 'EB') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var idestado = 2; //ES-solicitado
			var urlController = model.url.send;

			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;
			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				var idencargo = registry.idencargo;
				var params = { idencargo: idencargo, idestado: idestado };

				this.$puiRequests.patchRequest(
					urlController,
					params,
					() => {
						progress++;
						corrects++;
						finish();
					},
					() => {
						success = false;
						progress++;
						finish();
					}
				);
			}

			const finish = () => {
				if (counter === progress) {
					var message;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
					if (success) {
						message = counter + this.$puiI18n.t('action.notifySuccessMultiple');
						this.$puiNotify.success(message, title);
					} else {
						message = corrects + this.$puiI18n.t('action.notifyErrorMultiple') + counter;
						this.$puiNotify.error(message, title);
					}
				}
			};
		});
	}
};

const enviarSimpleAction = {
	id: 'enviarAction',
	selectionType: 'single',
	label: 'encargo.enviar',
	functionality: 'ENCARGO_ENVIAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado === 'EB') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		registries[0].simple = true;
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var idencargo = dataRegistry.idencargo;
			var idestado = 2; //ES-solicitado
			var urlController = model.url.send;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = { idencargo: idencargo, idestado: idestado };
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

const anularAction = {
	id: 'anularAction',
	selectionType: 'single',
	label: 'encargo.anular',
	functionality: 'ENCARGO_ANULAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'ES' || selected[0].codestado === 'ET' || selected[0].codestado === 'EL')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-anular-' + model.name + '-show', row);
	}
};

const duplicarAction = {
	id: 'duplicarAction',
	selectionType: 'single',
	label: 'encargo.duplicar',
	functionality: 'ENCARGO_DUPLICAR',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		model.copyObjectPk = {};

		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				model.copyObjectPk[column.name] = registries[0][column.name];
			}
		}
		if (model.copyObjectPk.idencargo != null) {
			model.oldModel = registries[0];
			const params = {
				router: this.$router,
				url: model.name + '/create/new',
				params: null,
				query: {}
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		}
	}
};

const crearAsignacionAction = {
	id: 'crearAsignacionAction',
	selectionType: 'single',
	label: 'encargo.crearAsignacion',
	functionality: 'MTO_ASIGNACION_COMP',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado === 'EL') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const targetModelName = 'asignacion';
		const bypassModel = this.$store.getters.getModelByName(targetModelName);

		const thisModel = this.$store.getters.getModelByName(targetModelName);
		if (thisModel) {
			thisModel.fromGrid = false;
		}
		var dataRegistry = registries[0];
		var idencargo = dataRegistry.idencargo;
		bypassModel.altaObjectPk = {};
		var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);

		if (idencargo != null) {
			var urlController = model.url.comprobarLote + '?idencargo=' + idencargo;
			var params = {};
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					bypassModel.altaObjectPk.idencargo = idencargo;
					const params = {
						router: this.$router,
						url: targetModelName + '/create/new',
						params: null,
						query: {}
					};
					this.$store.dispatch('puiRouterGoToUrl', params);
				},
				e => {
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					this.$puiNotify.error(message, title);
				}
			);
		}
	}
};

export default {
	gridactions: [enviarAction, anularAction, duplicarAction, crearAsignacionAction],
	formactions: [enviarSimpleAction, anularAction, duplicarAction, crearAsignacionAction]
};
