<template>
	<div class="pui-form" ref="timeslot">
		<v-layout ref="planifcab">
			<v-flex xs12 ml-3>
				<v-layout>
					<v-flex xs12 md4>
						<pui-date-field
							:label="$t('timeslot.fechainicio')"
							v-model="model.fechainicio"
							required
							disabled
							id="fechainicio"
							labelColumnStyles="xs12 md2"
							valueColumnStyles="xs12 md6"
							attach="fechainicio"
						></pui-date-field>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>

		<v-layout wrap>
			<v-flex xs12 md6 ml-3>
				<v-layout>
					<v-flex xs12>
						<v-layout wrap>
							<v-flex xs6></v-flex>
							<v-flex xs2>{{ $t('timeslot.total') }}</v-flex>
							<v-flex xs2>{{ $t('timeslot.asignados') }}</v-flex>
							<v-flex xs2>{{ $t('timeslot.disponibles') }}</v-flex>
						</v-layout>
						<div v-for="(asociado, index) in model.asociados" :key="index">
							<v-flex xs12>
								<v-layout>
									<v-flex xs12>
										<timeslot-detalle-form :formDisabled="formDisabled" :datos="model" :indice="index"></timeslot-detalle-form>
									</v-flex>
								</v-layout>
							</v-flex>
						</div>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import TimeslotDetalleForm from '../detalle/TimeslotDetalleForm';

export default {
	name: 'timeslotcab',
	components: {
		TimeslotDetalleForm
	},
	data() {
		return {};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		}
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"timeslot.idtimeslot": "Time slot identifier",
		"timeslot.fechainicio": "Date",
		"timeslot.fechafin": "End date",
		"timeslot.total": "Capacity",
		"timeslot.asignados": "Assigned",
		"timeslot.disponibles": "Avaible"
    },
    "es": {
        "timeslot.idtimeslot": "Identificador time slot",
		"timeslot.fechainicio": "Fecha",
		"timeslot.fechafin": "Fecha fin",
		"timeslot.total": "Capacidad",
		"timeslot.asignados": "Asignados",
		"timeslot.disponibles": "Disponibles"
    },
    "ca": {
        "timeslot.idtimeslot": "Identificador time slot",
		"timeslot.fechainicio": "Data",
		"timeslot.fechafin": "Data fi",
		"timeslot.total": "Capacidad",
		"timeslot.asignados": "Assignats",
		"timeslot.disponibles": "Disponibles"
    }
}
</i18n>
