<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:parentModelName="parentModelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:modelColumnDefs="columnDefs"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import TimeslotPcActions from './TimeslotPcActions';
export default {
	name: 'timeslotpcgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'timeslotpc',
			actions: TimeslotPcActions.gridactions,
			columnDefs: {
				fecha: { withoutHours: true },
				disponibles: {
					render: (data, type, row, meta) => {
						if (data < 0) {
							return `<label class="highlight-rounded highlight1">${data}</label>`;
						} else if (data === 0) {
							return `<label class="highlight-rounded highlight2">${data}</label>`;
						} else if (data > 0) {
							return `<label class="highlight-rounded highlight3">${data}</label>`;
						}
						return data;
					}
				}
			}
		};
	}
};
</script>

<style lang="postcss">
.timesslotgrid {
	overflow-y: auto;
}
.highlight1 {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.highlight2 {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highlight3 {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
.highlight4 {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highlight-rounded {
	padding: 3px 6px;
	border-radius: 5px;
}
</style>

<i18n>
{
    "en": {
        "grid.timeslotpc.idtimeslot": "Time slot identifier",
		"grid.timeslotpc.fecha": "Date",
		"grid.timeslotpc.horainicio": "Start hour",
		"grid.timeslotpc.horafin": "End hour",
		"grid.timeslotpc.capacidad": "Number of trucks",
		"grid.timeslotpc.asignados": "Assigned",
		"grid.timeslotpc.disponibles": "Avaible",
		"grid.timeslotpc.idpuntocarga": "Loading point code",
		"grid.timeslotpc.puntocarga": "Loading point",
		"grid.timeslotpc.fechacreacion":"Creation date"
    },
    "es": {
        "grid.timeslotpc.idtimeslot": "Identificador time slot",
		"grid.timeslotpc.fecha": "Fecha",
		"grid.timeslotpc.horainicio": "Hora de inicio",
		"grid.timeslotpc.horafin": "Hora de fin",
		"grid.timeslotpc.capacidad": "Nº de camiones",
		"grid.timeslotpc.asignados": "Asignados",
		"grid.timeslotpc.disponibles": "Disponibles",
		"grid.timeslotpc.idpuntocarga": "Cód. punto carga",
		"grid.timeslotpc.puntocarga": "Punto carga",
		"grid.timeslotpc.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.timeslotpc.idtimeslot": "Identificador time slot",
		"grid.timeslotpc.fecha": "Data",
		"grid.timeslotpc.horainicio": "Hora d' inici",
		"grid.timeslotpc.horafin": "Hora de fi",
		"grid.timeslotpc.capacidad": "Nº camions",
		"grid.timeslotpc.asignados": "Assignats",
		"grid.timeslotpc.disponibles": "Disponibles",
		"grid.timeslotpc.idpuntocarga": "Cod. punt càrrega",
		"grid.timeslotpc.puntocarga": "Punt càrrega",
		"grid.timeslotpc.fechacreacion":"Data creació"
    }
}
</i18n>
