<template>
	<div class="asignaciontrans puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('asignaciontrans.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('asignaciontrans.peso')" :value="getPeso"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('asignaciontrans.fecharecogida')" :value="getDiaRecogida"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'asignacioncompformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'asignacion'
		};
	},
	computed: {
		getMercancia() {
			return this.model && this.model.mercancia ? this.model.mercancia : '';
		},
		getDiaRecogida() {
			return this.model && this.model.fecharecogida
				? this.$dateTimeUtils.formatDate(this.model.fecharecogida, this.$store.getters.dateFormat)
				: '';
		},
		getPeso() {
			return this.model && this.model.pesoasignado ? this.model.pesoasignado : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'asignacion';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"asignaciontrans.mercancia": "Goods",
		"asignaciontrans.fecharecogida": "Pick up date",
		"asignaciontrans.peso": "Number of Kg."
	},
	"es": {
		"asignaciontrans.mercancia": "Mercancía",
		"asignaciontrans.fecharecogida": "Fecha de recogida",
		"asignaciontrans.peso": "Nº Kg."
	},
	"ca": {
		"asignaciontrans.mercancia": "Mercaderia",
		"asignaciontrans.fecharecogida": "Data de recollida",
		"asignaciontrans.peso": "Num. Kg."
	}
}
</i18n>
