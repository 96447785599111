<template>
	<div>
		<terminal-modals :modelName="model"></terminal-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import TerminalActions from './TerminalActions';
import TerminalModals from './TerminalModals';

export default {
	name: 'terminalgrid',
	components: {
		TerminalModals
	},
	data() {
		return {
			model: 'terminal',
			actions: TerminalActions.gridactions,
			columnDefs: {
				fecbaja: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.terminal.idterminal": "Code",
        "grid.terminal.nombre": "Name",
        "grid.terminal.cif": "Tax identifier",
        "grid.terminal.observaciones": "Comments",
		"grid.terminal.fechacreacion":"Creation date",
		"grid.terminal.indbaja":"Ind. Disabled",
		"grid.terminal.baja":"Disabled",
		"grid.terminal.fecbaja":"Disabled date"
    },
    "es": {
        "grid.terminal.idterminal": "Código",
        "grid.terminal.nombre": "Nombre",
        "grid.terminal.cif": "Identificador fiscal",
        "grid.terminal.observaciones": "Observaciones",
		"grid.terminal.fechacreacion":"Fecha creación",
		"grid.terminal.indbaja":"Ind. Baja",
		"grid.terminal.baja":"Baja",
		"grid.terminal.fecbaja":"Fecha baja"
    },
    "ca": {
        "grid.terminal.idterminal": "Codi",
        "grid.terminal.nombre": "Nom",
        "grid.terminal.cif": "Identificador fiscal",
        "grid.terminal.observaciones": "Observacions",
		"grid.terminal.fechacreacion":"Data creació",
		"grid.terminal.indbaja":"Ind. Baixa",
		"grid.terminal.baja":"Baixa",
		"grid.terminal.fecbaja":"Data baixa"
    }
}
</i18n>
