const generateAction = {
	id: 'generateAction',
	selectionType: 'single',
	label: 'timeslotplanif.generate',
	functionality: 'MTO_TIMESLOT',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.fechafin = null;
		this.$puiEvents.$emit('pui-modalDialogForm-date-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [generateAction],
	formactions: [generateAction]
};
