<template>
	<pui-datatable v-if="!masterDetail" :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:actions="actions"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:parentModelName="parentModelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:isEditableRegistry="isEditableRegistry"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import HistoricoCompActions from './HistoricoCompActions';

export default {
	name: 'historicocompgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'historicocomp',
			actions: HistoricoCompActions.gridactions
		};
	},
	created() {
		if (this.masterDetail) {
			const parentModel = this.$store.getters.getModelByName(this.parentModelName);
			const thisModel = this.$store.getters.getModelByName(this.model);
			if (parentModel && thisModel) {
				const parentModelPk = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
				thisModel.parentModelName = this.parentModelName;
				thisModel.parentModelPk = parentModelPk.pk;
			}
		}
	},
	methods: {
		isEditableRegistry() {
			return false;
		}
	}
};
</script>

<i18n>
{
    "en": {
		"grid.historicocomp.idhistorico":"Code",
		"grid.historicocomp.idencargo":"Order code",
		"grid.historicocomp.usralta":"Performer code",
		"grid.historicocomp.actor":"Performer",
		"grid.historicocomp.idestadoanterior":"Previous state code",
		"grid.historicocomp.estadoanterior":"Previous state",
		"grid.historicocomp.idestadonuevo":"New state code",
		"grid.historicocomp.estadonuevo":"New state",
		"grid.historicocomp.lang":"Language",
		"grid.historicocomp.idaccion":"Action code",
		"grid.historicocomp.accion":"Action",
		"grid.historicocomp.fecha":"Date",
		"grid.historicocomp.fechacreacion":"Creation date"
    },
    "es": {
		"grid.historicocomp.idhistorico":"Código",
		"grid.historicocomp.idencargo":"Código encargo",
		"grid.historicocomp.usralta":"Código actor",
		"grid.historicocomp.actor":"Actor",
		"grid.historicocomp.idestadoanterior":"Código estado anterior",
		"grid.historicocomp.estadoanterior":"Estado anterior",
		"grid.historicocomp.idestadonuevo":"Código estado nuevo",
		"grid.historicocomp.estadonuevo":"Estado nuevo",
		"grid.historicocomp.lang":"Idioma",
		"grid.historicocomp.idaccion":"Código acción",
		"grid.historicocomp.accion":"Acción",
		"grid.historicocomp.fecha":"Fecha",
		"grid.historicocomp.fechacreacion":"Fecha creación"
	},
    "ca": {
		"grid.historicocomp.idhistorico":"Codi",
		"grid.historicocomp.idencargo":"Codi encàrrec",
		"grid.historicocomp.usralta":"Codi actor",
		"grid.historicocomp.actor":"Actor",
		"grid.historicocomp.idestadoanterior":"Codi estat anterior",
		"grid.historicocomp.estadoanterior":"Estat anterior",
		"grid.historicocomp.idestadonuevo":"Codi estat nou",
		"grid.historicocomp.estadonuevo":"Estat nou",
		"grid.historicocomp.lang":"Idioma",
		"grid.historicocomp.idaccion":"Codi acció",
		"grid.historicocomp.accion":"Acció",
		"grid.historicocomp.fecha":"Data",
		"grid.historicocomp.fechacreacion":"Data creació"
	}
}
</i18n>
