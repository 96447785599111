<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import TimeslotActions from './TimeslotActions';

export default {
	name: 'timeslotgrid',
	data() {
		return {
			modelName: 'timeslot',
			actions: TimeslotActions.gridactions,
			columnDefs: {
				fecha: { withoutHours: true },
				disponibles: {
					render: (data, type, row, meta) => {
						if (data < 0) {
							return `<label class="highlight-rounded highlight1">${data}</label>`;
						} else if (data === 0) {
							return `<label class="highlight-rounded highlight2">${data}</label>`;
						} else if (data > 0) {
							return `<label class="highlight-rounded highlight3">${data}</label>`;
						}
						return data;
					}
				}
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return this.$dateTimeUtils.isLocalDateSameOrAfter(registry.fecha, this.$dateTimeUtils.getLocalDateNow());
		}
	}
};
</script>

<style lang="postcss">
.highlight1 {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.highlight2 {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highlight3 {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
.highlight4 {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highlight-rounded {
	padding: 3px 6px;
	border-radius: 5px;
}
</style>

<i18n>
{
    "en": {
		"grid.timeslot.idtimeslot": "Time slot identifier",
		"grid.timeslot.idplanif": "Planning code",
		"grid.timeslot.idpuntocarga": "Loading point code",
		"grid.timeslot.codpuntocarga": "Loading point code",
		"grid.timeslot.puntocarga": "Loading point",
		"grid.timeslot.fecha": "Date",
		"grid.timeslot.horainicio": "Start hour",
		"grid.timeslot.horafin": "End hour",
		"grid.timeslot.capacidad": "Number of trucks",
		"grid.timeslot.asignados": "Assigned",
		"grid.timeslot.disponibles": "Avaible",
		"grid.timeslot.fechacreacion":"Creation date"
    },
    "es": {
		"grid.timeslot.idtimeslot": "Identificador time slot",
		"grid.timeslot.idplanif": "Código planificación",
		"grid.timeslot.idpuntocarga": "Cód. punto carga",
		"grid.timeslot.codpuntocarga": "Código punto carga",
		"grid.timeslot.puntocarga": "Punto carga",
		"grid.timeslot.fecha": "Fecha",
		"grid.timeslot.horainicio": "Hora de inicio",
		"grid.timeslot.horafin": "Hora de fin",
		"grid.timeslot.capacidad": "Nº de camiones",
		"grid.timeslot.asignados": "Asignados",
		"grid.timeslot.disponibles": "Disponibles",
		"grid.timeslot.fechacreacion":"Fecha creación"
    },
    "ca": {
		"grid.timeslot.idtimeslot": "Identificador time slot",
		"grid.timeslot.idplanif": "Codi planificació",
		"grid.timeslot.idpuntocarga": "Cod. punt càrrega",
		"grid.timeslot.codpuntocarga": "Codi punt càrrega",
		"grid.timeslot.puntocarga": "Punt càrrega",
		"grid.timeslot.fecha": "Data",
		"grid.timeslot.horainicio": "Hora d' inici",
		"grid.timeslot.horafin": "Hora de fi",
		"grid.timeslot.capacidad": "Nº camions",
		"grid.timeslot.asignados": "Assignats",
		"grid.timeslot.disponibles": "Disponibles",
		"grid.timeslot.fechacreacion":"Data creació"
    }
}
</i18n>
