<template>
	<div>
		<pui-modal-dialog
			v-if="modals.reasignarCarga.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.reasignarCarga.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.reasignarCarga.titleText"
			:dialogName="`reasignarCarga-${this.modelName}`"
			ref="reasignarCarga"
			:widthDialog="700"
			mesageText
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<asignaciontransformheader v-if="parentModelPk" :modelPk="parentModelPk"></asignaciontransformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('vtcasignaciones.validate.enable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'asignacionmodals',
	data() {
		return {
			modals: {
				reasignarCarga: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-reasignarCarga-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-reasignarCarga-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-reasignarCarga-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idasignacion: data.idasignacion
				};
				this.setShowConfirm(true);
			});
		},
		setShowConfirm(action) {
			this.modals.reasignarCarga.show = action;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-reasignarCarga-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-reasignarCarga-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-reasignarCarga-${this.modelName}-show`);
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtcasignaciones.validate.enable": "The burden of this assignment will be reallocated. Do you want to continue?"
	},
	"es": {
		"vtcasignaciones.validate.enable": "Se reasignará la carga de esta asignación. ¿Desea continuar?"
	},
	"ca": {
		"vtcasignaciones.validate.enable": "Es reassignarà la càrrega d'aquesta assignació. Desitja continuar?"
	}
}
</i18n>
