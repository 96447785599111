<template>
	<div>
		<libramiento-trans-modals :modelName="model"></libramiento-trans-modals>
		<pui-datatable v-if="!masterDetail" :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:actions="actions"
			:parentModelName="parentModelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import LibramientoTransActions from './LibramientoTransActions';
import LibramientoTransModals from './LibramientoTransModals';

export default {
	name: 'libramientotransgrid',
	components: { LibramientoTransModals },
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'libramientotrans',
			actions: LibramientoTransActions.gridactions,
			columnDefs: {
				estado: {
					render: (data, type, row, meta) => {
						if (row.codestado === 'LS') {
							return (
								`<label class="highEncLibTrans-rounded highSolicitadoLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LS') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LA') {
							return (
								`<label class="highEncLibTrans-rounded highAnuladoLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LA') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LT') {
							return (
								`<label class="highEncLibTrans-rounded highAutorizadoLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LT') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LR') {
							return (
								`<label class="highEncLibTrans-rounded highRechazadoLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LR') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LM') {
							return (
								`<label class="highEncLibTrans-rounded highModifPuntLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LM') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LE') {
							return (
								`<label class="highEncLibTrans-rounded highEntregadoLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LE') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'LN') {
							return (
								`<label class="highEncLibTrans-rounded highNoRegLibTrans" title="` +
								this.$t('grid.libramientotrans.estado.LN') +
								`">${data}</label>`
							);
						}

						return data;
					}
				},
				fecharecogida: {
					withoutHours: true
				}
			}
		};
	},
	methods: {
		getEstadoLA() {
			return 'LA';
		},
		getEstadoLS() {
			return 'LS';
		}
	},
	computed: {
		getEstadoLAFixedFilter() {
			return { groups: [], groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: this.getEstadoLA() }] };
		}
	}
};
</script>

<style lang="postcss">
.highAnuladoLibTrans {
	color: rgb(152, 155, 153);
	background-color: rgba(227, 231, 230, 0.8) !important;
	font-weight: bold;
}
.highRechazadoLibTrans {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.highSolicitadoLibTrans {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highEntregadoLibTrans {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highAutorizadoLibTrans {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highModifPuntLibTrans {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highNoRegLibTrans {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highEncLibTrans-rounded {
	padding: 3px 6px;
	border-radius: 5px;
}
</style>

<i18n>
{
    "en": {
		"grid.libramientotrans.idlibramiento": "Code",
		"grid.libramientotrans.idasignacion": "Assignament code",
		"grid.libramientotrans.idmercancia": "Good code",
		"grid.libramientotrans.mercancia": "Good",
		"grid.libramientotrans.pesosolicitado": "Weight(kg)",
		"grid.libramientotrans.fecharecogida": "Pick-up date",
		"grid.libramientotrans.fechainicio": "Time slot beginnig",
		"grid.libramientotrans.fechafin": "Time slot end",
		"grid.libramientotrans.horarecogida": "Pick up time",
		"grid.libramientotrans.idterminal": "Terminal code",
		"grid.libramientotrans.terminalcarga": "Charging terminal",
		"grid.libramientotrans.idpuntocarga": "Loading point code",
		"grid.libramientotrans.puntocarga": "Loading point",
		"grid.libramientotrans.matricula": "Plate",
		"grid.libramientotrans.idconductor": "Driver code",
	    "grid.libramientotrans.conductor": "Driver",
		"grid.libramientotrans.indpcmodificado": "Check updated loading point",
		"grid.libramientotrans.pcmodificado": "Updated loading point",
		"grid.libramientotrans.codestado": "State code",
		"grid.libramientotrans.estado": "State",
		"grid.libramientotrans.idtransportista": "Carrier code",
		"grid.libramientotrans.fechaentrega": "Actual delivery date",
		"grid.libramientotrans.anularmotivo":"Reason for cancellation",
		"grid.libramientotrans.anularmotivo.title":"Cancel riddance",
		"grid.libramientotrans.idcomprador": "Buyer code",
	    "grid.libramientotrans.comprador": "Buyer",
		"grid.libramientotrans.fechacreacion":"Creation date"
    },
    "es": {
		"grid.libramientotrans.idlibramiento": "Código",
		"grid.libramientotrans.idasignacion": "Código asignación",
		"grid.libramientotrans.idmercancia": "Código Mercancía",
		"grid.libramientotrans.mercancia": "Mercancía",
		"grid.libramientotrans.pesosolicitado": "Peso (Kg)",
		"grid.libramientotrans.fecharecogida": "Fecha de recogida",
		"grid.libramientotrans.fechainicio": "Inicio time slot",
		"grid.libramientotrans.fechafin": "Fin time slot",
		"grid.libramientotrans.horarecogida": "Hora de recogida",
		"grid.libramientotrans.idterminal": "Código Terminal",
		"grid.libramientotrans.terminalcarga": "Terminal de carga",
		"grid.libramientotrans.idpuntocarga": "Código punto de carga",
		"grid.libramientotrans.puntocarga": "Punto de carga",
		"grid.libramientotrans.matricula": "Matrícula",
		"grid.libramientotrans.idconductor": "Código conductor",
	    "grid.libramientotrans.conductor": "Conductor",
		"grid.libramientotrans.indpcmodificado": "Ind. punto de carga modificado",
		"grid.libramientotrans.pcmodificado": "P.C. modificado",
		"grid.libramientotrans.codestado": "Código estado",
		"grid.libramientotrans.estado": "Estado",
		"grid.libramientotrans.idtransportista": "Código transportista",
		"grid.libramientotrans.fechaentrega": "Fecha real de entrega",
		"grid.libramientotrans.anularmotivo":"Motivo de anulación",
		"grid.libramientotrans.anularmotivo.title":"Anular libramiento",
		"grid.libramientotrans.idcomprador": "Código comprador",
	    "grid.libramientotrans.comprador": "Comprador",
		"grid.libramientotrans.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.libramientotrans.idlibramiento": "Codi",
		"grid.libramientotrans.idasignacion": "Codi assignació",
		"grid.libramientotrans.idmercancia": "Codi mercaderia",
		"grid.libramientotrans.mercancia": "Mercaderia",
		"grid.libramientotrans.pesosolicitado": "Pes (Kg)",
		"grid.libramientotrans.fecharecogida": "Data de recollida",
		"grid.libramientotrans.fechainicio": "Inici time slot",
		"grid.libramientotrans.fechafin": "Fi time slot",
		"grid.libramientotrans.horarecogida": "Hora de recollida",
		"grid.libramientotrans.idterminal": "Codi Terminal",
		"grid.libramientotrans.terminalcarga": "Terminal de càrrega",
		"grid.libramientotrans.idpuntocarga": "Codi punto de càrrega",
		"grid.libramientotrans.puntocarga": "Punt de càrrega",
		"grid.libramientotrans.matricula": "Matrícula",
		"grid.libramientotrans.idconductor": "Codi conductor",
	    "grid.libramientotrans.conductor": "Conductor",
		"grid.libramientotrans.indpcmodificado": "Ind. Punt de càrrega modificat",
		"grid.libramientotrans.pcmodificado": "Punt càrrega modificat",
		"grid.libramientotrans.codestado": "Codi estat",
		"grid.libramientotrans.estado": "Estat",
		"grid.libramientotrans.idtransportista": "Codi transportista",
		"grid.libramientotrans.fechaentrega": "Data real d'entrega",
		"grid.libramientotrans.anularmotivo":"Motiu d'anulació",
		"grid.libramientotrans.anularmotivo.title":"Anul·lar lliurament",
		"grid.libramientotrans.idcomprador": "Codi comprador",
	    "grid.libramientotrans.comprador": "Comprador",
		"grid.libramientotrans.fechacreacion":"Data creació"
 	}
}
</i18n>
