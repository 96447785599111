<template>
	<div class="motivoanulrechformheader">
		<v-layout wrap>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('grid.motivoanulrech.codigo')" :value="getCodigo"></pui-field>
			</v-flex>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('grid.motivoanulrech.descripcion')" :value="getDescripcion"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'motivoanulrechformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'motivoanulrech'
		};
	},
	computed: {
		getCodigo() {
			return this.model && this.model.codigo ? this.model.codigo : '';
		},
		getDescripcion() {
			return this.model && this.model.descripcion ? this.model.descripcion : '';
		}
	},
	methods: {
		extendFilter(filter) {
			filter.model = 'motivoanulrech';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {

	},
	"es": {

	},
	"ca": {

	}
}
</i18n>
