<template>
	<div class="comprador puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('vtccompradores.nombre')" :value="getNombre"></pui-field>
			</v-flex>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('vtccompradores.cif')" :value="getCif"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'compradorformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'comprador'
		};
	},
	computed: {
		getNombre() {
			return this.model && this.model.nombre ? this.model.nombre : '';
		},
		getCif() {
			return this.model && this.model.cif ? this.model.cif : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'comprador';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
        "vtccompradores.nombre": "Name",
        "vtccompradores.cif": "Tax identifier"
    },
    "es": {
        "vtccompradores.nombre": "Nombre",
        "vtccompradores.cif": "Identificador fiscal"
    },
    "ca": {
        "vtccompradores.nombre": "Nom",
        "vtccompradores.cif": "Identificador fiscal"
    }
}
</i18n>
