<template>
	<div>
		<encargo-comp-modals :modelName="model"></encargo-comp-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import EncargoCompActions from './EncargoCompActions';
import EncargoCompModals from './EncargoCompModals';
export default {
	name: 'encargocompgrid',
	components: { EncargoCompModals },
	data() {
		return {
			model: 'encargocomp',
			actions: EncargoCompActions.gridactions,
			columnDefs: {
				estado: {
					render: (data, type, row, meta) => {
						if (row.codestado === 'ES') {
							return (
								`<label class="highEncComp-rounded highSolicitadoComp" title="` +
								this.$t('grid.encargocomp.estado.ES') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'EA') {
							return (
								`<label class="highEncComp-rounded highAnuladoComp" title="` +
								this.$t('grid.encargocomp.estado.EA') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'ER') {
							return (
								`<label class="highEncComp-rounded highRechazadoComp" title="` +
								this.$t('grid.encargocomp.estado.ER') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'EL') {
							return (
								`<label class="highEncComp-rounded highLocMercanciaComp" title="` +
								this.$t('grid.encargocomp.estado.EL') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'EF') {
							return (
								`<label class="highEncComp-rounded highAsigTransComp" title="` +
								this.$t('grid.encargocomp.estado.EF') +
								`">${data}</label>`
							);
						} else if (row.codestado === 'EB') {
							return `<label title="` + this.$t('grid.encargocomp.estado.EB') + `">${data}</label>`;
						} else if (row.codestado === 'ET') {
							return (
								`<label class="highEncComp-rounded highEnTramiteComp" title="` +
								this.$t('grid.encargocomp.estado.ET') +
								`">${data}</label>`
							);
						}

						return data;
					}
				},
				fecharecogida: {
					withoutHours: true
				}
			}
		};
	},
	computed: {
		getEstadoEA() {
			return 'EA';
		},
		getEstadoEAFixedFilter() {
			return { groups: [], groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: 'EA' }] };
		}
	}
};
</script>

<style lang="postcss">
.highAnuladoComp {
	color: rgb(152, 155, 153);
	background-color: rgba(227, 231, 230, 0.8) !important;
	font-weight: bold;
}
.highRechazadoComp {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.highSolicitadoComp {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highLocMercanciaComp {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
.highAsigTransComp {
	color: rgb(60, 118, 61);
	background-color: rgba(203, 220, 196, 0.8) !important;
	font-weight: bold;
}
.highEnTramiteComp {
	color: rgb(255, 153, 0);
	background-color: rgba(240, 240, 193, 0.8) !important;
	font-weight: bold;
}
.highEncComp-rounded {
	padding: 3px 6px;
	border-radius: 5px;
}
</style>

<i18n>
{
    "en": {
        "grid.encargocomp.idencargo": "Id order",
        "grid.encargocomp.idcomprador": "Id buyer",
        "grid.encargocomp.contrato": "Contract number",
        "grid.encargocomp.aplicacion": "Application",
		"grid.encargocomp.referencia": "Reference",
		"grid.encargocomp.fecharecogida": "Pick-up date",
		"grid.encargocomp.idterminalgestora": "Id terminal",
		"grid.encargocomp.terminalgestora": "Management terminal",
		"grid.encargocomp.idmercancia": "Id good",
		"grid.encargocomp.mercancia": "Good",
		"grid.encargocomp.peso": "Weight(kg)",
		"grid.encargocomp.proveedor": "Supplier",
		"grid.encargocomp.estado": "State",
		"grid.encargocomp.codestado": "State code",
		"grid.encargocomp.anularmotivo":"Reason for cancellation",
		"grid.encargocomp.fechacreacion":"Creation date"
    },
    "es": {
        "grid.encargocomp.idencargo": "Id encargo",
        "grid.encargocomp.idcomprador": "Id comprador",
        "grid.encargocomp.contrato": "Número de contrato",
        "grid.encargocomp.aplicacion": "Aplicación",
		"grid.encargocomp.referencia": "Referencia",
		"grid.encargocomp.fecharecogida": "Fecha de recogida",
		"grid.encargocomp.idterminalgestora": "Id terminal",
		"grid.encargocomp.terminalgestora": "Terminal gestora",
		"grid.encargocomp.idmercancia": "Id mercancía",
		"grid.encargocomp.mercancia": "Mercancía",
		"grid.encargocomp.peso": "Peso (kg)",
		"grid.encargocomp.proveedor": "Proveedor",
		"grid.encargocomp.estado": "Estado",
		"grid.encargocomp.codestado": "Código estado",
		"grid.encargocomp.anularmotivo":"Motivo de anulación",
		"grid.encargocomp.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.encargocomp.idencargo": "Id encàrrec",
        "grid.encargocomp.idcomprador": "Id comprador",
        "grid.encargocomp.contrato": "Número de contracte",
        "grid.encargocomp.aplicacion": "Aplicació",
		"grid.encargocomp.referencia": "Referència",
		"grid.encargocomp.fecharecogida": "Data de recollida",
		"grid.encargocomp.idterminalgestora": "Id terminal",
		"grid.encargocomp.terminalgestora": "Terminal gestora",
		"grid.encargocomp.idmercancia": "Id Mercaderia",
		"grid.encargocomp.mercancia": "Mercaderia",
		"grid.encargocomp.peso": "Pes (kg)",
		"grid.encargocomp.proveedor": "Proveïdor",
		"grid.encargocomp.estado": "Estat",
		"grid.encargocomp.codestado": "Codi estat",
		"grid.encargocomp.anularmotivo":"Motiu d'anulació",
		"grid.encargocomp.fechacreacion":"Data creació"
    }
}
</i18n>
