<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'timeslotplanifauditgrid',
	data() {
		return {
			model: 'timeslotplanifaudit',
			columnDefs: {
				fechainicio: { withoutHours: true },
				fechafin: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
		"grid.timeslotplanifaudit.idterminal": "Terminal id",
		"grid.timeslotplanifaudit.terminal": "Terminal",
        "grid.timeslotplanifaudit.idplanif": "Code",
		"grid.timeslotplanifaudit.idpuntocarga": "Loading point identifier",
		"grid.timeslotplanifaudit.codpuntocarga": "Loading point code",
		"grid.timeslotplanifaudit.descpuntocarga": "Loading point",
		"grid.timeslotplanifaudit.fechainicio": "Start date",
		"grid.timeslotplanifaudit.fechafin": "End date",
        "grid.timeslotplanifaudit.observaciones": "Comments",
		"grid.timeslotplanifaudit.fechacreacion":"Creation date"
    },
    "es": {
		"grid.timeslotplanifaudit.idterminal": "Código terminal",
		"grid.timeslotplanifaudit.terminal": "Terminal",
        "grid.timeslotplanifaudit.idplanif": "Código planificación",
		"grid.timeslotplanifaudit.idpuntocarga": "Cód. punto carga",
		"grid.timeslotplanifaudit.codpuntocarga": "Código punto carga",
		"grid.timeslotplanifaudit.descpuntocarga": "Punto carga",
		"grid.timeslotplanifaudit.fechainicio": "Fecha inicio",
		"grid.timeslotplanifaudit.fechafin": "Fecha fin",
		"grid.timeslotplanifaudit.observaciones": "Observaciones",
		"grid.timeslotplanifaudit.fechacreacion":"Fecha creación"
    },
    "ca": {
		"grid.timeslotplanifaudit.idterminal": "Codi terminal",
		"grid.timeslotplanifaudit.terminal": "Terminal",
        "grid.timeslotplanifaudit.idplanif": "Codi planificació",
		"grid.timeslotplanifaudit.idpuntocarga": "Punt de càrrega",
		"grid.timeslotplanifaudit.codpuntocarga": "Codi punt càrrega",
		"grid.timeslotplanifaudit.descpuntocarga": "Punt càrrega",
		"grid.timeslotplanifaudit.fechainicio": "Data inici",
		"grid.timeslotplanifaudit.fechafin": "Data fi",
        "grid.timeslotplanifaudit.observaciones": "Observacions",
		"grid.timeslotplanifaudit.fechacreacion":"Data creació"
    }
}
</i18n>
