<template>
	<div class="novedades puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('novedades.idnovedad')" :value="getIdnovedad"></pui-field>
			</v-flex>
			<v-flex xs12 md8>
				<pui-field :label="this.$t('novedades.titulo')" :value="getTitulo"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'novedadesformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'novedades'
		};
	},
	computed: {
		getIdnovedad() {
			return this.model && this.model.idnovedad ? this.model.idnovedad : '';
		},
		getTitulo() {
			return this.model && this.model.titulo ? this.model.titulo : '';
		}
	}
};
</script>
