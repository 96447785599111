<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtclibramientostg.refusemotivo.title')"
			:modelName="modelName"
			:dialogName="dialogNameRefusemotivo"
			v-model="modalData"
			:showDialogProp="showDialogRefusemotivo"
			:onOk="onOkRefusemotivo"
			:widthDialog="800"
		>
			<template slot="message">
				<pui-form-header :showHeader="modalData.simple">
					<encargotermformheader :modelPk="parentModelPk" origen="LT"></encargotermformheader>
				</pui-form-header>
				<v-layout>
					<v-flex xd12>
						<pui-select
							attach="codigo"
							:label="$t('vtclibramientostg.refusemotivo')"
							clearable
							required
							v-model="modalData"
							modelName="vlupmotivoanulrech"
							:itemsToSelect="[{ codigo: modalData.codmotivoanulrechazo }]"
							:modelFormMapping="{ codigo: 'codmotivoanulrechazo' }"
							:itemValue="['codigo']"
							:itemText="(item) => `${item.descripcion}`"
							:fixedFilter="getEstadoLRFixedFilter"
							:order="{ descripcion: 'asc' }"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout>
					<v-flex xd12>
						<pui-text-area :label="$t('libramiento.obsrechazo')" v-model="modalData.obsrechazo" maxlength="300"></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtclibramientostg.modifyTPC.title')"
			:modelName="modelName"
			:dialogName="dialogNameModifyTPC"
			v-model="modalData"
			:showDialogProp="showDialogModifyTPC"
			:onOk="onOkModifyTPC"
			:widthDialog="800"
		>
			<template slot="message">
				<pui-form-header :showHeader="modalData.simple">
					<encargotermformheader :modelPk="parentModelPk" origen="LT"></encargotermformheader>
				</pui-form-header>
				<v-layout class="justify-center">
					<v-flex xs12>
						<pui-select
							:attach="`idterminal2${modelName}`"
							:id="`idterminal2${modelName}`"
							:label="$t('vtclibramientostg.modal.terminal')"
							clearable
							v-model="modalData"
							required
							modelName="vlupterminales"
							:itemsToSelect="[{ idterminal: modalData.idterminal }]"
							:modelFormMapping="{ idterminal: 'idterminal' }"
							:itemValue="['idterminal']"
							:itemText="(item) => `${item.nombre}`"
							:order="{ nombre: 'asc' }"
							labelColumnStyles="xs4"
							:fixedFilter="fixedFilterPuntoCarga"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout class="justify-center">
					<v-flex xs12>
						<!--<pui-select
							id="idpuntocarga"
							attach="idpuntocarga"
							:label="$t('vtclibramientostg.modal.puntocarga')"
							clearable
							v-model="modalData"
							required
							:itemsToSelect="[{ idpuntocarga: modalData.idpuntocarga }]"
							:itemValue="['idpuntocarga']"
							:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
							:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
							:items="puntosCarga"
							labelColumnStyles="xs4"
							valueColumnStyles="xs5"
							:order="{ descripcion: 'asc' }"
							:key="'lupaPunto' + lupaKey"
						></pui-select>
						-->
						<pui-select
							id="idpuntocarga"
							attach="idpuntocarga"
							:label="$t('vtclibramientostg.modal.puntocarga')"
							clearable
							v-model="modalData"
							required
							modelName="vluppuntocarga"
							:itemsToSelect="[{ idpuntocarga: modalData.idpuntocarga }]"
							:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
							:itemValue="['idpuntocarga']"
							:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
							:filterId="`idterminal2${modelName}`"
							:filterMap="{ idterminal: modalData.idterminal, indpublico: getPublico }"
							:fixedFilter="fixedFilterPuntoCarga"
							labelColumnStyles="xs4"
							valueColumnStyles="xs5"
							:order="{ descripcion: 'asc' }"
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="getResultTitle"
			:modelName="modelName"
			:dialogName="dialogNameResult"
			v-model="modalData"
			:showDialogProp="showDialogResult"
			:onOk="onOkResult"
			:widthDialog="900"
		>
			<template slot="message">
				<div v-for="(detalle, index) in modalData.data" :key="index">
					<v-layout wrap :class="index === 0 ? 'resultOK' : 'resultKO'">
						<v-flex xs12>{{ detalle }}</v-flex>
					</v-layout>
				</div>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'libramientotermgmodals',
	data() {
		return {
			modalData: {},
			dialogNameModifyTPC: 'modifyTPC',
			showDialogModifyTPC: true,
			dialogNameRefusemotivo: 'refusemotivo',
			showDialogRefusemotivo: true,
			parentModelPk: null,
			fixedFilterPuntoCarga: null,
			fixedFilterTerminal: {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'indbaja', op: 'eq', data: '0' }]
			},
			puntosCarga: [],
			fec: {},
			idpuntocarga: {},
			hora: {},
			lupaKey: 0,
			showDialogResult: true,
			dialogNameResult: 'result'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameModifyTPC}-${this.modelName}-show`, (data) => {
			this.parentModelPk = { idencargo: data.idencargo };
			this.fixedFilterPuntoCarga = this.makeFilterLoadingPoint(data.fecharecogida);
			this.showDialogModifyTPC = true;

			/*this.parentModelPk = { idencargo: data.idencargo };
			this.fec = data.fecharecogidaenc ? data.fecharecogidaenc : data.fecharecogida;
			this.idpuntocarga = data.idpuntocarga;
			const horaaut = data.horaautorizacion ? data.horaautorizacion : data.horarecogida;
			this.hora = data.codestado === 'LT' || data.codestado === 'LM' ? horaaut : data.horainicio;

			this.puntosCarga = this.getPuntosCarga(data.idterminal, this.idpuntocarga, this.fec, this.hora);
			this.showDialogModifyTPC = true;
			*/
		});
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameRefusemotivo}-${this.modelName}-show`, (data) => {
			this.parentModelPk = { idencargo: data.idencargo };
			this.showDialogRefusemotivo = true;
		});
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameResult}-${this.modelName}-show`, (data) => {
			this.showDialogResult = true;
		});
	},
	methods: {
		onOkModifyTPC(modalData) {
			let registries = modalData.registries;
			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;
			var idpuntocarga = modalData.idpuntocarga;
			var errors = [];
			let urlController = this.$store.getters.getModelByName(this.modelName).url.modifyTPC;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.modifyTPC');

			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				var idlibramiento = registry.idlibramiento;

				var params = { idlibramiento: idlibramiento, idpuntocarga: idpuntocarga };

				this.$puiRequests.patchRequest(
					urlController,
					params,
					(response) => {
						progress++;
						corrects++;
						finish();
					},
					(error) => {
						success = false;
						progress++;
						if (error.response && error.response.data) {
							let message = error.response.data.message;
							var data = JSON.parse(error.response.config.data);
							errors.push(this.$puiI18n.t('grid.libramientotermg.idlibramiento') + ' ' + data.idlibramiento + ': ' + message);
						}
						finish();
					}
				);
			}

			const finish = () => {
				if (counter === progress) {
					this.showDialogModifyTPC = false;
					var message;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

					var row = {};
					row.isAction = true;
					row.data = [];
					row.data.push(corrects + this.$puiI18n.t('action.notifySuccessMultiple'));
					row.data = row.data.concat(errors);
					this.$puiEvents.$emit('pui-modalDialogForm-' + this.dialogNameResult + '-' + this.modelName + '-show', row);

					/*var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.modifyTPC');
					if (success) {
						message = counter + this.$puiI18n.t('action.notifySuccessMultiple');
						this.$puiNotify.success(message, title);
					} else {
						message = corrects + this.$puiI18n.t('action.notifyErrorMultiple') + counter;
						this.$puiNotify.error(message, title);
					}*/
				}
			};
		},
		onOkRefusemotivo(modalData) {
			let registries = modalData.registries;
			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;
			var codrechazo = modalData.codmotivoanulrechazo;
			var obsrechazo = modalData.obsrechazo;

			let urlController = this.$store.getters.getModelByName(this.modelName).url.refuse;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.refuse');

			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				var idlibramiento = registry.idlibramiento;

				var params = { idlibramiento: idlibramiento, codrechazo: codrechazo, obsrechazo: obsrechazo };
				this.$puiRequests.patchRequest(
					urlController,
					params,
					(response) => {
						progress++;
						corrects++;
						finish();
					},
					(e) => {
						success = false;
						progress++;
						finish();
					}
				);

				const finish = () => {
					if (counter === progress) {
						this.showDialogRefusemotivo = false;
						var message;
						this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.refuse');
						if (success) {
							message = counter + this.$puiI18n.t('action.notifySuccessMultiple');
							this.$puiNotify.success(message, title);
						} else {
							message = corrects + this.$puiI18n.t('action.notifyErrorMultiple') + counter;
							this.$puiNotify.error(message, title);
						}
					}
				};
			}
		},
		getPuntosCarga(idterminal, idpuntocarga, fecrecogida, hora) {
			const params = { idterminal: idterminal, idpuntocarga: idpuntocarga, fecrecogida: fecrecogida, hora: hora };
			this.$puiRequests.getRequest(
				'/libramientotermg/obtenerCombo',
				params,
				(response) => {
					if (response.data) {
						this.puntosCarga = response.data;
						this.lupaKey++;
					}
				},
				(error) => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		makeFilterLoadingPoint(fecha) {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{
						field: 'fecbaja',
						op: 'gt',
						data: fecha
					},
					{
						field: 'fecbaja',
						op: 'nu',
						data: null
					}
				]
			};
		},
		onOkResult() {
			this.showDialogResult = false;
		}
	},
	computed: {
		getEstadoLR() {
			return 'LR';
		},
		getEstadoLRFixedFilter() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'indbaja', op: 'eq', data: '0' },
							{ field: 'indbaja', op: 'nu', data: null }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: 'LR' }] }
				],
				groupOp: 'and',
				rules: []
			};
		},
		getPublico() {
			return '1';
		},
		getResultTitle() {
			return this.$t('vtclibramientostg.modifyTPC.title');
		}
	} /*,
	watch: {
		'modalData.idterminal'(newValue) {
			this.puntosCarga = this.getPuntosCarga(newValue, this.idpuntocarga, this.fec, this.hora);
		}
	}*/
};
</script>
<style lang="postcss" scoped>
.resultKO {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.resultOK {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
</style>

<i18n>
{
	"en": {
		"vtclibramientostg.refusemotivo.title":"Refuse riddance",
		"vtclibramientostg.refusemotivo":"Reason for refusal",
		"vtclibramientostg.idpuntocarga": "Loading point code",
		"vtclibramientostg.modifyTPC.title":"Modify loading point",
		"vtclibramientostg.modal.puntocarga": "Loading point",
		"vtclibramientostg.modal.terminal": "Charging terminal"
	},
	"es": {
		"vtclibramientostg.refusemotivo.title":"Rechazar libramiento",
		"vtclibramientostg.refusemotivo":"Motivo de rechazo",
		"vtclibramientostg.idpuntocarga": "Código punto de carga",
		"vtclibramientostg.modifyTPC.title":"Modificar punto de carga",
		"vtclibramientostg.modal.puntocarga": "Punto de carga",
		"vtclibramientostg.modal.terminal": "Terminal de carga"
	},
	"ca": {
		"vtclibramientostg.refusemotivo.title":"Rebutjar lliurament",
		"vtclibramientostg.refusemotivo":"Motiu de rebuig",
		"vtclibramientostg.idpuntocarga": "Codi punt de càrrega",
		"vtclibramientostg.modifyTPC.title":"Modificar punt de càrrega",
		"vtclibramientostg.modal.puntocarga": "Punt de càrrega",
		"vtclibramientostg.modal.terminal": "Terminal de càrrega"
	}
}
</i18n>
