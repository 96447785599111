<template>
	<div class="timesslotdetalleform pui-form">
		<v-layout wrap>
			<v-flex xs12>
				<v-layout>
					<v-flex xs2>
						<pui-text-field
							v-model="datos.asociados[indice].horainicio"
							maxlength="5"
							disabled
							valueColumnStyles="xs1"
							required
						></pui-text-field>
					</v-flex>
					<v-flex xs1 class="text-center">{{ $t('timeslotdetalle.a') }}</v-flex>
					<v-flex xs2>
						<pui-text-field
							v-model="datos.asociados[indice].horafin"
							maxlength="5"
							disabled
							valueColumnStyles="xs1"
							required
						></pui-text-field>
					</v-flex>
					<v-flex xs1></v-flex>
					<v-flex xs2>
						<pui-number-field
							v-model.number="datos.asociados[indice].capacidad"
							decimals="0"
							minvalue="0"
							maxvalue="9999"
							:disabled="formDisabled"
							labelColumnStyles="delSpace"
						></pui-number-field>
					</v-flex>
					<v-flex xs2>
						<pui-number-field
							v-model.number="datos.asociados[indice].asignados"
							decimals="0"
							minvalue="0"
							maxvalue="9999"
							disabled
							labelColumnStyles="delSpace"
						></pui-number-field>
					</v-flex>
					<v-flex xs2>
						<pui-number-field
							v-model.number="getDisponibles"
							decimals="0"
							minvalue="0"
							maxvalue="9999"
							disabled
							labelColumnStyles="delSpace"
						></pui-number-field>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	name: 'timeslotdetalle',
	data() {
		return {};
	},
	props: {
		formDisabled: {
			type: Boolean,
			required: true
		},
		datos: {
			type: Object,
			required: true
		},
		indice: {
			type: Number,
			required: true
		}
	},
	computed: {
		getDisponibles() {
			return this.datos.asociados[this.indice].capacidad - this.datos.asociados[this.indice].asignados;
		}
	}
};
</script>
<style lang="postcss">
.timesslotdetalleform {
	overflow-y: auto;
}
.delSpace {
	display: none !important;
}
</style>

<i18n>
{
    "en" : {
		"timeslotdetalle.a" : "to"
    },
    "es": {
		"timeslotdetalle.a" : "a"
    },
    "ca": {
		"timeslotdetalle.a" : "a"
    }
}
</i18n>
