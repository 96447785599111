const editLotAction = {
	id: 'editLotAction',
	selectionType: 'single',
	label: 'loterecibido.editLot',
	functionality: 'MTO_LOTE',
	checkAvailability: function(registry) {
		if (registry && registry[0] && registry[0].codestado === 'ET' && registry[0].conpropia === 'true') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const childPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				childPk[column.name] = registries[0][column.name];
			}
		}
		const pkStr = JSON.stringify(childPk);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);
		const thisModel = this.$store.getters.getModelByName('lotegenerado');
		if (thisModel) {
			const parentModelPk = this.$puiUtils.getPathModelMethodPk('encargoterm');
			thisModel.parentModelName = 'encargoterm';
			thisModel.parentModelPk = parentModelPk.pk;
		}
		const params = {
			router: this.$router,
			url: 'lotegenerado/update/' + pk64 + '/',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [editLotAction],
	formactions: []
};
