export default {
	global: {
		appVersion: '1.17.1',
		appDefaultEntryPoint: 'home',
		baseUrl: '/agros',
		appContainerHeader: true,
		timeFormat: 'HH:mm:ss',
		containerActionHeader: false,
		thousandSeparator: '.',
		decimalSeparator: ',',
		appLoginActions: true,
		multiPanel: false
	},
	menu: {
		homeBtn: false,
		searchBtn: false,
		infoBtn: true,
		notificationsBtn: false,
		helpBtn: false,
		expandMenuBtn: true,
		items: null,
		showLeftBar: true,
		keepExpanded: true
	},
	login: {
		forgetPassword: true
	},
	grid: {
		showRowActions: false
	}
};
