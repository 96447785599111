<template>
	<div class="encargocomp puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('encargocomp.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('encargocomp.peso')" :value="getPeso"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('encargocomp.fecharecogida')" :value="getDiaRecogida"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';
export default {
	name: 'encargocompformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'encargocomp'
		};
	},
	computed: {
		getMercancia() {
			return this.model && this.model.mercancia ? this.model.mercancia : '';
		},
		getDiaRecogida() {
			return this.model && this.model.fecharecogida
				? this.$dateTimeUtils.formatDate(this.model.fecharecogida, this.$store.getters.dateFormat)
				: '';
		},
		getPeso() {
			return this.model && this.model.peso ? this.formatNumber(this.model.peso) : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'encargocomp';
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"encargocomp.mercancia": "Goods",
		"encargocomp.fecharecogida": "Pick up date",
		"encargocomp.terminalgestora": "Terminal origin",
		"encargocomp.contrato": "Contract number",
		"encargocomp.peso": "Number of Kg."
	},
	"es": {
		"encargocomp.mercancia": "Mercancía",
		"encargocomp.fecharecogida": "Fecha de recogida",
		"encargocomp.terminalgestora": "Terminal origen",
		"encargocomp.contrato": "Nº contrato",
		"encargocomp.peso": "Nº Kg."
	},
	"ca": {
		"encargocomp.mercancia": "Mercaderia",
		"encargocomp.fecharecogida": "Data de recollida",
		"encargocomp.terminalgestora": "Terminal origen",
		"encargocomp.contrato": "Núm. contracte",
		"encargocomp.peso": "Num. Kg."
	}
}
</i18n>
