<template>
	<div>
		<consultarecibida-modals :modelName="model"></consultarecibida-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import ConsultarecibidaActions from './ConsultarecibidaActions';
import ConsultarecibidaModals from './ConsultarecibidaModals';

export default {
	name: 'consultarecibidagrid',
	components: { ConsultarecibidaModals },
	data() {
		return {
			model: 'consultarecibida',
			actions: ConsultarecibidaActions.gridactions,
			columnDefs: {
				fecharecogida: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.consultarecibida.idconsulta": "Inquiry Code",
        "grid.consultarecibida.idmercancia": "Good Code",
        "grid.consultarecibida.mercancia": "Good",
        "grid.consultarecibida.peso": "Requested weight (kg)",
		"grid.consultarecibida.idterminalorigen": "Origin terminal code",
        "grid.consultarecibida.terminalorigen": "Origin terminal",
        "grid.consultarecibida.idterminaldestino": "Destination terminal code",
		"grid.consultarecibida.estado": "State",
		"grid.consultarecibida.codestado": "State Code",
		"grid.consultarecibida.referencia": "Reference",
		"grid.consultarecibida.motivorechazo": "Rejection reason",
		"grid.consultarecibida.pesolocalizado": "Localized weight",
		"vtcconsultasrealizadas.fecharecogida": "Pick-up date",
		"grid.consultarecibida.refusemotivo.title": "Reject inquiry",
		"grid.consultarecibida.indtienesubconsulta": "Ind. Have subinquiry",
		"grid.consultarecibida.fechacreacion":"Creation date"
    },
    "es": {
        "grid.consultarecibida.idconsulta": "Código Consulta",
        "grid.consultarecibida.idmercancia": "Código mercancía",
        "grid.consultarecibida.mercancia": "Mercancía",
        "grid.consultarecibida.peso": "Peso Solicitado (kg)",
        "grid.consultarecibida.idterminalorigen": "Código terminal origen",
		"grid.consultarecibida.terminalorigen": "Terminal origen",
        "grid.consultarecibida.idterminaldestino": "Código terminal destino",
        "grid.consultarecibida.estado": "Estado",
		"grid.consultarecibida.codestado": "Código Estado",
		"grid.consultarecibida.referencia": "Referencia",
		"grid.consultarecibida.motivorechazo": "Motivo rechazo",
		"grid.consultarecibida.pesolocalizado": "Peso localizado (kg)",
		"grid.consultarecibida.fecharecogida": "Fecha de recogida",
		"grid.consultarecibida.indtienesubconsulta": "Ind. Tiene subconsulta",
		"grid.consultarecibida.fechacreacion":"Fecha creación"

	},
    "ca": {
        "grid.consultarecibida.idconsulta": "Codi Consulta",
        "grid.consultarecibida.idmercancia": "Codi mercaderia",
        "grid.consultarecibida.mercancia": "Mercaderia",
        "grid.consultarecibida.peso": "Pes sol·licitat (kg)",
        "grid.consultarecibida.idterminalorigen": "Codi terminal origen",
        "grid.consultarecibida.terminalorigen": "Terminal origen",
		"grid.consultarecibida.idterminaldestino": "Codi terminal destí",
        "grid.consultarecibida.estado": "Estat",
		"grid.consultarecibida.codestado": "Codi Estat",
		"grid.consultarecibida.referencia": "Referència",
		"grid.consultarecibida.motivorechazo": "Motiu rebuig",
		"grid.consultarecibida.pesolocalizado": "Pes localitzat (kg)",
		"grid.consultarecibida.fecharecogida": "Data de recollida",
		"grid.consultarecibida.indtienesubconsulta": "Ind. Té subconsulta",
		"grid.consultarecibida.fechacreacion":"Data creació"

		}
}
</i18n>
