<template>
	<div>
		<asignacion-trans-modals :modelName="modelName"></asignacion-trans-modals>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
	</div>
</template>
<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import AsignacionTransActions from './AsignacionTransActions';
import AsignacionTransModals from './AsignacionTransModals';

export default {
	name: 'asignaciontransgrid',
	mixins: [PuiGridDetailMixin],
	components: { AsignacionTransModals },
	data() {
		return {
			modelName: 'asignaciontrans',
			actions: AsignacionTransActions.gridactions,
			columnDefs: {
				fecharecogida: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss"></style>

<i18n>
{
    "en": {
		"grid.asignaciontrans.idasignacion":"Assignment code",
		"grid.asignaciontrans.idlote":"Lot code",
		"grid.asignaciontrans.idencargo":"Order code",
		"grid.asignaciontrans.idcomprador":"Buyer code",
		"grid.asignaciontrans.comprador":"Buyer",
		"grid.asignaciontrans.idterminalgestora":"Terminal code",
		"grid.asignaciontrans.terminalgestora":"Management terminal",
		"grid.asignaciontrans.contrato":"Contract number",
		"grid.asignaciontrans.aplicacion":"Aplication",
		"grid.asignaciontrans.fecharecogida":"Pick-up date",
		"grid.asignaciontrans.idmercancia":"Good code",
		"grid.asignaciontrans.mercancia":"Good",
		"grid.asignaciontrans.peso":"Weight (kg)",
		"grid.asignaciontrans.pesopendiente":"Weight to be allocated (kg)",
		"grid.asignaciontrans.libramientos":"Generated deliverance",
		"grid.asignaciontrans.idtransportista":"Codi transportista",
		"grid.asignaciontrans.fechacreacion":"Creation date",
		"grid.asignaciontrans.empresacreacion":"Creation company"
    },
    "es": {
        "grid.asignaciontrans.idasignacion":"Código asignación",
		"grid.asignaciontrans.idlote":"Código lote",
		"grid.asignaciontrans.idencargo":"Código encargo",
		"grid.asignaciontrans.idcomprador":"Código comprador",
		"grid.asignaciontrans.comprador":"Comprador",
		"grid.asignaciontrans.idterminalgestora":"Código terminal",
		"grid.asignaciontrans.terminalgestora":"Terminal gestora",
		"grid.asignaciontrans.contrato":"Número de contrato",
		"grid.asignaciontrans.aplicacion":"Aplicación",
		"grid.asignaciontrans.fecharecogida":"Fecha de recogida",
		"grid.asignaciontrans.idmercancia":"Código mercancía",
		"grid.asignaciontrans.mercancia":"Mercancía",
		"grid.asignaciontrans.peso":"Peso (kg)",
		"grid.asignaciontrans.pesopendiente":"Peso pendiente de asignar (kg)",
		"grid.asignaciontrans.libramientos":"Libramientos generados",
		"grid.asignaciontrans.idtransportista":"Código transportista",
		"grid.asignaciontrans.fechacreacion":"Fecha creación",
		"grid.asignaciontrans.empresacreacion":"Empresa creadora"

    },
    "ca": {
		"grid.asignaciontrans.idasignacion":"Codi assignació",
		"grid.asignaciontrans.idlote":"Codi lot",
		"grid.asignaciontrans.idencargo":"Codi encàrrec",
		"grid.asignaciontrans.idcomprador":"Codi comprador",
		"grid.asignaciontrans.comprador":"Comprador",
		"grid.asignaciontrans.idterminalgestora":"Codi terminal",
		"grid.asignaciontrans.terminalgestora":"Terminal gestora",
		"grid.asignaciontrans.contrato":"Número de contracte",
		"grid.asignaciontrans.aplicacion":"Aplicació",
		"grid.asignaciontrans.fecharecogida":"Data de recollida",
		"grid.asignaciontrans.idmercancia":"Codi mercaderia",
		"grid.asignaciontrans.mercancia":"Mercaderia",
		"grid.asignaciontrans.peso":"Pes (kg)",
		"grid.asignaciontrans.pesopendiente":"Pes pendent d'assignar (kg)",
		"grid.asignaciontrans.libramientos":"Lliuraments generats",
		"grid.asignaciontrans.idtransportista":"Codi transportista",
		"grid.asignaciontrans.fechacreacion":"Data creació",
		"grid.asignaciontrans.empresacreacion":"Empresa creadora"
    }
}
</i18n>
