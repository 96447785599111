<template>
	<div class="pui-form">
		<punto-carga-modals :modelName="modelName"></punto-carga-modals>
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<punto-carga-form-header></punto-carga-form-header>
		</pui-form-header>

		<v-form class="pui-puntocargaform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('puntocarga.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#timeslotpc'" v-if="!isCreatingElement">{{ $t('puntocarga.timeslot') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout>
						<v-flex xs12 sm12 md8 lg8 xl8>
							<v-layout wrap class="pui-form-layout" v-if="this.method !== 'create'">
								<v-flex xs12 v-show="false">
									<pui-text-field
										:label="$t('puntocarga.idpuntocarga')"
										v-model="model.idpuntocarga"
										disabled
										required
										valueColumnStyles="xs4 sm2 md1"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap class="pui-form-layout">
								<v-flex xs12 v-show="false">
									<pui-select
										v-if="modelLoaded"
										:label="$t('puntocarga.idterminal')"
										clearable
										disabled
										v-model="model"
										required
										modelName="vlupterminales"
										:itemsToSelect="[{ idterminal: model.idterminal }]"
										:modelFormMapping="{ idterminal: 'idterminal' }"
										:itemValue="['idterminal']"
										:itemText="item => `${item.nombre}`"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('puntocarga.codigo')"
										v-model="model.codigo"
										maxlength="20"
										required
										:disabled="!isCreatingElement"
										valueColumnStyles="xs12 sm6 md6 xl6"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-text-field
										:label="$t('puntocarga.descripcion')"
										v-model="model.descripcion"
										maxlength="100"
										required
										:disabled="formDisabled"
										valueColumnStyles="xs12 sm8 md8 xl8"
									></pui-text-field>
								</v-flex>
								<v-flex xs12>
									<pui-checkbox
										:label="$t('puntocarga.indpublico')"
										v-model="model.indpublico"
										true-value="1"
										false-value="0"
										:disabled="formDisabled"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 v-if="!isCreatingElement">
									<pui-date-field
										:label="$t('puntocarga.fecbaja')"
										v-model="model.fecbaja"
										disabled
										valueColumnStyles="xs12 sm4 md4 xl2"
										id="fecbaja"
										attach="fecbaja"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`timeslotpc`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="timeslotpcgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idpuntocarga: 'idpuntocarga' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout v-show="tabmodel !== `timeslotpc`">
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">
										{{ this.$t('form.save') }}
									</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import PuntoCargaFormHeader from './PuntoCargaFormHeader';
import PuntoCargaActions from './PuntoCargaActions';
import PuntoCargaModals from './PuntoCargaModals';

export default {
	name: 'puntocarga',
	components: {
		PuntoCargaFormHeader,
		PuntoCargaModals
	},
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'puntocarga',
			actions: PuntoCargaActions.formactions,
			modeldetail: true,
			tabmodel: 'maintab'
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "puntocarga.idpuntocarga": "Internal Code",
        "puntocarga.idterminal": "Terminal",
        "puntocarga.codigo": "Code",
        "puntocarga.observaciones": "Description",
		"puntocarga.indpublico": "Visible to all other terminals",
		"puntocarga.maintab":"Loading point",
		"puntocarga.timeslot": "Time slots",
		"puntocarga.fecbaja": "Disabled date"
    },
    "es": {
        "puntocarga.idpuntocarga": "Código interno",
        "puntocarga.idterminal": "Terminal",
        "puntocarga.codigo": "Código",
        "puntocarga.descripcion": "Descripción",
        "puntocarga.indpublico": "Visible para el resto de terminales",
		"puntocarga.maintab":"Punto de carga",
		"puntocarga.timeslot": "Time slots",
		"puntocarga.fecbaja": "Fecha baja"
    },
    "ca": {
        "puntocarga.idpuntocarga": "Codi intern",
        "puntocarga.idterminal": "Nom",
        "puntocarga.codigo": "Codi",
        "puntocarga.descripcion": "Descripció",
        "puntocarga.indpublico": "Visible per a la resta de terminals",
		"puntocarga.maintab":"Punt de càrrega",
		"puntocarga.timeslot": "Time slots",
		"puntocarga.fecbaja": "Data baixa"
    }
}
</i18n>
