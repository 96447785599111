<template>
	<div>
		<comprador-modals :modelName="model"></comprador-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import CompradorActions from './CompradorActions';
import CompradorModals from './CompradorModals';

export default {
	name: 'compradorgrid',
	components: {
		CompradorModals
	},
	data() {
		return {
			model: 'comprador',
			actions: CompradorActions.gridactions,
			columnDefs: {
				fecbaja: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.comprador.idcomprador": "Code",
        "grid.comprador.nombre": "Name",
        "grid.comprador.cif": "Tax identifier",
        "grid.comprador.observaciones": "Comments",
		"grid.comprador.fechacreacion":"Creation date",
		"grid.comprador.indbaja":"Ind. Disabled",
		"grid.comprador.baja":"Disabled",
		"grid.comprador.fecbaja":"Disabled date"
    },
    "es": {
        "grid.comprador.idcomprador": "Código",
        "grid.comprador.nombre": "Nombre",
        "grid.comprador.cif": "Identificador fiscal",
        "grid.comprador.observaciones": "Observaciones",
		"grid.comprador.fechacreacion":"Fecha creación",
		"grid.comprador.indbaja":"Ind. Baja",
		"grid.comprador.baja":"Baja",
		"grid.comprador.fecbaja":"Fecha baja"
    },
    "ca": {
        "grid.comprador.idcomprador": "Codi",
        "grid.comprador.nombre": "Nom",
        "grid.comprador.cif": "Identificador fiscal",
        "grid.comprador.observaciones": "Observacions",
		"grid.comprador.fechacreacion":"Data creació",
		"grid.comprador.indbaja":"Ind. Baixa",
		"grid.comprador.baja":"Baixa",
		"grid.comprador.fecbaja":"Data baixa"
    }
}
</i18n>
