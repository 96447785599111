<template>
	<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */
import TipoDocumentoActions from './TipoDocumentoActions';

export default {
	name: 'tipodocumentogrid',
	data() {
		return {
			model: 'tipodocumento',
			actions: TipoDocumentoActions.gridactions
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
		"grid.tipodocumento.idtipodocumento": "Code",
        "grid.tipodocumento.descripcion": "Document type",
        "grid.tipodocumento.fecalta":"Creation date",
		"grid.tipodocumento.usralta":"Creation user",
		"grid.tipodocumento.fecmodif":"Editation date",
		"grid.tipodocumento.usrmodif":"Editation user"
    },
    "es": {
        "grid.tipodocumento.idtipodocumento": "Código",
        "grid.tipodocumento.descripcion": "Tipo documento",
		"grid.tipodocumento.fecalta":"Fecha creación",
		"grid.tipodocumento.usralta":"Usuario creación",
		"grid.tipodocumento.fecmodif":"Fecha modificación",
		"grid.tipodocumento.usrmodif":"Usuario modificación"
    },
    "ca": {
		"grid.tipodocumento.idtipodocumento": "Codi",
        "grid.tipodocumento.descripcion": "Tipus document",
        "grid.tipodocumento.fecalta":"Data creació",
		"grid.tipodocumento.usralta":"Usuari creació",
		"grid.tipodocumento.fecmodif":"Data modificació",
		"grid.tipodocumento.usrmodif":"Usuari modificació"
    }
}
</i18n>
