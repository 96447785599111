<template>
	<div class="pui-form">
		<encargo-comp-modals :modelName="modelName"></encargo-comp-modals>
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<encargocompformheader></encargocompformheader>
		</pui-form-header>
		<v-form class="pui-encargoform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('encargo.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#historicocomp'" v-if="!isCreatingElement">{{ $t('encargo.historico') }}</v-tab>
				<v-tab :key="2" :href="'#asignacion'" v-if="!isCreatingElement">{{ $t('encargo.asignaciones') }}</v-tab>
				<v-tab :key="3" :href="'#libramientocomp'" v-if="!isCreatingElement">{{ $t('encargo.libramientos') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<encargo-form-base
						:model="model"
						:formDisabled="!isCreatingElement && formDisabled"
						:modelLoaded="myModelLoaded"
						tipoEncargo="C"
						:isCreatingElement="isCreatingElement"
					></encargo-form-base>
				</v-tab-item>
				<v-tab-item :key="1" :value="`historicocomp`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="historicocompgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="`asignacion`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="asignaciongrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="3" :value="`libramientocomp`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="libramientocompgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout v-show="tabmodel === `maintab`">
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="mySave(false)">
										{{ this.$t('form.save') }}
									</v-btn>

									<v-btn text outlined @click.native="myBack()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import EncargoCompActions from './EncargoCompActions';
import EncargoCompModals from './EncargoCompModals';

import EncargoFormBase from '../EncargoFormBase';
export default {
	name: 'encargocomp',
	mixins: [PuiFormMethodsMixin],
	components: { EncargoFormBase, EncargoCompModals },
	data() {
		return {
			modelName: 'encargocomp',
			actions: EncargoCompActions.formactions,
			fechahoy: this.$dateTimeUtils.getLocalDateNow(),
			myModelLoaded: false,
			modeldetail: true,
			tabmodel: 'maintab',
			modelOld: null,
			testValue: false
		};
	},
	methods: {
		mySave(val) {
			this.modelOld = null;
			this.save(val);
		},
		myBack() {
			if (this.modelOld) {
				//this.model = this.modelOld;
				this.testValue = true;
				this.myModelLoaded = false;
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				this.onReady();
			} else {
				this.back();
			}
		},
		// override
		afterGetData() {
			if (this.method === 'create') {
				const copyModel = this.$store.getters.getModelByName(this.modelName);

				if (copyModel && copyModel.copyObjectPk) {
					// get copy model data and assign to current model
					this.getDataFromCopyModel(copyModel);
					this.pk = this.$puiUtils.utf8ToB64(JSON.stringify(copyModel.copyObjectPk));
					copyModel.copyObjectPk = undefined;
					this.modelOld = copyModel.oldModel;
					copyModel.oldModel = undefined;
				} else {
					if (this.modelOld !== null && this.testValue) {
						this.myModelLoaded = false;
						this.model = Object.assign({}, this.modelOld);
						this.method = 'update';
						this.formDisabled =
							this.formDisabled || (this.model.idestado !== null && this.model.idestado !== 1 && this.model.idestado !== 2);
						this.modelOld = null;
						this.back();
					}
					this.myModelLoaded = true;
				}
			} else {
				this.myModelLoaded = true;
				this.formDisabled = this.formDisabled || (this.model.idestado !== null && this.model.idestado !== 1 && this.model.idestado !== 2);
			}
		},
		getDataFromCopyModel(copyModel) {
			const controller = copyModel.url.duplica + this.getParams(copyModel.copyObjectPk);

			this.myModelLoaded = false;
			this.modelOld = Object.assign({}, copyModel.copyObjectPk);
			this.$puiRequests.getRequest(
				controller,
				null,
				response => {
					if (response && response.data) {
						var data = response.data;

						this.model = Object.assign({}, data);
						this.formDisabled = false;
						this.myModelLoaded = true;
					}
				},
				error => {
					this.onError(error);
				}
			);
		},
		/*getDataActualizado() {
			const controller = this.modelDependency.url.get + '?idencargo=' + this.model.idencargo;

			this.myModelLoaded = false;
			this.$puiRequests.getRequest(
				controller,
				null,
				response => {
					if (response && response.data) {
						var data = response.data;

						this.model = Object.assign({}, data);
						this.formDisabled = true;
						this.myModelLoaded = true;
					}
				},
				error => {
					this.onError(error);
				}
			);
		},*/
		onError(error) {
			this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
			if (this.method === 'update' && error.response && error.response.data && error.response.data.internalCode === 1060) {
				//this.getDataActualizado();
				this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
				this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
			}
		}
	}
};
</script>
<style scoped></style>
<i18n>
{
    "en": {
		"encargo.maintab":"Order",
		"encargo.asignaciones": "Assign Load",
		"encargo.historico": "Historic",
		"encargo.libramientos": "Riddances"
    },
    "es": {
		"encargo.maintab":"Encargo",
		"encargo.asignaciones": "Asignaciones",
		"encargo.historico": "Histórico",
		"encargo.libramientos":"Libramientos"
    },
    "ca": {
		"encargo.maintab":"Encàrrec",
		"encargo.asignaciones": "Assignacions",
		"encargo.historico": "Històric",
		"encargo.libramientos":"Lliuraments"
    }
}
</i18n>
