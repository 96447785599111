const disableUsuarioAplicacionAction = {
	id: 'disableUsuarioAplicacionAction',
	selectionType: 'single',
	label: 'usuarioaplicacion.disableuser',
	functionality: 'WRITE_PUI_USER_TERM',
	checkAvailability: function(user) {
		if (user[0] && user[0].disabled === 0) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-date-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, disableDate => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);

			var dataRegistry = registries[0];
			var usr = dataRegistry.usr;
			var urlController = model.url.disableUser + '?usr=' + usr;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = { date: disableDate };

			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

const enableUsuarioAplicacionAction = {
	id: 'enableUsuarioAplicacionAction',
	selectionType: 'single',
	label: 'usuarioaplicacion.enableuser',
	functionality: 'WRITE_PUI_USER_TERM',
	checkAvailability: function(user) {
		if (user[0] && user[0].disabled === 1) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var usr = dataRegistry.usr;
			var urlController = model.url.enableUser + '?usr=' + usr;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = {};
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

const resetPasswordUsuarioTerminalAction = {
	id: 'resetPasswordUsuarioTerminalAction',
	selectionType: 'single',
	label: 'usuarioaplicacion.resetPassword',
	functionality: 'WRITE_PUI_USER_TERM',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-resetpassword-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [disableUsuarioAplicacionAction, enableUsuarioAplicacionAction, resetPasswordUsuarioTerminalAction],
	formactions: [disableUsuarioAplicacionAction, enableUsuarioAplicacionAction, resetPasswordUsuarioTerminalAction]
};
