<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtcconsultasrecibidas.refusemotivo.title')"
			:modelName="modelName"
			:dialogName="dialogNameRefusemotivo"
			v-model="modalData"
			:showDialogProp="showDialogRefusemotivo"
			:onOk="onOkRefusemotivo"
			:widthDialog="800"
			:componentHeaderName="'consultarecibidaformheader'"
		>
			<template slot="message">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							id="motivorechazo"
							v-model="modalData.motivorechazo"
							:label="this.$t('vtcconsultasrecibidas.motivorechazo')"
							maxlength="300"
							clearable
							required
							labelColumnStyles="xs12 md2"
							valueColumnStyles="xs12 md10"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog
			v-if="modals.confirm.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.confirm.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.confirm.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
			:messageText="modals.confirm.titleText"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<consultarecibidaformheader v-if="parentModelPk" :modelPk="parentModelPk"></consultarecibidaformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ modals.confirm.messageText }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'consultarecibidamodals',
	data() {
		return {
			modalData: {},
			dialogNameRefusemotivo: 'refusemotivo',
			showDialogRefusemotivo: true,
			modals: {
				confirm: {
					disableOk: false,
					show: false,
					titleText: '',
					messageText: '',
					parentModelPk: null
				}
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameRefusemotivo}-${this.modelName}-show`, (data) => {
			this.parentModelPk = {
				idconsulta: data.idconsulta
			};
			this.showDialogRefusemotivo = true;
		});
		this.subscribeToModalDialogEvent();
	},
	methods: {
		onOkRefusemotivo(modalData) {
			let urlController = this.$store.getters.getModelByName(this.modelName).url.refuse;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('consulta.rejectinquiry');
			let params = {
				idconsulta: modalData.headerPk.idconsulta,
				motivorechazo: modalData.motivorechazo
			};
			this.$puiRequests.patchRequest(
				urlController,
				params,
				(response) => {
					this.showDialogRefusemotivo = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.showDialogRefusemotivo = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		subscribeToModalDialogEvent() {
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.modals.confirm.messageText = '';
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idconsulta: data.object.idconsulta
				};
				this.modals.confirm.messageText = this.$t('modal.validate.' + data.messageBody);
				this.setShowConfirm(true);
			});
		},
		setShowConfirm(action) {
			this.modals.confirm.show = action;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtcconsultasrecibidas.refusemotivo.title": "Reject inquiry",
		"vtcconsultasrecibidas.motivorechazo": "Rejection reason",
		"modal.validate.reject": "The Inquiry is going to be set to Rejected. Do you want to continue?",
		"modal.validate.finish": "The Inquiry is going to be set to Finished. Do you want to continue?"
	},
	"es": {
		"vtcconsultasrecibidas.refusemotivo.title": "Rechazar consulta",
		"vtcconsultasrecibidas.motivorechazo": "Motivo rechazo",
		"modal.validate.reject": "La consulta va a pasar a estado Rechazada. ¿Desea continuar?",
		"modal.validate.finish": "La consulta va a pasar a estado Finalizada. ¿Desea continuar?"
	},
	"ca": {
		"vtcconsultasrecibidas.refusemotivo.title": "Rebutjar consulta",
		"vtcconsultasrecibidas.motivorechazo": "Motiu rebuig",
		"modal.validate.reject": "La consulta passarà a estat Rebutjada. Desitja continuar?",
		"modal.validate.finish": "La consulta passarà a estat Finalitzada. Desitja continuar?"
	}
}
</i18n>
