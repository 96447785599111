<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtcencargoscomp.anularmotivo.title')"
			:modelName="modelName"
			:dialogName="dialogNameAnular"
			v-model="modalData"
			:showDialogProp="showDialogAnular"
			:onOk="onOkAnular"
			:componentHeaderName="'encargocompformheader'"
			:widthDialog="700"
		>
			<template slot="message">
				<v-layout class="justify-center">
					<v-flex xs12>
						<pui-select
							attach="codigo"
							:label="$t('vtcencargoscomp.anularmotivo')"
							clearable
							v-model="modalData"
							modelName="vlupmotivoanulrech"
							:itemsToSelect="[{ codigo: modalData.codmotivoanulrechazo }]"
							:modelFormMapping="{ codigo: 'codmotivoanulrechazo' }"
							:itemValue="['codigo']"
							:itemText="(item) => `${item.descripcion}`"
							:fixedFilter="getEstadoEAFixedFilter"
							:order="{ descripcion: 'asc' }"
							labelColumnStyles="xs6"
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog
			v-if="modals.confirm.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.confirm.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.confirm.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header :showHeader="simple">
						<encargocompformheader :modelPk="parentModelPk"></encargocompformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('vtcencargoscomp.confirm.enviar') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'consultarecibidamodals',
	data() {
		return {
			modalData: {},
			modals: {
				confirm: {
					disableOk: false,
					show: false,
					titleText: this.$t('vtcencargoscomp.enviar.title'),
					parentModelPk: null,
					simple: null
				}
			},
			dialogNameAnular: 'anular',
			showDialogAnular: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		onOkAnular(modalData) {
			let body = {
				idencargo: modalData.headerPk.idencargo,
				codanulacion: modalData.codmotivoanulrechazo
			};
			let urlController = this.$store.getters.getModelByName(this.modelName).url.cancel;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('encargo.anular');
			this.$puiRequests.patchRequest(
				urlController,
				body,
				(response) => {
					this.showDialogAnular = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.showDialogAnular = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		subscribeToModalDialogEvent() {
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idencargo: data.idencargo
				};
				this.simple = data.simple;
				this.setShowConfirm(true);
			});
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameAnular}-${this.modelName}-show`, (data) => {
				this.showDialogAnular = true;
			});
		},
		setShowConfirm(action) {
			this.modals.confirm.show = action;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	},
	computed: {
		getEstadoEA() {
			return 'EA';
		},
		getEstadoEAFixedFilter() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'indbaja', op: 'eq', data: '0' },
							{ field: 'indbaja', op: 'nu', data: null }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: 'EA' }] }
				],
				groupOp: 'and',
				rules: []
			};
		}
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtcencargoscomp.confirm.enviar":"The order will be sent to the management terminal. Would you like to continue?",
		"vtcencargoscomp.anularmotivo":"Reason for cancellation",
		"vtcencargoscomp.anularmotivo.title":"Cancel order",
		"vtcencargoscomp.enviar.title":"Send order"
	},
	"es": {
		"vtcencargoscomp.confirm.enviar":"Se enviará el encargo a la terminal gestora. ¿Desea continuar?",
		"vtcencargoscomp.anularmotivo":"Motivo de anulación",
		"vtcencargoscomp.anularmotivo.title":"Anular encargo",
		"vtcencargoscomp.enviar.title":"Enviar Encargo"
	},
	"ca": {
		"vtcencargoscomp.confirm.enviar":"S'enviarà l'encàrrec a la terminal gestora. Voleu continuar?",
		"vtcencargoscomp.anularmotivo":"Motiu d'anulació",
		"vtcencargoscomp.anularmotivo.title":"Anul·lar encàrrec",
		"vtcencargoscomp.enviar.title":"Enviar encàrrec"
	}
}
</i18n>
