<template>
	<div class="pui-form">
		<v-form class="pui-autorizacionform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs12 sm12 md8 lg8 xl8>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								attach="idtransportista"
								:label="$t('autorizacion.idtransportista')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								required
								modelName="vluptransportistas"
								:itemsToSelect="[{ idtransportista: model.idtransportista }]"
								:modelFormMapping="{ idtransportista: 'idtransportista' }"
								:itemValue="['idtransportista']"
								:itemText="(item) => `${item.nombre}`"
								:order="{ nombre: 'asc' }"
								labelColumnStyles="xs12 md2"
								valueColumnStyles="xs12 md9"
								:fixedFilter="getFiltroTransportista"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 md6>
							<pui-date-field
								:label="$t('autorizacion.fechainicio')"
								v-model="model.fechainicio"
								:max="model.fechafin"
								:min="feciniciominima"
								required
								:disabled="formDisabled"
								id="fechainicio"
								labelColumnStyles="xs12 md4"
								valueColumnStyles="xs12 md6"
								attach="fechainicio"
							></pui-date-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-date-field
								:label="$t('autorizacion.fechafin')"
								v-model="model.fechafin"
								:min="model.fechainicio"
								required
								:disabled="formDisabled"
								id="fechafin"
								labelColumnStyles="xs12 md4"
								valueColumnStyles="xs12 md6"
								attach="fechafin"
							></pui-date-field>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import AutorizacionActions from './AutorizacionActions';

export default {
	name: 'autorizacion',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'autorizacion',
			actions: AutorizacionActions.formactions,
			feciniciominima: null,
			fechahoy: this.$dateTimeUtils.getLocalDateNow(),
			transportistaFixedFilter: {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'indbaja', op: 'eq', data: '0' }]
			},
			transportistaAllFixedFilter: {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'indbaja', op: 'eq', data: '0' },
					{ field: 'indbaja', op: 'eq', data: '1' }
				]
			}
		};
	},
	watch: {
		'model.fechafin'(newValue) {
			if (newValue === '') {
				this.model.fechafin = null;
			}
		}
	},
	methods: {
		// override
		afterGetData() {
			this.feciniciominima = this.model.fechainicio != null ? this.model.fechainicio : this.$dateTimeUtils.getLocalDateNow();
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	},
	computed: {
		getFiltroTransportista() {
			if (this.formDisabled) {
				return this.transportistaAllFixedFilter;
			} else {
				return this.transportistaFixedFilter;
			}
		}
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "autorizacion.idautorizacion": "Authorization code",
        "autorizacion.idtransportista": "Carrier",
        "autorizacion.fechainicio": "Start date",
		"autorizacion.fechafin": "End date"
    },
    "es": {
        "autorizacion.idautorizacion": "Código autorización",
        "autorizacion.idtransportista": "Transportista",
        "autorizacion.fechainicio": "Fecha de inicio",
		"autorizacion.fechafin": "Fecha de fin"
    },
    "ca": {
        "autorizacion.idautorizacion": "Codi autorització",
        "autorizacion.idtransportista": "Transportista",
        "autorizacion.fechainicio": "Data inici",
		"autorizacion.fechafin": "Data fi"
    }
}
</i18n>
