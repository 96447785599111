<template>
	<div class="pui-form">
		<v-form class="pui-puntocargaaptform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs12 sm12 md8 lg8 xl8>
					<v-layout wrap class="pui-form-layout" v-if="this.method !== 'create'">
						<v-flex xs12 v-show="false">
							<pui-text-field
								:label="$t('puntocarga.idpuntocarga')"
								v-model="model.idpuntocarga"
								disabled
								required
								valueColumnStyles="xs4 sm2 md1"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								:label="$t('puntocarga.idterminal')"
								clearable
								:disabled="this.method !== 'create'"
								v-model="model"
								required
								modelName="vlupterminales"
								:itemsToSelect="[{ idterminal: model.idterminal }]"
								:modelFormMapping="{ idterminal: 'idterminal' }"
								:itemValue="['idterminal']"
								:itemText="(item) => `${item.nombre}`"
								:order="{ nombre: 'asc' }"
							></pui-select>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('puntocarga.codigo')"
								v-model="model.codigo"
								maxlength="20"
								required
								:disabled="formDisabled"
								valueColumnStyles="xs12 sm6 md6 xl6"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('puntocarga.descripcion')"
								v-model="model.descripcion"
								maxlength="100"
								required
								:disabled="formDisabled"
								valueColumnStyles="xs12 sm8 md8 xl8"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-checkbox
								:label="$t('puntocarga.indpublico')"
								v-model="model.indpublico"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 v-if="!isCreatingElement">
							<pui-date-field
								:label="$t('puntocarga.fecbaja')"
								v-model="model.fecbaja"
								disabled
								valueColumnStyles="xs12 sm4 md4 xl2"
								id="fecbaja"
								attach="fecbaja"
							></pui-date-field>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import PuntoCargaAptActions from './PuntoCargaAptActions';

export default {
	name: 'puntocargaapt',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'puntocargaapt',
			actions: PuntoCargaAptActions.formactions
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "puntocarga.idpuntocarga": "Internal Code",
        "puntocarga.idterminal": "Terminal",
        "puntocarga.codigo": "Code",
        "puntocarga.observaciones": "Description",
		"puntocarga.indpublico": "Visible to all other terminals",
		"puntocarga.maintab":"Loading point",
		"puntocarga.timeslot": "Time slots",
		"puntocarga.fecbaja": "Disabled date"
    },
    "es": {
        "puntocarga.idpuntocarga": "Código interno",
        "puntocarga.idterminal": "Terminal",
        "puntocarga.codigo": "Código",
        "puntocarga.descripcion": "Descripción",
        "puntocarga.indpublico": "Visible para el resto de terminales",
		"puntocarga.maintab":"Punto de carga",
		"puntocarga.timeslot": "Time slots",
		"puntocarga.fecbaja": "Fecha baja"
    },
    "ca": {
        "puntocarga.idpuntocarga": "Codi intern",
        "puntocarga.idterminal": "Terminal",
        "puntocarga.codigo": "Codi",
        "puntocarga.descripcion": "Descripció",
        "puntocarga.indpublico": "Visible per a la resta de terminals",
		"puntocarga.maintab":"Punt de càrrega",
		"puntocarga.timeslot": "Time slots",
		"puntocarga.fecbaja": "Data baixa"
    }
}
</i18n>
