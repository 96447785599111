<template>
	<div class="pui-form" v-if="modelLoaded">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('consultaaudit.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#loteaudit'" v-if="!isCreatingElement">{{ $t('consultaaudit.lotes') }}</v-tab>
				<v-tab :key="2" :href="'#historicoaudit'" v-if="!isCreatingElement">{{ $t('consultaaudit.historico') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<pui-field-set>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:label="$t('consultaaudit.idconsulta')"
											v-model="model.idconsulta"
											required
											disabled
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs4 sm2 md1"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											id="idencargo"
											:label="this.$t('consultaaudit.idencargo')"
											attach="idencargo"
											disabled
											v-model="model"
											modelName="vlupencargo"
											:itemsToSelect="[{ idencargo: model.idencargo }]"
											:modelFormMapping="{ idencargo: 'idencargo' }"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:itemValue="['idencargo']"
											:itemText="item => `${getDateFormated(item.fecharecogida)} - ${item.mercancia} - ${item.contrato}`"
											:order="{ fecharecogida: 'asc' }"
											detailModelName="encargoaudit"
											detailComponentName="detailencargoauditform"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:label="this.$t('consultaaudit.idterminalorigen')"
											id="idterminalorigen"
											attach="idterminalorigen"
											disabled
											v-model="model"
											modelName="vlupterminales"
											:itemsToSelect="[{ idterminal: model.idterminalorigen }]"
											:modelFormMapping="{ idterminal: 'idterminalorigen' }"
											itemValue="idterminal"
											itemText="nombre"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:order="{ nombre: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											v-model="model.referencia"
											:label="this.$t('consultaaudit.referencia')"
											disabled
											maxlength="100"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:label="this.$t('consultaaudit.idterminaldestino')"
											id="idterminaldestino"
											attach="idterminaldestino"
											disabled
											v-model="model"
											modelName="vlupterminales"
											:itemsToSelect="[{ idterminal: model.idterminaldestino }]"
											:modelFormMapping="{ idterminal: 'idterminaldestino' }"
											itemValue="idterminal"
											itemText="nombre"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:order="{ nombre: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:label="this.$t('consultaaudit.pesosolicitado')"
											v-model.number="model.pesosolicitado"
											decimals="0"
											suffix="kg"
											disabled
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm4 md1"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											v-if="modelLoaded"
											attach="idestado"
											:label="$t('consultaaudit.idestado')"
											clearable
											v-model="model"
											disabled
											modelName="vlupestados"
											:itemsToSelect="[{ idestado: model.idestado }]"
											:modelFormMapping="{ idestado: 'idestado' }"
											:itemValue="['idestado']"
											:itemText="item => `${item.descestado}`"
											:key="'lupaEstado' + lupaKey"
											:order="{ descestado: 'asc' }"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap v-if="model.idestado === 10">
									<v-flex xs12>
										<pui-text-area
											v-model="model.motivorechazo"
											:label="this.$t('consultaaudit.motivorechazo')"
											maxlength="300"
											disabled
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-area
											v-model="model.observaciones"
											:label="this.$t('consultaaudit.observaciones')"
											maxlength="300"
											disabled
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-date-field
											id="fecalta"
											:label="$t('consultaaudit.fecalta')"
											v-model="model.fecalta"
											disabled
											timesecs
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md6 xl3"
											attach="fecalta"
										></pui-date-field>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`loteaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="loteauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idconsulta: 'idconsulta' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="`historicoaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="historicoauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idconsulta: 'idconsulta' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'consultaauditform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'consultaaudit',
			modeldetail: true,
			tabmodel: 'maintab',
			lupaKey: 0
		};
	},
	methods: {
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		}
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"consultaaudit.idencargo": "Order",
		"consultaaudit.idconsulta": "Inquiry code",
		"consultaaudit.referencia": "Reference",
        "consultaaudit.idterminaldestino": "Destination terminal",
        "consultaaudit.idterminalorigen": "Origin terminal",
        "consultaaudit.pesosolicitado": "Weight (kg)",
		"consultaaudit.observaciones": "Inquiry state",
		"consultaaudit.fecalta": "Creation date",
		"consultaaudit.idestado":"State",
		"consultaaudit.maintab":"Inquiry",
		"consultaaudit.lotes":"Lots",
		"consultaaudit.motivorechazo": "Rejection reason"
    },
    "es": {
		"consultaaudit.idencargo": "Encargo",
		"consultaaudit.idconsulta": "Código consulta",
		"consultaaudit.referencia": "Referencia",
        "consultaaudit.idterminaldestino": "Terminal destino",
        "consultaaudit.idterminalorigen": "Terminal origen",
		"consultaaudit.pesosolicitado": "Peso (kg)",
        "consultaaudit.observaciones": "Observaciones",
		"consultaaudit.fecalta": "Fecha de creación",
		"consultaaudit.idestado":"Estado",
		"consultaaudit.maintab":"Consulta",
		"consultaaudit.lotes":"Lotes",
		"consultaaudit.motivorechazo": "Motivo rechazo"
    },
    "ca": {
		"consultaaudit.idencargo": "Encàrrec",
		"consultaaudit.idconsulta": "Codi consulta",
		"consultaaudit.referencia": "Referència",
        "consultaaudit.idterminaldestino": "Terminal destí",
        "consultaaudit.idterminalorigen": "Terminal origen",
        "consultaaudit.pesosolicitado": "Pes sol·licitat (kg)",
        "consultaaudit.observaciones": "Observacions",
		"consultaaudit.fecalta": "Data de creació",
		"consultaaudit.idestado":"Estat",
		"consultaaudit.maintab":"Consulta",
		"consultaaudit.lotes":"Lots",
		"consultaaudit.motivorechazo": "Motiu rebuig"
    }
}
</i18n>
