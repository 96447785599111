<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('transportista.action.disableTitle')"
			:modelName="modelName"
			v-model="modalData"
			:dialogName="dialogNameDisable"
			:showDialogProp="showDialogDisable"
			:onOk="onOkDisable"
			:widthDialog="800"
			componentHeaderName="transportistaformheader"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex sm8>
						<pui-date-field
							:label="$t('transportista.action.disableLabel')"
							v-model="modalData.fecbaja"
							required
							toplabel
							:min="fechahoy"
							attach="fecbaja"
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap v-if="!isMobile">
					<v-flex xs12 style="height: 60px"></v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog
			v-if="modals.enable.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.enable.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.enable.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<transportistaformheader :modelPk="parentModelPk"></transportistaformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('transportista.validate.enable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'transportistamodals',
	data() {
		return {
			modals: {
				enable: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			},
			modalData: {},
			fechahoy: this.$dateTimeUtils.getLocalDateNow(),
			dialogNameDisable: 'disable',
			showDialogDisable: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameDisable}-${this.modelName}-show`, (data) => {
				this.showDialogDisable = true;
			});
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idtransportista: data.idtransportista
				};
				this.modals.enable.titleText = this.$t('transportista.action.enableTitle');
				this.setShowConfirm(true);
			});
		},
		setShowConfirm(action) {
			this.modals.enable.show = action;
		},
		onOkDisable(modalData) {
			var idtransportista = modalData.headerPk.idtransportista;
			let urlController = this.$store.getters.getModelByName(this.modelName).url.disable + '?idtransportista=' + idtransportista;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(this.$t('transportista.disabletransportista'));
			var params = { date: modalData.fecbaja };

			this.$puiRequests.patchRequest(
				urlController,
				params,
				(response) => {
					this.showDialogDisable = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.showDialogDisable = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"transportista.action.disableTitle": "Deactivate",
		"transportista.action.disableLabel": "Deactivate Date",
		"transportista.validate.disable": "The Carrier will be deactivated. Do you want to continue?",
		"transportista.validate.enable": "The deactivated carrier will be activated. Do you want to continue?",
		"transportista.action.enableTitle": "Enable"
	},
	"es": {
		"transportista.action.disableTitle": "Desactivar",
		"transportista.action.disableLabel": "Fecha baja",
		"transportista.validate.disable": "Se desactivará el transportista. ¿Desea continuar?",
		"transportista.validate.enable": "Se activará el transportista. ¿Desea continuar?",
		"transportista.action.enableTitle": "Activar"
	},
	"ca": {
		"transportista.action.disableTitle": "Desactivar",
		"transportista.action.disableLabel": "Data de baixa",
		"transportista.validate.disable": "Es desactivarà el transportista. Desitja continuar?",
		"transportista.validate.enable": "S'activarà el transportista. Desitja continuar?",
		"transportista.action.enableTitle": "Activar"
	}
}
</i18n>
