<template>
	<div class="pui-form mt-4">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<documentacionformheader></documentacionformheader>
		</pui-form-header>
		<v-form class="pui-documentacionform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout class="pui-form-layout">
				<v-flex xs8>
					<v-layout wrap>
						<v-flex xs12 v-if="this.method != 'create'">
							<pui-text-field
								:label="$t('documentacion.iddoc')"
								v-model="model.iddoc"
								required
								disabled
								valueColumnStyles="xs4 sm2 md1"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								attach="idtipodocumento"
								:label="$t('documentacion.idtipodocumento')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								modelName="vluptipodocumento"
								:itemsToSelect="[{ idtipodocumento: model.idtipodocumento }]"
								:modelFormMapping="{ idtipodocumento: 'idtipodocumento' }"
								itemValue="idtipodocumento"
								itemText="descripcion"
								valueColumnStyles="xs12 md4 lg4"
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-text-field
								:label="$t('documentacion.nombre')"
								v-model="model.nombre"
								maxlength="100"
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-select
								v-if="modelLoaded"
								attach="idtipousuario"
								:label="$t('documentacion.idtipousuario')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								required
								modelName="vluptipousuario"
								:itemsToSelect="[{ codtipusuario: model.idtipousuario }]"
								:modelFormMapping="{ codtipusuario: 'idtipousuario' }"
								itemValue="codtipusuario"
								itemText="descripcion"
								valueColumnStyles="xs12 md4 lg4"
								:key="tipousuarioKey"
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-text-field
								:label="$t('documentacion.url')"
								v-model="model.url"
								maxlength="1000"
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs6>
							<pui-checkbox
								:label="$t('documentacion.indpublicado')"
								v-model="model.indpublicado"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-text-area
								v-model="model.observaciones"
								:label="this.$t('documentacion.observaciones')"
								maxlength="300"
								clearable
								:disabled="formDisabled"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import DocumentacionActions from './DocumentacionActions';

export default {
	name: 'documentacion',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'documentacion',
			actions: DocumentacionActions.formactions
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "documentacion.iddoc": "Code",
		"documentacion.nombre": "Name",
		"documentacion.idtipousuario": "User type",
        "documentacion.url": "URL",
        "documentacion.observaciones": "Comments",
		"documentacion.idtipodocumento": "Document type",
		"documentacion.indpublicado": "Publish"
    },
    "es": {
        "documentacion.iddoc": "Código",
		"documentacion.nombre": "Nombre",
		"documentacion.idtipousuario": "Tipo de usuario",
        "documentacion.url": "URL",
        "documentacion.observaciones": "Observaciones",
		"documentacion.idtipodocumento": "Tipo documento",
		"documentacion.indpublicado": "Publicado"
    },
    "ca": {
        "documentacion.iddoc": "Codi",
		"documentacion.nombre": "Nom",
		"documentacion.idtipousuario": "Tipus d'usuari",
        "documentacion.url": "URL",
        "documentacion.observaciones": "Observacions",
		"documentacion.idtipodocumento": "Tipus document",
		"documentacion.indpublicado": "Publicat"
    }
}
</i18n>
