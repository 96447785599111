<template>
	<pui-modal-dialog
		v-if="show"
		:cancelText="$t('form.cancel')"
		:okText="$t('form.save')"
		:disableOk="disableOk"
		:titleText="titleText"
		:messageText="messageText"
		dialogName="passwordDialog"
		:widthDialog="700"
	>
		<template slot="message">
			<v-form class="mb-4 pb-4" ref="password_form">
				<v-layout wrap>
					<v-layout wrap class="pa-3">
						<v-flex xs12>
							<pui-text-field
								:label="$t('current')"
								v-model="oldPassword"
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:type="hidePass ? 'text' : 'password'"
								realtime
								noeditable
								required
								:autocomplete="'old-password'"
								@click:append="hidePass = !hidePass"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('new')"
								v-model="newPassword"
								:append-icon="hidePass1 ? 'visibility_off' : 'visibility'"
								:type="hidePass1 ? 'text' : 'password'"
								:rules="[rules.notEqualsPassword]"
								realtime
								required
								noeditable
								:autocomplete="'new-password'"
								@click:append="hidePass1 = !hidePass1"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('confirm')"
								v-model="confirmPassword"
								:append-icon="hidePass2 ? 'visibility_off' : 'visibility'"
								:type="hidePass2 ? 'text' : 'password'"
								:rules="[rules.equalsPassword]"
								required
								realtime
								noeditable
								:autocomplete="'confirm-password'"
								@click:append="hidePass2 = !hidePass2"
							></pui-text-field>
						</v-flex>
					</v-layout>
				</v-layout>
			</v-form>
		</template>
	</pui-modal-dialog>
</template>

<script>
export default {
	name: 'usuarioaplicacionpassworddialog',
	data() {
		return {
			showError: false,
			hidePass: false,
			hidePass1: false,
			hidePass2: false,
			serverError: '',
			modelDependencyName: 'puiuser',
			show: false,
			titleText: this.$t('puiuserresetpassword.title'),
			messageText: '',
			usr: null,
			oldPassword: null,
			newPassword: null,
			confirmPassword: null,
			rules: {
				notEqualsPassword: (value) => value !== this.oldPassword || this.$t('password_are_equal'),
				equalsPassword: (value) => value === this.newPassword || this.$t('password_not_equal'),
				passwordRules: (value) => this.validatePasswordRules(value) || this.$t('usuarioaplicacion.validate.pass')
			}
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		}
	},
	computed: {
		disableOk() {
			return false; //!(this.newPassword !== null && this.newPassword !== '' && this.newPassword === this.confirmPassword);
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		validatePasswordRules(v) {
			var re = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-zA-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-z])(?=.*[A-Z]).{8,})$/i;
			return re.test(v);
		},
		subscribeToModalDialogEvent() {
			const self = this;
			this.$puiEvents.$on('pui-modalDialog-passwordDialog-ok', () => {
				if (!this.$refs.password_form.validate()) {
					return;
				}
				const url = this.$store.getters.getModelByName(this.modelDependencyName).url.changeUserPassword;
				const params = { oldPassword: this.oldPassword, newPassword: this.newPassword };
				this.$puiRequests.getRequest(
					url,
					params,
					() => {
						this.$puiNotify.success(this.$t('password_changed'));
						self.show = false;
						this.afterSave();
					},
					(error) => {
						self.show = false;
						if (error.response && error.response.data) {
							let message = error.response.data.message;
							if (error.response.data.internalCode === 202) {
								message = this.$t('usuarioaplicacion.validate.pass.new');
								if (message == null || message === '') {
									message = error.response.data.message;
								}
							}
							this.$puiNotify.error(message);
						}
					}
				);
			});
			this.$puiEvents.$on('pui-modalDialog-passwordDialog-cancel', () => {
				self.show = false;
				this.$puiEvents.$emit('onPui-action-running-ended-' + self.modelName);
			});
			this.$puiEvents.$on('pui-modalDialog-passwordDialog-show', (usr) => {
				this.usr = usr;
				this.oldPassword = null;
				this.newPassword = null;
				this.confirmPassword = null;
				this.show = true;
			});
		},
		afterSave() {
			this.changeAppLang(this.model.language);
			this.$store.dispatch('puiRouterGoToDefaultEntryPage', this.$router);
		},
		changeAppLang(lang) {
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		}
	},
	destroyed() {
		this.$puiEvents.$off('pui-modalDialog-passwordDialog-ok');
		this.$puiEvents.$off('pui-modalDialog-passwordDialog-cancel');
		this.$puiEvents.$off('pui-modalDialog-passwordDialog-show');
	}
};
</script>

<i18n>
{
	"en": {
		"puiuserresetpassword.title": "Update password",
		"password_changed": "Password changed successfuly",
		"resetPassword": "Change password",
		"current": "Current password",
		"new": "New password",
		"confirm": "Confirm new password",
		"password_are_equal": "The current and new passwords are equals",
		"password_not_equal": "Passwords do not match",
		"changePassword": "Change password"
	},
	"es": {
		"puiuserresetpassword.title": "Modificar contraseña",
		"puiuserresetpassword.newpassword": "Nueva contraseña",
		"puiuserresetpassword.202": "",
		"password_not_equal": "Las contraseñas no coinciden",
		"puiuserresetpassword.confirmpassword": "Confirmar nueva contraseña",
		"password_changed": "Contraseña cambiada con éxito",
		"resetPassword": "Cambiar contraseña",
		"current": "Contraseña actual",
		"new": "Nueva contraseña",
		"confirm": "Confirmar nueva contraseña",
		"password_are_equal": "La contraseña actual y nueva no pueden ser iguales",
		"password_not_equal": "Las contraseñas no coinciden",
		"changePassword": "Cambiar contraseña"
	},
	"ca": {
		"puiuserresetpassword.title": "Modificar contrasenya",
		"puiuserresetpassword.newpassword": "Nova contrasenya",
		"puiuserresetpassword.202": "",
		"password_not_equal": "Passwords coincideixen",
		"puiuserresetpassword.confirmpassword": "Confirmar nova contrasenya",
		"password_changed": "Contrasenya canviada amb èxit",
		"resetPassword": "Canviar contrasenya",
		"current": "Actual",
		"new": "Nova",
		"confirm": "Confirmar nova contrasenya",
		"password_are_equal": "L'actual contrasenya i la nova no poden ser iguals",
		"password_not_equal": "Passwords coincideixen",
		"changePassword": "Canviar contrasenya"
	}
}
</i18n>
