<template>
	<div class="pui-form" v-if="modelLoaded && myModelLoaded">
		<consultarealizada-modals :modelName="modelName"></consultarealizada-modals>
		<pui-form-header :showHeader="showParentModelHeader || !isCreatingElement" v-if="parentModelName === 'encargoterm'">
			<encargotermformheader :modelPk="parentModelPk"></encargotermformheader>
		</pui-form-header>
		<pui-form-header
			:showHeader="showParentModelHeader || !isCreatingElement"
			v-else-if="parentModelName === 'consultarecibida' && this.method === 'create'"
		>
			<consultarecibidaformheader :modelPk="parentModelPk"></consultarecibidaformheader>
		</pui-form-header>
		<pui-form-header v-else-if="this.method !== 'create'" :showHeader="showParentModelHeader || !isCreatingElement">
			<consultarealizadaformheader :modelPk="parentModelPk"></consultarealizadaformheader>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded && myModelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('consultarealizada.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#loterecibido'" v-if="!isCreatingElement">{{ $t('consultarealizada.lotesrecibidos') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<pui-field-set>
								<v-layout wrap v-if="this.method !== 'create' && !esSubconsulta">
									<v-flex xs12>
										<pui-select
											id="idencargo"
											:label="$t('consultarealizada.idencargo')"
											attach="idencargo"
											disabled
											v-model="model"
											modelName="vlupencargo"
											:itemsToSelect="[{ idencargo: model.idencargo }]"
											:modelFormMapping="{ idencargo: 'idencargo' }"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:itemValue="['idencargo']"
											:itemText="item => `${getDateFormated(item.fecharecogida)} - ${item.mercancia} - ${item.contrato}`"
											:order="{ fecharecogida: 'asc' }"
											detailModelName="encargoterm"
											detailComponentName="detailencargotermform"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap v-if="!showParentModelHeader && this.method === 'create'">
									<v-flex xs12>
										<pui-select
											id="idencargo"
											:label="this.$t('consultarealizada.idencargo')"
											attach="idencargo"
											clearable
											required
											:disabled="formDisabled || this.method != 'create'"
											v-model="model"
											modelName="vlupencargo"
											:itemsToSelect="[{ idencargo: model.idencargo }]"
											:modelFormMapping="{ idencargo: 'idencargo' }"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:fixedFilter="fixedFilterEncargo"
											:itemValue="['idencargo']"
											:itemText="item => `${getDateFormated(item.fecharecogida)} - ${item.mercancia} - ${item.contrato}`"
											:order="{ fecharecogida: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap v-if="showInquiry">
									<v-flex xs12>
										<pui-select
											id="idconsultaorigen"
											:label="this.$t('consultarealizada.idconsultaorigen')"
											attach="idconsultaorigen"
											disabled
											v-model="model"
											modelName="vlupconsultas"
											:itemsToSelect="[{ idconsulta: model.idconsultaorigen }]"
											:modelFormMapping="{ idconsulta: 'idconsultaorigen' }"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:itemValue="['idconsulta']"
											:itemText="item => `${item.pesosolicitado} Kg - ${item.referencia}`"
											:order="{ idconsulta: 'asc' }"
											detailModelName="consultarealizada"
											detailComponentName="detailconsultaform"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											v-model="model.referencia"
											:label="this.$t('consultarealizada.referencia')"
											:disabled="formDisabled"
											required
											maxlength="100"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:label="this.$t('consultarealizada.idterminaldestino')"
											id="idterminaldestino"
											attach="idterminaldestino"
											clearable
											required
											:disabled="formDisabled || this.method != 'create'"
											v-model="model"
											modelName="vlupterminales"
											:itemsToSelect="[{ idterminal: model.idterminaldestino }]"
											:modelFormMapping="{ idterminal: 'idterminaldestino' }"
											itemValue="idterminal"
											itemText="nombre"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
											:fixedFilter="getFiltroTerminal"
											:order="{ nombre: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:label="this.$t('consultarealizada.pesosolicitado')"
											v-model.number="model.pesosolicitado"
											decimals="0"
											suffix="kg"
											required
											:disabled="formDisabled"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm2 md4"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap v-if="model.idestado === 10">
									<v-flex xs12>
										<pui-text-area
											v-model="model.motivorechazo"
											:label="this.$t('consultarealizada.motivorechazo')"
											maxlength="300"
											disabled
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-area
											v-model="model.observaciones"
											:label="this.$t('consultarealizada.observaciones')"
											maxlength="300"
											clearable
											:disabled="formDisabled"
											labelColumnStyles="xs12 sm4 md2 lg1"
											valueColumnStyles="xs12 sm6 md4"
										></pui-text-area>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`loterecibido`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="loterecibidogrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idconsulta: 'idconsulta' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ConsultarealizadaActions from './ConsultarealizadaActions';
import ConsultarealizadaModals from './ConsultarealizadaModals';

export default {
	name: 'consultarealizadaform',
	mixins: [PuiFormMethodsMixin],
	components: { ConsultarealizadaModals },
	data() {
		return {
			modelName: 'consultarealizada',
			actions: ConsultarealizadaActions.formactions,
			parentModelName: null,
			parentModelPk: null,
			showParentModelHeader: false,
			myModelLoaded: false,
			fixedFilterTerminalDestino: null,
			fixedFilterAllTerminalDestino: null,
			fixedFilterEncargo: null,
			terminalOrigenPadre: null,
			modeldetail: true,
			tabmodel: 'maintab'
		};
	},
	methods: {
		// override
		afterGetData() {
			this.fixedFilterTerminalDestino = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'idterminal', op: 'ne', data: this.model.idterminalorigen },
					{ field: 'indbaja', op: 'eq', data: '0' }
				]
			};
			this.fixedFilterAllTerminalDestino = {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'indbaja', op: 'eq', data: '0' },
							{ field: 'indbaja', op: 'eq', data: '1' }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'idterminal', op: 'ne', data: this.model.idterminalorigen }] }
				],
				groupOp: 'and',
				rules: []
			};
			this.fixedFilterEncargo = {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'estado', op: 'eq', data: 'ES' },
							{ field: 'estado', op: 'eq', data: 'ET' }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'idterminal', op: 'eq', data: this.model.idterminalorigen }] }
				],
				groupOp: 'and',
				rules: []
			};

			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel.parentModelPk != null) {
				this.parentModelName = thisModel.parentModelName;
				if (this.parentModelName === 'encargoterm') {
					this.model.idencargo = thisModel.parentModelPk.idencargo;
				} else if (this.parentModelName === 'consultarecibida') {
					this.model.idconsultaorigen = thisModel.parentModelPk.idconsulta;
					if (this.method === 'create') this.getParentData(this.model.idconsultaorigen);
				}
				this.showParentModelHeader = true;

				delete thisModel.parentModelPk;
				delete thisModel.parentModelName;
			}

			this.myModelLoaded = true;
		},
		getParentData(idconsulta) {
			const params = { idconsulta: idconsulta };
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			const urlController = thisModel.url.get;
			this.$puiRequests.getRequest(
				urlController,
				params,
				response => {
					this.copyDataFromParent(response.data);
				},
				() => {
					const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ';
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.Model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		},
		copyDataFromParent(parentData) {
			this.model.pesosolicitado = parentData.pesosolicitado;
			this.model.observaciones = parentData.observaciones;
			this.terminalOrigenPadre = parentData.idterminalorigen;
			if (this.terminalOrigenPadre) {
				this.fixedFilterTerminalDestino.rules[1] = { field: 'idterminal', op: 'ne', data: this.terminalOrigenPadre };
			}
		},
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		getFormHeaderTitle() {
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel.parentModelPk != null) {
				this.parentModelName = thisModel.parentModelName;
			}

			if (this.parentModelName === 'encargoterm') {
				return this.$t('encargo.generateInquiry');
			} else if (this.parentModelName === 'consultarecibida') {
				return this.$t('consulta.generateSubInquiry');
			} else {
				return this.$t('form.consultarealizada');
			}
		}
	},
	watch: {
		model(newVal) {
			if (newVal.idencargo && this.parentModelName === 'encargoterm') {
				this.parentModelPk = {
					idencargo: newVal.idencargo
				};
				this.showParentModelHeader = true;
			} else if (newVal.idconsultaorigen && this.parentModelName === 'consultarecibida') {
				this.parentModelPk = {
					idconsulta: newVal.idconsultaorigen
				};
				this.showParentModelHeader = true;
			} else if (newVal.idconsulta) {
				this.parentModelPk = {
					idconsulta: newVal.idconsulta
				};
				this.showParentModelHeader = true;
			}
		}
	},
	computed: {
		showInquiry() {
			return this.parentModelName === 'consultarecibida';
		},
		esSubconsulta() {
			return this.model.idconsultaorigen && this.model.idconsultaorigen !== null;
		},
		getFiltroTerminal() {
			if (this.formDisabled) {
				return this.fixedFilterAllTerminalDestino;
			} else {
				return this.fixedFilterTerminalDestino;
			}
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"consultarealizada.idencargo": "Order",
		"consultarealizada.idconsultaorigen": "Origin inquiry",

		"consultarealizada.referencia": "Reference",
        "consultarealizada.idterminaldestino": "Destination terminal",
        "consultarealizada.pesosolicitado": "Weight (kg)",
		"consultarealizada.observaciones": "Inquiry state",

		"consultarealizada.maintab":"Inquiry",
		"consultarealizada.lotesrecibidos":"Received lots",
		"consultarealizada.motivorechazo": "Rejection reason"
    },
    "es": {
		"consultarealizada.idencargo": "Encargo",
		"consultarealizada.idconsultaorigen": "Consulta Origen",

		"consultarealizada.referencia": "Referencia",
        "consultarealizada.idterminaldestino": "Terminal destino",
		"consultarealizada.pesosolicitado": "Peso (kg)",
        "consultarealizada.observaciones": "Observaciones",

		"consultarealizada.maintab":"Consulta",
		"consultarealizada.lotesrecibidos":"Lotes recibidos",
		"consultarealizada.motivorechazo": "Motivo rechazo"
    },
    "ca": {
		"consultarealizada.idencargo": "Encàrrec",
		"consultarealizada.idconsultaorigen": "Consulta Origen",

		"consultarealizada.referencia": "Referència",
        "consultarealizada.idterminaldestino": "Terminal destí",
        "consultarealizada.pesosolicitado": "Pes sol·licitat (kg)",
        "consultarealizada.observaciones": "Observacions",

		"consultarealizada.maintab":"Consulta",
		"consultarealizada.lotesrecibidos":"Lots rebuts",
		"consultarealizada.motivorechazo": "Motiu rebuig"
    }
}
</i18n>
