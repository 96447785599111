<template>
	<div class="pui-form">
		<comprador-modals :modelName="modelName"></comprador-modals>
		<v-form class="pui-compradorform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs8>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 v-if="this.method != 'create'">
							<pui-text-field
								:label="$t('comprador.idcomprador')"
								v-model="model.idcomprador"
								required
								disabled
								valueColumnStyles="xs4 sm2 md1"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('comprador.nombre')"
								v-model="model.nombre"
								maxlength="100"
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:label="$t('comprador.cif')"
								v-model="model.cif"
								maxlength="30"
								required
								:disabled="formDisabled"
								valueColumnStyles="xs12 sm4 md2 xl2"
							></pui-text-field>
						</v-flex>
						<v-flex xs12>
							<v-layout wrap v-if="this.method != 'create'">
								<v-flex xs6>
									<pui-checkbox
										:label="$t('comprador.indbaja')"
										v-model="model.indbaja"
										:trueValue="'1'"
										:falseValue="'0'"
										disabled
									></pui-checkbox>
								</v-flex>
								<v-flex xs6 v-if="model.indbaja === '1'">
									<pui-date-field
										:label="$t('comprador.fecbaja')"
										v-model="model.fecbaja"
										disabled
										valueColumnStyles="xs6"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12>
							<pui-text-area
								v-model="model.observaciones"
								:label="this.$t('comprador.observaciones')"
								maxlength="300"
								clearable
								:disabled="formDisabled"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import CompradorActions from './CompradorActions';
import CompradorModals from './CompradorModals';

export default {
	name: 'comprador',
	components: {
		CompradorModals
	},
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'comprador',
			actions: CompradorActions.formactions
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "comprador.idcomprador": "Code",
        "comprador.nombre": "Name",
        "comprador.cif": "Tax identifier",
        "comprador.observaciones": "Comments",
        "comprador.indbaja": "Disabled",
        "comprador.fecbaja": "Disabled date"
    },
    "es": {
        "comprador.idcomprador": "Código",
        "comprador.nombre": "Nombre",
        "comprador.cif": "Identificador fiscal",
        "comprador.observaciones": "Observaciones",
        "comprador.indbaja": "Baja",
        "comprador.fecbaja": "Fecha baja"
    },
    "ca": {
        "comprador.idcomprador": "Codi",
        "comprador.nombre": "Nom",
        "comprador.cif": "Identificador fiscal",
        "comprador.observaciones": "Observacions",
        "comprador.indbaja": "Baixa",
        "comprador.fecbaja": "Data baixa"
    }
}
</i18n>
