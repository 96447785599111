import { render, staticRenderFns } from "./LibramientoTermcModals.vue?vue&type=template&id=20339d82&scoped=true&"
import script from "./LibramientoTermcModals.vue?vue&type=script&lang=js&"
export * from "./LibramientoTermcModals.vue?vue&type=script&lang=js&"
import style0 from "./LibramientoTermcModals.vue?vue&type=style&index=0&id=20339d82&lang=postcss&scoped=true&"
import style1 from "./LibramientoTermcModals.vue?vue&type=style&index=1&id=20339d82&scoped=true&lang=css&"
import style2 from "./LibramientoTermcModals.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20339d82",
  null
  
)

/* custom blocks */
import block0 from "./LibramientoTermcModals.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataTable,VFlex,VForm,VLayout,VTextField})
