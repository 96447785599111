<template>
	<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */
import ConductorbloqueadoActions from './ConductorbloqueadoActions';

export default {
	name: 'conductorbloqueadogrid',
	data() {
		return {
			model: 'conductorbloqueado',
			actions: ConductorbloqueadoActions.gridactions
		};
	}
};
</script>

<style lang="postcss" scoped></style>
