<template>
	<div class="divlibramiento">
		<pui-form-header :showHeader="parentModelPk !== null">
			<generarlibramientotransformheader :modelPk="parentModelPk"></generarlibramientotransformheader>
		</pui-form-header>
		<pui-modal-dialog
			v-if="modals.confirm.show"
			:cancelText="$t('form.no')"
			:disableOk="modals.confirm.disableOk"
			:okText="$t('form.yes')"
			:messageText="modals.confirm.titleText"
			:titleText="``"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
		></pui-modal-dialog>
		<v-form class="pui-libramientotransform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12>
					<v-flex xs12 md8 v-show="false">
						<pui-select
							v-if="modelLoaded"
							attach="idasignacion"
							:label="$t('asignacion.idasignacion')"
							clearable
							disabled
							v-model="model"
							required
							modelName="vlupasignacion"
							:itemsToSelect="[{ idasignacion: model.idasignacion }]"
							:modelFormMapping="{ idasignacion: 'idasignacion' }"
							:itemValue="['idasignacion']"
							:itemText="(item) => `${item.pesoasignado} Kg - ${item.mercancia} - ${getDateFormated(item.fecharecogida)}`"
							labelColumnStyles="xs12 md2"
							valueColumnStyles="xs12 md8"
						></pui-select>
					</v-flex>
					<div v-for="(libramiento, index) in model.libramientos" :key="index">
						<generar-libramiento-trans-core-form
							:model="libramiento"
							:formDisabled="formDisabled"
							:modelLoaded="modelLoaded"
							:parentlist="model.libramientos"
							:indice="index"
							:method="method"
							:fixedFilterTimeslot="fixedFilterTimeslot"
							:fixedFilterConductor="fixedFilterConductor"
							:parentModel="model"
						></generar-libramiento-trans-core-form>
					</div>
					<v-layout wrap>
						<v-flex xs12>
							<v-btn depressed color="secondary" class="elevation-0" @click.native="addLibramiento()">{{
								this.$t('addLibramiento')
							}}</v-btn>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 style="height: 80px;"></v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12>
						<v-layout>
							<v-flex xs12 align-self-center>
								<span class="labelCheck">
									<pui-checkbox v-model="aceptadasNormativa" :trueValue="1" :falseValue="0" class="userCheck"></pui-checkbox>
									{{ normativaSeguridadText }}
									<a :href="normativaSeguridadUrl" target="_blank">{{ $t('descargarDocumento') }}</a>
								</span>
							</v-flex>
						</v-layout>
						<v-layout>
							<v-flex xs12 align-self-center>
								<span class="labelCheck">
									<pui-checkbox v-model="aceptadasLimpieza" :trueValue="1" :falseValue="0" class="userCheck"></pui-checkbox>
									{{ seguridadAlimentariaText }}
								</span>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn
										v-if="!formDisabled"
										:disabled="formDisabled || semaforo"
										depressed
										color="secondary"
										class="elevation-0"
										@click.native="popUpBeforeSave()"
										>{{ this.$t('form.save') }}</v-btn
									>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import GenerarLibramientoTransCoreForm from './GenerarLibramientoTransCoreForm';
export default {
	name: 'asignaciontrans',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'asignaciontrans',
			fixedFilterTimeslot: null,
			fixedFilterConductor: null,
			aceptadasNormativa: 0,
			aceptadasLimpieza: 0,
			showSeguridadAlimentaria: false,
			showNormativaSeguridad: false,
			normativaSeguridadText: '',
			seguridadAlimentariaText: '',
			normativaSeguridadUrl: '',
			urlControllerSeg: '/libramientotrans/getSeguridadAlimentariaText',
			urlControllerNor: '/libramientotrans/getNormativaSeguridadText',
			urlControllerNorUrl: '/libramientotrans/getNormativaSeguridadUrl',
			parentModelPk: null,
			modals: {
				confirm: {
					disableOk: false,
					show: false,
					labelText: '',
					titleText: this.$t('libramiento.modal.titulofinalizar')
				}
			},
			pesodefecto: null,
			semaforo: false
		};
	},
	components: { GenerarLibramientoTransCoreForm },
	methods: {
		// override
		afterGetData() {
			//this.model.libramientos.push({});
			this.pesodefecto = this.model.libramientos[0].pesosolicitado;
			this.fixedFilterTimeslot = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'idasignacion', op: 'eq', data: this.model.idasignacion }]
			};
			this.fixedFilterConductor = {
				groupOp: 'and',
				rules: [
					{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista },
					{ field: 'idterminal', op: 'in', data: this.model.idterminal + ',' + this.model.idterminalcarga }
				],
				groups: []
			};

			this.parentModelPk = { idasignacion: this.model.idasignacion };
		},
		addLibramiento() {
			this.model.libramientos.push({ pesosolicitado: this.pesodefecto });
		},
		popUpBeforeSave() {
			this.beforeSave && this.beforeSave();
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				return;
			}
			//Si no han aceptado las condiciones no se crean
			if (this.aceptadasNormativa === 0 || this.aceptadasLimpieza === 0) {
				this.$puiNotify.info(this.$t('libramiento.aceptadasCondiciones'));
				return;
			}

			if (this.semaforo) {
				this.$puiNotify.error(this.$t('error.operacion_en_curso'));
				return;
			}
			this.semaforo = true;

			// Se llama al negocio para que nos diga si el usuario puede elegir finalizar o no y mostrar el modal para ello.
			// Esto ocurre dependiendo de si tiene consulta de origen o no
			var urlController = this.modelDependency.url.popupEndRiddance;
			this.$puiRequests.postRequest(
				urlController + '?idasignacion=' + this.model.idasignacion,
				this.model.libramientos,
				(response) => {
					if (response.data) {
						this.doCustomSave(true);
					} else {
						this.doCustomSave(false);
					}
				},
				() => {
					this.doCustomSave(false);
				}
			);
		},
		doCustomSave(showModal) {
			if (showModal) {
				this.setShowConfirm(true);
				this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
					this.setShowConfirm(false);
					this.unSubscribeEvents();
					this.generate(this.shouldStayInPage, this.modelDependency.url.generate);
				});
				this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
					this.setShowConfirm(false);
					this.semaforo = false;
				});
			} else {
				this.generate(this.shouldStayInPage, this.modelDependency.url.generate);
			}
		},
		generate(shouldStayInPage, url) {
			this.$puiRequests.postRequest(
				url + '?idasignacion=' + this.model.idasignacion,
				this.model.libramientos,
				(response) => {
					this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					this.afterSave && this.afterSave(response.data);
					this.semaforo = false;
					if (!(shouldStayInPage === true)) {
						this.back();
					}
				},
				(error) => {
					this.onSaveError && this.onSaveError();
					this.onError(error);
					this.semaforo = false;
				}
			);
		},
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		getFormHeaderTitle() {
			return this.$t('asignaciontrans.generarlibramiento');
		},
		getSeguridadAlimentaria() {
			this.$puiRequests.getRequest(
				this.urlControllerSeg,
				null,
				(response) => {
					if (response.data) {
						this.seguridadAlimentariaText = response.data;
					}
				},
				(error) => {
					this.$puiNotify.error(error);
				}
			);
		},
		getNormativaSeguridad() {
			this.$puiRequests.getRequest(
				this.urlControllerNor,
				null,
				(response) => {
					if (response.data) {
						this.normativaSeguridadText = response.data;
					}
				},
				(error) => {
					this.$puiNotify.error(error);
				}
			);
		},
		getNormativaSeguridadUrl() {
			this.$puiRequests.getRequest(
				this.urlControllerNorUrl,
				null,
				(response) => {
					if (response.data) {
						this.normativaSeguridadUrl = response.data;
					}
				},
				(error) => {
					this.$puiNotify.error(error);
				}
			);
		},
		setShowConfirm(value) {
			this.modals.confirm.show = value;
		},
		unSubscribeEvents() {
			this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
			this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		}
	},
	created() {
		this.getSeguridadAlimentaria();
		this.getNormativaSeguridad();
		this.getNormativaSeguridadUrl();
	},
	destroy() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
	}
};
</script>
<style lang="postcss">
.divlibramiento {
	overflow-y: auto;
}
.labelCheck {
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.6px;
	color: var(--N-400);
	padding-top: 1px;
	display: inline-block;
	display: -moz-inline-stack;
	//position: relative;
	//left: -100px;
}
.userCheck {
	display: inline-block;
	//position: relative;
	//left: -100px;
}
</style>

<i18n>
{
    "en": {
		"asignacion.idasignacion": "Assignment",
		"addLibramiento": "+",
		"seguridadAlimentaria": "Food safety",
		"normativaSeguridad": "Safety regulations",
		"aceptolascondicionesde":"I accept the conditions of ",
		"y":" and ",
		"libramiento.aceptadasCondiciones": "You must accept the security conditions to generate the library"
    },
    "es": {
        "asignacion.idasignacion": "Asignación",
		"addLibramiento": "+",
		"seguridadAlimentaria": "Seguridad alimentaria",
		"normativaSeguridad": "Normativa de seguridad",
		"aceptolascondicionesde":"Acepto las condiciones de ",
		"y":" y ",
		"libramiento.aceptadasCondiciones": "Debe aceptar las condiciones de seguridad para generar el libramiento",
		"descargarDocumento": "Descargar documento adjunto."
    },
    "ca": {
        "asignacion.idasignacion": "Assignació",
		"addLibramiento": "+",
		"seguridadAlimentaria": "Seguretat alimentaria",
		"normativaSeguridad": "Normativa de seguretat",
		"aceptolascondicionesde":"Acepte les condicions de ",
		"y":" i ",
		"libramiento.aceptadasCondiciones": "Ha d'acceptar les condicions de seguretat per a generar el lliurament",
		"descargarDocumento": "Descarregar document adjunt."
    }
}
</i18n>
