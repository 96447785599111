<template>
	<div class="pui-form" v-if="modelLoaded && myModelLoaded">
		<div ref="windowcontainer">
			<pui-form-header :showHeader="parentModelName === 'consultarecibida'">
				<consultarecibidaformheader :modelPk="parentModelPk" :headerOwner="modelName" :modelLink="modelHeader"></consultarecibidaformheader>
			</pui-form-header>
			<pui-form-header :showHeader="parentModelName === 'encargoterm'">
				<encargotermformheader :modelPk="parentModelPk" :modelLink="modelHeader"></encargotermformheader>
			</pui-form-header>
			<pui-modal-dialog
				v-if="modals.confirm.show"
				:cancelText="$t('form.no')"
				:disableOk="modals.confirm.disableOk"
				:okText="$t('form.yes')"
				:messageText="modals.confirm.titleText"
				:titleText="``"
				:dialogName="`confirm-${this.modelName}`"
				ref="confirm"
			></pui-modal-dialog>
			<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="fixedFilterPuntoCarga !== null">
				<v-layout wrap class="pui-form-layout">
					<v-layout>
						<v-flex xs12>
							<lote-generado-detalle-form
								:formDisabled="formDisabled"
								:datos="model"
								:dinamycForm="dinamycForm"
								:method="this.method"
								:fixedFilterPuntoCarga="fixedFilterPuntoCarga"
								:isCreatingElement="isCreatingElement"
							></lote-generado-detalle-form>
						</v-flex>
					</v-layout>
				</v-layout>
				<pui-form-footer>
					<v-layout>
						<v-flex xs12 sm12 md8 lg8 xl8>
							<v-layout>
								<v-flex xs12 md4>
									<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
								</v-flex>
								<v-flex xs12 md8>
									<v-layout justify-end fill-height>
										<v-btn
											v-if="!formDisabled"
											depressed
											color="secondary"
											class="elevation-0"
											@click.native="popUpBeforeSave()"
											>{{ this.$t('form.save') }}</v-btn
										>
										<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-form-footer>
			</v-form>
		</div>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import LoteGeneradoDetalleForm from './LotegeneradoDetalleForm';
import LotegeneradoActions from './LotegeneradoActions';

export default {
	name: 'lotegeneradoform',
	mixins: [PuiFormMethodsMixin],
	components: {
		LoteGeneradoDetalleForm
	},
	data() {
		return {
			modelName: 'lotegenerado',
			actions: LotegeneradoActions.formactions,
			parentModelName: null,
			parentModelPk: null,
			showParentModelHeader: false,
			heightPantalla: 0,
			dinamycForm: { showLoadingPoint: false, autoInsertFromOrder: false, fromGrid: false },
			myModelLoaded: false,
			modals: {
				confirm: {
					disableOk: false,
					show: false,
					labelText: '',
					titleText: this.$t('lotegenerado.modal.titulofinalizar'),
					observacionesfact: null
				}
			},
			loteIndex0: null,
			modelHeader: { encargoterm: {}, consultarecibida: {} },
			fixedFilterPuntoCarga: null
		};
	},
	watch: {
		'modelHeader.consultarecibida'(newValue) {
			if (newValue.fecharecogida) {
				this.model.fecharecogida = newValue.fecharecogida;
				this.fixedFilterPuntoCarga = this.makeFilterLoadingPoint(this.model.fecharecogida);
			}
		},
		'modelHeader.encargoterm'(newValue) {
			if (newValue.fecharecogida) {
				this.model.fecharecogida = newValue.fecharecogida;
				this.fixedFilterPuntoCarga = this.makeFilterLoadingPoint(this.model.fecharecogida);
			}
		}
	},
	methods: {
		// override
		afterGetData() {
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel.parentModelName) {
				if (thisModel.parentModelName === 'consultarecibida') {
					// Obtengo la PK de la consulta del padre y seteamos la variable que modifica el diseño del formulario
					this.model.idconsulta = thisModel.parentModelPk.idconsulta;
					this.dinamycForm.showLoadingPoint = true;
				} else if (thisModel.parentModelName === 'encargoterm') {
					// Obtengo la PK de encargo del padre y seteamos la variable que modifica el diseño del formulario
					this.model.idencargo = thisModel.parentModelPk.idencargo;
					this.dinamycForm.autoInsertFromOrder = true;
				}

				this.parentModelName = thisModel.parentModelName;
				this.parentModelPk = thisModel.parentModelPk;
				delete thisModel.parentModelName;
				delete thisModel.parentModelPk;

				// Diseño mostrar o ocultar lupa terminal
				if (thisModel.fromGrid) {
					this.dinamycForm.fromGrid = thisModel.fromGrid;
					delete thisModel.fromGrid;
				}
			}

			this.model.lotesadicionales = [];

			this.myModelLoaded = true;
		},
		beforeSave() {
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				return;
			}
			if (this.model.lotesadicionales[0].idlote) this.model.idlote = this.model.lotesadicionales[0].idlote;
			this.model.idpuntocarga = this.model.lotesadicionales[0].idpuntocarga;
			this.model.peso = this.model.lotesadicionales[0].peso;
			this.model.observaciones = this.model.lotesadicionales[0].observaciones;
			this.loteIndex0 = this.model.lotesadicionales[0];
			this.model.lotesadicionales.splice(0, 1);
		},
		afterSave(respuesta) {
			if (respuesta.idlote && !this.loteIndex0.idlote) this.loteIndex0.idlote = respuesta.idlote;
			this.model.lotesadicionales.unshift(this.loteIndex0);
			this.loteIndex0 = null;
		},
		onSaveError() {
			this.model.lotesadicionales.unshift(this.loteIndex0);
			this.loteIndex0 = null;
		},
		setShowConfirm(value) {
			this.modals.confirm.show = value;
		},
		popUpBeforeSave() {
			if (this.dinamycForm.autoInsertFromOrder) {
				this.autoinsert();
			} else {
				// Se llama al negocio para que nos diga si el usuario puede elegir finalizar o no y mostrar el modal para ello.
				// Esto ocurre dependiendo de si tiene consulta de origen o no
				var params = { idconsulta: this.model.idconsulta };
				const modelUrl = this.$store.getters.getModelByName(this.modelName).url;
				var urlController = modelUrl.popupEndLot;
				this.$puiRequests.getRequest(
					urlController,
					params,
					response => {
						if (response.data) {
							this.doCustomSave(true);
						} else {
							this.doCustomSave(false);
						}
					},
					() => {
						this.doCustomSave(false);
					}
				);
			}
		},
		doCustomSave(showModal) {
			if (showModal && this.method === 'create') {
				this.setShowConfirm(true);
				this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
					this.setShowConfirm(false);
					this.model.finalizar = true;
					this.unSubscribeEvents();
					this.save(false);
				});
				this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
					this.setShowConfirm(false);
					this.model.finalizar = false;
					this.unSubscribeEvents();
					this.save(false);
				});
			} else {
				this.save(false);
				this.model.finalizar = false;
			}
		},
		unSubscribeEvents() {
			this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
			this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		},
		makeFilterLoadingPoint(fecha) {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{
						field: 'fecbaja',
						op: 'gt',
						data: fecha
					},
					{
						field: 'fecbaja',
						op: 'nu',
						data: null
					}
				]
			};
		},
		autoinsert() {
			this.beforeSave();
			this.$puiRequests.postRequest(
				this.modelDependency.url.autoinsert,
				this.model,
				response => {
					this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					this.afterSave && this.afterSave(response.data);
					this.model.finalizar = false;
					this.back();
				},
				error => {
					this.onSaveError && this.onSaveError();
					this.onError(error);
					this.model.finalizar = false;
				}
			);
		}
		/*		makeFilterLoadingPoint(fecha) {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{
						field: 'fechini',
						op: 'eq',
						data: this.$dateTimeUtils.formatDate(fecha, 'YYYY-MM-DD')
					},
					{
						field: 'fechfin',
						op: 'eq',
						data: this.$dateTimeUtils.formatDate(fecha, 'YYYY-MM-DD')
					}
				]
			};
		}
	*/
	},
	destroy() {
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"lotegenerado.modal.titulofinalizar":"If you have located all the goods requested in the query, would you like to finalize it?"
    },
    "es": {
		"lotegenerado.modal.titulofinalizar":"Si ya ha localizado toda la mercancía solicitada en la consulta ¿desea finalizarla?"
    },
    "ca": {
		"lotegenerado.modal.titulofinalizar":"Si ja ha localitzat tota la mercaderia sol·licitada en la consulta desitja finalitzar-la?"
    }
}
</i18n>
