<template>
	<div class="encargoterm puiformheader">
		<v-layout wrap>
			<v-flex xs12 md3 v-if="origen == 'ET' && !getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md6 v-if="origen == 'LT'">
				<pui-field :label="this.$t('encargoterm.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md6 v-if="origen == 'LT'">
				<pui-field :label="this.$t('encargoterm.comprador')" :value="getComprador"></pui-field>
			</v-flex>
			<v-flex xs12 md6 v-if="origen == 'LT'">
				<pui-field :label="this.$t('encargoterm.contrato')" :value="getContrato"></pui-field>
			</v-flex>
			<v-flex xs12 md3 v-if="!getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.peso')" :value="getPeso"></pui-field>
			</v-flex>
			<v-flex xs12 md3 v-if="origen == 'ET' && !getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.kiloslocalizados')" :value="getPesoLocalizado"></pui-field>
			</v-flex>
			<v-flex xs12 md3 v-if="origen == 'ET' && !getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.fecharecogida')" :value="getDiaRecogida"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.peso')" :value="getPeso"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.kiloslocalizados')" :value="getPesoLocalizado"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.fecharecogida')" :value="getDiaRecogida"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.comprador')" :value="getComprador"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="getCabeceraLote()">
				<pui-field :label="this.$t('encargoterm.contrato')" :value="getContrato"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'encargotermformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'encargoterm'
		};
	},
	computed: {
		getMercancia() {
			return this.model && this.model.mercancia ? this.model.mercancia : '';
		},
		getDiaRecogida() {
			return this.model && this.model.fecharecogida
				? this.$dateTimeUtils.formatDate(this.model.fecharecogida, this.$store.getters.dateFormat)
				: '';
		},
		getPeso() {
			return this.model && this.model.peso ? this.formatNumber(this.model.peso) + ' Kg' : '0 Kg';
		},
		getContrato() {
			return this.model && this.model.contrato ? this.model.contrato : '';
		},
		getPesoLocalizado() {
			return this.model && this.model.kiloslocalizados ? this.formatNumber(this.model.kiloslocalizados) + ' Kg' : '0 Kg';
		},
		getComprador() {
			return this.model && this.model.comprador ? this.model.comprador : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'encargoterm';
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		},
		getCabeceraLote() {
			return this.modelLink && this.modelLink.encargoterm;
		}
	},
	props: {
		origen: {
			type: String,
			required: false,
			default: 'ET' //encargo terminal
		},
		modelLink: {
			type: Object,
			required: false
		}
	},
	watch: {
		model(newValue) {
			if (this.modelLink && this.modelLink.encargoterm) this.modelLink.encargoterm = newValue;
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"encargoterm.mercancia": "Goods",
		"encargoterm.fecharecogida": "Pick up date",
		"encargoterm.contrato": "Contract number",
		"encargoterm.peso": "Weight (kg)",
		"encargoterm.kiloslocalizados": "Localized weight",
		"encargoterm.comprador": "Buyer"
	},
	"es": {
		"encargoterm.mercancia": "Mercancía",
		"encargoterm.fecharecogida": "Fecha de recogida",
		"encargoterm.contrato": "Nº contrato",
		"encargoterm.peso": "Peso (kg)",
		"encargoterm.kiloslocalizados": "Peso localizado (kg)",
		"encargoterm.comprador": "Comprador"
	},
	"ca": {
		"encargoterm.mercancia": "Mercaderia",
		"encargoterm.fecharecogida": "Data de recollida",
		"encargoterm.contrato": "Núm. contracte",
		"encargoterm.peso": "Pes (kg)",
		"encargoterm.kiloslocalizados": "Pes localitzat (kg)",
		"encargoterm.comprador": "Comprador"
	}
}
</i18n>
