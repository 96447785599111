<template>
	<div>
		<pui-modal-dialog
			v-if="modals.auditoria.show"
			:okText="$t('form.close')"
			:titleText="modals.auditoria.titleText"
			:dialogName="`auditoria-${this.modelName}`"
			ref="auditoria"
			:widthDialog="widthDialog"
			messageText
		>
			<template slot="message">
				<v-form action>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<pui-quick-edition-datatable
								id="auditasignacionform"
								modelName="auditasignacion"
								formPosition="right"
								formComponentName="auditasignacionform"
								disabled
								:quickEditionExternalFilter="externalFilter"
								:modelColumnDefs="modelColumnDefs"
								:formModelTemplate="formModelTemplate"
							></pui-quick-edition-datatable>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
/* eslint-disable */
//import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'asignacionmodals',
	//	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modals: {
				auditoria: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			},
			widthDialog: window.innerWidth - 50,
			formModelTemplate: {
				type: null,
				content: null
			},
			modelColumnDefs: {
				id: { visible: false },
				datetime: { visible: true },
				usr: { visible: true },
				client: { visible: false },
				ip: { visible: false },
				type: { visible: true },
				model: { visible: false },
				pk: { visible: false },
				content: { visible: false }
			},
			parentModelPk: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			//Modal auditoria
			this.$puiEvents.$on(`pui-modalDialog-auditoria-${this.modelName}-ok`, () => {
				this.setShowAuditoria(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-auditoria-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idasignacion: data.idasignacion
				};

				this.setShowAuditoria(true);
			});
		},
		setShowAuditoria(action) {
			this.modals.auditoria.show = action;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-auditoria-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-auditoria-${this.modelName}-show`);
	},
	computed: {
		externalFilter() {
			const filter = {
				groupOp: 'and',
				rules: [
					{ field: 'model', op: 'eq', data: 'asignaciontrans' },
					{ field: 'pk', op: 'eq', data: this.parentModelPk.idasignacion }
				],
				groups: [
					{
						groupOp: 'or',
						rules: [{ field: 'type', op: 'in', data: 'insert,update,delete' }]
					}
				]
			};
			return filter;
		}
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtcasignaciones.validate.enable": "The burden of this assignment will be reallocated. Do you want to continue?"
	},
	"es": {
		"vtcasignaciones.validate.enable": "Se reasignará la carga de esta asignación. ¿Desea continuar?"
	},
	"ca": {
		"vtcasignaciones.validate.enable": "Es reassignarà la càrrega d'aquesta assignació. Desitja continuar?"
	}
}
</i18n>
