<template>
	<div class="pui-form">
		<v-form class="pui-encargoauditform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('encargoaudit.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#consultaaudit'" v-if="!isCreatingElement">{{ $t('encargoaudit.consulta') }}</v-tab>
				<v-tab :key="2" :href="'#loteaudit'" v-if="!isCreatingElement">{{ $t('encargoaudit.lote') }}</v-tab>
				<v-tab :key="3" :href="'#asignacionaudit'" v-if="!isCreatingElement">{{ $t('encargoaudit.asignacion') }}</v-tab>
				<v-tab :key="4" :href="'#libramientoaudit'" v-if="!isCreatingElement">{{ $t('encargoaudit.libramiento') }}</v-tab>
				<v-tab :key="5" :href="'#historicoaudit'" v-if="!isCreatingElement">{{ $t('encargoaudit.historico') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 md8>
							<v-flex xs12>
								<pui-text-field
									:label="$t('encargoaudit.idencargo')"
									v-model="model.idencargo"
									required
									disabled
									valueColumnStyles="xs4 sm2 md1"
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-select
									id="idcomprador"
									attach="idcomprador"
									:label="$t('encargoaudit.idcomprador')"
									clearable
									disabled
									v-model="model"
									modelName="vlupcompradores"
									:itemsToSelect="[{ idcomprador: model.idcomprador }]"
									:modelFormMapping="{ idcomprador: 'idcomprador' }"
									:itemValue="['idcomprador']"
									:itemText="item => `${item.nombre}`"
									:key="'lupaComp' + lupaKey"
									:order="{ nombre: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-select
									id="idterminal"
									attach="idterminal"
									:label="$t('encargoaudit.idterminal')"
									clearable
									disabled
									v-model="model"
									modelName="vlupterminales"
									:itemsToSelect="[{ idterminal: model.idterminal }]"
									:modelFormMapping="{ idterminal: 'idterminal' }"
									:itemValue="['idterminal']"
									:itemText="item => `${item.nombre}`"
									:key="'lupaTerm' + lupaKey"
									:order="{ nombre: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-date-field
									id="fecharecogida"
									:label="$t('encargoaudit.fecharecogida')"
									v-model="model.fecharecogida"
									disabled
									valueColumnStyles="xs12 sm4 md4 xl2"
									attach="fecharecogida"
								></pui-date-field>
							</v-flex>
							<v-flex xs12>
								<pui-select
									id="idmercancia"
									attach="idmercancia"
									:label="$t('encargoaudit.idmercancia')"
									clearable
									disabled
									v-model="model"
									modelName="vlupmercancias"
									:itemsToSelect="[{ idmercancia: model.idmercancia }]"
									:modelFormMapping="{ idmercancia: 'idmercancia' }"
									:itemValue="['idmercancia']"
									:itemText="item => `${item.nombre}`"
									:key="'lupaMer' + lupaKey"
									:order="{ nombre: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-number-field
									id="peso"
									:label="$t('encargoaudit.peso')"
									v-model="model.peso"
									disabled
									suffix="kg"
									valueColumnStyles="xs12 sm6 md4 xl2"
								></pui-number-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('encargoaudit.proveedor')"
									v-model="model.proveedor"
									maxlength="100"
									disabled
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('encargoaudit.contrato')"
									v-model="model.contrato"
									maxlength="50"
									disabled
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('encargoaudit.aplicacion')"
									v-model="model.aplicacion"
									maxlength="50"
									disabled
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('encargoaudit.referencia')"
									v-model="model.referencia"
									maxlength="50"
									disabled
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-select
									attach="idestado"
									:label="$t('encargoaudit.idestado')"
									clearable
									v-model="model"
									disabled
									modelName="vlupestados"
									:itemsToSelect="[{ idestado: model.idestado }]"
									:modelFormMapping="{ idestado: 'idestado' }"
									:itemValue="['idestado']"
									:itemText="item => `${item.descestado}`"
									:key="'lupaEstado' + lupaKey"
									:order="{ descestado: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-select
									id="idmotivoanulrechazo"
									attach="idmotivoanulrechazo"
									:label="$t('encargoaudit.idmotivoanulrechazo')"
									disabled
									v-model="model"
									modelName="vlupmotivoanulrech"
									:itemsToSelect="[{ idmotivo: model.idmotivoanulrechazo }]"
									:modelFormMapping="{ idmotivo: 'idmotivoanulrechazo' }"
									:itemValue="['idmotivo']"
									:itemText="item => `${item.descripcion}`"
									:order="{ descripcion: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-text-area
									v-model="model.obsrechazo"
									:label="this.$t('encargoaudit.obsrechazo')"
									maxlength="1000"
									clearable
									disabled
								></pui-text-area>
							</v-flex>
							<v-flex xs12>
								<pui-text-area
									v-model="model.observaciones"
									:label="this.$t('encargoaudit.observaciones')"
									maxlength="300"
									clearable
									disabled
								></pui-text-area>
							</v-flex>
							<v-flex xs12>
								<pui-text-area
									v-model="model.obsvalidacion"
									:label="this.$t('encargo.obsvalidacion')"
									maxlength="1000"
									clearable
									disabled
								></pui-text-area>
							</v-flex>
							<v-flex xs12>
								<pui-date-field
									id="fechacreacion"
									:label="$t('encargoaudit.fechacreacion')"
									v-model="model.fecalta"
									disabled
									timesecs
									valueColumnStyles="xs12 sm6 md4"
									attach="fecalta"
								></pui-date-field>
							</v-flex>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`consultaaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="consultaauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="`loteaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="loteauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="3" :value="`asignacionaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="asignacionauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="4" :value="`libramientoaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="libramientoauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="5" :value="`historicoaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="historicoauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idencargo: 'idencargo' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'encargoauditform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'encargoaudit',
			modeldetail: true,
			tabmodel: 'maintab'
		};
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"encargoaudit.idencargo": "Order code",
		"encargoaudit.idcomprador": "Buyer",
		"encargoaudit.idmercancia": "Good",
		"encargoaudit.idterminal": "Management terminal",
		"encargoaudit.proveedor": "Supplier",
		"encargoaudit.idtransportista": "Carrier",
		"encargoaudit.idestado": "State",
		"encargoaudit.idmotivoanulrechazo": "Reason for cancellation/rejection",
		"encargoaudit.peso": "Weight",
		"encargoaudit.contrato": "Contract number",
		"encargoaudit.aplicacion": "Application",
		"encargoaudit.referencia": "Reference",
		"encargoaudit.observaciones": "Remarks",
		"encargoaudit.fecharecogida": "Pick-up date",
		"encargoaudit.fechacreacion": "Creation date",
		"encargoaudit.obsrechazo":"Rejection Remarks",

		"encargoaudit.maintab": "Order",
		"encargoaudit.consulta": "Inquiry",
		"encargoaudit.lote": "Lots",
		"encargoaudit.asignacion": "Assignments",
		"encargoaudit.libramiento": "Riddances",
		"encargoaudit.historico": "Historical"
    },
    "es": {
        "encargoaudit.idencargo": "Código encargo",
		"encargoaudit.idcomprador": "Comprador",
		"encargoaudit.idmercancia": "Mercancía",
		"encargoaudit.idterminal": "Terminal gestora",
		"encargoaudit.proveedor": "Proveedor",
		"encargoaudit.idtransportista": "Transportista",
		"encargoaudit.idestado": "Estado",
		"encargoaudit.idmotivoanulrechazo": "Motivo anulación/rechazo",
		"encargoaudit.peso": "Peso",
		"encargoaudit.contrato": "Número de contrato",
		"encargoaudit.aplicacion": "Aplicación",
		"encargoaudit.referencia": "Referencia",
		"encargoaudit.observaciones": "Observaciones",
		"encargoaudit.fecharecogida": "Fecha de recogida",
		"encargoaudit.fechacreacion": "Fecha de creación",
		"encargoaudit.obsrechazo":"Observaciones rechazo",

		"encargoaudit.maintab": "Encargo",
		"encargoaudit.consulta": "Consultas",
		"encargoaudit.lote": "Lotes",
		"encargoaudit.asignacion": "Asignaciones",
		"encargoaudit.libramiento": "Libramientos",
		"encargoaudit.historico": "Histórico"
    },
    "ca": {
        "encargoaudit.idencargo": "Codi encàrrec",
		"encargoaudit.idcomprador": "Comprador",
		"encargoaudit.idmercancia": "Mercaderia",
		"encargoaudit.idterminal": "Terminal gestora",
		"encargoaudit.proveedor": "Proveïdor",
		"encargoaudit.idtransportista": "Transportista",
		"encargoaudit.idestado": "Estat",
		"encargoaudit.idmotivoanulrechazo": "Motiu anul·lació/rebuig",
		"encargoaudit.peso": "Pes",
		"encargoaudit.contrato": "Número de contracte",
		"encargoaudit.aplicacion": "Aplicació",
		"encargoaudit.referencia": "Referència",
		"encargoaudit.observaciones": "Observacions",
		"encargoaudit.fecharecogida": "Data de recollida",
		"encargoaudit.fechacreacion": "Data de creació",
		"encargoaudit.obsrechazo":"Observacions rebuig",

		"encargoaudit.maintab": "Encàrrec",
		"encargoaudit.consulta": "Consultes",
		"encargoaudit.lote": "Lots",
		"encargoaudit.asignacion": "Assignacions",
		"encargoaudit.libramiento": "Lliuraments",
		"encargoaudit.historico": "Històric"
    }
}
</i18n>
