const disableTransportistaAction = {
	id: 'disableTransportistaAction',
	selectionType: 'single',
	label: 'transportista.disabletransportista',
	functionality: 'DISABLE_TRANSPORTISTA',
	checkAvailability: function(transportista) {
		if (transportista[0] && transportista[0].indbaja === '0') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.fecbaja = this.$dateTimeUtils.getLocalDateNow();
		this.$puiEvents.$emit('pui-modalDialogForm-disable-' + model.name + '-show', row);
	}
};

const enableTransportistaAction = {
	id: 'enableTransportistaAction',
	selectionType: 'single',
	label: 'transportista.enabletransportista',
	functionality: 'ENABLE_TRANSPORTISTA',
	checkAvailability: function(transportista) {
		if (transportista[0] && transportista[0].indbaja === '1') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var idtransportista = dataRegistry.idtransportista;
			var urlController = model.url.enable + '?idtransportista=' + idtransportista;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = {};
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

export default {
	gridactions: [enableTransportistaAction, disableTransportistaAction],
	formactions: [enableTransportistaAction, disableTransportistaAction]
};
