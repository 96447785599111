<template>
	<div class="consultarecibida puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('consultarecibida.estado')" :value="getEstado"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('consultarecibida.mercancia')" :value="getMercancia"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('consultarecibida.fecharecogida')" :value="getDiaRecogida"></pui-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('consultarecibida.peso')" :value="getPesoSolicitado"></pui-field>
			</v-flex>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('consultarecibida.pesoLocalizado')" :value="getPesoLocalizado"></pui-field>
			</v-flex>
			<v-flex xs12 md4 v-if="headerOwner === 'lotegenerado'">
				<pui-field :label="this.$t('consultarecibida.referencia')" :value="getReferencia"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'consultarecibidaformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'consultarecibida'
		};
	},
	props: {
		headerOwner: {
			type: String,
			required: false
		},
		modelLink: {
			type: Object,
			required: false
		}
	},
	computed: {
		getMercancia() {
			return this.model && this.model.mercancia ? this.model.mercancia : '';
		},
		getDiaRecogida() {
			return this.model && this.model.fecharecogida
				? this.$dateTimeUtils.formatDate(this.model.fecharecogida, this.$store.getters.dateFormat)
				: '';
		},
		getEstado() {
			return this.model && this.model.estado ? this.model.estado : '';
		},
		getPesoSolicitado() {
			return this.model && this.model.peso ? this.formatNumber(this.model.peso) + ' Kg' : '0 Kg';
		},
		getPesoLocalizado() {
			return this.model && this.model.pesolocalizado ? this.formatNumber(this.model.pesolocalizado) + ' Kg' : '0 Kg';
		},
		getReferencia() {
			return this.model && this.model.referencia ? this.model.referencia : '';
		}
	},
	methods: {
		// Override
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'consultarecibida';
			// Seteo original 1 para que la relacion CONSULTA_LOTE sea correcta ya que esta tabla requiere "original 1" para obtener la relacion original
			filter.filter.rules.push({ field: 'original', op: 'eq', data: '1' });
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		}
	},
	watch: {
		model(newValue) {
			if (this.modelLink && this.modelLink.consultarecibida) this.modelLink.consultarecibida = newValue;
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"consultarecibida.mercancia": "Goods",
		"consultarecibida.fecharecogida": "Pick up date",
		"consultarecibida.estado": "Inquiry state",
		"consultarecibida.peso": "Requested weight",
		"consultarecibida.pesoLocalizado": "Localized weight",
		"consultarecibida.referencia": "Reference"
	},
	"es": {
		"consultarecibida.mercancia": "Mercancía",
		"consultarecibida.fecharecogida": "Fecha de recogida",
		"consultarecibida.estado": "Estado consulta",
		"consultarecibida.peso": "Peso solicitado",
		"consultarecibida.pesoLocalizado": "Peso localizado",
		"consultarecibida.referencia": "Referencia"
	},
	"ca": {
		"consultarecibida.mercancia": "Mercaderia",
		"consultarecibida.fecharecogida": "Data de recollida",
		"consultarecibida.estado": "Estat consulta",
		"consultarecibida.peso": "Pes sol·licitat",
		"consultarecibida.pesoLocalizado": "Pes localitzat",
		"consultarecibida.referencia": "Referència"
	}
}
</i18n>
