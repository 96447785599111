<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtctimeslotplanif.action.generateTitle')"
			:modelName="modelName"
			:dialogName="dialogNameDate"
			v-model="modalData"
			:showDialogProp="showDialogDate"
			:onOk="onOkDate"
			:widthDialog="800"
			componentHeaderName="timeslotplanifformheader"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex xs6>
						<pui-date-field
							:label="$t('vtctimeslotplanif.action.fechainicioLabel')"
							v-model="modalData.fechainicio"
							required
							:max="modalData.fechafin"
							attach="fechainicioGenerateTS"
							id="fechainicioGenerateTS"
						></pui-date-field>
					</v-flex>
					<v-flex xs6>
						<pui-date-field
							:label="$t('vtctimeslotplanif.action.fechafinLabel')"
							v-model="modalData.fechafin"
							required
							:min="modalData.fechainicio"
							attach="fechafinGenerateTS"
							id="fechafinGenerateTS"
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap v-if="!isMobile">
					<v-flex xs12 style="height: 60px"></v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="getResultTitle"
			:modelName="modelName"
			:dialogName="dialogNameResult"
			v-model="modalData"
			:showDialogProp="showDialogResult"
			:onOk="onOkResult"
			:widthDialog="900"
			componentHeaderName="timeslotplanifformheader"
		>
			<template slot="message">
				<div v-for="(detalle, index) in modalData.data" :key="index">
					<v-layout wrap :class="index === 0 ? 'resultOK' : 'resultKO'">
						<v-flex xs12>{{ detalle }}</v-flex>
					</v-layout>
				</div>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'timeslotplanifmodals',
	data() {
		return {
			modalData: {},
			dialogNameDate: 'date',
			showDialogDate: true,
			isMobile: false,
			showDialogResult: true,
			dialogNameResult: 'result'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameDate}-${this.modelName}-show`, (data) => {
				this.showDialogDate = true;
			});
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameResult}-${this.modelName}-show`, (data) => {
				this.showDialogResult = true;
			});
		},
		onOkDate(modalData) {
			let body = {
				idplanif: modalData.headerPk.idplanif,
				fechainicio: modalData.fechainicio,
				fechafin: modalData.fechafin
			};
			let urlController = this.$store.getters.getModelByName(this.modelName).url.generate;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('timeslotplanif.generate');
			this.$puiRequests.patchRequest(
				urlController,
				body,
				(response) => {
					this.showDialogDate = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					let creados = '';
					if (response && response.data) {
						var data = response.data;

						var data = response.data;
						var row = {};
						row.headerPk = modalData.headerPk;
						row.isAction = true;
						row.data = data;
						this.$puiEvents.$emit('pui-modalDialogForm-' + this.dialogNameResult + '-' + this.modelName + '-show', row);
					}
				},
				(e) => {
					this.showDialogDate = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		onOkResult(modalData) {
			this.showDialogResult = false;
		}
	},
	computed: {
		getResultTitle() {
			return this.$t('timeslotplanif.generate');
		}
	}
};
</script>
<style lang="postcss" scoped>
.resultKO {
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
}
.resultOK {
	color: rgb(108, 168, 99);
	background-color: rgba(223, 240, 216, 0.8) !important;
	font-weight: bold;
}
</style>

<i18n>
{
	"en": {
		"vtctimeslotplanif.action.generateTitle": "Generate time slotS",
		"vtctimeslotplanif.action.fechainicioLabel": "Start date",
		"vtctimeslotplanif.action.fechafinLabel": "End date",
		"vtctimeslotplanif.action.timeslotsCreados": ". A total of ",
		"vtctimeslotplanif.action.timeslots": " timeslots"
	},
	"es": {
		"vtctimeslotplanif.action.generateTitle": "Generar time slots",
		"vtctimeslotplanif.action.fechainicioLabel": "Fecha inicio",
		"vtctimeslotplanif.action.fechafinLabel": "Fecha fin",
		"vtctimeslotplanif.action.timeslotsCreados": ". Se han creado un total de ",
		"vtctimeslotplanif.action.timeslots": " timeslots"

	},
	"ca": {
		"vtctimeslotplanif.action.generateTitle": "Generar time slots",
		"vtctimeslotplanif.action.fechainicioLabel": "Data inici",
		"vtctimeslotplanif.action.fechafinLabel": "Data fi",
		"vtctimeslotplanif.action.timeslotsCreados": ". S'han creat un total de ",
		"vtctimeslotplanif.action.timeslots": " timeslots"
	}
}
</i18n>
