<template>
	<div>
		<usuario-aplicacion-modals :modelName="model"></usuario-aplicacion-modals>
		<pui-datatable
			:modelName="model"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			:showDeleteBtn="false"
			showFilterListBtn
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import UsuarioTerminalActions from './UsuarioTerminalActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuarioterminalgrid',
	components: {
		UsuarioAplicacionModals
	},
	data() {
		return {
			model: 'usuarioterminal',
			actions: UsuarioTerminalActions.gridactions,
			columnDefs: {
				disableddate: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return registry.disabled !== 1;
		}
	}
};
</script>

<style scoped></style>

<i18n>
{
	"en": {
		"usuarioaplicacion" :"Users",
		"grid.usuarioterminal.usr": "User",
		"grid.usuarioterminal.name": "Name",
		"grid.usuarioterminal.email": "Email",
		"grid.usuarioterminal.disableddate": "Disabled Date",
		"grid.usuarioterminal.disabledmask": "Disabled",
		"grid.usuarioterminal.language": "Language",
		"grid.usuarioterminal.idterminal": "Freight forwarder code",
		"grid.usuarioterminal.fechacreacion":"Creation date",
		"grid.usuarioterminal.usuariocreacion":"Creation user"
	},
	"es": {
		"usuarioaplicacion" :"Usuarios",
		"grid.usuarioterminal.usr": "Usuario",
		"grid.usuarioterminal.name": "Nombre",
		"grid.usuarioterminal.email": "Email",
		"grid.usuarioterminal.disableddate": "Fecha desactivación",
		"grid.usuarioterminal.disabledmask": "Activo",
		"grid.usuarioterminal.language": "Idioma",
		"grid.usuarioterminal.idterminal": "Código terminal",
		"grid.usuarioterminal.fechacreacion":"Fecha creación",
		"grid.usuarioterminal.usuariocreacion":"Usuario alta"

	},
	"ca": {
		"usuarioaplicacion" :"Usuaris",
		"grid.usuarioterminal.usr": "Usuari",
		"grid.usuarioterminal.name": "Nom",
		"grid.usuarioterminal.email": "Email",
		"grid.usuarioterminal.disableddate": "Data Desactivació",
		"grid.usuarioterminal.disabledmask": "Actiu",
		"grid.usuarioterminal.language": "Idioma",
		"grid.usuarioterminal.idterminal": "Codi terminal",
		"grid.usuarioterminal.fechacreacion":"Data creació",
		"grid.usuarioterminal.usuariocreacion":"Usuari alta"
	}
}
</i18n>
