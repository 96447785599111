<template>
	<div>
		<punto-carga-modals :modelName="model"></punto-carga-modals>
		<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuntoCargaActions from './PuntoCargaActions';
import PuntoCargaModals from './PuntoCargaModals';

export default {
	name: 'puntocargagrid',
	components: {
		PuntoCargaModals
	},
	data() {
		return {
			model: 'puntocarga',
			actions: PuntoCargaActions.gridactions,
			columnDefs: {
				fecbaja: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.puntocarga.idpuntocarga": "Internal code",
        "grid.puntocarga.idterminal": "Terminal code",
        "grid.puntocarga.codigo": "Code",
        "grid.puntocarga.descripcion": "Description",
        "grid.puntocarga.indpublico": "Ind. Visible for the subtraction of terminals",
        "grid.puntocarga.publico": "Visible for the subtraction of terminals",
		"grid.puntocarga.fechacreacion":"Creation date",
		"grid.puntocarga.fecbaja":"Disabled date"
    },
    "es": {
        "grid.puntocarga.idpuntocarga": "Código interno",
        "grid.puntocarga.idterminal": "Código terminal",
        "grid.puntocarga.codigo": "Código",
        "grid.puntocarga.descripcion": "Descripción",
        "grid.puntocarga.indpublico": "Ind. Visible para el resto de terminales",
        "grid.puntocarga.publico": "Visible para el resto de terminales",
		"grid.puntocarga.fechacreacion":"Fecha creación",
		"grid.puntocarga.fecbaja":"Fecha baja"
    },
    "ca": {
        "grid.puntocarga.idpuntocarga": "Codi intern",
        "grid.puntocarga.idterminal": "Codi terminal",
        "grid.puntocarga.codigo": "Codi",
        "grid.puntocarga.descripcion": "Descripció",
        "grid.puntocarga.indpublico": "Ind. Visible per a la resta de terminals",
        "grid.puntocarga.publico": "Visible per a la resta de terminals",
		"grid.puntocarga.fechacreacion":"Data creació",
		"grid.puntocarga.fecbaja":"Data baixa"
    }
}
</i18n>
