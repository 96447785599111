<template>
	<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn> </pui-datatable>
</template>

<script>
/* eslint-disable */
import DocumentacionActions from './DocumentacionActions';

export default {
	name: 'documentaciongrid',
	data() {
		return {
			model: 'documentacion',
			actions: DocumentacionActions.gridactions,
			columnDefs: {
				tipodocumento: {
					render: (data, type, row, meta) => {
						if (data === null) {
							return ``;
						}
						return data;
					}
				}
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.documentacion.iddoc": "Code",
        "grid.documentacion.nombre": "Name",
		"grid.documentacion.url": "URL",
		"grid.documentacion.idtipousuario": "User type code",
		"grid.documentacion.tipousuario": "User type",
        "grid.documentacion.observaciones": "Comments",
		"grid.documentacion.fecalta":"Creation date",
		"grid.documentacion.usralta":"Creation user",
		"grid.documentacion.fecmodif":"Editation date",
		"grid.documentacion.usrmodif":"Editation user",
		"grid.documentacion.idtipodocumento":"document type code",
		"grid.documentacion.tipodocumento":"Docuemtn type",
		"grid.documentacion.indpublicado":"Ind. Publish",
		"grid.documentacion.publicado":"Publish"
    },
    "es": {
        "grid.documentacion.iddoc": "Código",
        "grid.documentacion.nombre": "Nombre",
		"grid.documentacion.url": "URL",
		"grid.documentacion.idtipousuario": "Código tipo usuario",
		"grid.documentacion.tipousuario": "Tipo usuario",
        "grid.documentacion.observaciones": "Observaciones",
		"grid.documentacion.fecalta":"Fecha creación",
		"grid.documentacion.usralta":"Usuario creación",
		"grid.documentacion.fecmodif":"Fecha modificación",
		"grid.documentacion.usrmodif":"Usuario modificación",
		"grid.documentacion.idtipodocumento":"Código tipo documento",
		"grid.documentacion.tipodocumento":"Tipo documento",
		"grid.documentacion.indpublicado":"Ind. Publicado",
		"grid.documentacion.publicado":"Publicado"
    },
    "ca": {
        "grid.documentacion.iddoc": "Codi",
        "grid.documentacion.nombre": "Nom",
		"grid.documentacion.url": "URL",
		"grid.documentacion.idtipousuario": "Codi tipus usuari",
		"grid.documentacion.tipousuario": "Tipus usuari",
        "grid.documentacion.observaciones": "Observacions",
		"grid.documentacion.fecalta":"Data creació",
		"grid.documentacion.usralta":"Usuari creació",
		"grid.documentacion.fecmodif":"Data modificació",
		"grid.documentacion.usrmodif":"Usuari modificació",
		"grid.documentacion.idtipodocumento":"Codi tipus document",
		"grid.documentacion.tipodocumento":"Tipus document",
		"grid.documentacion.indpublicado":"Ind. Publicat",
		"grid.documentacion.publicado":"Publicat"
    }
}
</i18n>
