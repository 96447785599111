<template>
	<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn> </pui-datatable>
</template>

<script>
/* eslint-disable */
import NovedadesActions from './NovedadesActions';

export default {
	name: 'novedadesgrid',
	data() {
		return {
			model: 'novedades',
			actions: NovedadesActions.gridactions,
			columnDefs: {
				fecpublicacion: { withoutHours: true }
			}
		};
	}
};
</script>
