<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:isEditableRegistry="isEditableRegistry"
			:showCreateBtn="showCreateBtn"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import LotegeneradoActions from './LotegeneradoActions';

export default {
	name: 'lotegeneradogrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'lotegenerado',
			actions: LotegeneradoActions.gridactions
		};
	},
	methods: {
		isEditableRegistry(registry) {
			if (registry.codestado === 'ET') {
				return true;
			} else {
				return false;
			}
		}
	},
	created() {
		if (this.masterDetail) {
			let thisModel = this.$store.getters.getModelByName(this.model);
			if (thisModel) {
				let parentModelPk = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
				thisModel.parentModelName = this.parentModelName;
				thisModel.parentModelPk = parentModelPk.pk;
				thisModel.fromGrid = true;
			}
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
     "en": {
		"grid.lotegenerado.idlote": "Lot code",
		"grid.lotegenerado.idpuntocarga": "Loading point code",
		"grid.lotegenerado.puntocarga": "Loading point",
		"grid.lotegenerado.pesolocalizado": "Located weight (kg)",
		"grid.lotegenerado.observaciones": "Comments",
		"grid.lotegenerado.fechacreacion":"Creation date"
    },
    "es": {
		"grid.lotegenerado.idlote": "Código lote",
		"grid.lotegenerado.idpuntocarga": "Código punto de carga",
		"grid.lotegenerado.puntocarga": "Punto de carga",
		"grid.lotegenerado.pesolocalizado": "Peso localizado (kg)",
		"grid.lotegenerado.observaciones": "Observaciones",
		"grid.lotegenerado.fechacreacion":"Fecha creación"
	},
    "ca": {
		"grid.lotegenerado.idlote": "Codi lot",
		"grid.lotegenerado.idpuntocarga": "Codi punt de càrrega",
		"grid.lotegenerado.puntocarga": "Punt de Càrrega",
		"grid.lotegenerado.pesolocalizado": "Pes localitzat (kg)",
		"grid.lotegenerado.observaciones": "Observacions",
		"grid.lotegenerado.fechacreacion":"Data creació"
	}
}
</i18n>
