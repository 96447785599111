<template>
	<div class="pui-form">
		<v-form class="pui-form-layout mb-4 pb-4 mt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs12 md3>
					<pui-text-field
						v-if="modelLoaded"
						:label="$t('conductorbloqueado.nifconductor')"
						v-model="model.nifconductor"
						maxlength="20"
						required
						:disabled="formDisabled"
						labelColumnStyles="xs5"
						:rules="nifRules"
					></pui-text-field>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ConductorbloqueadoActions from './ConductorbloqueadoActions';

export default {
	name: 'conductorbloqueado',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'conductorbloqueado',
			actions: ConductorbloqueadoActions.formactions,
			nifRules: [(v) => !v || this.isSomeValidIdentityDocument(v) || this.$t('conductorbloqueado.validate.nifconductor')]
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	},
	methods: {
		validateIdentityDocument(numdoc, letter, doctype) {
			if (!numdoc || !doctype) return false;

			if (doctype.toLowerCase() === 'cif') {
				var CIF = numdoc;
				var v1 = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
				var v2 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
				numdoc = CIF.toUpperCase();
				var ultdigito = CIF.substr(8, 1);
				var temp = 0;
				var temp2 = 0;

				if (!/^[A-Z]\d{7}([A-Z]|\d)$/.test(CIF)) {
					return false;
				}

				for (var i = 2; i <= 6; i += 2) {
					temp = temp + v1[parseInt(CIF.substr(i - 1, 1), 10)];
					temp = temp + parseInt(CIF.substr(i, 1), 10);
				}
				temp = temp + v1[parseInt(CIF.substr(7, 1), 10)];
				temp = 10 - (temp % 10);

				//puede ser un nº o una letter
				temp2 = v2[temp - 1];

				if (temp === 10) {
					if (!(ultdigito === 'J') && !(ultdigito === '0')) {
						return false;
					}
				} else {
					if (ultdigito !== temp.toString() && ultdigito !== temp2) {
						return false;
					}
				}
			}

			if (doctype.toLowerCase() === 'nif') {
				var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
				var nifRexp = /^[0-9]{8}$/i;
				var str = numdoc.toUpperCase();

				if (!nifRexp.test(str)) return false;

				if (!letter) return false;
				var charIndex = parseInt(str.substr(0, 8)) % 23;

				if (validChars.charAt(charIndex) === letter) return true;

				return false;
			}
			if (doctype.toLowerCase() === 'nie') {
				validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
				var nieRexp = /^[XYZ]{1}[0-9]{7}$/i;
				str = numdoc.toUpperCase();

				if (!nieRexp.test(str)) return false;

				var nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

				if (!letter) return false;
				charIndex = parseInt(nie.substr(0, 8)) % 23;

				if (validChars.charAt(charIndex) === letter) return true;

				return false;
			}
			return true;
		},
		isSomeValidIdentityDocument(numdoc) {
			if (!numdoc || numdoc.length != 9) return false;
			console.log(numdoc);
			//let validCIF = this.isValidCIF(numdoc);
			let validNIF = this.isValidNIF(numdoc);
			let validNIE = this.isValidNIE(numdoc);

			return validNIF || validNIE;
		},
		isValidCIF(numdoc) {
			if (!numdoc || numdoc.length != 9) return false;
			return this.validateIdentityDocument(numdoc, null, 'cif');
		},
		isValidNIF(numdoc) {
			if (!numdoc || numdoc.length != 9) return false;
			return this.validateIdentityDocument(numdoc.substr(0, 8), numdoc.substr(8, 1), 'nif');
		},
		isValidNIE(numdoc) {
			if (!numdoc || numdoc.length != 9) return false;
			return this.validateIdentityDocument(numdoc.substr(0, 8), numdoc.substr(8, 1), 'nie');
		}
	}
};
</script>
<style scoped></style>
