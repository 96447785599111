<template>
	<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */
import PuntoCargaAptActions from './PuntoCargaAptActions';

export default {
	name: 'puntocargaaptgrid',
	data() {
		return {
			model: 'puntocargaapt',
			actions: PuntoCargaAptActions.gridactions,
			columnDefs: {
				fecbaja: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.puntocargaapt.idpuntocarga": "Internal code",
		"grid.puntocargaapt.idterminal": "Terminal code",
		"grid.puntocargaapt.terminal": "Terminal",
        "grid.puntocargaapt.codigo": "Code",
        "grid.puntocargaapt.descripcion": "Description",
        "grid.puntocargaapt.indpublico": "Ind. Visible for the subtraction of terminals",
        "grid.puntocargaapt.publico": "Visible for the subtraction of terminals",
		"grid.puntocargaapt.fechacreacion":"Creation date",
		"grid.puntocargaapt.fecbaja":"Disabled date"
    },
    "es": {
        "grid.puntocargaapt.idpuntocarga": "Código interno",
		"grid.puntocargaapt.idterminal": "Código terminal",
		"grid.puntocargaapt.terminal": "Terminal",
        "grid.puntocargaapt.codigo": "Código",
        "grid.puntocargaapt.descripcion": "Descripción",
        "grid.puntocargaapt.indpublico": "Ind. Visible para el resto de terminales",
        "grid.puntocargaapt.publico": "Visible para el resto de terminales",
		"grid.puntocargaapt.fechacreacion":"Fecha creación",
		"grid.puntocargaapt.fecbaja":"Fecha baja"
    },
    "ca": {
        "grid.puntocargaapt.idpuntocarga": "Codi intern",
		"grid.puntocargaapt.idterminal": "Codi terminal",
		"grid.puntocargaapt.terminal": "Terminal",
        "grid.puntocargaapt.codigo": "Codi",
        "grid.puntocargaapt.descripcion": "Descripció",
        "grid.puntocargaapt.indpublico": "Ind. Visible per a la resta de terminals",
        "grid.puntocargaapt.publico": "Visible per a la resta de terminals",
		"grid.puntocargaapt.fechacreacion":"Data creació",
		"grid.puntocargaapt.fecbaja":"Data baixa"
    }
}
</i18n>
