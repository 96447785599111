<template>
	<div class="userpanel">
		<v-layout align-center>
			<v-flex xs1></v-flex>
			<v-flex :class="getIdtranspCols">
				<img id="logotipoapp" src="../../assets/images/LogoAPT.png" alt="agros" />
			</v-flex>
			<v-flex xs1 v-show="isDevelopmentEnvironment">
				<img id="logotipopruebas" src="../../assets/images/pruebas.png" alt="test" />
			</v-flex>
			<v-flex xs2>
				<div class="avisoCau">
					{{ horarioCau }}
					<br />
					<a :href="mailCau" v-if="textoMailCau != ''">{{ textoMailCau }}</a>
					<br />
					{{ telfCau }}
				</div>
			</v-flex>
			<v-flex xs1 v-show="showDocumentacion">
				<v-btn text class="userPanelDoc" @click="goDocumentacion()">
					<img id="logotipoapp" src="../../assets/images/documentacion.png" alt="documentacion" />
					{{ $t('documentacion') }}
				</v-btn>
			</v-flex>
			<v-flex xs1></v-flex>
			<v-flex xs1 class="text-right">
				<span class="userPanelName text-right" @click="showSettings()">{{ userName }}</span>
			</v-flex>
			<v-flex xs2>
				<v-btn text class="userPanelElement" @click="closeSession()">{{ translateMenu2 }}</v-btn>
			</v-flex>
			<v-flex xs1 class="userPanelVersion">{{ translateVersion }}</v-flex>
		</v-layout>
		<div class="userPanel1 elevation-1" v-show="showPanel" :class="{ 'userPanel--mobile': isMobile }" @click="clickDivPanel()">
			<v-layout>
				<v-flex sm12>
					<v-card class="pl-3 pr-3 pt-2 pb-2">
						<div class="userPanelName">{{ userName }}</div>
						<v-btn text right class="userPanelElement" @click="goSettings()">{{ translateMenu1 }}</v-btn>
						<div v-show="lastLoginTime">
							<div class="userPanelName">{{ $t('lastlogin') }}</div>
							<div class="userPanelLast">{{ lastLoginTime }}</div>
							<div class="userPanelLast">{{ $t('ip') }}: {{ lastLoginIp }}</div>
						</div>
						<a href="#" v-if="showLegalRequirements" @click="dialogs.legalRequirements.show = true">{{ $t('legalrequirements') }}</a>
					</v-card>
				</v-flex>
			</v-layout>
			<pui-modal-dialog
				v-if="dialogs.legalRequirements.show"
				:titleText="translateLegal"
				messageText
				:okText="dialogs.legalRequirements.okText"
				:widthDialog="dialogs.legalRequirements.width"
				dialogName="legalRequirementsDialog"
				ref="legalRequirementsDialog"
			>
				<template slot="message">
					<pre class="messageStyle">{{ dialogs.legalRequirements.messageText }}</pre>
				</template>
			</pui-modal-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'appcontainerheader',
	data: () => ({
		idTool: 'userpanel',
		showPanel: false,
		enterShowPanel: false,
		userName: '',
		version: '---',
		lastLoginTime: '',
		lastLoginIp: '',
		loginUrlController: '/login/signout',
		userUrlController: '/usuarioaplicacionsettings',
		isMobile: false,
		showLegalRequirements: false,
		isDevelopmentEnvironment: false,
		dialogs: {
			legalRequirements: {
				urlController: '/puivariable/getApplicationLegalText',
				show: false,
				titleText: '',
				messageText: '',
				okText: 'OK',
				width: 650
			}
		},
		documentacioncabUrlController: '/documentacioncab',
		showDocumentacion: false,
		mailCau: '',
		textoMailCau: '',
		telfCau: '',
		urlControllerCau: '/usuarioaplicacion/getHorarioCau',
		horarioCau: ''
	}),
	methods: {
		goSettings() {
			const params = {
				router: this.$router,
				url: `${this.userUrlController}`,
				params: null,
				query: {},
				replace: true
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
			this.showPanel = false;
		},
		closePanel() {
			if (this.showPanel) {
				this.showPanel = false;
			}
		},
		showSettings() {
			this.showPanel = !this.showPanel;
			this.enterShowPanel = true;
		},
		clickDivPanel() {
			this.enterShowPanel = true;
		},
		closeSession() {
			this.$store.dispatch('puiLoginDoLogout', { router: this.$router, vue: this });
		},
		getBrowserLang() {
			var navigatorLang = navigator.language || navigator.userLanguage;
			if (navigatorLang.length > 2) {
				navigatorLang = navigatorLang.substring(0, 2);
			}
			return navigatorLang; //this.$store.getters.getAppLangs.includes(navigatorLang) ? navigatorLang : this.$store.getters.getDefaultAppLang;
		},
		changeAppLang(lang) {
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		},
		getLegalRequirements() {
			this.$puiRequests.getRequest(
				this.dialogs.legalRequirements.urlController,
				null,
				response => {
					if (response.data && response.data !== '-') {
						this.dialogs.legalRequirements.messageText = response.data;
						this.showLegalRequirements = true;
					}
				},
				error => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		goDocumentacion() {
			const params = {
				router: this.$router,
				url: `${this.documentacioncabUrlController}`,
				params: null,
				query: {},
				replace: true
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
			this.showPanel = false;
		},
		getEnlaceDocumentacion() {
			this.$puiRequests.getRequest(
				this.documentacioncabUrlController + '/getEnlaceDocumentacion',
				null,
				response => {
					if (response.data) {
						this.showDocumentacion = response.data;
					}
				},
				error => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getMailCau() {
			this.$puiRequests.getRequest(
				'/usuarioaplicacion/getMailCau',
				null,
				response => {
					if (response.data) {
						if (response.data !== '-') {
							this.textoMailCau = response.data;
							this.mailCau = 'mailto:' + this.textoMailCau;
						}
					}
				},
				error => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getTelfCau() {
			this.$puiRequests.getRequest(
				'/usuarioaplicacion/getTelfCau',
				null,
				response => {
					if (response.data) {
						if (response.data !== '-') {
							this.telfCau = response.data;
						}
					}
				},
				error => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getDevelopement() {
			this.$puiRequests.getRequest(
				'/puivariable/isDevelopmentEnvironment',
				null,
				response => {
					if (response.data) {
						this.isDevelopmentEnvironment = response.data;
					}
				},
				error => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getHorarioCau() {
			this.$puiRequests.getRequest(
				this.urlControllerCau,
				null,
				response => {
					if (response.data) {
						if (response.data !== '-') {
							this.horarioCau = response.data;
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		}
	},
	computed: {
		translateMenu1() {
			return this.$t('personalSettings');
		},
		translateMenu2() {
			return this.$t('logOut');
		},
		translateVersion() {
			return this.$t('version') + ' ' + this.version;
		},
		translateLegal() {
			return this.$t('legalrequirements');
		},
		getTokenKey() {
			var session = this.$store.getters.getSession;
			return session.keepSessionAliveInfoKey;
		},
		getIdtranspCols: function() {
			var tamanyo = '4';
			if (this.isDevelopmentEnvironment && this.showDocumentacion) {
				tamanyo = '2';
			} else if (!this.isDevelopmentEnvironment && this.showDocumentacion) {
				tamanyo = '3';
			} else if (this.isDevelopmentEnvironment && !this.showDocumentacion) {
				tamanyo = '3';
			}
			return 'xs' + tamanyo;
		}
	},
	created() {
		this.isMobile = this.$store.getters.isMobile;
		var session = this.$store.getters.getSession;
		this.version = this.$store.getters.appVersion;
		this.userName = session.sub || session.name;
		//this.isDevelopmentEnvironment = this.$store.getters.isDevelopmentEnvironment;
		//const doc = this.$store.getters.getModelByName('documentacioncab').functionalities;
		//this.showDocumentacion = doc !== null && doc !== undefined && doc !== '';

		const userLastLoginTime = this.$store.getters.getUserLastLoginTime(this);
		if (userLastLoginTime) {
			this.lastLoginTime = userLastLoginTime;
		}
		const userLastLoginIp = this.$store.getters.getUserLastLoginIp;
		if (userLastLoginIp) {
			this.lastLoginIp = userLastLoginIp;
		}
		this.$puiEvents.$on('onPui-click-mainContainer', () => {
			if (!this.enterShowPanel) {
				this.showPanel = false;
			}
			this.enterShowPanel = false;
		});
		this.$puiEvents.$on('pui-modalDialog-legalRequirementsDialog-ok', () => {
			this.dialogs.legalRequirements.show = false;
		});

		this.getLegalRequirements();
		this.getEnlaceDocumentacion();
		this.getMailCau();
		this.getTelfCau();
		this.getHorarioCau();
		this.getDevelopement();
	},
	beforeDestroy() {
		this.$puiEvents.$off('pui-modalDialog-legalRequirementsDialog-ok');
		this.$puiEvents.$off('onPui-click-mainContainer');
	}
};
</script>

<style lang="postcss" scoped>
.userpanel {
	position: relative;
	width: 100%;
	top: -20px;
}
.userPanelName {
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.6px;
	color: var(--N-400);
	padding-top: 1px;
	padding-left: 5px;
}
.userPanelElement {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--N-500);
	padding-top: 1px;
	padding-left: 15px;
}
.userPanelElement:hover {
	cursor: pointer;
}
.userPanelName:hover {
	cursor: pointer;
}
.userPanelVersion {
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: var(--N-200);
	padding-top: 1px;
	padding-left: 5px;
}
.userPanel1 {
	background-color: var(--N-0);
	width: 200px;
	position: absolute;
	top: 80px;
	right: 200px;
	min-height: 30px;
	z-index: 200;
	border-radius: 5px;
	&--mobile {
		bottom: auto;
		left: auto;
		top: 50px;
		right: 15px;
	}
}
.userPanelLast {
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	text-align: right;
	color: var(--N-200);
	padding-left: 15px;
}
.messageStyle {
	line-height: 20px;
	white-space: pre-wrap;
}
.userPanelDoc {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--N-500);
	padding-top: 1px;
}
.userPanelDoc:hover {
	cursor: pointer;
}
.avisoCau {
	position: relative;
	color: rgb(0, 0, 0);
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	top: 0px;
}
</style>
<i18n>
{
	"en": {
		"personalSettings": "Personal settings",
		"logOut": "Close session",
		"version": "v",
		"lastlogin": "Last login:",
		"legalrequirements": "Legal requirements",
		"logoutSuccess": "The log out is done",
		"logoutError": "Doing log out",
		"titleLogoutError":"Error",
		"ip":"IP",
		"documentacion":"Documentation"
	},
	"es": {
		"personalSettings": "Configuración personal",
		"logOut": "Cerrar sesión",
		"version": "v",
		"lastlogin": "Último acceso:",
		"legalrequirements": "Obligaciones legales",
		"logoutSuccess": "La sesión se ha cerrado correctamente",
		"logoutError": "Cerrando la sesión",
		"titleLogoutError":"Error",
		"ip":"IP",
		"documentacion":"Documentación"
	},
	"ca": {
		"personalSettings": "Configuració personal",
		"logOut": "Tancar sessió",
		"version": "v",
		"lastlogin": "Últim accés:",
		"legalrequirements": "Obligacions legals",
		"logoutSuccess": "La sessió s'ha tancat correctament",
		"logoutError": "Tancant la sessió",
		"titleLogoutError":"Error",
		"ip":"IP",
		"documentacion":"Documentació"
	}
}
</i18n>
