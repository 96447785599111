<template>
	<div>
		<motivo-anul-rech-modals :modelName="model"></motivo-anul-rech-modals>
		<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import MotivoAnulRechActions from './MotivoAnulRechActions';
import MotivoAnulRechModals from './MotivoAnulRechModals';

export default {
	name: 'motivoanulrechgrid',
	components: {
		MotivoAnulRechModals
	},
	data() {
		return {
			model: 'motivoanulrech',
			actions: MotivoAnulRechActions.gridactions
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.motivoanulrech.entidad": "Entity",
        "grid.motivoanulrech.tipo": "State",
        "grid.motivoanulrech.codigo": "Code",
        "grid.motivoanulrech.descripcion": "Description",
		"grid.motivoanulrech.codestado": "Cod. State",
		"grid.motivoanulrech.fechacreacion":"Creation date"
    },
    "es": {
        "grid.motivoanulrech.entidad": "Entidad",
        "grid.motivoanulrech.tipo": "Estado",
        "grid.motivoanulrech.codigo": "Código",
        "grid.motivoanulrech.descripcion": "Descripción",
		"grid.motivoanulrech.codestado": "Cod. Estado",
		"grid.motivoanulrech.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.motivoanulrech.entidad": "Entitat",
        "grid.motivoanulrech.tipo": "Estat",
        "grid.motivoanulrech.codigo": "Codi",
        "grid.motivoanulrech.descripcion": "Descripció",
		"grid.motivoanulrech.codestado": "Cod. Estat",
		"grid.motivoanulrech.fechacreacion":"Data creació"
    }
}
</i18n>
