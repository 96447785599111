<template>
	<div class="aviso" id="avisoDIV">
		<v-card-actions id="accept-rules" :class="($store.getters.isMobile ? 'pl-3 pr-3' : 'pl-5 pr-5') + ' pt-0 pb-0'">
			<div v-if="showCheck">
				<pui-checkbox
					v-model="aceptadasNormativa"
					:trueValue="1"
					:falseValue="0"
					class="userCheckLogin"
					style="margin-left: 0px !important"
					id="aceptadaNormativaCheck"
				>
				</pui-checkbox>
				<label @click="aceptadasNormativa = aceptadasNormativa ? 0 : 1"> {{ $t('texto.check') }} </label
				><a :href="dialogs.accessRequirements.messageText" target="_blank">{{ $t('texto.condiciones') }}</a>
			</div>
		</v-card-actions>
		<div style="font-size: 14px; font-weight: bold;text-align: center;">
			{{ horarioCau }}
			<br />
			<a :href="mailCau" v-if="textoMailCau != ''">{{ textoMailCau }}</a>
			<br />
			{{ telfCau }}
		</div>
		<div v-if="avisoText" class="avisoColor" :class="{ 'avisoColor--mobile': $store.getters.isMobile }">{{ avisoText }}</div>
	</div>
</template>

<script>
export default {
	name: 'apploginactions',
	data() {
		return {
			avisoText: null,
			urlControllerAviso: '/usuarioaplicacion/getAvisoLoginText',
			urlControllerShowCheck: '/usuarioaplicacion/getShowAccessLogin',
			urlControllerCau: '/usuarioaplicacion/getHorarioCau',
			aceptadasNormativa: 0,
			showCheck: false,
			dialogs: {
				accessRequirements: {
					urlController: '/usuarioaplicacion/getAccessLegalText',
					show: false,
					titleText: '',
					messageText: ''
				}
			},
			isDevelopmentEnvironment: false,
			mailCau: '',
			textoMailCau: '',
			telfCau: '',
			horarioCau: ''
		};
	},
	methods: {
		getAviso() {
			this.$puiRequests.getRequest(
				this.urlControllerAviso + '?language=' + this.getBrowserLang(),
				null,
				response => {
					if (response.data) {
						if (response.data !== '-' && response.data.trim().length !== 0) {
							this.avisoText = response.data;
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		},
		getAccess() {
			this.$puiRequests.getRequest(
				this.dialogs.accessRequirements.urlController,
				null,
				response => {
					if (response.data) {
						if (response.data !== '-' && response.data.trim().length !== 0) {
							this.dialogs.accessRequirements.messageText = response.data;
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		},
		getShowAccess() {
			this.$puiRequests.getRequest(
				this.urlControllerShowCheck,
				null,
				response => {
					if (response.data) {
						if (response.data === '1') {
							this.showCheck = true;
							setTimeout(() => {
								const keepSessionContainer = document.querySelector('#puilogin > .v-card__actions');
								keepSessionContainer.parentElement.insertBefore(document.getElementById('accept-rules'), keepSessionContainer);
							}, 0);
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		},
		setStyle() {
			if (this.showCheck) {
				var offsets = document.getElementById('password')?.getBoundingClientRect();
				var offsetsX = document.getElementById('keepSession')?.getBoundingClientRect();
				var offsetsDiv = document.getElementById('avisoDIV')?.getBoundingClientRect();
				var top = offsets.top;
				var left = offsetsX.left;
				top = top - offsetsDiv.top + document.getElementById('keepSession').offsetHeight + 10;
				var leftAPoner = left - offsetsDiv.left - 8;
				return '{z-index: 5; position: relative; left: ' + leftAPoner + 'px; top: ' + top + 'px;}';
			} else {
				return '';
			}
		},
		getMailCau() {
			this.$puiRequests.getRequest(
				'/usuarioaplicacion/getMailCau',
				null,
				response => {
					if (response.data) {
						if (response.data !== '-' && response.data.trim().length !== 0) {
							this.textoMailCau = response.data;
							this.mailCau = 'mailto:' + this.textoMailCau;
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		},
		getTelfCau() {
			this.$puiRequests.getRequest(
				'/usuarioaplicacion/getTelfCau',
				null,
				response => {
					if (response.data) {
						if (response.data !== '-' && response.data.trim().length !== 0) {
							this.telfCau = response.data;
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		},
		getHorarioCau() {
			this.$puiRequests.getRequest(
				this.urlControllerCau + '?language=' + this.getBrowserLang(),
				null,
				response => {
					if (response.data) {
						if (response.data !== '-' && response.data.trim().length !== 0) {
							this.horarioCau = response.data;
						}
					}
				},
				error => {
					this.$puiNotify.error(error);
				}
			);
		},
		getBrowserLang() {
			var navigatorLang = navigator.language || navigator.userLanguage;
			if (navigatorLang.length > 2) {
				navigatorLang = navigatorLang.substring(0, 2);
			}
			return navigatorLang; //this.$store.getters.getAppLangs.includes(navigatorLang) ? navigatorLang : this.$store.getters.getDefaultAppLang;
		}
	},
	created() {
		this.isDevelopmentEnvironment = this.$store.getters.isDevelopmentEnvironment;
		this.getAviso();
		this.getShowAccess();
		this.getAccess();
		this.getMailCau();
		this.getTelfCau();
		this.getHorarioCau();
		this.$puiEvents.$on('pui-modalDialog-accessRequirementsDialog-ok', () => {
			this.dialogs.accessRequirements.show = false;
		});
	}
};
</script>

<style lang="postcss" scoped>
.aviso {
	margin: auto;
}
.avisoColor {
	position: relative;
	color: rgb(169, 68, 66);
	background-color: rgba(242, 222, 222, 0.8) !important;
	font-weight: bold;
	font-size: 20px;
	text-align: center;
	top: -620px;
	&--mobile {
		position: absolute;
		top: 10px;
		left: 0px;
		width: 100%;
	}
}
.labelCheckLogin {
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.6px;
	color: var(--N-400);
	padding-top: 1px;
	display: inline-block;
	display: -moz-inline-stack;
}
.userCheckLogin {
	display: inline-block;
	width: 23px;
}
.messageStyleLogin {
	line-height: 20px;
	white-space: pre-wrap;
}
.forgetPassword[data-v-232982ba] {
	margin-left: auto;
	margin-right: 40px;
	margin-top: auto;
	margin-bottom: auto;
}
.forgetPassword[data-v-b0bbe0ac] {
	margin-left: auto;
	margin-right: 40px;
	margin-top: auto;
	margin-bottom: auto;
}
</style>

<i18n>
{
	"en": {
		"texto.check":"I have read and accept",
		"texto.condiciones":"Terms of use",
		"texto.cerrar":"Close"
	},
	"es": {
		"texto.check":"He leído y acepto",
		"texto.condiciones":"Condiciones de uso",
		"texto.cerrar":"Cerrar"
	},
	"ca": {
		"texto.check":"He llegit i acepte",
		"texto.condiciones":"Condicions d'us",
		"texto.cerrar":"Cerrar"
	}
}
</i18n>
