<template>
	<div class="divlibramiento">
		<pui-form-header showHeader>
			<asignaciontransformheader></asignaciontransformheader>
		</pui-form-header>
		<v-form class="pui-asignaciontransform mb-4 pb-4 mt-3" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12>
					<v-layout wrap class="pui-form-layout" v-show="false">
						<v-flex xs12 md8>
							<pui-number-field
								:label="$t('asignacion.pesoasignado')"
								v-model="model.pesoasignado"
								decimals="0"
								max="8000000"
								required
								disabled
								labelColumnStyles="xs12 md2"
								valueColumnStyles="xs12 md4"
								id="pesoasignado"
							></pui-number-field>
						</v-flex>
					</v-layout>
					<div v-for="(reasignacion, index2) in model.reasignaciones" :key="index2">
						<re-asignacion-trans-core-form
							:model="reasignacion"
							:formDisabled="formDisabled"
							:modelLoaded="modelLoaded"
							:parentlist="model.reasignaciones"
							:indice="index2"
							:method="method"
							:parentModel="model"
							:fixedFilterTransportista="fixedFilterTransportista"
						></re-asignacion-trans-core-form>
					</div>
					<v-layout wrap>
						<v-flex xs12>
							<v-btn depressed color="secondary" class="elevation-0" @click.native="addTransportista()">{{
								this.$t('addTransportista')
							}}</v-btn>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 style="height: 40px"></v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ReAsignacionTransCoreForm from './ReAsignacionTransCoreForm';
export default {
	name: 'asignaciontrans',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'asignaciontrans',
			fixedFilterTransportista: null
		};
	},
	components: { ReAsignacionTransCoreForm },
	methods: {
		// override
		afterGetData() {
			this.model.reasignaciones.push({});
			this.fixedFilterTransportista = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'idtransportista', op: 'ne', data: this.model.idtransportista },
					{ field: 'indbaja', op: 'eq', data: '0' }
				]
			};
		},
		addTransportista() {
			this.model.reasignaciones.push({});
		},
		save(shouldStayInPage) {
			this.beforeSave && this.beforeSave();
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				return;
			}

			this.reassign(shouldStayInPage, this.modelDependency.url.reassign);
		},
		reassign(shouldStayInPage, url) {
			this.$puiRequests.postRequest(
				url + '?idasignacion=' + this.model.idasignacion,
				this.model.reasignaciones,
				response => {
					this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					this.afterSave && this.afterSave(response.data);
					if (!(shouldStayInPage === true)) {
						this.back();
					}
				},
				error => {
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			);
		}
	}
};
</script>
<style lang="postcss">
.divlibramiento {
	overflow-y: auto;
}
</style>

<i18n>
{
    "en": {
		"asignacion.idencargo": "Order code",
		"asignacion.idcomprador": "Buyer",
		"asignacion.idmercancia": "Good",
		"asignacion.idterminal": "Management terminal",
		"asignacion.pesoasignado": "Weight(kg)",
		"asignacion.contrato": "Contract",
		"asignacion.aplicacion": "Application",
		"asignacion.fecharecogida": "Pick-up date",
		"asignacion.libramientos":"Have deliverance",
		"addTransportista": "+"
    },
    "es": {
        "asignacion.idencargo": "Código encargo",
		"asignacion.idcomprador": "Comprador",
		"asignacion.idmercancia": "Mercancía",
		"asignacion.idterminal": "Terminal gestora",
		"asignacion.pesoasignado": "Peso(kg)",
		"asignacion.contrato": "Contrato",
		"asignacion.aplicacion": "Aplicación",
		"asignacion.fecharecogida": "Fecha de recogida",
		"asignacion.libramientos":"Tiene libramientos",
		"addTransportista": "+"
    },
    "ca": {
        "asignacion.idencargo": "Codi encàrrec",
		"asignacion.idcomprador": "Comprador",
		"asignacion.idmercancia": "Mercaderia",
		"asignacion.idterminal": "Terminal gestora",
		"asignacion.pesoasignado": "Pes(kg)",
		"asignacion.contrato": "Contracte",
		"asignacion.aplicacion": "Aplicació",
		"asignacion.fecharecogida": "Data de recollida",
		"asignacion.libramientos":"Té lliuraments",
		"addTransportista": "+"
    }
}
</i18n>
