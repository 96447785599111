<template>
	<div class="pui-form" v-if="modelLoaded">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('loteaudit.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#asignacionaudit'" v-if="!isCreatingElement">{{ $t('loteaudit.asignaciones') }}</v-tab>
				<v-tab :key="2" :href="'#libramientoaudit'" v-if="!isCreatingElement">{{ $t('loteaudit.libramientos') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout wrap>
								<v-flex xs12>
									<pui-text-field
										:label="$t('loteaudit.idlote')"
										v-model="model.idlote"
										required
										disabled
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs4 sm2 md1"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										id="idencargo"
										:label="this.$t('loteaudit.idencargo')"
										attach="idencargo"
										disabled
										v-model="model"
										modelName="vlupencargo"
										:itemsToSelect="[{ idencargo: model.idencargo }]"
										:modelFormMapping="{ idencargo: 'idencargo' }"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:itemValue="['idencargo']"
										:itemText="item => `${getDateFormated(item.fecharecogida)} - ${item.mercancia} - ${item.contrato}`"
										:order="{ fecharecogida: 'asc' }"
										detailModelName="encargoaudit"
										detailComponentName="detailencargoauditform"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="showInquiry">
								<v-flex xs12>
									<pui-select
										id="idconsulta"
										:label="this.$t('loteaudit.idconsulta')"
										attach="idconsulta"
										disabled
										v-model="model"
										modelName="vlupconsultas"
										:itemsToSelect="[{ idconsulta: model.idconsulta }]"
										:modelFormMapping="{ idconsulta: 'idconsulta' }"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:itemValue="['idconsulta']"
										:itemText="item => `${item.pesosolicitado} Kg - ${item.referencia}`"
										:order="{ idconsulta: 'asc' }"
										detailModelName="consultarealizada"
										detailComponentName="detailconsultaform"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:label="this.$t('loteaudit.idterminalorigen')"
										id="idterminaldestino"
										attach="idterminaldestino"
										disabled
										v-model="model"
										modelName="vlupterminales"
										:itemsToSelect="[{ idterminal: model.idterminaldestino }]"
										:modelFormMapping="{ idterminal: 'idterminaldestino' }"
										:itemValue="['idterminal']"
										itemText="nombre"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:order="{ nombre: 'asc' }"
										:key="'idterminaldestino_' + updateTerminal"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										id="idpuntocarga"
										attach="idpuntocarga"
										:label="$t('loteaudit.idpuntocarga')"
										clearable
										v-model="model"
										disabled
										modelName="vluppuntocarga"
										:itemsToSelect="[{ idpuntocarga: model.idpuntocarga }]"
										:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
										:itemValue="['idpuntocarga']"
										:itemText="item => `${item.codigo} - ${item.descripcion}`"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:order="{ descripcion: 'asc' }"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:label="this.$t('loteaudit.idterminaldestino')"
										id="idterminalorigen"
										attach="idterminalorigen"
										disabled
										v-model="model"
										modelName="vlupterminales"
										:itemsToSelect="[{ idterminal: model.idterminalorigen }]"
										:modelFormMapping="{ idterminal: 'idterminalorigen' }"
										:itemValue="['idterminal']"
										itemText="nombre"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:order="{ nombre: 'asc' }"
										:key="'idterminalorigen_' + updateTerminal"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-number-field
										:label="$t('loteaudit.pesosolicitado')"
										v-model.number="model.peso"
										decimals="0"
										suffix="Kg"
										disabled
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm4 md1"
									></pui-number-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-text-area
										v-model="model.observaciones"
										:label="$t('loteaudit.observaciones')"
										maxlength="300"
										disabled
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										id="fecalta"
										:label="$t('loteaudit.fecalta')"
										v-model="model.fecalta"
										disabled
										timesecs
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md6 xl3"
										attach="fecalta"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`asignacionaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="asignacionauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idlote: 'idlote' }"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="`libramientoaudit`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="libramientoauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idlote: 'idlote' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'loteauditform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'loteaudit',
			updateTerminal: 0,
			showInquiry: true
		};
	},
	methods: {
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		afterGetData() {
			this.updateTerminal++;
		}
	},
	created() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-idconsulta', newVal => {
			var lupaModel = newVal.model;

			this.model.idterminalorigen = lupaModel.idterminalorigen;
			this.model.idterminaldestino = lupaModel.idterminaldestino;
			if (this.model.idterminalorigen === this.model.idterminaldestino) {
				this.showInquiry = false;
			}
			this.updateTerminal++;
		});
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-idconsulta');
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"loteaudit.idencargo": "Order",
		"loteaudit.idconsulta": "Inquiry",
        "loteaudit.idterminaldestino": "Destination terminal",
        "loteaudit.idterminalorigen": "Origin terminal",
        "loteaudit.idpuntocarga": "Loading point",
        "loteaudit.pesosolicitado": "Weight (kg)",
		"loteaudit.observaciones": "Inquiry state",
		"loteaudit.fecalta": "Creation date",
		"loteaudit.idlote": "Lot code",
		"loteaudit.maintab":"Lots",
		"loteaudit.libramientos":"Riddances",
		"loteaudit.asignaciones":"Assignaments"
    },
    "es": {
		"loteaudit.idencargo": "Encargo",
		"loteaudit.idconsulta": "Consulta",
        "loteaudit.idterminaldestino": "Terminal destino",
        "loteaudit.idterminalorigen": "Terminal origen",
        "loteaudit.idpuntocarga": "Punto de carga",
		"loteaudit.pesosolicitado": "Peso (kg)",
        "loteaudit.observaciones": "Observaciones",
		"loteaudit.fecalta": "Fecha de creación",
		"loteaudit.idlote": "Código lote",
		"loteaudit.maintab":"Lotes",
		"loteaudit.libramientos":"Libramientos",
		"loteaudit.asignaciones":"Asignaciones"
    },
    "ca": {
		"loteaudit.idencargo": "Encàrrec",
		"loteaudit.idconsulta": "Consulta",
        "loteaudit.idterminaldestino": "Terminal destí",
        "loteaudit.idterminalorigen": "Terminal origen",
        "loteaudit.idpuntocarga": "Punt de Càrrega",
        "loteaudit.pesosolicitado": "Pes sol·licitat (kg)",
        "loteaudit.observaciones": "Observacions",
		"loteaudit.fecalta": "Data de creació",
		"loteaudit.idlote": "Codi lot",
		"loteaudit.maintab":"Lots",
		"loteaudit.libramientos":"Lliuraments",
		"loteaudit.asignaciones":"Assignacions"
    }
}
</i18n>
