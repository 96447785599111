<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('puntocarga.action.disableTitle')"
			:modelName="modelName"
			v-model="modalData"
			:dialogName="dialogNameDisable"
			:showDialogProp="showDialogDisable"
			:onOk="onOkDisable"
			:widthDialog="800"
			componentHeaderName="puntocargaformheader"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex sm8>
						<pui-date-field
							:label="$t('puntocarga.action.disableLabel')"
							v-model="modalData.fecbaja"
							required
							toplabel
							:min="fechahoy"
							attach="fecbaja"
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap v-if="!isMobile">
					<v-flex xs12 style="height: 60px"></v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog
			v-if="modals.enable.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.enable.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.enable.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<puntocargaformheader :modelPk="parentModelPk"></puntocargaformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('puntocarga.validate.enable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('puntocarga.action.deleteTitle')"
			:modelName="modelName"
			v-model="modalData"
			:dialogName="dialogNameDelete"
			:showDialogProp="showDialogDelete"
			:onOk="onOkDelete"
			:widthDialog="800"
			componentHeaderName="puntocargaformheader"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex xs6>
						<pui-date-field
							:label="$t('puntocarga.action.fechainicioLabel')"
							v-model="modalData.fechainicio"
							required
							:max="modalData.fechafin"
							attach="fechainicioDeleteTS"
							id="fechainicioDeleteTS"
						></pui-date-field>
					</v-flex>
					<v-flex xs6>
						<pui-date-field
							:label="$t('puntocarga.action.fechafinLabel')"
							v-model="modalData.fechafin"
							:min="modalData.fechainicio"
							attach="fechafinDeleteTS"
							id="fechafinDeleteTS"
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12>{{ $t('puntocarga.action.deleteText') }}</v-flex>
				</v-layout>
				<v-layout wrap v-if="!isMobile">
					<v-flex xs12 style="height: 100px"></v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'puntocargamodals',
	data() {
		return {
			modals: {
				enable: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			},
			modalData: {},
			fechahoy: this.$dateTimeUtils.getLocalDateNow(),
			dialogNameDisable: 'disable',
			showDialogDisable: true,
			dialogNameDelete: 'delete',
			showDialogDelete: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameDisable}-${this.modelName}-show`, (data) => {
				this.showDialogDisable = true;
			});
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					idpuntocarga: data.idpuntocarga
				};
				this.modals.enable.titleText = this.$t('puntocarga.action.enableTitle');
				this.setShowConfirm(true);
			});
			this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameDelete}-${this.modelName}-show`, (data) => {
				this.showDialogDelete = true;
			});
		},
		setShowConfirm(action) {
			this.modals.enable.show = action;
		},
		onOkDisable(modalData) {
			var idpuntocarga = modalData.headerPk.idpuntocarga;
			let urlController = this.$store.getters.getModelByName(this.modelName).url.disable + '?idpuntocarga=' + idpuntocarga;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(this.$t('puntocarga.disable'));
			var params = { date: modalData.fecbaja };

			this.$puiRequests.patchRequest(
				urlController,
				params,
				(response) => {
					this.showDialogDisable = false;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				(e) => {
					this.showDialogDisable = false;
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		onOkDelete(modalData) {
			if (modalData.fechafin === null) {
				this.$puiNotify.error(this.$t('fechafin.obligatoria'));
				return;
			}
			let registries = modalData.registries;
			var counter = registries.length;
			var progress = 0;
			var success = true;
			var corrects = 0;

			let urlController = this.$store.getters.getModelByName(this.modelName).url.deleteTS;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('puntocarga.deletePTSAction');

			for (let i = 0; i < counter; i++) {
				const registry = registries[i];
				var idpuntocarga = registry.idpuntocarga;

				var params = { idpuntocarga: idpuntocarga, fechainicio: modalData.fechainicio, fechafin: modalData.fechafin };

				this.$puiRequests.patchRequest(
					urlController,
					params,
					(response) => {
						progress++;
						if (response && response.data) {
							var data = response.data;
							corrects = corrects + data;
						}
						finish();
					},
					(e) => {
						success = false;
						progress++;
						finish();
					}
				);
			}

			const finish = () => {
				if (counter === progress) {
					this.showDialogDelete = false;
					var message;
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

					var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('puntocarga.deletePTSAction');
					if (success) {
						message = corrects + this.$puiI18n.t('action.notifySuccessDeleteMultiple');
						this.$puiNotify.success(message, title);
					} else {
						message = corrects + this.$puiI18n.t('action.notifyErrorDeleteMultiple');
						this.$puiNotify.error(message, title);
					}
				}
			};
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-show`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"puntocarga.action.disableTitle": "Deactivate",
		"puntocarga.action.disableLabel": "Deactivate Date",
		"puntocarga.validate.disable": "The loading point will be deactivated. Do you want to continue?",
		"puntocarga.validate.enable": "The deactivated loading point will be activated. Do you want to continue?",
		"puntocarga.action.deleteTitle": "Delete time slots",
		"puntocarga.action.deleteText":"All time slots at this loading point will be deleted during the indicated period, provided they have no associated release",
		"puntocarga.action.fechainicioLabel": "Start date",
		"puntocarga.action.fechafinLabel": "End date",
		"fechafin.obligatoria": "End date is required",
		"puntocarga.action.enableTitle": "Enable"
	},
	"es": {
		"puntocarga.action.disableTitle": "Desactivar",
		"puntocarga.action.disableLabel": "Fecha baja",
		"puntocarga.validate.disable": "Se desactiva el punto de carga. ¿Desea continuar?",
		"puntocarga.validate.enable": "Se activará el punto de carga. ¿Desea continuar?",
		"puntocarga.action.deleteTitle": "Eliminar time slots",
		"puntocarga.action.deleteText":"Se borrarán todos los time slots de este punto de carga durante el periodo indicado, siempre y cuando no tengan asociados ningún libramiento",
		"puntocarga.action.fechainicioLabel": "Fecha inicio",
		"puntocarga.action.fechafinLabel": "Fecha fin",
		"fechafin.obligatoria": "La fecha de fin es obligatoria",
		"puntocarga.action.enableTitle": "Activar"
	},
	"ca": {
		"puntocarga.action.disableTitle": "Desactivar",
		"puntocarga.action.disableLabel": "Data de baixa",
		"puntocarga.validate.enable": "Es desactivará el punt de càrrega. Desitja continuar?",
		"puntocarga.validate.enable": "S'ºactivarà el punt de càrrega. Desitja continuar?",
		"puntocarga.action.deleteTitle": "Eliminar time slots",
		"puntocarga.action.deleteText": "S'esborraran tots els time slots d'aquest punt de càrrega durant el període indicat, sempre que no tinguen associats cap lliurament",
		"puntocarga.action.fechainicioLabel": "Data inici",
		"puntocarga.action.fechafinLabel": "Data fi",
		"fechafin.obligatoria": "La data fi es obligatoria",
		"puntocarga.action.enableTitle": "Activar"
	}
}
</i18n>
