<template>
	<div class="pui-form mt-4">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<novedadesformheader></novedadesformheader>
		</pui-form-header>
		<v-form class="pui-novedadesform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout class="pui-form-layout">
				<v-flex xs8>
					<v-layout wrap>
						<v-flex xs12 v-if="this.method != 'create'">
							<pui-text-field
								:label="$t('novedades.idnovedad')"
								v-model="model.idnovedad"
								required
								disabled
								valueColumnStyles="xs4 sm2 md1"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-text-field
								:label="$t('novedades.titulo')"
								v-model="model.titulo"
								maxlength="300"
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-text-area
								v-model="model.descripcion"
								:label="this.$t('novedades.descripcion')"
								maxlength="3000"
								clearable
								required
								:disabled="formDisabled"
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12>
							<pui-date-field
								v-if="modelLoaded"
								:label="$t('novedades.fecpublicacion')"
								v-model="model.fecpublicacion"
								required
								:disabled="formDisabled"
								valueColumnStyles="xs12 sm4 md4 xl2"
								id="fecpublicacion"
								attach="fecpublicacion"
							></pui-date-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs6>
							<pui-checkbox
								:label="$t('novedades.indpublicado')"
								v-model="model.indpublicado"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import NovedadesActions from './NovedadesActions';

export default {
	name: 'novedades',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'novedades',
			actions: NovedadesActions.formactions
		};
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
