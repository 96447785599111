<template>
	<div>
		<usuario-aplicacion-modals :modelName="model"></usuario-aplicacion-modals>
		<pui-datatable
			:modelName="model"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			:showDeleteBtn="false"
			showFilterListBtn
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import UsuarioTransportistaActions from './UsuarioTransportistaActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuariotransportistagrid',
	components: {
		UsuarioAplicacionModals
	},
	data() {
		return {
			model: 'usuariotransportista',
			actions: UsuarioTransportistaActions.gridactions,
			columnDefs: {
				disableddate: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return registry.disabled !== 1;
		}
	}
};
</script>

<style scoped></style>

<i18n>
{
	"en": {
		"usuarioaplicacion" :"Users",
		"grid.usuariotransportista.usr": "User",
		"grid.usuariotransportista.name": "Name",
		"grid.usuariotransportista.email": "Email",
		"grid.usuariotransportista.disableddate": "Disabled Date",
		"grid.usuariotransportista.disabledmask": "Disabled",
		"grid.usuariotransportista.language": "Language",
		"grid.usuariotransportista.idtransportista": "Freight forwarder code",
		"grid.usuariotransportista.nifconductor": "Tax Identifier Driver",
		"grid.usuariotransportista.matriconductor": "Driver's license plate",
		"grid.usuariotransportista.remolqueconductor": "Driving trailer",
		"grid.usuariotransportista.movilconductor": "Driving mobile",
		"grid.usuariotransportista.idtipousuario": "User type code",
		"grid.usuariotransportista.tipousuario": "User type",
		"grid.usuariotransportista.fechacreacion":"Creation date",
		"grid.usuariotransportista.usuariocreacion":"Creation user"
	},
	"es": {
		"usuarioaplicacion" :"Usuarios",
		"grid.usuariotransportista.usr": "Usuario",
		"grid.usuariotransportista.name": "Nombre",
		"grid.usuariotransportista.email": "Email",
		"grid.usuariotransportista.disableddate": "Fecha desactivación",
		"grid.usuariotransportista.disabledmask": "Activo",
		"grid.usuariotransportista.language": "Idioma",
		"grid.usuariotransportista.idtransportista": "Código transportista",
		"grid.usuariotransportista.nifconductor": "Identificador fiscal conductor",
		"grid.usuariotransportista.matriconductor": "Matrícula conductor",
		"grid.usuariotransportista.remolqueconductor": "Remolque conductor",
		"grid.usuariotransportista.movilconductor": "Móvil conductor",
		"grid.usuariotransportista.idtipousuario": "Código tipo usuario",
		"grid.usuariotransportista.tipousuario": "Tipo usuario",
		"grid.usuariotransportista.fechacreacion":"Fecha creación",
		"grid.usuariotransportista.usuariocreacion":"Usuario alta"

	},
	"ca": {
		"usuarioaplicacion" :"Usuaris",
		"grid.usuariotransportista.usr": "Usuari",
		"grid.usuariotransportista.name": "Nom",
		"grid.usuariotransportista.email": "Email",
		"grid.usuariotransportista.disableddate": "Data Desactivació",
		"grid.usuariotransportista.disabledmask": "Actiu",
		"grid.usuariotransportista.language": "Idioma",
		"grid.usuariotransportista.idtransportista": "Codi transportista",
		"grid.usuariotransportista.nifconductor": "Identificador fiscal conductor",
		"grid.usuariotransportista.matriconductor": "Matrícula conductor",
		"grid.usuariotransportista.remolqueconductor": "Remolc conductor",
		"grid.usuariotransportista.movilconductor": "Mobil conductor",
		"grid.usuariotransportista.idtipousuario": "Codi tipo usuari",
		"grid.usuariotransportista.tipousuario": "Tipo usuari",
		"grid.usuariotransportista.fechacreacion":"Data creació",
		"grid.usuariotransportista.usuariocreacion":"Usuari alta"
	}
}
</i18n>
