<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:modelColumnDefs="columnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'encargoauditgrid',
	data() {
		return {
			model: 'encargoaudit',
			columnDefs: {
				fecharecogida: { withoutHours: true }
			},
			masterDetailColumnVisibles: {
				idencargo: true,
				comprador: true,
				terminal: true,
				fecharecogida: true,
				mercancia: true,
				peso: true,
				proveedor: true,
				contrato: true,
				aplicacion: true,
				referencia: true,
				estado: true,
				fechacreacion: true,
				motivoanulrechazo: true,
				codmotivoanulrechazo: true
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.encargoaudit.idencargo": "Order code",
		"grid.encargoaudit.idcomprador": "Buyer code",
		"grid.encargoaudit.comprador": "Buyer",
        "grid.encargoaudit.contrato": "Contract number",
        "grid.encargoaudit.aplicacion": "Application code",
		"grid.encargoaudit.referencia": "Reference",
		"grid.encargoaudit.fecharecogida": "Pick-up date",
		"grid.encargoaudit.idterminal": "Terminal code",
		"grid.encargoaudit.terminal": "Terminal",
		"grid.encargoaudit.idmercancia": "Good code",
		"grid.encargoaudit.mercancia": "Good",
		"grid.encargoaudit.peso": "Weight(kg)",
		"grid.encargoaudit.proveedor": "Supplier",
		"grid.encargoaudit.estado": "State",
		"grid.encargoaudit.codestado": "State code",
		"grid.encargoaudit.fechacreacion": "Creation date",
		"grid.encargoaudit.idmotivoanulrechazo": "Reason for cancellation/rejection code",
		"grid.encargoaudit.motivoanulrechazo": "Reason for cancellation/rejection"
    },
    "es": {
        "grid.encargoaudit.idencargo": "Código encargo",
		"grid.encargoaudit.idcomprador": "Código comprador",
		"grid.encargoaudit.comprador": "Comprador",
        "grid.encargoaudit.contrato": "Número de contrato",
        "grid.encargoaudit.aplicacion": "Código de aplicación",
		"grid.encargoaudit.referencia": "Referencia",
		"grid.encargoaudit.fecharecogida": "Fecha de recogida",
		"grid.encargoaudit.idterminal": "Código terminal",
		"grid.encargoaudit.terminal": "Terminal",
		"grid.encargoaudit.idmercancia": "Código mercancía",
		"grid.encargoaudit.mercancia": "Mercancía",
		"grid.encargoaudit.peso": "Peso (kg)",
		"grid.encargoaudit.proveedor": "Proveedor",
		"grid.encargoaudit.estado": "Estado",
		"grid.encargoaudit.codestado": "Código Estado",
		"grid.encargoaudit.fechacreacion": "Fecha de creación",
		"grid.encargoaudit.idmotivoanulrechazo": "Código motivo anulación/rechazo",
		"grid.encargoaudit.motivoanulrechazo": "Motivo anulación/rechazo"
    },
    "ca": {
        "grid.encargoaudit.idencargo": "Codi encàrrec",
		"grid.encargoaudit.idcomprador": "Codi comprador",
		"grid.encargoaudit.comprador": "Comprador",
        "grid.encargoaudit.contrato": "Número de contracte",
        "grid.encargoaudit.aplicacion": "Codi d’aplicació",
		"grid.encargoaudit.referencia": "Referència",
		"grid.encargoaudit.fecharecogida": "Data de recollida",
		"grid.encargoaudit.idterminal": "Codi terminal",
		"grid.encargoaudit.terminal": "Terminal",
		"grid.encargoaudit.idmercancia": "Codi mercaderia",
		"grid.encargoaudit.mercancia": "Mercaderia",
		"grid.encargoaudit.peso": "Pes (kg)",
		"grid.encargoaudit.proveedor": "Proveïdor",
		"grid.encargoaudit.codestado": "Codi estat",
		"grid.encargoaudit.estado": "Estat",
		"grid.encargoaudit.fechacreacion": "Data de creació",
		"grid.encargoaudit.idmotivoanulrechazo": "Codi motiu anul·lació/rebuig",
		"grid.encargoaudit.motivoanulrechazo": "Motiu anul·lació/rebuig"
    }
}
</i18n>
