<template>
	<pui-datatable
		v-if="createdEnded"
		:modelName="model"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:parentModelName="parentModelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:isEditableRegistry="isEditableRegistry"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
		showFilterListBtn
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'historicoauditgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'historicoaudit',
			masterDetailColumnVisibles: {
				idhistorico: true,
				actor: true,
				usuario: true,
				estadoanterior: true,
				estadonuevo: true,
				accion: true,
				fechacreacion: true
			},
			createdEnded: false
		};
	},
	created() {
		if (this.masterDetail && this.parentModelName === 'libramientoaudit') {
			this.masterDetailColumnVisibles.puntocargaanterior = true;
			this.masterDetailColumnVisibles.puntocarganuevo = true;
		}
		this.createdEnded = true;
	},
	methods: {
		isEditableRegistry() {
			return false;
		}
	}
};
</script>

<i18n>
{
    "en": {
		"grig.historicoaudit.idhistorico":"Code",
		"grig.historicoaudit.idencargo":"Order code",
		"grig.historicoaudit.actor":"Performer",
		"grig.historicoaudit.idestadoanterior":"Previous state code",
		"grig.historicoaudit.estadoanterior":"Previous state",
		"grig.historicoaudit.idestadonuevo":"New state code",
		"grig.historicoaudit.estadonuevo":"New state",
		"grig.historicoaudit.idpuntocargaanterior":"Previous L.P. code",
		"grig.historicoaudit.puntocargaanterior":"Previous L.P. ",
		"grig.historicoaudit.idpuntocarganuevo":"New L.P. code",
		"grig.historicoaudit.puntocarganuevo":"New L.P.",
		"grig.historicoaudit.idaccion":"Action code",
		"grig.historicoaudit.accion":"Action",
		"grig.historicoaudit.fechacreacion":"Creation Date",
		"grig.historicoaudit.usuario":"User"
    },
    "es": {
		"grig.historicoaudit.idhistorico":"Código",
		"grig.historicoaudit.idencargo":"Cód. encargo",
		"grig.historicoaudit.actor":"Actor",
		"grig.historicoaudit.idestadoanterior":"Cód. estado anterior",
		"grig.historicoaudit.estadoanterior":"Estado anterior",
		"grig.historicoaudit.idestadonuevo":"Cód. estado nuevo",
		"grig.historicoaudit.estadonuevo":"Estado nuevo",
		"grig.historicoaudit.idpuntocargaanterior":"Cód. Punto de carga anterior",
		"grig.historicoaudit.puntocargaanterior":"Punto de carga anterior",
		"grig.historicoaudit.idpuntocarganuevo":"Cód. Punto de carga nuevo",
		"grig.historicoaudit.puntocarganuevo":"Punto de carga nuevo",
		"grig.historicoaudit.idaccion":"Cód. acción",
		"grig.historicoaudit.accion":"Acción",
		"grig.historicoaudit.fechacreacion":"Fecha de creación",
		"grig.historicoaudit.usuario":"Usuario"
	},
    "ca": {
		"grig.historicoaudit.idhistorico":"Codi",
		"grig.historicoaudit.idencargo":"Cod. Encàrrec",
		"grig.historicoaudit.actor":"Actor",
		"grig.historicoaudit.idestadoanterior":"Cod. estat anterior",
		"grig.historicoaudit.estadoanterior":"Estat anterior",
		"grig.historicoaudit.idestadonuevo":"Cod. estat nou",
		"grig.historicoaudit.estadonuevo":"Estat nou",
		"grig.historicoaudit.idaccion":"Cod. acció",
		"grig.historicoaudit.idpuntocargaanterior":"Cod. Punt de càrrega anterior",
		"grig.historicoaudit.puntocargaanterior":"Punt de càrrega anterior",
		"grig.historicoaudit.idpuntocarganuevo":"Cod. Punt de càrrega nou",
		"grig.historicoaudit.puntocarganuevo":"Punt de càrrega nou",
		"grig.historicoaudit.accion":"Acció",
		"grig.historicoaudit.fechacreacion":"Data de creació",
		"grig.historicoaudit.usuario":"Usuari"
	}
}
</i18n>
