<template>
	<div class="libramiento puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('libramiento.xxxx')" :value="getXXXX"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'libramientoformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'libramiento'
		};
	},
	computed: {
		getXXXX() {
			return this.model && this.model.xxxx ? this.model.xxxx : '';
		}
	},
	methods: {
		extendFilter(filter) {
			filter.model = 'libramiento';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"libramiento.xxxx": "Goods"
	},
	"es": {
		"libramiento.xxxx": "Mercancía"
	},
	"ca": {
		"libramiento.xxxx": "Mercaderia"
	}
}
</i18n>
