<template>
	<pui-datatable :modelName="model" :actions="actions" :modelColumnDefs="columnDefs" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */

import AutorizacionActions from './AutorizacionActions';

export default {
	name: 'autorizaciongrid',
	data() {
		return {
			model: 'autorizacion',
			actions: AutorizacionActions.gridactions,
			columnDefs: {
				fechainicio: { withoutHours: true },
				fechafin: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.autorizacion.idautorizacion": "Authorization code",
        "grid.autorizacion.idtransportista": "Carrier code No",
        "grid.autorizacion.transportista": "Carrier",
        "grid.autorizacion.fechainicio": "Start date",
		"grid.autorizacion.fechafin": "End date",
		"grid.autorizacion.fechacreacion":"Creation date"
    },
    "es": {
        "grid.autorizacion.idautorizacion": "Código autorización",
        "grid.autorizacion.idtransportista": "Código transportista",
        "grid.autorizacion.transportista": "Transportista",
        "grid.autorizacion.fechainicio": "Fecha de inicio",
		"grid.autorizacion.fechafin": "Fecha de fin",
		"grid.autorizacion.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.autorizacion.idautorizacion": "Codi autorització",
        "grid.autorizacion.idtransportista": "Codi transportista",
        "grid.autorizacion.transportista": "Transportista",
        "grid.autorizacion.fechainicio": "Data inici",
		"grid.autorizacion.fechafin": "Data fi",
		"grid.autorizacion.fechacreacion":"Data creació"
    }
}
</i18n>
/*conf comprador oculto en el clob pui_model:
"columns":[{"name":"idcomprador","title":"vtcautorizaciones.idcomprador","type":"numeric","data":"idcomprador","visibility":"hidden"}],*/
