<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			:modelColumnDefs="columnDefs"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			:modelColumnDefs="columnDefs"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'libramientoauditgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'libramientoaudit',
			masterDetailColumnVisibles: {
				idlibramiento: true,
				terminalcarga: true,
				puntocarga: true,
				pcmodificado: true,
				fecharealrecogida: true,
				fechacreacion: true,
				fechaencargo: true,
				pesolibramiento: true,
				transportista: true,
				franjahoraria: true,
				horarecogida: true,
				idconductor: true,
				matricula: true,
				remolque: true,
				estado: true,
				codmotivoanulrechazo: true,
				motivoanulrechazo: true,
				comprador: true,
				terminalgestora: true,
				autmodificado: true,
				destinomercancia: true,
				obsrechazo: true
			},
			columnDefs: {
				fechaencargo: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
		"grid.libramientoaudit.idencargo": "Code order",
		"grid.libramientoaudit.idlote": "Code lot",
		"grid.libramientoaudit.idlibramiento": "Code",
		"grid.libramientoaudit.idtransportista": "Carrier code",
		"grid.libramientoaudit.transportista": "Carrier",
		"grid.libramientoaudit.conductor": "Driver",
		"grid.libramientoaudit.matricula": "Plate",
		"grid.libramientoaudit.remolque": "Remolque",
		"grid.libramientoaudit.fecharealrecogida": "Pick-up date",
		"grid.libramientoaudit.franjahoraria": "Time slot band",
		"grid.libramientoaudit.horainicio": "Time slot beginnig",
		"grid.libramientoaudit.horafin": "Time slot end",
		"grid.libramientoaudit.horarecogida": "Authorized Time",
		"grid.libramientoaudit.idterminal": "Terminal code",
		"grid.libramientoaudit.terminalcarga": "Charging terminal",
		"grid.libramientoaudit.indpcmodificado": "Check updated L.P",
		"grid.libramientoaudit.pcmodificado": "Updated L.P",
		"grid.libramientoaudit.codestado": "State code",
		"grid.libramientoaudit.estado": "State",
		"grid.libramientoaudit.idpuntocarga": "Loading point code",
		"grid.libramientoaudit.puntocarga": "Loading point",
		"grid.libramientoaudit.fechacreacion": "Creation date",
		"grid.libramientoaudit.fechaencargo": "Fecha de encargo",
		"grid.libramientoaudit.pesolibramiento": "Peso (kg)",
		"grid.libramientoaudit.idmotivoanulrechazo": "Reason for cancellation/rejection code",
		"grid.libramientoaudit.motivoanulrechazo": "Reason for cancellation/rejection",
		"grid.libramientoaudit.idcomprador": "Buyer code",
		"grid.libramientoaudit.comprador": "Buyer",
		"grid.libramientoaudit.idterminalgestora": "Terminal code",
		"grid.libramientoaudit.terminalgestora": "Charging terminal",
		"grid.libramientoaudit.indautmodificado": "Ind. Modified by carrier",
		"grid.libramientoaudit.autmodificado": "Modified by carrier"
    },
    "es": {
		"grid.libramientoaudit.idencargo": "Código encargo",
		"grid.libramientoaudit.idlote": "Código lote",
        "grid.libramientoaudit.idlibramiento": "Código libramiento",
		"grid.libramientoaudit.idtransportista": "Código transportista",
		"grid.libramientoaudit.conductor": "Conductor",
		"grid.libramientoaudit.matricula": "Matrícula",
		"grid.libramientoaudit.remolque": "Remolque",
		"grid.libramientoaudit.transportista": "Transportista",
		"grid.libramientoaudit.fecharealrecogida": "Fecha de recogida",
		"grid.libramientoaudit.franjahoraria": "Franja horaria time slot",
		"grid.libramientoaudit.horainicio": "Inicio time slot",
		"grid.libramientoaudit.horafin": "Fin time slot",
		"grid.libramientoaudit.horarecogida": "Hora autorizada",
		"grid.libramientoaudit.idterminal": "Código terminal",
		"grid.libramientoaudit.terminalcarga": "Terminal de carga",
		"grid.libramientoaudit.indpcmodificado": "Ind. P.C. modificado",
		"grid.libramientoaudit.pcmodificado": "P.C. modificado",
		"grid.libramientoaudit.codestado": "Código estado",
		"grid.libramientoaudit.estado": "Estado",
		"grid.libramientoaudit.idpuntocarga": "Código punto de carga",
		"grid.libramientoaudit.puntocarga": "Punto de carga",
		"grid.libramientoaudit.fechacreacion": "Fecha de creación",
		"grid.libramientoaudit.fechaencargo": "Fecha de encargo",
		"grid.libramientoaudit.pesolibramiento": "Peso (kg)",
		"grid.libramientoaudit.idmotivoanulrechazo": "Código motivo anulación/rechazo",
		"grid.libramientoaudit.motivoanulrechazo": "Motivo anulación/rechazo",
		"grid.libramientoaudit.idcomprador": "Código comprador",
		"grid.libramientoaudit.comprador": "Comprador",
		"grid.libramientoaudit.idterminalgestora": "Código terminal gestora",
		"grid.libramientoaudit.terminalgestora": "Terminal gestora",
		"grid.libramientoaudit.indautmodificado": "Ind. Modificado por transportista",
		"grid.libramientoaudit.autmodificado": "Modificado por transportista"
    },
    "ca": {
		"grid.libramientoaudit.idencargo": "Codi encàrrec",
		"grid.libramientoaudit.idlote": "Codi lot",
        "grid.libramientoaudit.idlibramiento": "Codi lliurament",
		"grid.libramientoaudit.idtransportista": "Codi transportista",
		"grid.libramientoaudit.transportista": "Transportista",
		"grid.libramientoaudit.conductor": "Conductor",
		"grid.libramientoaudit.matricula": "Matrícula",
		"grid.libramientoaudit.remolque": "Remolc",
		"grid.libramientoaudit.fecharealrecogida": "Data de recollida",
		"grid.libramientoaudit.franjahoraria": "Franja horària time slot",
		"grid.libramientoaudit.horainicio": "Inici time slot",
		"grid.libramientoaudit.horafin": "Fi time slot",
		"grid.libramientoaudit.horarecogida": "Hora autoritzada",
		"grid.libramientoaudit.idterminal": "Codi terminal",
		"grid.libramientoaudit.terminalcarga": "Terminal de càrrega",
		"grid.libramientoaudit.indpcmodificado": "Ind. Punt càrrega modificat",
		"grid.libramientoaudit.pcmodificado": "Punt càrrega modificat",
		"grid.libramientoaudit.codestado": "Codi estat",
		"grid.libramientoaudit.estado": "Estat",
		"grid.libramientoaudit.idpuntocarga": "Codi punt de càrrega",
		"grid.libramientoaudit.puntocarga": "Punt de càrrega",
		"grid.libramientoaudit.fechacreacion": "Data de creació",
		"grid.libramientoaudit.fechaencargo": "Data encàrrec",
		"grid.libramientoaudit.pesolibramiento": "Pes (kg)",
		"grid.libramientoaudit.idmotivoanulrechazo": "Codi motiu anul·lació/rebuig",
		"grid.libramientoaudit.motivoanulrechazo": "Motiu anul·lació/rebuig",
		"grid.libramientoaudit.idcomprador": "Codi comprador",
		"grid.libramientoaudit.comprador": "Comprador",
		"grid.libramientoaudit.idterminalgestora": "Codi terminal gestora",
		"grid.libramientoaudit.terminalgestora": "Terminal gestora",
		"grid.libramientoaudit.indautmodificado": "Ind. Modificat per transportista",
		"grid.libramientoaudit.autmodificado": "Modificat per transportista"
 	}
}
</i18n>
