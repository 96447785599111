<template>
	<div>
		<usuarioaplicacionpassworddialog :modelName="modelName"></usuarioaplicacionpassworddialog>
		<v-form class="pui-userform" ref="form">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 lg8>
					<pui-field-set>
						<v-layout wrap class="pa-3">
							<v-flex xs12>
								<pui-text-field :label="$t('puiuser.usr')" v-model="model.usr" maxlength="100" disabled></pui-text-field>
								<pui-text-field :label="$t('puiuser.name')" v-model="model.name" maxlength="200" required></pui-text-field>
								<pui-text-field
									:label="$t('puiuser.email')"
									v-model="model.email"
									maxlength="100"
									required
									:rules="[rules.emailRules]"
								></pui-text-field>
								<pui-select
									:label="$t('puiuser.language')"
									v-model="model"
									clearable
									required
									modelName="vpuilanguage"
									:itemsToSelect="[{ isocode: model.language }]"
									:modelFormMapping="{ isocode: 'language' }"
									itemValue="isocode"
									itemText="name"
									:order="{ name: 'asc' }"
								></pui-select>
								<v-btn depressed color="secondary" class="elevation-0" @click.native="showDialog()">{{ $t('changePassword') }}</v-btn>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-modal-dialog
			v-if="modals.confirmlang.show"
			:disableOk="modals.confirmlang.disableOk"
			:okText="$t('form.accept')"
			:messageText="modals.confirmlang.text"
			:titleText="modals.confirmlang.titleText"
			:dialogName="`confirmlang-${this.modelName}`"
			ref="confirmlang"
			:widthDialog="800"
		></pui-modal-dialog>
		<!--		<v-form class="mb-4 pb-4" ref="password_form">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 lg8>
					<pui-field-set ref>
						<v-layout wrap class="pa-3">
							<v-flex xs12>
								<pui-text-field
									:label="$t('current')"
									v-model="oldPassword"
									type="password"
									realtime
									noeditable
									:rules="[rules.passwordRules]"
									:autocomplete="'old-password'"
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('new')"
									v-model="newPassword"
									type="password"
									:rules="[rules.notEqualsPassword]"
									realtime
									noeditable
									:autocomplete="'new-password'"
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('confirm')"
									v-model="confirmPassword"
									type="password"
									:rules="[rules.equalsPassword]"
									realtime
									noeditable
									:autocomplete="'confirm-password'"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>
		</v-form>
		-->
	</div>
</template>

<script>
import UsuarioAplicacionPasswordDialog from './UsuarioAplicacionPasswordDialog';
export default {
	name: 'usuarioaplicacionsettingsform',
	components: {
		usuarioaplicacionpassworddialog: UsuarioAplicacionPasswordDialog
	},
	data() {
		return {
			showError: false,
			serverError: '',
			modelDependencyName: 'puiuser',
			modelName: 'puiuser',
			model: {},
			oldPassword: null,
			newPassword: null,
			confirmPassword: null,
			rules: {
				notEqualsPassword: value => value !== this.oldPassword || this.$t('password_are_equal'),
				equalsPassword: value => value === this.newPassword || this.$t('password_not_equal'),
				passwordRules: value => this.validatePasswordRules(value) || this.$t('usuarioaplicacion.validate.pass'),
				emailRules: value => this.validateEmailRules(value) || this.$t('usuarioaplicacion.validate.email')
			},
			language: null,
			modals: {
				confirmlang: {
					disableOk: false,
					show: false,
					titleText: this.$t('usuarioaplicacion.modal.confirmlang.title'),
					text: ''
				}
			}
		};
	},
	created() {
		this.model = this.$store.getters.getSession;
		this.language = this.model.language;
		this.subscribeToModalDialogEvent();
	},
	methods: {
		validatePasswordRules(v) {
			var re = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-zA-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-z])(?=.*[A-Z]).{8,})$/i;
			return re.test(v);
		},
		validateEmailRules(v) {
			// eslint-disable-next-line
			var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
			return re.test(v);
		},
		back() {
			this.$store.dispatch('puiRouterGoToDefaultEntryPage', this.$router);
		},
		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			var urlController = `/usuarioaplicacion/changeSettings?usr=${this.model.usr}`;

			delete this.model.functionalities;
			delete this.model.token;
			delete this.model.profiles;
			delete this.model.isocode;
			delete this.model.image;
			delete this.model.isdefault;
			delete this.model.keepSessionAliveInfoKey;
			delete this.model.usr;

			/*
			if (this.oldPassword !== null || this.newPassword !== null || this.confirmPassword !== null) {
				if (!this.$refs.password_form.validate()) {
					return;
				}
				urlController = urlController + `&oldPassword=${this.oldPassword}&newPassword=${this.newPassword}`;
			}
*/
			this.$puiRequests.patchRequest(
				urlController,
				this.model,
				() => {
					this.afterSave();
				},
				e => {
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.model);
					this.$puiNotify.error(message, '');
				}
			);
		},
		afterSave() {
			if (this.language !== this.model.language) {
				this.$puiEvents.$emit('pui-modalDialog-confirmlang-' + this.modelName + '-show', '');
			} else {
				this.changeAppLang(this.model.language);
				this.$store.dispatch('puiRouterGoToDefaultEntryPage', this.$router);
			}
		},
		changeAppLang(lang) {
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		},
		onError(e) {
			this.showError = true;
			this.serverError = e;
		},
		showDialog() {
			this.$puiEvents.$emit('pui-modalDialog-passwordDialog-show', this.model.usr);
		},
		subscribeToModalDialogEvent() {
			// Modal Minorpeso
			this.$puiEvents.$on(`pui-modalDialog-confirmlang-${this.modelName}-ok`, () => {
				this.modals.confirmlang.show = false;
				this.changeAppLang(this.model.language);
				this.$store.dispatch('puiRouterGoToDefaultEntryPage', this.$router);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirmlang-${this.modelName}-cancel`, () => {
				this.modals.confirmlang.show = false;
				this.changeAppLang(this.model.language);
				this.$store.dispatch('puiRouterGoToDefaultEntryPage', this.$router);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirmlang-${this.modelName}-show`, () => {
				this.modals.confirmlang.text = this.$t('usuarioaplicacion.modal.confirmlang.text1');
				this.modals.confirmlang.show = true;
			});
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-confirmlang-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirmlang-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirmlang-${this.modelName}-show`);
	}
};
</script>
<i18n>
{
	"en": {
    "password_changed": "Password changed successfuly",
    "resetPassword": "Change password",
    "current": "Current password",
    "new": "New password",
    "confirm": "Confirm new password",
    "password_are_equal": "The current and new passwords are equals",
	"password_not_equal": "Passwords do not match",
	"changePassword": "Change password",
	"usuarioaplicacion.validate.email":"The email is not well-formed 'email@domain.com'",
	"usuarioaplicacion.modal.confirmlang.title":"Change language"
	},
	"es": {
    "password_changed": "Contraseña cambiada con éxito",
    "resetPassword": "Cambiar contraseña",
    "current": "Contraseña actual",
    "new": "Nueva contraseña",
    "confirm": "Confirmar nueva contraseña",
    "password_are_equal": "La contraseña actual y nueva no pueden ser iguales",
	"password_not_equal": "Las contraseñas no coinciden",
	"changePassword": "Cambiar contraseña",
	"usuarioaplicacion.validate.email":"El email no está bien formado 'email@dominio.com'",
	"usuarioaplicacion.modal.confirmlang.title":"Cambio de idioma",
	"usuarioaplicacion.modal.confirmlang.text1":"Vuelva a iniciar sesión para que surtan efecto los cambios"
  },
  "ca": {
    "password_changed": "Contrasenya canviada amb èxit",
    "resetPassword": "Canviar contrasenya",
    "current": "Actual",
    "new": "Nova",
    "confirm": "Confirmar nova contrasenya",
    "password_are_equal": "L'actual contrasenya i la nova no poden ser iguals",
	"password_not_equal": "Passwords coincideixen",
	"changePassword": "Canviar contrasenya",
	"usuarioaplicacion.validate.email":"L'email no està ben format 'email@domini.com'",
	"usuarioaplicacion.modal.confirmlang.title":"Canvi d'idioma",
	"usuarioaplicacion.modal.confirmlang.text1":"Torne a iniciar una sessió per a efectuar els canvis"
	}
}
</i18n>
