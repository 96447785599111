<template>
	<div class="pui-form">
		<libramiento-termg-modals :modelName="modelName"></libramiento-termg-modals>
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<encargotermformheader :modelPk="parentModelPk" origen="LT"></encargotermformheader>
		</pui-form-header>
		<v-form class="pui-libramientotermgform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout>
				<v-flex xs12>
					<v-layout wrap class="pui-form-layout">
						<libramiento-form-base
							:model="model"
							:formDisabled="formDisabled"
							:modelLoaded="modelLoaded"
							tipoLibramiento="TG"
						></libramiento-form-base>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">{{
										this.$t('form.save')
									}}</v-btn>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import LibramientoFormBase from '../LibramientoFormBase';
import LibramientoTermgActions from './LibramientoTermgActions';
import LibramientoTermgModals from './LibramientoTermgModals';

export default {
	name: 'libramientotermgform',
	mixins: [PuiFormMethodsMixin],
	components: { LibramientoFormBase, LibramientoTermgModals },
	data() {
		return {
			modelName: 'libramientotermg',
			actions: LibramientoTermgActions.formactions,
			parentModelPk: null
		};
	},
	methods: {
		// Override
		afterGetData() {
			this.parentModelPk = { idencargo: this.model.idencargo };
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"libramientotermg.maintab": "Order",
		"libramientotermg.consultasrealizadas": "Inquiry performed",
		"libramientotermg.loterecibido": "Lots received"
    },
    "es": {
        "libramientotermg.maintab": "Encargo",
		"libramientotermg.consultasrealizadas": "Consultas realizadas",
		"libramientotermg.loterecibido": "Lotes recibidos"
    },
    "ca": {
        "libramientotermg.maintab": "Encàrrec",
		"libramientotermg.consultasrealizadas": "Consultes realitzades",
		"libramientotermg.loterecibido": "Lots rebuts"
    }
}
</i18n>
