<template>
	<v-layout wrap>
		<v-flex xs11>
			<pui-field-set>
				<v-layout wrap>
					<v-flex xs12 md6>
						<pui-select
							v-if="modelLoaded"
							attach="ciftransportista"
							:label="$t('asignacion.idtransportista')"
							clearable
							:disabled="formDisabled"
							v-model="model"
							required
							modelName="vluptransportistas"
							:itemsToSelect="[{ cif: model.ciftransportista }]"
							:modelFormMapping="{ cif: 'ciftransportista' }"
							:itemValue="['cif']"
							:itemText="(item) => `${item.nombre}`"
							:key="'itrkey' + this.indice"
							:id="'idtr' + this.indice"
							:fixedFilter="fixedFilterTransportista"
							:order="{ nombre: 'asc' }"
							labelColumnStyles="xs12 md4"
							valueColumnStyles="xs12 md8"
						></pui-select>
					</v-flex>
					<v-flex xs12 md6>
						<pui-number-field
							:label="$t('asignacion.kilosasig')"
							v-model="model.pesoasignado"
							required
							decimals="0"
							max="8000000"
							:disabled="formDisabled"
							:id="'pesottrans' + this.indice"
							suffix="Kg"
							labelColumnStyles="xs12 md4"
							valueColumnStyles="xs12 md4"
						></pui-number-field>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
		<v-flex xs1 md1 class="pointer" @click="removeTransportista()" v-show="indice > 0 && !formDisabled">
			<v-list-item>
				<v-icon small class="trashIcon">fa fa-trash-alt</v-icon>
			</v-list-item>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {};
	},
	computed: {},
	watch: {},
	methods: {
		// override
		afterGetData() {},
		removeTransportista() {
			this.parentlist.splice(this.indice, 1);
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		parentlist: {
			type: Array,
			required: true
		},
		indice: {
			type: Number,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		fixedFilterTransportista: {
			type: Object,
			required: false
		}
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"asignacion.idtransportista": "Carrier",
		"asignacion.kilosasig": "Assigned weigth"
    },
    "es": {
        "asignacion.idtransportista": "Transportista",
		"asignacion.kilosasig": "Peso asignado"
    },
    "ca": {
        "asignacion.idtransportista": "Transportista",
		"asignacion.kilosasig": "Pes assignat"
    }
}
</i18n>
