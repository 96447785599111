<template>
	<div class="pui-usuariotransportistaformheader">
		<v-layout wrap>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('usuarioaplicacion.usr')" :value="getUser"></pui-field>
			</v-flex>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('usuarioaplicacion.name')" :value="getName"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'usuariotransportistaformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'usuariotransportista'
		};
	},
	computed: {
		getUser() {
			return this.model && this.model.usr ? this.model.usr : '';
		},
		getName() {
			return this.model && this.model.name ? this.model.name : '';
		}
	},
	methods: {
		extendFilter(filter) {
			filter.model = 'usuariotransportista';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"usuarioaplicacion.usr": "User",
		"usuarioaplicacion.name": "User Name"
	},
	"es": {
		"usuarioaplicacion.usr": "Usuario",
		"usuarioaplicacion.name": "Nombre del usuario"
	},
	"ca": {
		"usuarioaplicacion.usr": "Usuari",
		"usuarioaplicacion.name": "Nom"
	}
}
</i18n>
