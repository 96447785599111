<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtclibramientostrans.anularmotivo.title')"
			:modelName="modelName"
			:dialogName="dialogNameAnular"
			v-model="modalData"
			:showDialogProp="showDialogAnular"
			:onOk="onOkAnular"
			:widthDialog="1000"
			:componentHeaderName="'libramientotransformheader'"
		>
			<template slot="message">
				<v-layout class="justify-center">
					<v-flex xs12>
						<pui-select
							attach="codigo"
							:label="$t('vtclibramientostrans.anularmotivo')"
							clearable
							required
							v-model="modalData"
							modelName="vlupmotivoanulrech"
							:itemsToSelect="[{ codigo: modalData.codmotivoanulrechazo }]"
							:modelFormMapping="{ codigo: 'codmotivoanulrechazo' }"
							:itemValue="['codigo']"
							:itemText="(item) => `${item.descripcion}`"
							:fixedFilter="getEstadoLAFixedFilter"
							:order="{ descripcion: 'asc' }"
							labelColumnStyles="xs3"
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
/* eslint-disable */

export default {
	name: 'libramientotransmodals',
	data() {
		return {
			modalData: {},
			dialogNameAnular: 'anular',
			showDialogAnular: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameAnular}-${this.modelName}-show`, (data) => {
			this.showDialogAnular = true;
		});
	},
	methods: {
		onOkAnular(modalData) {
			let body = {
				idlibramiento: modalData.headerPk.idlibramiento,
				codanulacion: modalData.codmotivoanulrechazo
			};
			var urlController = '/libramiento/cancel';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('libramiento.anular');
			this.$puiRequests.patchRequest(
				urlController,
				body,
				(response) => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
					this.showDialogAnular = false;
				},
				(e) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
					this.showDialogAnular = false;
				}
			);
		},
		getEstadoLA() {
			return 'LA';
		},
		getEstadoLS() {
			return 'LS';
		}
	},
	computed: {
		getEstadoLAFixedFilter() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'indbaja', op: 'eq', data: '0' },
							{ field: 'indbaja', op: 'nu', data: null }
						]
					},
					{ groupOp: 'and', rules: [{ field: 'codestado', op: 'eq', data: this.getEstadoLA() }] }
				],
				groupOp: 'and',
				rules: []
			};
		}
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtclibramientostrans.anularmotivo":"Reason for cancellation",
		"vtclibramientostrans.anularmotivo.title":"Cancel riddance"
	},
	"es": {
		"vtclibramientostrans.anularmotivo":"Motivo de anulación",
		"vtclibramientostrans.anularmotivo.title":"Anular libramiento"
	},
	"ca": {
		"vtclibramientostrans.anularmotivo":"Motiu d'anulació",
		"vtclibramientostrans.anularmotivo.title":"Anul·lar lliurament"
	}
}
</i18n>
