<template>
	<div class="timeslotplanif puiformheader">
		<v-layout wrap>
			<v-flex xs12 md3>
				<pui-field :label="this.$t('timeslotplanif.fechainicio')" :value="getFechainicio"></pui-field>
			</v-flex>
			<v-flex xs12 md3>
				<pui-field :label="this.$t('timeslotplanif.fechafin')" :value="getFechafin"></pui-field>
			</v-flex>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('timeslotplanif.descpuntocarga')" :value="getPuntocarga"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'timeslotplanifformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'timeslotplanif'
		};
	},
	computed: {
		getFechainicio() {
			return this.model && this.model.fechainicio ? this.$dateTimeUtils.formatDate(this.model.fechainicio, this.$store.getters.dateFormat) : '';
		},
		getFechafin() {
			return this.model && this.model.fechafin ? this.$dateTimeUtils.formatDate(this.model.fechafin, this.$store.getters.dateFormat) : '';
		},
		getPuntocarga() {
			return this.model && this.model.descpuntocarga ? this.model.descpuntocarga : '';
		}
	},
	methods: {
		extendFilter(filter) {
			filter.model = 'timeslotplanif';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"timeslotplanif.descpuntocarga": "Loading point",
		"timeslotplanif.fechainicio": "Start date",
		"timeslotplanif.fechafin": "End date"
	},
	"es": {
		"timeslotplanif.descpuntocarga": "Punto carga",
		"timeslotplanif.fechainicio": "Fecha inicio",
		"timeslotplanif.fechafin": "Fecha fin"
	},
	"ca": {
		"timeslotplanif.descpuntocarga": "Punt càrrega",
		"timeslotplanif.fechainicio": "Data inici",
		"timeslotplanif.fechafin": "Data fi"

	}
}
</i18n>
