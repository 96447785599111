<template>
	<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */
import CamionActions from './CamionActions';

export default {
	name: 'camiongrid',
	data() {
		return {
			model: 'camion',
			actions: CamionActions.gridactions
		};
	}
};
</script>

<style lang="postcss" scoped></style>
