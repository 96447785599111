<template>
	<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
</template>

<script>
/* eslint-disable */
import MercanciaActions from './MercanciaActions';

export default {
	name: 'mercanciagrid',
	data() {
		return {
			model: 'mercancia',
			actions: MercanciaActions.gridactions
		};
	}
};
</script>

<style lang="postcss" scoped></style>

<i18n>
{
    "en": {
        "grid.mercancia.idmercancia": "Code",
        "grid.mercancia.nombre": "Name",
        "grid.mercancia.observaciones": "Comments",
		"grid.mercancia.fechacreacion":"Creation date"
    },
    "es": {
        "grid.mercancia.idmercancia": "Código",
        "grid.mercancia.nombre": "Nombre",
        "grid.mercancia.observaciones": "Observaciones",
		"grid.mercancia.fechacreacion":"Fecha creación"
    },
    "ca": {
        "grid.mercancia.idmercancia": "Codi",
        "grid.mercancia.nombre": "Nom",
        "grid.mercancia.observaciones": "Observacions",
		"grid.mercancia.fechacreacion":"Data creació"
    }
}
</i18n>
