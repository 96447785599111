import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en.json';
import es from './es.json';
import ca from './ca.json';
import enPui from 'pui9-translations/translations/en.json';
import esPui from 'pui9-translations/translations/es.json';
import caPui from 'pui9-translations/translations/ca.json';
import * as deepmerge from 'deepmerge';

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'es',
	fallbackLocale: 'es',
	silentFallbackWarn: true,
	messages: { en: deepmerge.all([enPui, en]), es: deepmerge.all([esPui, es]), ca: deepmerge.all([caPui, ca]) }
});
