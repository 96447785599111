<template>
	<div class="pui-form">
		<v-form class="pui-detaildetailencargoauditform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<encargo-form-base
				:model="model"
				:formDisabled="formDisabled"
				:modelLoaded="modelLoaded"
				tipoEncargo="T"
				:isCreatingElement="isCreatingElement"
				auditoria="A"
			></encargo-form-base>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import EncargoFormBase from '@/components/encargo/EncargoFormBase';

export default {
	name: 'detailencargoauditform',
	mixins: [PuiFormMethodsMixin],
	components: { EncargoFormBase },
	data() {
		return {
			modelName: 'encargoaudit'
		};
	}
};
</script>

<style scoped></style>

<i18n>
{
	"en": {
    },
    "es": {
    },
    "ca": {
	}
}
</i18n>
