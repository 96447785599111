<template>
	<div v-if="mounted">
		<loterecibido-modals :modelName="model"></loterecibido-modals>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import LoterecibidoActions from './LoterecibidoActions';
import LoterecibidoModals from './LoterecibidoModals';

export default {
	name: 'loterecibidogrid',
	mixins: [PuiGridDetailMixin],
	components: { LoterecibidoModals },
	data() {
		return {
			model: 'loterecibido',
			actions: LoterecibidoActions.gridactions,
			onlyReadData: false,
			mounted: false
		};
	},
	methods: {
		// Override
		extendFilter(filter) {
			if (this.masterDetail && this.parentModelName === 'encargoterm' && this.parentPkChildFk.extendFilter) {
				filter.rules.push(this.parentPkChildFk.extendFilter);
			}
		},
		isEditableRegistry() {
			return false;
		}
	},
	created() {
		// Esta carga en this.model se utiliza para cargar la información y disponer de ella en LoteRecibidoForm.vue
		if (this.masterDetail) {
			const thisModel = this.$store.getters.getModelByName(this.model);
			if (thisModel) {
				const parentModelPk = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
				thisModel.parentModelName = this.parentModelName;
				thisModel.parentModelPk = parentModelPk.pk;
			}
			if (this.showCreateBtn || this.showDeleteBtn) {
				this.onlyReadData = true;
			}
		}
		this.mounted = true;
	}
};
</script>

<i18n>
{
    "en": {
		"grid.loterecibido.idlote": "Lot code",
		"grid.loterecibido.idterminalcarga": "Charging terminal code",
		"grid.loterecibido.terminalcarga": "Charging terminal",
		"grid.loterecibido.pesolocalizado": "Located weight (kg)",
		"grid.loterecibido.observaciones": "Comments",
		"grid.loterecibido.fechacreacion":"Creation date"
    },
    "es": {
		"grid.loterecibido.idlote": "Código lote",
		"grid.loterecibido.idterminalcarga": "Código terminal de carga",
		"grid.loterecibido.terminalcarga": "Terminal de carga",
		"grid.loterecibido.pesolocalizado": "Peso localizado (kg)",
		"grid.loterecibido.observaciones": "Observaciones",
		"grid.loterecibido.fechacreacion":"Fecha creación"
	},
    "ca": {
		"grid.loterecibido.idlote": "Codi lot",
		"grid.loterecibido.idterminalcarga": "Codi terminal de càrrega",
		"grid.loterecibido.terminalcarga": "Terminal de càrrega",
		"grid.loterecibido.pesolocalizado": "Pes localitzat (kg)",
		"grid.loterecibido.observaciones": "Observacions",
		"grid.loterecibido.fechacreacion":"Data creació"
	}
}
</i18n>
