<template>
	<div>
		<pui-modal-dialog
			v-if="modals.disableUser.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.disableUser.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.disableUser.titleText"
			:dialogName="`date-${this.modelName}`"
			ref="date"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<usuarioaplicacionformheader
							v-if="parentModelPk && modelName === 'usuarioaplicacion'"
							:modelPk="parentModelPk"
						></usuarioaplicacionformheader>
						<usuariocompradorformheader
							v-if="parentModelPk && modelName === 'usuariocomprador'"
							:modelPk="parentModelPk"
						></usuariocompradorformheader>
						<usuarioterminalformheader
							v-if="parentModelPk && modelName === 'usuarioterminal'"
							:modelPk="parentModelPk"
						></usuarioterminalformheader>
						<usuariotransportistaformheader
							v-if="parentModelPk && modelName === 'usuariotransportista'"
							:modelPk="parentModelPk"
						></usuariotransportistaformheader>
					</pui-form-header>
					<v-layout class="justify-center">
						<v-flex sm8 v-show="false">
							<pui-date-field
								:label="modals.disableUser.labelText"
								v-model="modals.disableUser.fechaBaja"
								required
								toplabel
								attach="fechaBaja"
							></pui-date-field>
						</v-flex>
					</v-layout>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('vtcusuarioaplicacion.validate.disable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
		<pui-modal-dialog
			v-if="modals.enableUser.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.enableUser.disableOk"
			:okText="$t('form.accept')"
			:titleText="modals.enableUser.titleText"
			:dialogName="`confirm-${this.modelName}`"
			ref="confirm"
			:widthDialog="700"
		>
			<template slot="message">
				<v-form action>
					<pui-form-header showHeader>
						<usuarioaplicacionformheader
							v-if="parentModelPk && modelName === 'usuarioaplicacion'"
							:modelPk="parentModelPk"
						></usuarioaplicacionformheader>
						<usuariocompradorformheader
							v-if="parentModelPk && modelName === 'usuariocomprador'"
							:modelPk="parentModelPk"
						></usuariocompradorformheader>
						<usuarioterminalformheader
							v-if="parentModelPk && modelName === 'usuarioterminal'"
							:modelPk="parentModelPk"
						></usuarioterminalformheader>
						<usuariotransportistaformheader
							v-if="parentModelPk && modelName === 'usuariotransportista'"
							:modelPk="parentModelPk"
						></usuariotransportistaformheader>
					</pui-form-header>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12>
							<v-layout>
								<v-flex xs12 align-self-center>
									<span>{{ $t('vtcusuarioaplicacion.validate.enable') }}</span>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="this.$t('vtcusuarioaplicacion.resetpassword.title')"
			:modelName="modelName"
			:dialogName="dialogNameResetpassword"
			v-model="modalData"
			:showDialogProp="showDialogResetpassword"
			:onOk="onOkResetpassword"
			:widthDialog="800"
		>
			<template slot="message">
				<v-form class="mb-4 pb-4" ref="password_form">
					<pui-form-header showHeader>
						<usuarioaplicacionformheader
							v-if="parentModelPk && modelName === 'usuarioaplicacion'"
							:modelPk="parentModelPk"
						></usuarioaplicacionformheader>
						<usuariocompradorformheader
							v-if="parentModelPk && modelName === 'usuariocomprador'"
							:modelPk="parentModelPk"
						></usuariocompradorformheader>
						<usuarioterminalformheader
							v-if="parentModelPk && modelName === 'usuarioterminal'"
							:modelPk="parentModelPk"
						></usuarioterminalformheader>
						<usuariotransportistaformheader
							v-if="parentModelPk && modelName === 'usuariotransportista'"
							:modelPk="parentModelPk"
						></usuariotransportistaformheader>
					</pui-form-header>
					<v-layout wrap>
						<v-layout wrap class="pa-3">
							<v-flex xs12>
								<pui-text-field
									:label="$t('new')"
									v-model="newPassword"
									:rules="[rules.passwordRules]"
									realtime
									required
									noeditable
									:append-icon="hidePass ? 'visibility_off' : 'visibility'"
									:type="hidePass ? 'text' : 'password'"
									:autocomplete="'new-password'"
									@click:append="hidePass = !hidePass"
								></pui-text-field>
							</v-flex>
							<v-flex xs12>
								<pui-text-field
									:label="$t('confirm')"
									v-model="confirmPassword"
									:rules="[rules.equalsPassword]"
									required
									realtime
									noeditable
									:append-icon="hidePass1 ? 'visibility_off' : 'visibility'"
									:type="hidePass1 ? 'text' : 'password'"
									:autocomplete="'confirm-password'"
									@click:append="hidePass1 = !hidePass1"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
/* eslint-disable */
import UsuarioAplicacionPasswordDialog from './UsuarioAplicacionPasswordDialog';

export default {
	name: 'usuarioaplicacionmodals',
	components: {
		usuarioaplicacionpassworddialog: UsuarioAplicacionPasswordDialog
	},
	data() {
		return {
			modals: {
				disableUser: {
					disableOk: false,
					show: false,
					labelText: this.$t('vtcusuarioaplicacion.action.disableLabel'),
					//titleText: this.$t('vtcusuarioaplicacion.action.disableTitle'),
					fechaBaja: null,
					parentModelPk: null
				},
				enableUser: {
					disableOk: false,
					show: false,
					titleText: '',
					parentModelPk: null
				}
			},
			modalData: {},
			dialogNameResetpassword: 'resetpassword',
			showDialogResetpassword: true,
			newPassword: null,
			confirmPassword: null,
			hidePass: false,
			hidePass1: false,
			rules: {
				equalsPassword: (value) => value === this.newPassword || this.$t('password_not_equal'),
				passwordRules: (value) => this.validatePasswordRules(value) || this.$t('usuarioaplicacion.validate.pass')
			},
			modelDependencyName: 'puiuser',
			parentModelPk: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogNameResetpassword}-${this.modelName}-show`, (data) => {
			this.parentModelPk = {
				usr: data.usr
			};
			this.showDialogResetpassword = true;
		});
		this.subscribeToModalDialogEvent();
	},
	methods: {
		validatePasswordRules(v) {
			var re = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-zA-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-z])(?=.*[A-Z]).{8,})$/i;
			return re.test(v);
		},
		subscribeToModalDialogEvent() {
			// Modal Ask Date
			this.$puiEvents.$on(`pui-modalDialog-date-${this.modelName}-ok`, () => {
				if (this.modals.disableUser.fechaBaja) {
					this.setShowDate(false);
					this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName, this.modals.disableUser.fechaBaja);
				}
			});
			this.$puiEvents.$on(`pui-modalDialog-date-${this.modelName}-cancel`, () => {
				this.setShowDate(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-date-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					usr: data.usr
				};
				this.setShowDate(true);
				this.modals.disableUser.fechaBaja = this.$dateTimeUtils.getLocalDateNow();
			});
			// Modal Confirm
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-ok`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$emit(`pui-action-runAction-` + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-cancel`, () => {
				this.setShowConfirm(false);
				this.$puiEvents.$off(`pui-action-runAction-` + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-confirm-${this.modelName}-show`, (data) => {
				this.parentModelPk = {
					usr: data.usr
				};
				this.setShowConfirm(true);
			});
		},
		setShowDate(action) {
			this.modals.disableUser.show = action;
		},
		setShowConfirm(action) {
			this.modals.enableUser.show = action;
		},
		onOkResetpassword(modalData) {
			if (!this.$refs.password_form.validate()) {
				return;
			}
			let urlController = this.$store.getters.getModelByName(this.modelName).url.setPassword;
			const params = { usr: this.modalData.headerPk.usr, newPassword: this.newPassword };
			this.$puiRequests.getRequest(
				urlController,
				params,
				(response) => {
					this.showDialogResetpassword = false;
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				},
				(error) => {
					if (error.response && error.response.data) {
						let message = error.response.data.message;
						if (error.response.data.internalCode === 202) {
							message = this.$t('puiuserresetpassword.202');
							if (message == null || message === '') {
								message = error.response.data.message;
							}
						}
						this.$puiNotify.error(message);
					}
				}
			);
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-date-${this.modelName}-show`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-confirm-${this.modelName}-show`);
	}
};
</script>
<style lang="postcss" scoped></style>

<i18n>
{
	"en": {
		"vtcusuarioaplicacion.action.disableTitle": "Deactivate user",
		"vtcusuarioaplicacion.action.disableLabel": "Deactivate Date",
		"vtcusuarioaplicacion.validate.disable": "The user will be deactivated. Do you want to continue?",
		"vtcusuarioaplicacion.validate.enable": "The deactivated user will be activated. Do you want to continue?",
		"vtcusuarioaplicacion.resetpassword.title": "Reset password",
    	"new": "New password",
    	"confirm": "Confirm new password",
    	"password_are_equal": "The current and new passwords are equals",
		"password_not_equal": "Passwords do not match",
		"changePassword": "Change password",
		"puiuserresetpassword.202": ""
	},
	"es": {
		"vtcusuarioaplicacion.action.disableTitle": "Desactivar usuario",
		"vtcusuarioaplicacion.action.disableLabel": "Fecha desactivació",
		"vtcusuarioaplicacion.validate.disable": "Se desactivará el usuario. ¿Desea continuar?",
		"vtcusuarioaplicacion.validate.enable": "Se activará el usuario desactivado. ¿Desea continuar?",
		"vtcusuarioaplicacion.resetpassword.title": "Resetear contraseña",
    	"new": "Nueva contraseña",
    	"confirm": "Confirmar nueva contraseña",
    	"password_are_equal": "La contraseña actual y nueva no pueden ser iguales",
		"password_not_equal": "Las contraseñas no coinciden",
		"changePassword": "Cambiar contraseña",
		"puiuserresetpassword.202": ""
	},
	"ca": {
		"vtcusuarioaplicacion.action.disableTitle": "Desactivar usuario",
		"vtcusuarioaplicacion.action.disableLabel": "Data de desactivación",
		"vtcusuarioaplicacion.validate.enable": "Es desactivarà l'usuari. Desitja continuar?",
		"vtcusuarioaplicacion.validate.enable": "S' activarà l'usuari desactivat. Desitja continuar?",
		"vtcusuarioaplicacion.resetpassword.title": "Resetear contrasenya",
    	"new": "Nova",
    	"confirm": "Confirmar nova contrasenya",
    	"password_are_equal": "L'actual contrasenya i la nova no poden ser iguals",
		"password_not_equal": "Passwords coincideixen",
		"changePassword": "Canviar contrasenya",
		"puiuserresetpassword.202": ""
	}
}
</i18n>
