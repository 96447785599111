<template>
	<div class="pui-form" v-if="modelLoaded">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent>
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12>
					<v-layout wrap v-if="hideConsultaLup">
						<v-flex xs12>
							<pui-select
								id="idconsulta"
								:label="$t('detaillote.idconsulta')"
								attach="idconsulta"
								disabled
								v-model="model"
								modelName="vlupconsultas"
								:itemsToSelect="[{ idconsulta: model.idconsulta }]"
								:modelFormMapping="{ idconsulta: 'idconsulta' }"
								labelColumnStyles="xs12 sm4 md2 lg1"
								valueColumnStyles="xs12 sm6 md4"
								:itemValue="['idconsulta']"
								:itemText="item => `${item.pesosolicitado} Kg - ${item.referencia}`"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-select
								:label="$t('detaillote.idterminalorigen')"
								id="idterminaldestino"
								attach="idterminaldestino"
								disabled
								v-model="model"
								modelName="vlupterminales"
								:itemsToSelect="[{ idterminal: model.idterminaldestino }]"
								:modelFormMapping="{ idterminal: 'idterminaldestino' }"
								itemValue="idterminal"
								itemText="nombre"
								labelColumnStyles="xs12 sm4 md2 lg1"
								valueColumnStyles="xs12 sm6 md4"
								:order="{ nombre: 'asc' }"
								:key="'idterminaldestino_' + updateTerminal"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-select
								id="idpuntocarga"
								attach="idpuntocarga"
								:label="$t('detaillote.idpuntocarga')"
								clearable
								v-model="model"
								disabled
								modelName="vluppuntocarga"
								:itemsToSelect="[{ idpuntocarga: model.idpuntocarga }]"
								:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
								:itemValue="['idpuntocarga']"
								:itemText="item => `${item.codigo} - ${item.descripcion}`"
								labelColumnStyles="xs12 sm4 md2 lg1"
								valueColumnStyles="xs12 sm6 md4"
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-select
								:label="this.$t('detaillote.idterminaldestino')"
								id="idterminalorigen"
								attach="idterminalorigen"
								disabled
								v-model="model"
								modelName="vlupterminales"
								:itemsToSelect="[{ idterminal: model.idterminalorigen }]"
								:modelFormMapping="{ idterminal: 'idterminalorigen' }"
								itemValue="idterminal"
								itemText="nombre"
								labelColumnStyles="xs12 sm4 md2 lg1"
								valueColumnStyles="xs12 sm6 md4"
								:order="{ nombre: 'asc' }"
								:key="'idterminalorigen_' + updateTerminal"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-number-field
								:label="$t('detaillote.pesosolicitado')"
								v-model.number="model.peso"
								decimals="0"
								suffix="Kg"
								disabled
								labelColumnStyles="xs12 sm4 md2 lg1"
								valueColumnStyles="xs12 sm4 md1"
							></pui-number-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.observaciones"
								:label="$t('detaillote.observaciones')"
								maxlength="300"
								disabled
								labelColumnStyles="xs12 sm4 md2 lg1"
								valueColumnStyles="xs12 sm6 md4"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-form>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'detailloteform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'loteaudit',
			updateTerminal: 0,
			hideConsultaLup: true
		};
	},
	methods: {
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		}
	},
	created() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-idconsulta', newVal => {
			var lupaModel = newVal.model;

			this.model.idterminalorigen = lupaModel.idterminalorigen;
			this.model.idterminaldestino = lupaModel.idterminaldestino;

			this.updateTerminal++;
			this.hideConsultaLup = false;
		});
	}
};
</script>

<style scoped></style>

<i18n>
{
    "en": {
		"detaillote.idencargo": "Order",
		"detaillote.idconsulta": "Inquiry",
        "detaillote.idterminaldestino": "Destination terminal",
        "detaillote.idterminalorigen": "Origin terminal",
        "detaillote.idpuntocarga": "Loading point",
        "detaillote.pesosolicitado": "Weight (kg)",
		"detaillote.observaciones": "Inquiry state"
    },
    "es": {
		"detaillote.idencargo": "Encargo",
		"detaillote.idconsulta": "Consulta",
        "detaillote.idterminaldestino": "Terminal destino",
        "detaillote.idterminalorigen": "Terminal origen",
        "detaillote.idpuntocarga": "Punto de carga",
		"detaillote.pesosolicitado": "Peso (kg)",
        "detaillote.observaciones": "Observaciones"
    },
    "ca": {
		"detaillote.idencargo": "Encàrrec",
		"detaillote.idconsulta": "Consulta",
        "detaillote.idterminaldestino": "Terminal destí",
        "detaillote.idterminalorigen": "Terminal origen",
        "detaillote.idpuntocarga": "Punt de Càrrega",
        "detaillote.pesosolicitado": "Pes sol·licitat (kg)",
        "detaillote.observaciones": "Observacions"
    }
}
</i18n>
