<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="model"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			:modelColumnDefs="columnDefs"
			showFilterListBtn
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:isEditableRegistry="isEditableRegistry"
			:modelColumnDefs="columnDefs"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'asignacionauditgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'asignacionaudit',
			masterDetailColumnVisibles: {
				idasignacion: true,
				comprador: true,
				terminalgestora: true,
				contrato: true,
				aplicacion: true,
				fecharecogida: true,
				mercancia: true,
				peso: true,
				empresacreacion: true,
				fechacreacion: true,
				transportista: true,
				tlibramientos: true
			},
			columnDefs: {
				fecharecogida: {
					withoutHours: true
				}
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>

<style lang="postcss"></style>

<i18n>
{
    "en": {
		"grid.asignacionaudit.idasignacion":"Assignment code",
		"grid.asignacionaudit.idlote":"Lot code",
		"grid.asignacionaudit.idencargo":"Order code",
		"grid.asignacionaudit.idcomprador":"Buyer code",
		"grid.asignacionaudit.comprador":"Buyer",
		"grid.asignacionaudit.idterminalgestora":"Terminal code",
		"grid.asignacionaudit.terminalgestora":"Management terminal",
		"grid.asignacionaudit.contrato":"Contract number",
		"grid.asignacionaudit.aplicacion":"Aplication",
		"grid.asignacionaudit.fecharecogida":"Pick-up date",
		"grid.asignacionaudit.idmercancia":"Good code",
		"grid.asignacionaudit.mercancia":"Good",
		"grid.asignacionaudit.peso":"Weight (kg)",
		"grid.asignacionaudit.pesopendiente":"Weight to be allocated (kg)",
		"grid.asignacionaudit.libramientos":"Generated deliverance",
		"grid.asignacionaudit.idtransportista":"Carrier code",
		"grid.asignacionaudit.transportista":"Carrier",
		"grid.asignacionaudit.fechacreacion":"Creation date",
		"grid.asignacionaudit.empresacreacion":"Creation company",
		"grid.asignacionaudit.tlibramientos":"Generadted deliverance"
    },
    "es": {
        "grid.asignacionaudit.idasignacion":"Código asignación",
		"grid.asignacionaudit.idlote":"Código lote",
		"grid.asignacionaudit.idencargo":"Código encargo",
		"grid.asignacionaudit.idcomprador":"Código comprador",
		"grid.asignacionaudit.comprador":"Comprador",
		"grid.asignacionaudit.idterminalgestora":"Código terminal",
		"grid.asignacionaudit.terminalgestora":"Terminal gestora",
		"grid.asignacionaudit.contrato":"Número de contrato",
		"grid.asignacionaudit.aplicacion":"Aplicación",
		"grid.asignacionaudit.fecharecogida":"Fecha de recogida",
		"grid.asignacionaudit.idmercancia":"Código mercancía",
		"grid.asignacionaudit.mercancia":"Mercancía",
		"grid.asignacionaudit.peso":"Peso (kg)",
		"grid.asignacionaudit.pesopendiente":"Peso pendiente de asignar (kg)",
		"grid.asignacionaudit.libramientos":"Libramientos generados",
		"grid.asignacionaudit.idtransportista":"Código transportista",
		"grid.asignacionaudit.transportista":"Transportista",
		"grid.asignacionaudit.fechacreacion":"Fecha creación",
		"grid.asignacionaudit.empresacreacion":"Empresa creadora",
		"grid.asignacionaudit.tlibramientos":"Libramientos generados"

    },
    "ca": {
		"grid.asignacionaudit.idasignacion":"Codi assignació",
		"grid.asignacionaudit.idlote":"Codi lot",
		"grid.asignacionaudit.idencargo":"Codi encàrrec",
		"grid.asignacionaudit.idcomprador":"Codi comprador",
		"grid.asignacionaudit.comprador":"Comprador",
		"grid.asignacionaudit.idterminalgestora":"Codi terminal",
		"grid.asignacionaudit.terminalgestora":"Terminal gestora",
		"grid.asignacionaudit.contrato":"Número de contracte",
		"grid.asignacionaudit.aplicacion":"Aplicació",
		"grid.asignacionaudit.fecharecogida":"Data de recollida",
		"grid.asignacionaudit.idmercancia":"Codi mercaderia",
		"grid.asignacionaudit.mercancia":"Mercaderia",
		"grid.asignacionaudit.peso":"Pes (kg)",
		"grid.asignacionaudit.pesopendiente":"Pes pendent d'assignar (kg)",
		"grid.asignacionaudit.libramientos":"Lliuraments generats",
		"grid.asignacionaudit.idtransportista":"Codi transportista",
		"grid.asignacionaudit.transportista":"Transportista",
		"grid.asignacionaudit.fechacreacion":"Data creació",
		"grid.asignacionaudit.empresacreacion":"Empresa creadora",
		"grid.asignacionaudit.tlibramientos":"Lliuraments generats"
    }
}
</i18n>
