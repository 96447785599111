<template>
	<div>
		<pui-datatable :modelName="model" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'auditasignaciongrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'asignacionaudit',
			externalFilter: {
				groupOp: 'and',
				groups: [],
				rules: [{ field: 'type', op: 'eq', data: 'signin' }]
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return false;
		}
	}
};
</script>
