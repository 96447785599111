const reasignarCargaAction = {
	id: 'reasignarCargaAction',
	selectionType: 'single',
	label: 'asignaciontrans.reasignarcarga',
	functionality: 'REASIGNAR_CARGA',
	checkAvailability: function(asignacion) {
		if (asignacion[0] && asignacion[0].indlibramientos === '0') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-reasignarCarga-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);

			model.consultaPk = {};

			for (var index in model.columns) {
				const column = model.columns[index];
				if (column.isPk) {
					model.consultaPk[column.name] = registries[0][column.name];
				}
			}
			const pkStr = JSON.stringify(model.consultaPk);
			const pk64 = this.$puiUtils.utf8ToB64(pkStr);
			const params = {
				router: this.$router,
				url: model.name + '/reasignacion/' + pk64 + '/reasignacion/create/new/',
				params: null,
				query: {}
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		});
	}
};

const generarLibramientoAction = {
	id: 'generarLibramientoAction',
	selectionType: 'single',
	label: 'asignaciontrans.generarlibramiento',
	functionality: 'MTO_LIBRAMIENTO_TRANS',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		model.consultaPk = {};

		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				model.consultaPk[column.name] = registries[0][column.name];
			}
		}
		const pkStr = JSON.stringify(model.consultaPk);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);
		const params = {
			router: this.$router,
			url: model.name + '/libramiento/' + pk64 + '/libramiento/create/new/',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [reasignarCargaAction, generarLibramientoAction],
	formactions: [reasignarCargaAction, generarLibramientoAction]
};
