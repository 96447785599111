<template>
	<div class="documentacion puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-field :label="this.$t('documentacion.iddoc')" :value="getIddoc"></pui-field>
			</v-flex>
			<v-flex xs12 md8>
				<pui-field :label="this.$t('documentacion.nombre')" :value="getNombre"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'documentacionformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'documentacion'
		};
	},
	computed: {
		getIddoc() {
			return this.model && this.model.iddoc ? this.model.iddoc : '';
		},
		getNombre() {
			return this.model && this.model.nombre ? this.model.nombre : '';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
<i18n>
{
	"en": {
		"documentacion.iddoc":"Code",
		"documentacion.nombre":"Name"
	},
	"es": {
		"documentacion.iddoc":"Código",
		"documentacion.nombre":"Nombre"
	},
	"ca": {
		"documentacion.iddoc":"Codi",
		"documentacion.nombre":"Nom"
	}
}
</i18n>
