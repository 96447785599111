<template>
	<div class="pui-form" ref="timeslotpc">
		<pui-form-header :showHeader="showParentModelHeader || !isCreatingElement" v-if="modelLoaded">
			<punto-carga-form-header v-if="showParentModelHeader" :modelPk="parentModelPk"></punto-carga-form-header>
		</pui-form-header>
		<v-form class="pui-timeslotform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<timeslot-cab-form :model="model" :formDisabled="formDisabled" :modelLoaded="modelLoaded"></timeslot-cab-form>

			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="save(false)">
										{{ this.$t('form.save') }}
									</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import TimeslotCabForm from '../cabecera/TimeslotCabForm';
import PuntoCargaFormHeader from '../../puntocarga/PuntoCargaFormHeader';
import TimeslotPcActions from './TimeslotPcActions';

export default {
	name: 'timeslotpcform',
	mixins: [PuiFormMethodsMixin],
	components: {
		TimeslotCabForm,
		PuntoCargaFormHeader
	},
	data() {
		return {
			modelName: 'timeslotpc',
			actions: TimeslotPcActions.formactions,
			parentModelName: 'puntocarga',
			parentModelPk: null,
			showParentModelHeader: false
		};
	},
	mounted() {
		const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
		if (parentModel != null) {
			const pk = parentModel.pk;
			this.model.idpuntocarga = pk.idpuntocarga;
			this.showParentModelHeader = true;
		}
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	watch: {
		model(newVal) {
			if (newVal.idpuntocarga) {
				this.model.idpuntocarga = newVal.idpuntocarga;
				this.parentModelPk = {
					idpuntocarga: newVal.idpuntocarga
				};
				this.showParentModelHeader = true;
			}
		}
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"timeslot.idtimeslot": "Time slot identifier",
		"timeslot.fechainicio": "Date",
		"timeslot.fechafin": "End date",
		"timeslot.total": "Capacity",
		"timeslot.asignados": "Assigned",
		"timeslot.disponibles": "Avaible"
    },
    "es": {
        "timeslot.idtimeslot": "Identificador time slot",
		"timeslot.fechainicio": "Fecha",
		"timeslot.fechafin": "Fecha fin",
		"timeslot.total": "Capacidad",
		"timeslot.asignados": "Asignados",
		"timeslot.disponibles": "Disponibles"
    },
    "ca": {
        "timeslot.idtimeslot": "Identificador time slot",
		"timeslot.fechainicio": "Data",
		"timeslot.fechafin": "Data fi",
		"timeslot.total": "Capacidad",
		"timeslot.asignados": "Assignats",
		"timeslot.disponibles": "Disponibles"
    }
}
</i18n>
