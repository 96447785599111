<template>
	<pui-datatable v-if="!masterDetail" :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:actions="actions"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:parentModelName="parentModelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:isEditableRegistry="isEditableRegistry"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import HistoricoLibTermcActions from './HistoricoLibTermcActions';

export default {
	name: 'historicolibtermcgrid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			model: 'historicolibtermc',
			actions: HistoricoLibTermcActions.gridactions
		};
	},
	created() {
		if (this.masterDetail) {
			const parentModel = this.$store.getters.getModelByName(this.parentModelName);
			const thisModel = this.$store.getters.getModelByName(this.model);
			if (parentModel && thisModel) {
				const parentModelPk = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
				thisModel.parentModelName = this.parentModelName;
				thisModel.parentModelPk = parentModelPk.pk;
			}
		}
	},
	methods: {
		isEditableRegistry() {
			return false;
		}
	}
};
</script>

<i18n>
{
    "en": {
		"grid.historicolibtermc.idhistorico":"Code",
		"grid.historicolibtermc.idlibramiento":"Order code",
		"grid.historicolibtermc.usralta":"Performer code",
		"grid.historicolibtermc.actor":"Performer",
		"grid.historicolibtermc.idestadoanterior":"Previous state code",
		"grid.historicolibtermc.estadoanterior":"Previous state",
		"grid.historicolibtermc.idestadonuevo":"New state code",
		"grid.historicolibtermc.estadonuevo":"New state",
		"grid.historicolibtermc.lang":"Language",
		"grid.historicolibtermc.idaccion":"Action code",
		"grid.historicolibtermc.accion":"Action",
		"grid.historicolibtermc.idpuntocargaanterior":"Id. Previous loading point",
		"grid.historicolibtermc.puntocargaanterior":"Previous loading point",
		"grid.historicolibtermc.idpuntocarganuevo":"Id. New loading point",
		"grid.historicolibtermc.puntocarganuevo":"New loading point",
		"grid.historicolibtermc.fecha":"Date",
		"grid.historicolibtermc.fechacreacion":"Creation date"
    },
    "es": {
		"grid.historicolibtermc.idhistorico":"Código",
		"grid.historicolibtermc.idlibramiento":"Código encargo",
		"grid.historicolibtermc.usralta":"Código actor",
		"grid.historicolibtermc.actor":"Actor",
		"grid.historicolibtermc.idestadoanterior":"Código estado anterior",
		"grid.historicolibtermc.estadoanterior":"Estado anterior",
		"grid.historicolibtermc.idestadonuevo":"Código estado nuevo",
		"grid.historicolibtermc.estadonuevo":"Estado nuevo",
		"grid.historicolibtermc.lang":"Idioma",
		"grid.historicolibtermc.idaccion":"Código acción",
		"grid.historicolibtermc.accion":"Acción",
		"grid.historicolibtermc.idpuntocargaanterior":"Id. Punto de carga anterior",
		"grid.historicolibtermc.puntocargaanterior":"Punto de carga anterior",
		"grid.historicolibtermc.idpuntocarganuevo":"Id. Punto de carga nuevo",
		"grid.historicolibtermc.puntocarganuevo":"Punto de carga nuevo",
		"grid.historicolibtermc.fecha":"Fecha",
		"grid.historicolibtermc.fechacreacion":"Fecha creación"
	},
    "ca": {
		"grid.historicolibtermc.idhistorico":"Codi",
		"grid.historicolibtermc.idlibramiento":"Codi encàrrec",
		"grid.historicolibtermc.usralta":"Codi actor",
		"grid.historicolibtermc.actor":"Actor",
		"grid.historicolibtermc.idestadoanterior":"Codi estat anterior",
		"grid.historicolibtermc.estadoanterior":"Estat anterior",
		"grid.historicolibtermc.idestadonuevo":"Codi estat nou",
		"grid.historicolibtermc.estadonuevo":"Estat nou",
		"grid.historicolibtermc.lang":"Idioma",
		"grid.historicolibtermc.idaccion":"Codi acció",
		"grid.historicolibtermc.accion":"Acció",
		"grid.historicolibtermc.idpuntocargaanterior":"Id. Punt de càrrega anterior",
		"grid.historicolibtermc.puntocargaanterior":"Punt de càrrega anterior",
		"grid.historicolibtermc.idpuntocarganuevo":"Id. Punt de càrrega nou",
		"grid.historicolibtermc.puntocarganuevo":"Punt de càrrega nou",
		"grid.historicolibtermc.fecha":"Data",
		"grid.historicolibtermc.fechacreacion":"Data creació"
	}
}
</i18n>
