const validateAction = {
	id: 'validateAction',
	selectionType: 'single',
	label: 'encargo.validate',
	functionality: 'ENCARGO_VALIDAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && selected[0].codestado === 'ET') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		var dataRegistry = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		var idencargo = dataRegistry.idencargo;
		var urlController = model.url.prevalidate + '?idencargo=' + idencargo;
		var params = {};
		var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
		this.$puiRequests.patchRequest(
			urlController,
			params,
			response => {
				/*Validamos que*/
				const data = {};
				data.prevalidate = response.data;
				data.headerPk = objectPk;
				this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', data);
				this.$puiEvents.$on('pui-action-runAction-' + model.name, obsvalidacion => {
					this.$puiEvents.$off('pui-action-runAction-' + model.name);

					var urlController = model.url.validate;
					var params = { idencargo: idencargo, obsvalidacion: obsvalidacion };
					this.$puiRequests.patchRequest(
						urlController,
						params,
						() => {
							this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
							this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

							var message = this.$puiI18n.t('puiaction.notifySuccess');
							this.$puiNotify.success(message, title);
						},
						e => {
							var message = this.$puiI18n.t('puiaction.notifyError');
							if (e.response && e.response.data) {
								message = e.response.data.message;
							}
							this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
							this.$puiNotify.error(message, title);
						}
					);
				});
			},
			e => {
				var message = this.$puiI18n.t('puiaction.notifyError');
				if (e.response && e.response.data) {
					message = e.response.data.message;
				}
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				this.$puiNotify.error(message, title);
			}
		);
	}
};

const refuseAction = {
	id: 'refuseAction',
	selectionType: 'single',
	label: 'encargo.refuse',
	functionality: 'ENCARGO_RECHAZAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'ES' || selected[0].codestado === 'ET')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-refusemotivo-' + model.name + '-show', row);
	}
};

const generateInquiryAction = {
	id: 'generateInquiryAction',
	selectionType: 'single',
	label: 'encargo.generateInquiry',
	functionality: 'MTO_CONSULTA',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'ES' || selected[0].codestado === 'ET')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const childTarget = 'consultarealizada';
		const modelChild = this.$store.getters.getModelByName(childTarget);
		modelChild.parentModelPk = {};
		modelChild.parentModelName = model.name;

		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				modelChild.parentModelPk[column.name] = registries[0][column.name];
			}
		}
		const params = {
			router: this.$router,
			url: '/' + childTarget + '/create/new',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

const generateLotAction = {
	id: 'generateLotAction',
	selectionType: 'single',
	label: 'encargo.generateLot',
	functionality: 'MTO_LOTE',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'ES' || selected[0].codestado === 'ET')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const childTarget = 'lotegenerado';
		const modelChild = this.$store.getters.getModelByName(childTarget);
		modelChild.parentModelPk = {};
		modelChild.parentModelName = model.name;

		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				modelChild.parentModelPk[column.name] = registries[0][column.name];
			}
		}
		const params = {
			router: this.$router,
			url: '/' + childTarget + '/create/new',
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [validateAction, refuseAction, generateInquiryAction, generateLotAction],
	formactions: [validateAction, refuseAction, generateInquiryAction, generateLotAction]
};
