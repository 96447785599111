<template>
	<div class="lotegeneradodetalleform pui-form">
		<v-layout wrap>
			<v-flex xs12>
				<div v-for="(detalle, index) in datos.lotesadicionales" :key="index">
					<pui-field-set :title="$t('lotegeneradodetalle.title') + ' ' + (index + 1)">
						<v-flex xs12>
							<v-layout wrap v-show="isCreatingElement">
								<v-flex xs1>
									<v-list-item>
										<v-icon small class="trashIcon pointer" @click="removeDetalle(index)" v-show="!formDisabled"
											>fa fa-trash-alt</v-icon
										>
									</v-list-item>
								</v-flex>
							</v-layout>
							<v-layout wrap v-show="dinamycForm.fromGrid" v-if="!dinamycForm.showLoadingPoint && !dinamycForm.autoInsertFromOrder">
								<v-flex xs12>
									<pui-select
										:id="'idterminal' + index"
										:label="$t('lotegeneradodetalle.idterminal')"
										attach="idterminal"
										clearable
										:disabled="formDisabled"
										v-model="datos.lotesadicionales[index]"
										modelName="vlupterminales"
										:itemsToSelect="[{ idterminal: detalle.idterminal }]"
										:modelFormMapping="{ idterminal: 'idterminal' }"
										itemValue="idterminal"
										itemText="nombre"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:order="{ nombre: 'asc' }"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="dinamycForm.showLoadingPoint || dinamycForm.autoInsertFromOrder">
								<v-flex xs12>
									<pui-select
										:id="'idpuntocarga' + index"
										attach="idpuntocarga"
										:label="$t('lotegeneradodetalle.idpuntocarga')"
										clearable
										v-model="datos.lotesadicionales[index]"
										required
										:disabled="formDisabled"
										modelName="vluppuntocarga"
										:itemsToSelect="[{ idpuntocarga: detalle.idpuntocarga }]"
										:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
										:itemValue="['idpuntocarga']"
										:itemText="(item) => `${item.codigo} - ${item.descripcion}`"
										filterId="idterminal"
										:filterMap="{ idterminal: datos.idterminal }"
										:fixedFilter="fixedFilterPuntoCarga"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
										:order="{ descripcion: 'asc' }"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-number-field
										:label="$t('lotegeneradodetalle.peso')"
										v-model.number="detalle.peso"
										decimals="0"
										minvalue="0"
										maxvalue="9999999"
										suffix="Kg"
										required
										:disabled="formDisabled"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md2 lg2"
									></pui-number-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-text-area
										v-model="detalle.observaciones"
										:label="$t('lotegeneradodetalle.observaciones')"
										maxlength="300"
										clearable
										:disabled="formDisabled"
										labelColumnStyles="xs12 sm4 md2 lg1"
										valueColumnStyles="xs12 sm6 md4"
									></pui-text-area>
								</v-flex>
							</v-layout>
						</v-flex>
					</pui-field-set>
				</div>
			</v-flex>
			<v-flex xs12 v-show="isCreatingElement">
				<v-btn depressed color="secondary" class="elevation-0" @click.native="addDetalle()">{{
					this.$t('lotegeneradodetalle.addDetalle')
				}}</v-btn>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	name: 'lotegeneradodetalle',
	data() {
		return {};
	},
	props: {
		formDisabled: {
			type: Boolean,
			required: true
		},
		datos: {
			type: Object,
			required: true
		},
		dinamycForm: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		fixedFilterPuntoCarga: {
			type: Object,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	mounted() {
		this.getInitial();
	},
	methods: {
		removeDetalle(index) {
			this.datos.lotesadicionales.splice(index, 1);
			if (this.datos.lotesadicionales.length === 0) {
				this.addDetalle();
			}
		},
		addDetalle() {
			if (this.dinamycForm.showLoadingPoint || this.dinamycForm.autoInsertFromOrder) {
				this.datos.lotesadicionales.push({
					idterminal: this.datos.idterminal,
					idpuntocarga: this.datos.idpuntocarga,
					peso: this.datos.peso,
					observaciones: this.datos.observaciones
				});
			} else {
				this.datos.lotesadicionales.push({});
			}
		},
		getInitial() {
			if (this.datos.lotesadicionales.length === 0) {
				this.addDetalle();
			}
		}
	}
};
</script>
<style lang="postcss">
.lotegeneradodetalleform {
	overflow-y: auto;
}
.delSpace {
	display: none !important;
}
</style>

<i18n>
{
    "en" : {
		"lotegeneradodetalle.title":"Lot",
		"lotegeneradodetalle.addDetalle": "+",
		"lotegeneradodetalle.idterminal": "Loading terminal",
		"lotegeneradodetalle.idpuntocarga": "Loading point",
		"lotegeneradodetalle.peso": "Weight (kg)",
		"lotegeneradodetalle.observaciones": "Observacions"
    },
    "es": {
		"lotegeneradodetalle.title":"Lote",
		"lotegeneradodetalle.addDetalle": "+",
		"lotegeneradodetalle.idterminal": "Terminal carga",
		"lotegeneradodetalle.idpuntocarga": "Punto de carga",
		"lotegeneradodetalle.peso": "Peso (kg)",
		"lotegeneradodetalle.observaciones": "Observaciones"
    },
    "ca": {
        "lotegeneradodetalle.title":"Lot",
		"lotegeneradodetalle.addDetalle": "+",
		"lotegeneradodetalle.idterminal": "Terminal càrrega",
		"lotegeneradodetalle.idpuntocarga": "Punt de càrrega",
		"lotegeneradodetalle.peso": "Peso (kg)",
		"lotegeneradodetalle.observaciones": "Observacions"
    }
}
</i18n>
