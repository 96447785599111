const disablePuntoCargaAction = {
	id: 'disablePuntoCargaAction',
	selectionType: 'single',
	label: 'puntocarga.disable',
	functionality: 'MTO_PUNTO_CARGA',
	checkAvailability: function(registro) {
		if (registro[0] && registro[0].fecbaja === null) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.fecbaja = this.$dateTimeUtils.getLocalDateNow();
		this.$puiEvents.$emit('pui-modalDialogForm-disable-' + model.name + '-show', row);
	}
};

const enablePuntoCargaAction = {
	id: 'enablePuntoCargaAction',
	selectionType: 'single',
	label: 'puntocarga.enable',
	functionality: 'MTO_PUNTO_CARGA',
	checkAvailability: function(registro) {
		if (registro[0] && registro[0].fecbaja !== null) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		this.$puiEvents.$emit('pui-modalDialog-confirm-' + model.name + '-show', registries[0]);
		this.$puiEvents.$on('pui-action-runAction-' + model.name, () => {
			this.$puiEvents.$off('pui-action-runAction-' + model.name);
			var dataRegistry = registries[0];
			var idpuntocarga = dataRegistry.idpuntocarga;
			var urlController = model.url.enable + '?idpuntocarga=' + idpuntocarga;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);
			var params = {};
			this.$puiRequests.patchRequest(
				urlController,
				params,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-showFormLoading-${model.name}`);
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);

					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
				}
			);
		});
	}
};

const deletePTSAction = {
	id: 'detetePTSAction',
	selectionType: 'single',
	label: 'puntocarga.deletePTSAction',
	functionality: 'MTO_TIMESLOT',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		if (registries.length === 1) {
			row.headerPk = objectPk;
		}
		row.isAction = true;
		row.registries = registries;
		row.fechainicio = this.$dateTimeUtils.getLocalDateNow();
		row.fechafin = null;
		this.$puiEvents.$emit('pui-modalDialogForm-delete-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [disablePuntoCargaAction, deletePTSAction, enablePuntoCargaAction],
	formactions: [disablePuntoCargaAction, deletePTSAction, enablePuntoCargaAction]
};
