<template>
	<div>
		<usuario-aplicacion-modals :modelName="model"></usuario-aplicacion-modals>
		<pui-datatable
			:modelName="model"
			:actions="actions"
			:modelColumnDefs="columnDefs"
			:isEditableRegistry="isEditableRegistry"
			:showDeleteBtn="false"
			showFilterListBtn
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import UsuarioAplicacionActions from './UsuarioAplicacionActions';
import UsuarioAplicacionModals from './UsuarioAplicacionModals';

export default {
	name: 'usuarioaplicaciongrid',
	components: {
		UsuarioAplicacionModals
	},
	data() {
		return {
			model: 'usuarioaplicacion',
			actions: UsuarioAplicacionActions.gridactions,
			columnDefs: {
				disableddate: { withoutHours: true }
			}
		};
	},
	methods: {
		isEditableRegistry(registry) {
			return registry.disabled !== 1;
		}
	}
};
</script>

<style scoped></style>

<i18n>
{
	"en": {
		"usuarioaplicacion" :"Users",
		"grid.usuarioaplicacion.usr": "User",
		"grid.usuarioaplicacion.name": "Name",
		"grid.usuarioaplicacion.email": "Email",
		"grid.usuarioaplicacion.disableddate": "Disabled Date",
		"grid.usuarioaplicacion.disabledmask": "Disabled",
		"grid.usuarioaplicacion.language": "Language",
		"grid.usuarioaplicacion.idtipousuario": "User type code",
		"grid.usuarioaplicacion.tipousuario": "User type",
		"grid.usuarioaplicacion.idterminal": "Terminal code",
		"grid.usuarioaplicacion.terminal": "Terminal",
		"grid.usuarioaplicacion.cifterminal": "Terminal tax identifier",
		"grid.usuarioaplicacion.idcomprador": "Buyer code",
		"grid.usuarioaplicacion.comprador": "Buyer name",
		"grid.usuarioaplicacion.cifcomprador": "Buyer tax identifier",
		"grid.usuarioaplicacion.idtransportista": "Freight forwarder code",
		"grid.usuarioaplicacion.transportista": "Freight forwarder",
		"grid.usuarioaplicacion.ciftransportista": "Freight forwarder tax identifier",
		"grid.usuarioaplicacion.nifconductor": "Tax Identifier Driver",
		"grid.usuarioaplicacion.matriconductor": "Driver's license plate",
		"grid.usuarioaplicacion.remolqueconductor": "Driving trailer",
		"grid.usuarioaplicacion.movilconductor": "Driving mobile",
		"grid.usuarioaplicacion.fechacreacion":"Creation date",
		"grid.usuarioaplicacion.usuariocreacion":"Creation user"
	},
	"es": {
		"usuarioaplicacion" :"Usuarios",
		"grid.usuarioaplicacion.usr": "Usuario",
		"grid.usuarioaplicacion.name": "Nombre",
		"grid.usuarioaplicacion.email": "Email",
		"grid.usuarioaplicacion.disableddate": "Fecha desactivación",
		"grid.usuarioaplicacion.disabledmask": "Activo",
		"grid.usuarioaplicacion.language": "Idioma",
		"grid.usuarioaplicacion.idtipousuario": "Código tipo usuario",
		"grid.usuarioaplicacion.tipousuario": "Tipo usuario",
		"grid.usuarioaplicacion.idterminal": "Código terminal",
		"grid.usuarioaplicacion.terminal": "Terminal",
		"grid.usuarioaplicacion.cifterminal": "Cif terminal",
		"grid.usuarioaplicacion.idcomprador": "Código comprador",
		"grid.usuarioaplicacion.comprador": "Comprador",
		"grid.usuarioaplicacion.cifcomprador": "Cif comprador",
		"grid.usuarioaplicacion.idtransportista": "Código transportista",
		"grid.usuarioaplicacion.transportista": "Transportista",
		"grid.usuarioaplicacion.ciftransportista": "Cif transportista",
		"grid.usuarioaplicacion.nifconductor": "Identificador fiscal conductor",
		"grid.usuarioaplicacion.matriconductor": "Matrícula conductor",
		"grid.usuarioaplicacion.remolqueconductor": "Remolque conductor",
		"grid.usuarioaplicacion.movilconductor": "Móvil conductor",
		"grid.usuarioaplicacion.fechacreacion":"Fecha creación",
		"grid.usuarioaplicacion.usuariocreacion":"Usuario alta"

	},
	"ca": {
		"usuarioaplicacion" :"Usuaris",
		"grid.usuarioaplicacion.usr": "Usuari",
		"grid.usuarioaplicacion.name": "Nom",
		"grid.usuarioaplicacion.email": "Email",
		"grid.usuarioaplicacion.disableddate": "Data Desactivació",
		"grid.usuarioaplicacion.disabledmask": "Actiu",
		"grid.usuarioaplicacion.language": "Idioma",
		"grid.usuarioaplicacion.idtipousuario": "Codi tipo usuari",
		"grid.usuarioaplicacion.tipousuario": "Tipo usuari",
		"grid.usuarioaplicacion.idterminal": "Codi terminal",
		"grid.usuarioaplicacion.terminal": "Terminal",
		"grid.usuarioaplicacion.cifterminal": "Cif terminal",
		"grid.usuarioaplicacion.idcomprador": "Codi comprador",
		"grid.usuarioaplicacion.comprador": "Comprador",
		"grid.usuarioaplicacion.cifcomprador": "Cif comprador",
		"grid.usuarioaplicacion.idtransportista": "Codi transportista",
		"grid.usuarioaplicacion.transportista": "Transportista",
		"grid.usuarioaplicacion.ciftransportista": "Cif transportista",
		"grid.usuarioaplicacion.nifconductor": "Identificador fiscal conductor",
		"grid.usuarioaplicacion.matriconductor": "Matrícula conductor",
		"grid.usuarioaplicacion.remolqueconductor": "Remolc conductor",
		"grid.usuarioaplicacion.movilconductor": "Mobil conductor",
		"grid.usuarioaplicacion.fechacreacion":"Data creació",
		"grid.usuarioaplicacion.usuariocreacion":"Usuario alta"
	}
}
</i18n>
