<template>
	<div class="pui-form">
		<v-layout>
			<v-flex xs12>
				<v-layout wrap>
					<v-flex xs12>
						<pui-select
							id="idtransportista"
							attach="idtransportista"
							:label="$t('asignacion.idtransportista')"
							:disabled="formDisabled"
							v-model="model"
							modelName="vluptransportistas"
							:itemsToSelect="[{ idtransportista: model.idtransportista }]"
							:modelFormMapping="{ idtransportista: 'idtransportista' }"
							:itemValue="['idtransportista']"
							:itemText="(item) => `${item.nombre}`"
							:order="{ nombre: 'asc' }"
							labelColumnStyles="xs12 sm1"
							valueColumnStyles="xs12 sm4"
							:fixedFilter="getFiltroTransportista"
						></pui-select>
					</v-flex>
					<v-flex xs12>
						<pui-number-field
							id="pesoasignado"
							:label="$t('asignacion.pesoasignado')"
							v-model="model.pesoasignado"
							:disabled="formDisabled"
							suffix="Kg"
							labelColumnStyles="xs12 sm1"
							valueColumnStyles="xs12 sm2"
						></pui-number-field>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import AsignacionActions from './AsignacionActions';

export default {
	data() {
		return {
			actions: AsignacionActions.formactions,
			fixedFilterTransportista: {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'indbaja', op: 'eq', data: '0' }]
			},
			transportistaAllFixedFilter: {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'indbaja', op: 'eq', data: '0' },
					{ field: 'indbaja', op: 'eq', data: '1' }
				]
			}
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		method: {
			type: String,
			required: true
		}
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.model}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
	},
	destroyed() {
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.model}`);
	},
	computed: {
		getFiltroTransportista() {
			if (this.formDisabled) {
				return this.transportistaAllFixedFilter;
			} else {
				return this.transportistaFixedFilter;
			}
		}
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
        "asignacion.idtransportista": "Carrier",
		"asignacion.pesoasignado": "Assigned kg"
    },
    "es": {
        "asignacion.idtransportista": "Transportista",
		"asignacion.pesoasignado": "Kilos asignados"
    },
    "ca": {
        "asignacion.idtransportista": "Transportista",
		"asignacion.pesoasignado": "Quilos assignats"
    }
}
</i18n>
