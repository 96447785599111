const anularAction = {
	id: 'anularAction',
	selectionType: 'single',
	label: 'libramiento.anular',
	functionality: 'LIBRAMIENTO_ANULAR',
	checkAvailability: function(selected) {
		if (selected && selected[0] && (selected[0].codestado === 'LS' || selected[0].codestado === 'LT' || selected[0].codestado === 'LM')) {
			return true;
		} else {
			return false;
		}
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-anular-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [anularAction],
	formactions: [anularAction]
};
