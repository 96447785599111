<template>
	<div class="pui-form">
		<v-form class="pui-libramientoauditform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" :href="'#maintab'">{{ $t('libramientoaudit.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#historicolibtranstermc'" v-if="!isCreatingElement">{{ $t('libramientoaudit.historicos') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="`maintab`">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs10>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-text-field
										:label="$t('libramientoaudit.idlibramiento')"
										v-model="model.idlibramiento"
										required
										disabled
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs4 sm2"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-select
										id="idencargo"
										:label="$t('libramientoaudit.idencargo')"
										attach="idencargo"
										disabled
										v-model="model"
										modelName="vlupencargo"
										:itemsToSelect="[{ idencargo: model.idencargo }]"
										:modelFormMapping="{ idencargo: 'idencargo' }"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm7"
										:itemValue="['idencargo']"
										:itemText="(item) => `${getDateFormated(item.fecharecogida)} - ${item.mercancia} - ${item.contrato}`"
										:order="{ fecharecogida: 'asc' }"
										detailModelName="encargoaudit"
										detailComponentName="detailencargoauditform"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-select
										id="idlote"
										:label="$t('libramientoaudit.idlote')"
										attach="idlote"
										disabled
										v-model="model"
										modelName="vluplotes"
										:itemsToSelect="[{ idlote: model.idlote }]"
										:modelFormMapping="{ idlote: 'idlote' }"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm7"
										:itemValue="['idlote']"
										:itemText="(item) => `${item.puntocarga} - ${item.peso} Kg`"
										:order="{ idlote: 'asc' }"
										detailModelName="loteudit"
										detailComponentName="detailloteform"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout>
								<v-flex xs12 sm6>
									<pui-select
										id="idtransportista"
										attach="idtransportista"
										:label="$t('libramientoaudit.idtransportista')"
										disabled
										v-model="model"
										modelName="vluptransportistas"
										:itemsToSelect="[{ idtransportista: model.idtransportista }]"
										:modelFormMapping="{ idtransportista: 'idtransportista' }"
										:itemValue="['idtransportista']"
										:itemText="(item) => `${item.nombre}`"
										:key="'itrkey' + this.indice"
										:order="{ nombre: 'asc' }"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-select>
								</v-flex>
								<v-flex xs12 sm5 offset-sm1>
									<pui-select
										id="idconductor"
										attach="idconductor"
										:label="$t('libramientoaudit.conductor')"
										disabled
										v-model="model"
										modelName="vlupconductorlibramiento"
										:itemsToSelect="[{ usr: model.idconductor }]"
										:modelFormMapping="{ usr: 'idconductor' }"
										:itemValue="['usr']"
										:itemText="(item) => `${item.nombre} ${getMovilFormated(item.movilconductor)}`"
										:order="{ nombre: 'asc' }"
										labelColumnStyles="xs12 sm3"
										valueColumnStyles="xs12 sm8"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6 v-show="false">
									<pui-text-field
										id="matricula"
										:label="$t('libramientoaudit.matricula')"
										v-model="model.matricula"
										maxlength="20"
										disabled
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md6>
									<pui-select
										attach="idcamion"
										:label="$t('libramiento.idcamion')"
										clearable
										disabled
										v-model="model"
										required
										modelName="vlupcamion"
										:itemsToSelect="[{ idcamion: model.idcamion }]"
										:modelFormMapping="{ idcamion: 'idcamion' }"
										:itemValue="['idcamion']"
										:itemText="(item) => `${item.matricula} - ${formatNumber(item.capacidad)} Kg`"
										:id="'idcamion'"
										filterId="idtransportista"
										:filterMap="{ idtransportista: model.idtransportista }"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-select>
								</v-flex>
								<v-flex xs12 sm5 offset-sm1>
									<pui-text-field
										id="remolque"
										:label="$t('libramientoaudit.remolque')"
										v-model="model.remolque"
										maxlength="20"
										disabled
										labelColumnStyles="xs12 sm3"
										valueColumnStyles="xs12 sm8"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 md6>
									<pui-number-field
										id="pesosolicitado"
										:label="$t('libramientoaudit.pesosolicitado')"
										v-model="model.pesosolicitado"
										decimals="0"
										max="9999999"
										disabled
										suffix="Kg"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-number-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 md6>
									<pui-select
										id="idpuntocarga"
										attach="idpuntocarga"
										:label="$t('libramientoaudit.idpuntocarga')"
										v-model="model"
										disabled
										modelName="vluppuntocarga"
										:itemsToSelect="[{ idpuntocarga: model.idpuntocarga }]"
										:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
										:itemValue="['idpuntocarga']"
										:itemText="(item) => `${item.terminal} / ${item.codigo} - ${item.descripcion}`"
										:order="{ descripcion: 'asc' }"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-select
										id="idtimeslot"
										attach="idtimeslot"
										:label="$t('libramientoaudit.idtimeslot')"
										disabled
										v-model="model"
										modelName="vluptimeslotlibramiento"
										:itemsToSelect="[{ idtimeslot: model.idtimeslot }]"
										:modelFormMapping="{ idtimeslot: 'idtimeslot' }"
										:itemValue="['idtimeslot']"
										:itemText="
											(item) =>
												`${getDateFormated(item.fecharecogida)} ` +
												$t('libramientoaudit.de') +
												` ${item.horainicio} ` +
												$t('libramientoaudit.a') +
												` ${item.horafin}`
										"
										:order="{ horainicio: 'asc' }"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-select>
								</v-flex>
								<v-flex xs12 sm5 offset-sm1>
									<pui-text-field
										id="horaautorizacion"
										:label="$t('libramientoaudit.horaautorizacion')"
										v-model="model.horaautorizacion"
										maxlength="20"
										disabled
										labelColumnStyles="xs12 sm3"
										valueColumnStyles="xs12 sm2"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-text-area
										id="destinomercancia"
										:label="$t('libramientoaudit.destinomercancia')"
										v-model="model.destinomercancia"
										disabled
										required
										maxlength="300"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-text-area
										id="observaciones"
										:label="$t('libramientoaudit.observaciones')"
										v-model="model.observaciones"
										disabled
										maxlength="300"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										attach="idestado"
										id="idestado"
										:label="$t('libramientoaudit.idestado')"
										clearable
										:disabled="formDisabled"
										v-model="model"
										required
										modelName="vlupestados"
										:itemsToSelect="[{ idestado: model.idestado }]"
										:modelFormMapping="{ idestado: 'idestado' }"
										:itemValue="['idestado']"
										:itemText="(item) => `${item.descestado}`"
										:order="{ descestado: 'asc' }"
										labelColumnStyles="xs12 sm4 md2"
										valueColumnStyles="xs12 sm6 md4"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										id="idmotivoanulrechazo"
										attach="idmotivoanulrechazo"
										:label="$t('libramientoaudit.idmotivoanulrechazo')"
										disabled
										v-model="model"
										modelName="vlupmotivoanulrech"
										:itemsToSelect="[{ idmotivo: model.idmotivoanulrechazo }]"
										:modelFormMapping="{ idmotivo: 'idmotivoanulrechazo' }"
										:itemValue="['idmotivo']"
										:itemText="(item) => `${item.descripcion}`"
										:order="{ descripcion: 'asc' }"
										labelColumnStyles="xs12 sm4 md2"
										valueColumnStyles="xs12 sm6 md4"
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-text-area
										id="obsrechazo"
										:label="$t('libramientoaudit.obsrechazo')"
										v-model="model.obsrechazo"
										disabled
										maxlength="300"
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-date-field
										id="fecalta"
										:label="$t('libramientoaudit.fecalta')"
										v-model="model.fecalta"
										disabled
										timesecs
										labelColumnStyles="xs12 sm4"
										valueColumnStyles="xs12 sm8"
										attach="fecalta"
									></pui-date-field>
								</v-flex>
								<v-flex xs12 sm4>
									<pui-checkbox
										:label="$t('libramientoaudit.indpcmodificado')"
										v-model="model.indpcmodificado"
										true-value="1"
										false-value="0"
										disabled
										labelColumnStyles="xs12 sm2"
										valueColumnStyles="xs12 sm10"
									></pui-checkbox>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6>
									<pui-field-set :title="$t('libramientoaudit.section.infentrega')">
										<v-layout wrap>
											<v-flex xs12 sm12>
												<pui-date-field
													id="fecharecogida"
													:label="$t('libramientoaudit.fecharecogida')"
													v-model="model.fecharecogida"
													disabled
													timesecs
													labelColumnStyles="xs12 sm4"
													valueColumnStyles="xs12 sm8"
													attach="fecharecogida"
												></pui-date-field>
											</v-flex>
										</v-layout>
										<v-layout wrap>
											<v-flex xs12 sm12>
												<pui-text-area
													id="obsrecogida"
													:label="$t('libramientoaudit.obsrecogida')"
													v-model="model.obsrecogida"
													disabled
													maxlength="300"
													labelColumnStyles="xs12 sm4"
													valueColumnStyles="xs12 sm8"
												></pui-text-area>
											</v-flex>
										</v-layout>
									</pui-field-set>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="`historicolibtranstermc`" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="historicoauditgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idlibramiento: 'idlibramiento' }"
					></pui-master-detail>
					<!--
					<pui-master-detail
						componentName="historicolibtranstermcgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{idlibramiento:'idlibramiento'}"
					></pui-master-detail>
					-->
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4></v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'libramientoauditform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'libramientoaudit'
		};
	},
	methods: {
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		getMovilFormated(movil) {
			return movil === null ? '' : ' - ' + movil;
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		}
	}
};
</script>

<style></style>

<i18n>
{
    "en": {
		"libramientoaudit.idencargo": "Order",
		"libramientoaudit.idlote": "Lot",
		"libramientoaudit.idestado": "State",
		"libramientoaudit.idmotivoanulrechazo": "Reason for rejection/cancellation",
		"libramientoaudit.idtransportista": "Transportista",
		"libramientoaudit.conductor": "Driver",
		"libramientoaudit.matricula": "Plate",
		"libramientoaudit.remolque": "Remolque",
		"libramientoaudit.pesosolicitado": "Weight (Kg)",
		"libramientoaudit.indpcmodificado": "Modified load point",
		"libramientoaudit.idpuntocarga": "Terminal and loading point",
		"libramientoaudit.idtimeslot": "Time Slot for pick-up",
		"libramientoaudit.horaautorizacion": "Authorized Time",
		"libramientoaudit.observaciones": "Driver observations",
		"libramientoaudit.fecharecogida": "Pickup date",
		"libramientoaudit.obsrecogida": "Pickup observations",
		"libramientoaudit.fecalta": "Creation date",
		"libramientoaudit.section.infentrega": "Delivery Information",
		"libramientoaudit.de": "from",
		"libramientoaudit.a": "to",
		"libramientoaudit.maintab": "Riddance",
		"libramientoaudit.historicos": "Historical",
		"libramientoaudit.idlibramiento": "Code"
    },
    "es": {
		"libramientoaudit.idencargo": "Encargo",
		"libramientoaudit.idlote": "Lote",
		"libramientoaudit.idestado": "Estado",
		"libramientoaudit.idmotivoanulrechazo": "Motivo rechazo/anulación",
		"libramientoaudit.idtransportista": "Transportista",
		"libramientoaudit.conductor": "Conductor",
		"libramientoaudit.matricula": "Matrícula",
		"libramientoaudit.remolque": "Remolque",
		"libramientoaudit.pesosolicitado": "Peso (Kg)",
		"libramientoaudit.indpcmodificado": "Punto de carga modificado",
		"libramientoaudit.idpuntocarga": "Terminal y punto de carga",
		"libramientoaudit.idtimeslot": "Time Slot de recogida",
		"libramientoaudit.horaautorizacion": "Hora autorizada",
		"libramientoaudit.observaciones": "Observaciones del transportista",
		"libramientoaudit.fecharecogida": "Fecha de recogida",
		"libramientoaudit.obsrecogida": "Observaciones de recogida",
		"libramientoaudit.fecalta": "Fecha de creación",
		"libramientoaudit.section.infentrega": "Información entrega",
		"libramientoaudit.de": "de",
		"libramientoaudit.a": "a",

        "libramientoaudit.maintab": "Libramiento",
		"libramientoaudit.historicos": "Histórico",
		"libramientoaudit.idlibramiento": "Código libramiento"
    },
    "ca": {
		"libramientoaudit.idencargo": "Encàrrec",
		"libramientoaudit.idlote": "Lot",
		"libramientoaudit.idestado": "Estat",
		"libramientoaudit.idmotivoanulrechazo": "Motiu rebuig/anul·lació",
		"libramientoaudit.idtransportista": "Transportista",
		"libramientoaudit.conductor": "Conductor",
		"libramientoaudit.matricula": "Matrícula",
		"libramientoaudit.remolque": "Remolc",
		"libramientoaudit.pesosolicitado": "Pes (Kg)",
		"libramientoaudit.indpcmodificado": "Punt de càrrega modificat",
		"libramientoaudit.idpuntocarga": "Terminal i punt de càrrega",
		"libramientoaudit.idtimeslot": "Time Slot de recollida",
		"libramientoaudit.horaautorizacion": "hora autoritzada",
		"libramientoaudit.observaciones": "Observacions del transportista",
		"libramientoaudit.fecharecogida": "Data de recollida",
		"libramientoaudit.obsrecogida": "Observacions de recollida",
		"libramientoaudit.fecalta": "Data de creació",
		"libramientoaudit.section.infentrega": "Informació lliurament",
		"libramientoaudit.de": "de",
		"libramientoaudit.a": "a",
        "libramientoaudit.maintab": "Lliurament",
		"libramientoaudit.historicos": "Històric",
		"libramientoaudit.idlibramiento": "Codi lliurament"
    }
}
</i18n>
